/*
  HACK: stylů pro audio player v detailu nodu
 */

.audio-article-player.file .field-main-audio {
  margin-bottom: -5px;
}

.article-type--image .field-main-audio {
  margin-bottom: 1rem;

  @include breakpoint($breakpoint_desktop) {
    margin-left: 4.375rem;
  }

  .file-audio {
    margin: 0;
  }
}

.audio-article-player .sm2-bar-ui,
.article-type--image .sm2-bar-ui,
.asset-type-audio .sm2-bar-ui {
  .sm2-inline-element {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    min-width: 50px;
    min-height: 50px;
    height: 100%;
    padding: 0;
    margin: 0;

    .sm2-button-bd {
      position: relative;
      height: 50px;
      width: 50px;
      z-index: 10;

      .sm2-inline-button.play-pause {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        text-indent: -9999px;
      }
    }

    &.sm2-volume {
      z-index: 1;

      .sm2-button-bd {
        width: 100%;
      }

      .sm2-volume-control {
        text-indent: -9999px;
        float: left;
      }
    }

    .sm2-inline-button.next,
    .sm2-inline-button.previous {
      display: none;
    }

    .sm2-playlist-target a {
      display: none;
    }
  }
}

.audio-article-player .file-audio .playing,
.audio-article-player .file-audio .paused {
  .sm2-progress {
    top: -8px;

    @include breakpoint($breakpoint_desktop) {
      top: -34px;
    }

    .sm2-progress-bar {
      height: 0.7em;
    }
  }
}

.page-node .file-audio .sm2-bar-ui .sm2-extra-controls .sm2-button-element {
  display: none;
}

/* Serial player */
#a004p {

  .sm2-inline-element {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 0;
    margin: 0;

    .sm2-playlist {
      display: none;
    }

    .sm2-progress {
      
      top: 40px !important;
      left: 14px;

      @include breakpoint($breakpoint_desktop) {
        top: 22px !important;
        left: 30px;
      }
  
      .sm2-progress-bar {
        height: 0.7em;
      }

    }

    .sm2-button-bd {
      position: relative;
      height: 50px;
      width: 50px;
      z-index: 10;

      .sm2-inline-button.play-pause {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        text-indent: -9999px;
      }
    }
    
    &.sm2-volume {
      z-index: 1;

      padding-left: 35px;
      top: 36px;

      .sm2-button-bd {
        width: 100%;
      }

      .sm2-volume-control {
        text-indent: -9999px;
        float: left;
      }
    }

    .sm2-inline-button.next,
    .sm2-inline-button.previous {
      display: none;
    }

    .sm2-playlist-target a {
      display: none;
    }
    
  }

  .sm2-playlist-wrapper {
    padding-bottom: 1rem;
    ul li {
      .sm2-row {
        justify-content: space-between;
        a {
          padding: 0.5em 0.25em 0.5em 0.75em;
        }
        .audio-info-wrap {
          width: 5rem;
          align-items: center;
          position: static;
          .playlist-audio-length {
            position: static;
          }
        }
      }
    }
  }

  .sm2-bar-ui {
    .sm2-extra-controls {
      .sm2-button-element {
        display: none;
      }
    }
    .paused,
    .playing {
      .sm2-playlist {
        position: relative;
        height: 1.45em;
        line-height: 100%;

        .sm2-playlist-target {
          position: relative;
        }

        .sm2-playlist-bd {
          position: absolute;
        }
      }

      .sm2-progress {
        .sm2-progress-bar {
          height: 0.7em;
        }
      }
    }
  }
}

/* player uvnitř těla */
.page-node .wysiwyg-asset.asset-type-audio .file-audio,
.page-node .article-type--image .file-audio {
  .sm2-main-controls {
    padding: 20px 19px 20px 20px;
    display: flex;
    flex-wrap: wrap;

    .sm2-controls-wrapper {
      min-height: auto;
      margin: 0;
      padding: 0 0 0 1rem;
      display: flex;
      flex-direction: column;

      .sm2-progress {
        position: static;
        float: none;
        padding: 0 0 15px 0;
      }

      .sm2-bottom-controls {
        padding: 0 0 0 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .sm2-time-wrapper {
          position: static;
          height: 2rem;
        }

        .sm2-volume {
          position: static;
          float: none;
          margin: 0;
          height: 20px;

          .sm2-button-bd {
            width: 100%;

            .sm2-volume-control.volume-shade {
              position: absolute;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .sm2-inline-element {
    float: none;
  }

  .sm2-bar-ui .sm2-main-controls .sm2-time-wrapper {
    float: none;
    margin: 0;
    padding: 0;
  }

  .sm2-progress {
    @include breakpoint($breakpoint_desktop) {
      top: 0;
    }

    .sm2-progress-bar {
      height: 0.7em;
    }
  }
}
