//
// Styles - Node Type Short News
//



.node-type-short-news {

  h1.article-type {
	color: $color_text_accent;
	line-height: 1.2em;
  }

}
