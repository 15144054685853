//
// Box
//



.box {

	margin-top: 2em;

	@include breakpoint($breakpoint_desktop) {
		margin-top: 3em;
	}

	h2 {

		margin-top: 0.3334em;
		margin-bottom: 0.3334em;

		padding-left: 0.5em;

		text-transform: uppercase;
		@include adjust-font-size-to($fontsize_box-heading, 1.25);

		@include breakpoint($breakpoint_desktop) {

			margin-top: 0.6667em;
			margin-bottom: 0.6667em;

			@include adjust-font-size-to($fontsize_box-heading_desktop, 1.3334);
		}
	}

	// titulek boxu, nebo-li prvni h2 boxu
	h2:first-of-type {
		margin-top: 0;

		// h2: varinata h2--title-tag
		&.h2--title-tag {
			position: relative; // kvuli lince

			padding: 0;
			margin: 0;
			margin-bottom: 1em;

			color: $color_text_accent_inverted;

			font-size: 14px;
			line-height: 1.3;
			text-transform: none;

			overflow: hidden; // kvuli bg
		}

		// h2--title-tag: linka
		&.h2--title-tag::after {
			content: "";

			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;

			width: 100%;
			height: 1px;

			background-color: $color_divider;
		}

			// h2--title-tag: span
			&.h2--title-tag span {
				position: relative; // kvuli bg
				display: inline-block;

				padding: 0.25em 0.5em;
				padding-right: 0;

				background-color: #519fd7;
				color: $color_text_accent_inverted;

				z-index: 1;
			}

			// h2--title-tag: background
			&.h2--title-tag span::before {
				content: "";

				position: absolute;
				top: -50%;
				right: -5px;

				width: 100%;
				height: 200%;

				background-color: $color_decoration_main;

				transform: rotate(-15deg);
				z-index: -1;
			}
	}


	ul {
		list-style-image: none;
		li { list-style: none; }
	}

	.image {
		overflow: hidden;

		img {
			display: block;
			// tahle sranda je opustena
			//transition: transform 0.3s;
		}
		/*img:hover {
			transform: scale(1.1, 1.1);
		}*/
	}


	// pripad kdy je paragraph obalen cely odkazem (napriklad klikaci perexy)
	// bez dekorace
	a:hover > p {
		// hack s inline block nutny pro pretizeni rodicovske text-decorace ktera se nechova v css standardne
		// http://stackoverflow.com/questions/1823341/how-do-i-get-this-css-text-decoration-override-to-work
		display: inline-block;
		width: 100%;

		text-decoration: none;
		// zde by byl vyborny tenhle mixin, ale potrebuje specifikovat barvu
		// a color v css nema zadnou default / auto hodnotu a cimkoliv jinym by se prebila a ovlivnila
 		// @include link-style(auto, none, auto, none);
	}
}
