//
// B-052a Styles
//



$b-052a_item_padding_horizontal: 0.75em;
$b-052a_item_fontsize_mobile: 20px;
$b-052a_item_fontsize_desktop: 20px;
//$b-052a_item_background-arrow-size: 14px; // arrow size



.b-052a {
}

	.b-052a__title {
		// v plabu neni, ale v drupalu tato trida pritomna
	}

	.b-052a__list {

		padding: 0 1em;
	}

		.b-052a__list-item {

			//background: $color_background-1;
		}

			.b-052a__link {

				position: relative;

				display: block;
				width: 100%;
				box-sizing: border-box;

				padding: 0.8em $b-052a_item_padding_horizontal;
				//padding-right: calc(2 * #{$b-052a_item_padding_horizontal} + #{$b-052a_item_background-arrow-size}); // arrow mobile padding

				@include adjust-font-size-to($b-052a_item_fontsize_mobile);

				// arrow bg
				/*background-image: $file_icon_arrow_single_east_colored-decoration-main;
				background-repeat: no-repeat;
				background-position: right $b-052a_item_padding_horizontal center;
				background-position-x: right $b-052a_item_padding_horizontal; // IE safe positioning
				background-position-y: center;
				background-size: $b-052a_item_background-arrow-size $b-052a_item_background-arrow-size;*/

				@include breakpoint($breakpoint_desktop) {

					padding: 0.5em $b-052a_item_padding_horizontal;
					//padding-right: calc(2 * #{$b-052a_item_padding_horizontal} + #{$b-052a_item_background-arrow-size}); // arrow desktop padding

					@include adjust-font-size-to($b-052a_item_fontsize_desktop, 1.5);
				}
			}

			// oddelovaci pseudo linka...
			.b-052a__list-item > .b-052a__link:before {

				content: "";

				position: absolute;
				left: 0; // fallback pro calc, pokud nefunguje calc sirky, zafunguje 100% a musime zarovnat uplne vlevo)
				left: calc(#{$b-052a_item_padding_horizontal});
				bottom: 0;

				width: 100%; // fallback pro calc
				width: calc(100% - 2 * #{$b-052a_item_padding_horizontal});

				border-bottom: 1px solid $color_divider;
			}
			// ...neni na poslednim miste
			.b-052a__list-item:last-child > .b-052a__link:before {

				//content: initial; // nefunguje v IE
				display:none;
			}
