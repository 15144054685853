//
// B-050 Style
//



.b-050 {
}

	.b-050__list {
		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

		.b-050__list-item {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			margin-bottom: 1.5em;
			padding: 0;

			@include breakpoint($breakpoint_desktop) {
				// trik jak 3 itemy radku + jejich margin roztahnout na 100%
				// ale ne pokud jich neni na radek plny pocet
				flex-basis: 30%; // toto neni tretina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do tretin
				max-width: 32%; // (fallback pro no-calc pro nasledujici radek)
				max-width: calc(33.33% - 1.2em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 1.2em? protoze je to 1.8 * 2 / 3 viz (*)

				box-sizing: border-box;

				margin-right: 1.8em; // (*)
				margin-bottom: 2em;
			}
		}

			.b-050__block {
			}

			.b-050__block--description {
			}
				.b-050__image {
					position: relative;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-050__block--description h3 {
					margin-top: 0.5em;
					margin-bottom: 0.25em;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-050__block--description p {
					margin-top: 0.25em;
					margin-bottom: 0.5em;
					color: $color_text_paragraph;
				}


			.b-050__block--metainfo {
				display: flex;
				flex-direction: column;
			}

				.b-050__subblock {

					@include breakpoint($breakpoint_desktop) {
						// pokud je v desktopove velikosti tlacitko zalomeno na dalsi radek
						// tak je dobre mit grow definovany aby radek popisu i tlacitka
						// vyplnily celou sirku bloku
						flex-grow: 1;
					}
				}

				.b-050__subblock--metainfo-text {

					@include breakpoint($breakpoint_desktop) {

						padding-top: 0.25em;

						// pokud text vedle tlacitka, tak toto je vzduch mezi
						margin-right: 1em;
						// pokud text nad tlacitkem (wrap v desktop stavu), tak vzduch mezi
						padding-bottom: 0.5em;
					}
				}

					.b-050__metainfo-row {

						//@include adjust-font-size-to($fontsize_box_petit_main);
						@include adjust-font-size-to($fontsize_box_petit_main_desktop);

						@include breakpoint($breakpoint_desktop) {
							@include adjust-font-size-to($fontsize_box_petit_main_desktop);
						}
					}

					// nepouzite specificke tridy radku
					.b-050__metainfo-row--timespan {
					}

					.b-050__metainfo-row--scheduled {
					}

						.b-050__metainfo-row-label {
							color: $color_text_paragraph;
						}

						.b-050__metainfo-row-value {
							@include typography_font(bold);
						}


				.b-050__subblock--metainfo-button {
					margin-top: 1em;

					@include breakpoint($breakpoint_desktop) {
						// mobile-first reset
						margin-top: 0;
					}
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-050__subblock--metainfo-button .button-box-flat--readmore {
						//margin-top: 0.5em;
					}


		.b-050__list-item:last-child {
			margin-bottom: 0;

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 1.5em;
			}
		}

		.b-050__list-item:nth-child(3n+3) {

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}

			.b-050__block--podcast {
				vertical-align: middle;
			}

			.b-050__podcast-icon {
				display: block;
				float: left;
				a {
					display: block;
				}
			}

			.b-050__podcast-label {
				display: block;
				float: left;
				margin: .3em 0 0 .6em;
				font-size: .875em;
				a {
					color: $color_decoration_main;
				}
			}



// pokud je v aside ma pravidla mobilu
.l-grid--4-1,
.l-grid--4-9 {
	.b-050 {
		.b-050__list {

			// desktop s pravidly mobilu
			@include breakpoint($breakpoint_desktop) {
				display: flex;
				flex-direction: column;
			}

			.b-050__list-item {

				// desktop s pravidly mobilu
				@include breakpoint($breakpoint_desktop) {
					// reset puvodnich desktop hodnot pro:
					// trik jak 3 itemy radku + jejich margin roztahnout na 100%
					// ale ne pokud jich neni na radek plny pocet
					flex-basis: auto; // default
					flex-grow: 0; // default
					max-width: none; // default

					box-sizing: content-box; // default

					margin-right: 0;
					margin-bottom: 1.5em;
				}
			}

			.b-050__list-item:last-child {

				// desktop s pravidly mobilu
				@include breakpoint($breakpoint_desktop) {
					margin-bottom: 0;
				}
			}

				.b-050__block {
				}

				.b-050__block--description {
				}
					.b-050__image {
					}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-050__block--description h3 {
					}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-050__block--description p {
					}


				.b-050__block--metainfo {
				}

					.b-050__subblock {

						// desktop s pravidly mobilu
						@include breakpoint($breakpoint_desktop) {
							// reset puvodnich desktop hodnot pro:
							// pokud je v desktopove velikosti tlacitko zalomeno na dalsi radek
							// tak je dobre mit grow definovany aby radek popisu i tlacitka
							// vyplnily celou sirku bloku
							flex-grow: 0; // default
						}
					}

					.b-050__subblock--metainfo-text {

						// desktop s pravidly mobilu
						@include breakpoint($breakpoint_desktop) {
							padding-top: 0;

							// pokud text vedle tlacitka, tak toto je vzduch mezi
							margin-right: 0;
							// pokud text nad tlacitkem (wrap v desktop stavu), tak vzduch mezi
							padding-bottom: 0;
						}
					}

						.b-050__metainfo-row {

							// desktop s pravidly mobilu
							@include breakpoint($breakpoint_desktop) {
								//@include adjust-font-size-to($fontsize_box_petit_main);
							@include adjust-font-size-to($fontsize_box_petit_main_desktop);
							}
						}
						// nepouzite specificke tridy radku
							.b-050__metainfo-row--timespan {
						}

						.b-050__metainfo-row--scheduled {
						}

							.b-050__metainfo-row-label {
							}

							.b-050__metainfo-row-value {
							}


					.b-050__subblock--metainfo-button {

						// desktop s pravidly mobilu
						@include breakpoint($breakpoint_desktop) {
							// mobile-first reset
							margin-top: 1em;
						}
					}


		}
	}
}
