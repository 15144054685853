//
// Badges
//



.badge {
	display: inline-block;

	width: 1.5em;
	height: 1.5em;

	margin: 0.5em 0.5em 0 0;

	span {
		display: none;
	}

	//cursor: help;

	background-position: center;
	background-repeat: no-repeat;

	@include breakpoint($breakpoint_desktop) {
	}
}

.badge-gallery {
	background-image: $file_icon_badge_gallery;
}

.badge-audio {
	background-image: $file_icon_badge_audio;
}

.badge-video {
	background-image: $file_icon_badge_video;
}

.badge-facebook {
	background-image: $file_icon_badge_facebook;
}

.badge-x {
	background-image: $file_icon_badge_x;
}

.badge-youtube {
	background-image: $file_icon_badge_youtube;
}

.badge-instagram {
	background-image: $file_icon_badge_instagram;
}

.badge-photocounter {
	width: auto;

	background-image: $file_icon_gallery_colored-white;
	background-position: 100% center;

	padding-right: 2.5em;

	@include typography_font(medium);
	color: $color_text_accent_inverted;

	span {
		display: inline;
	}
}

.badge.badge-video-play {
	display: flex;
	align-items: center;

	width: auto;
	height: auto;

	margin: 0.75em 0.75em 0 0;

	padding: 2px 6px;

	@include typography_font(medium);
	font-size: 10px;
	line-height: 14px;

	color: $color_text_accent;
	background-color: $color_background-0_alpha-mid;
	border-radius: 3px;

	@include link-style($color_text_link, none, $color_text_link, none);
	//@include link-style($color_background-9, none, $color_background-9, none);

	span {
		display: inline;
		vertical-align: middle;
	}
	&:after {

		content: "";

		display: block;
		height: 14px;
		float: right;

		background-repeat: no-repeat;

		background-image: $file_icon_badge_video-play_colored-black;
		width: 14px;
	}
}

.badge-audio-play,
.badge-serial-play {
	display: flex;
	align-items: center;

	width: auto;
	height: auto;

	margin: 0.75em 0.75em 0 0;

	padding: 2px 6px;

	@include typography_font(medium);
	font-size: 10px;
	line-height: 14px;

	color: $color_text_accent;
	background-color: $color_background-0_alpha-mid;
	border-radius: 3px;

	@include link-style($color_text_link, none, $color_text_link, none);
	//@include link-style($color_background-9, none, $color_background-9, none);

	span {
		display: inline;
		vertical-align: middle;
	}
	&:after {
		content: "";

		display: block;
		height: 10px;

		margin-left: 5px;

		float: right;

		background-repeat: no-repeat;
	}
}
.badge-audio-play--inverted,
.badge-serial-play--inverted {
	color: $color_text_accent_inverted;
	background-color: $color_background-9_alpha-mid;

	@include link-style($color_text_link_inverted, none, $color_text_link_inverted, none);
}
.badge-audio-play {
	&:after {
		background-image: $file_icon_badge_audio-play_colored-black;
		//background-image: $file_icon_badge_audio-play_colored-1a1a1e;
		width: 9px;
	}
}
.badge-serial-play {
	&:after {
		background-image: $file_icon_badge_serial-play_colored-black;
		//background-image: $file_icon_badge_serial-play_colored-1a1a1e;
		width: 15px;
	}
}
.badge-audio-play--inverted {
	&:after {
		background-image: $file_icon_badge_audio-play_colored-white;
		width: 9px;
	}
}
.badge-serial-play--inverted {
	&:after {
		background-image: $file_icon_badge_serial-play_colored-white;
		width: 15px;
	}
}




// vizualni debug - plab only
.patternlab {

	// v atomech
	.badge-photocounter {
		background-image: $file_icon_gallery_colored-73737f;

		span {
			color: $color_text_paragraph;
		}

	}

	// v boxu
	.box .badge-photocounter {
		background-image: $file_icon_gallery_colored-white;

		span {
			color: $color_text_accent_inverted;
		}
	}
}
