//
// Rastova aplikace Otazky a.k.a SocApp - widget
//

$icon-path: "../images/icon/";

.app-otazky-widget {

	display: flex;
	flex-direction: column;

	#otazky-box-widget {

		// list
		.feed {

			@include breakpoint($breakpoint_desktop) {
				display: grid;
				grid-auto-flow: row; // může být nastaveno na row / column dle potřeby, címž se měni logika řazení příspěvků
				grid-template-columns: 1fr 1fr;
			}

			//item
			.message {

				margin-bottom: 1em;
				padding-bottom: 1em;
				padding-left: 1.5em;
				border-bottom: 1px dotted $color_divider;

				// IE
				min-height: 1px;

				@include breakpoint($breakpoint_desktop) {
					box-sizing: border-box;
					margin-right: 1.5em;
				}

				&.wf {
					background: url("#{$icon-path}ico-1-mail.svg") 0 2px no-repeat;
					background-size: 20px 20px;
				}

				&.sm {
					background: url("#{$icon-path}ico-2-message.svg") 0 2px no-repeat;
					background-size: 20px 20px;
				}

				&.tw {
					background: url("#{$icon-path}ico-3-twitter.svg") 0 2px no-repeat;
					background-size: 20px 20px;
				}

				&.fb {
					background: url("#{$icon-path}ico-4-facebook.svg") 0 2px no-repeat;
					background-size: 20px 20px;
				}

				.name {
					@include typography_font(medium);
				}

				.separator {
					padding: 0 0.5em;
				}

				.text {

				}

				// posledni item v prvnim sloupci neboli druhy odzadu
				&:nth-last-child(2) {
					@include breakpoint($breakpoint_desktop) {
						border: none;
					}
				}

				// posledni item
				&:last-child {
					border: none;
				}
			}
		}
	}

	.button-box-flat-small {

		display: inline-flex;

		@include breakpoint($breakpoint_desktop) {

			align-self: flex-end;
		}
	}

}
