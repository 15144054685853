//
// Styles - Node Type Profile
//



// B-005 - Kolegové, Další osobnosti

.node-type-profile .b-005 {

	.b-005__list-item {
		width: 50%;
	}
}

// jruz - Shame

.node-type-profile {

	// content wrapper - _node.scss
	.inner-content-wrapper {
		margin-top: 32px;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 48px;
		}
	}



	.content-1-1, .content-1-2, .content-1-3 {
		clear: both;
		overflow: hidden;
	}

	.l-grid--8-1 {
		clear: both;
	}

	.content-1-1 {
		//margin-top: 1em;

		@include breakpoint($breakpoint_desktop) {
			//margin-top: 3em;
		}
	}

	.page h1 {
		width: 100%;
		margin: 0;
		font-size: 26px;
		line-height: 1.2;

		color: $color_text_accent;

		@include breakpoint($breakpoint_desktop) {
			float: right;
			width: 54%;
			margin: -.1em 0 0 .4em;
			font-size: 36px;
		}
	}

	.field-term-positions,
	.field-employment-web {
		@include typography_font(medium);
		color: $color_text_paragraph;
		border-bottom: 1px solid $color_background-2;
		padding: 0 0 .5em 0;
		font-size: 14px;

		@include breakpoint($breakpoint_desktop) {
			float: right;
			width: 54%;
			margin-left: 1em;
			padding: .5em 0 1em;
			font-size: 16px;
		}
	}

	.field-image-primary {

		@include breakpoint($breakpoint_desktop) {
			//float: left; // float behav upd
			width: 40%;
			//margin: -2.3em 0 0 0; // float behav upd
		}
	}

	.img-metadata,
	.node-image-metadata--profile {
		font-size: $fontsize_h5_desktop; //14px

		@include breakpoint($breakpoint_desktop) {
			font-size: $base-font-size;
			//float: left;// float behav upd
			//clear: left;// float behav upd
			width: 40%;
		}
	}

	.field-about-brief,
	.field-about-briefly {
		@include typography_font(medium);
		padding: 1em 0;
		font-size: 18px;

		@include breakpoint($breakpoint_desktop) {
			float: right;
			width: 54%;
			margin-left: .8em;
			font-size: 20px;
			line-height: 1.5;
		}
	}

	.content-1-2 {
		position: relative;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 2em;
		}

		.field-motto, .body {
			font-size: $base-font-size;
			line-height: 1.6em;
			color: $color_text_paragraph;
			margin-left: 0;
			padding-left: 0;

			@include breakpoint($breakpoint_desktop) {
				font-size: 20px;
				margin-left: 70px;
				padding-left: 30px;
			}
		}

		p {
			margin: 0.5em 0;
		}

		p:first-child {
			margin-top: 0;
		}

	}

	.sidebar-1-1 {

		@include breakpoint($breakpoint_desktop) {
			margin-top: 15em;
		}
	}
}
