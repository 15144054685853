//
// B-031-v2 Styles
//

// vars
//$breakpoint_desktop: 0; //debug



.b-031a-v2 {
}

	.b-031a-v2__subsection {

		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;

			position: relative; // kvuli absolutnimu pozicovani gradientu

			overflow: hidden;
		}
	}

		.b-031a-v2__block--first {

			@include breakpoint($breakpoint_desktop) {
				flex-grow: 1;
			}
		}
		.b-031a-v2__block--first,
		.b-031a-v2__block--first:hover {
			@include breakpoint($breakpoint_desktop) {
				transition: min-width 250ms;
			}
		}
		// gradient
		/*.b-031a-v2__block--first:hover:before {
			display: none;
			@extend .bg-gradient-transparent-to-color-background-0;

			@include breakpoint($breakpoint_desktop) {
				content: '';

				position: absolute;
				right: 0;

				width: 25%;

				z-index: 2;
			}
		}*/

		.b-031a-v2__block--second {
			padding: 1em;

			// ciste debug default barva pozadi pro zobrazeni samostatneho atomu, pokud
			// nema rodicovsky obal, ktery jej kontextove obarvi tridou .bg-color-main
			background-color: $color_background-2;

			@include breakpoint($breakpoint_desktop) {
				display: flex;
				flex-direction: column;

				overflow: hidden;
			}

			.b-031a-v2--inverted & {
				background-color: $color_background-8;
			}
		}

			// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
			.b-031a-v2__block--second > h3 {

				margin: 0;

				// TODO
				a {
					@include link-style($color_text_accent, none, $color_text_accent, underline);

				}

				.b-031a-v2--inverted & {
					a {
						@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, underline);
					}
				}
			}

			// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
			.b-031a-v2__block--second > a:not(.button) {

				@include link-style($color_text_paragraph, none, $color_text_paragraph, underline);

				.b-031a-v2--inverted & {
					@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, underline);
				}

				// TODO
				p {

					display: inline-block;
					margin: 0.25em 0 0 0;

					@include breakpoint($breakpoint_desktop) {
						margin: 0.25em 0 1em 0;

						font-size: 15px; // default je 16px
					}
				}
			}

			// clearfix
			.b-031a-v2__block--second > a:after {
				clear: both;
				content: "";
				display: table;
			}

			// readmore
			.b-031a-v2 .button-box-flat--readmore {
				margin-top: 1em;

				@include breakpoint($breakpoint_desktop) {
					//display: inline-flex;
					align-self: flex-start;

					margin-top: auto;
					//margin-right: auto;
				}
			}

			// pro sychr kdyby byl vlozen button s neinverzni tridou
			.b-031a-v2--inverted.b-031a-v2 .button-box-flat--readmore {
				@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, underline);
			}

			// pro sychr kdyby byl vlozen button s neinverzni tridou
			.b-031a-v2--inverted.b-031a-v2 .button-box-flat--readmore {
				background-color: transparent;
				border: 1px solid $color_text_link_inverted;
			}

				.b-031a-v2 .button-box-flat--readmore .button-box-flat__label {
					border-right-color: $color_divider;

					@include breakpoint($breakpoint_desktop) {
						padding: 0.25em 0.5em;
					}
				}

				// pro sychr kdyby byl vlozen button s neinverzni tridou
				.b-031a-v2--inverted .button-box-flat--readmore .button-box-flat__label {
					border-right-color: $color_text_link_inverted;
				}


			.b-031a-v2 .button-box-flat--readmore .button-box-flat__icon--arrow-double-east {
				background-image: $file_icon_arrow_double_east_colored-decoration-main;
			}

			.b-031a-v2--inverted .button-box-flat--readmore .button-box-flat__icon--arrow-double-east {
				background-image: $file_icon_arrow_double_east_colored-white;
			}

			// platnost
			.b-031a-v2__subsection .caption {
				margin-bottom: 0.5em;
			}

				.b-031a-v2__subsection .caption .caption__icon {

					background-image: $file_icon_sandtimer_colored-white;
				}

				.b-031a-v2__subsection .caption .caption__label {
					color: $color_text_accent_inverted;
				}

			// playitko
			.b-031a-v2 .button-play {
				background-image: $file_button_play_colored-white;

				margin-top: 1em;

				.button__label {
					// zobrazeni popisku (stylovani ve vychozim stavu skryteho popisku je vlastnosti atomu)
					display: flex;
				}
			}

			.b-031a-v2 .button-play::after {
				background-image: $file_button_play_colored-white;
			}



//
// B-031-v2a Styles
//

//vars
$b-031a-v2a_block-second_padding: 1.25em;
$b-031a-v2a_height: 200px;
$b-031a-v2a_block-first_width: 300px;
$b-031a-v2a_block-second_width: 450px;
$b-031a-v2a_block-second_width-nopadding: calc(#{$b-031a-v2a_block-second_width} - (2 * #{$b-031a-v2a_block-second_padding}));
$b-031a-v2a_block-second_height-nopadding: calc(#{$b-031a-v2a_height} - (2 * #{$b-031a-v2a_block-second_padding}));
$b-031a-v2a_height: 200px;



.b-031a-v2a {
}

	.b-031a-v2a__subsection {
	}

		.b-031a-v2a__block--first {

			@include breakpoint($breakpoint_desktop) {
				min-width: 112.5px;
				max-width: $b-031a-v2a_block-first_width;
			}

			.b-031a-v2a__image--3x2 {
				@include breakpoint($breakpoint_desktop) {
					position: relative;
					height: $b-031a-v2a_height;
				}

				a {
					@include breakpoint($breakpoint_desktop) {
						position: absolute;
						left: 50%;
						transform: translate(-50%, 0);

						width: $b-031a-v2a_block-first_width; // drupal fix
					}
				}
			}
		}
		.b-031a-v2a__block--first:hover {
			@include breakpoint($breakpoint_desktop) {
				min-width: $b-031a-v2a_block-first_width;
			}
		}
		// gradient
		// TODO nadefinovat breakpoint kdy se ma zobrazovat
		/*.l-grid--8-1 .b-031a-v2a__block--first:hover:before {
			//@include breakpoint($breakpoint_desktop) {
			@media (max-width: 1239px) {
				display: block;
				height: $b-031a-v2a_height;
			}
		}*/


			// TODO k zamysleni: stridani formatu obrazku
			// otazka je je-li toto reseni spravne, pripadne jestli je to vubec v tomhle pripade potreba
			.b-031a-v2a__image--3x2 {

				display: none;

				@include breakpoint($breakpoint_desktop) {
					display: block;
				}
			}
			.b-031a-v2a__image--16x9 {

				display: block;

				@include breakpoint($breakpoint_desktop) {
					display: none;
				}
			}

				.b-031a-v2a__image--3x2 img {

					@include breakpoint($breakpoint_desktop) {
						width: auto;
						height: $b-031a-v2a_height !important; // important je fix proti lazyloadu
					}
				}


		.b-031a-v2a__block--second {
			@include breakpoint($breakpoint_desktop) {
				padding: $b-031a-v2a_block-second_padding;

				width: $b-031a-v2a_block-second_width-nopadding;
				min-width: $b-031a-v2a_block-second_width-nopadding;
				height: $b-031a-v2a_block-second_height-nopadding;

				box-sizing: content-box; // ie11
			}

		}



//
// B-031-v2b Styles
//

//vars
$b-031a-v2b_block-second_padding: 1.25em;
$b-031a-v2b_height: 170px;
$b-031a-v2b_block-first_width: 510px;
$b-031a-v2b_block-second_width: 660px;
$b-031a-v2b_block-second_width-nopadding: calc(#{$b-031a-v2b_block-second_width} - (2 * #{$b-031a-v2b_block-second_padding}));
$b-031a-v2b_block-second_height-nopadding: calc(#{$b-031a-v2b_height} - (2 * #{$b-031a-v2b_block-second_padding}));



.b-031a-v2b {
}

	.b-031a-v2b__subsection {
	}

		.b-031a-v2b__block--first {

			@include breakpoint($breakpoint_desktop) {
				min-width: 215px;
				max-width: $b-031a-v2b_block-first_width;
			}

			.b-031a-v2b__image--3x1 {
				@include breakpoint($breakpoint_desktop) {
					position: relative;
					height: $b-031a-v2b_height;
				}

				a {
					@include breakpoint($breakpoint_desktop) {
						position: absolute;
						left: 50%;
						transform: translate(-50%, 0);

						width: $b-031a-v2b_block-first_width; // drupal fix
					}
				}
			}
		}
		.b-031a-v2b__block--first:hover {
			@include breakpoint($breakpoint_desktop) {
				min-width: $b-031a-v2b_block-first_width;
			}
		}
		// gradient
		// TODO nadefinovat breakpoint kdy se ma zobrazovat
		/*.l-grid--12-1 .b-031a-v2b__block--first:hover:before {
			//@include breakpoint($breakpoint_desktop) {
			@media (max-width: 1249px) {
				display: block;
				height: $b-031a-v2b_height;
			}
		}*/

			.b-031a-v2b__image--3x1 {
			}

				.b-031a-v2b__image--3x1 img {

					@include breakpoint($breakpoint_desktop) {
						width: auto;
						height: $b-031a-v2b_height !important; // important je fix proti lazyloadu
					}
				}


		.b-031a-v2b__block--second {
			@include breakpoint($breakpoint_desktop) {
				padding: $b-031a-v2b_block-second_padding;

				width: $b-031a-v2b_block-second_width-nopadding;
				min-width: $b-031a-v2b_block-second_width-nopadding;
				height: $b-031a-v2b_block-second_height-nopadding;

				box-sizing: content-box; // ie11
			}

		}


	//
	// B-031-v2c Styles
	//

	//vars
	$b-031a-v2c_block-second_padding: 1.25em;
	$b-031a-v2c_height: 200px;
	$b-031a-v2c_block-first_width: 300px;
	$b-031a-v2c_block-second_width: 450px;
	$b-031a-v2c_block-second_width-nopadding: calc(#{$b-031a-v2c_block-second_width} - (2 * #{$b-031a-v2c_block-second_padding}));
	$b-031a-v2c_block-second_height-nopadding: calc(#{$b-031a-v2c_height} - (2 * #{$b-031a-v2c_block-second_padding}));
	$b-031a-v2c_height: 200px;



	.b-031a-v2c {
	}

		.b-031a-v2c__subsection {
		}

			.b-031a-v2c__block--first {

				@include breakpoint($breakpoint_desktop) {
					min-width: 112.5px;
					max-width: $b-031a-v2c_block-first_width;
				}

				.b-031a-v2c__image--3x2 {
					@include breakpoint($breakpoint_desktop) {
						position: relative;
						height: $b-031a-v2c_height;
					}

					a {
						@include breakpoint($breakpoint_desktop) {
							position: absolute;
							left: 50%;
							transform: translate(-50%, 0);

							width: $b-031a-v2c_block-first_width; // drupal fix
						}
					}
				}
			}
			.b-031a-v2c__block--first:hover {
				@include breakpoint($breakpoint_desktop) {
					//min-width: $b-031a-v2c_block-first_width; // u varianty C nechceme tenhle efekt kvuli povolenemu overflow u .b-031a-v2c__block-second, protoze by pak pretekalo
				}
			}
			// gradient
			// TODO nadefinovat breakpoint kdy se ma zobrazovat
			/*.l-grid--8-1 .b-031a-v2c__block--first:hover:before {
				//@include breakpoint($breakpoint_desktop) {
				@media (max-width: 1239px) {
					display: block;
					height: $b-031a-v2c_height;
				}
			}*/

				// TODO k zamysleni: stridani formatu obrazku
				// otazka je je-li toto reseni spravne, pripadne jestli je to vubec v tomhle pripade potreba
				.b-031a-v2c__image--3x2 {

					display: none;

					@include breakpoint($breakpoint_desktop) {
						display: block;
					}
				}
				.b-031a-v2c__image--16x9 {

					display: block;

					@include breakpoint($breakpoint_desktop) {
						display: none;
					}
				}

					.b-031a-v2c__image--3x2 img {

						@include breakpoint($breakpoint_desktop) {
							width: auto;
							height: $b-031a-v2c_height !important; // important je fix proti lazyloadu
						}
					}


			.b-031a-v2c__block--second {
				@include breakpoint($breakpoint_desktop) {
					padding: $b-031a-v2c_block-second_padding;

					width: $b-031a-v2c_block-second_width-nopadding;
					height: $b-031a-v2c_block-second_height-nopadding;

					box-sizing: content-box; // ie11
				}
			}


			// pro variantu boxu b-031a-v2c nechceme overflow hidden z duvodu zobrazeni podcastove bubliny, ktera presahuje rodicovsky box
			.b-031a-v2c .b-031a-v2__subsection {

				@include breakpoint($breakpoint_desktop) {
					overflow: visible;
				}
			}

			.b-031a-v2c .b-031a-v2__subsection .b-031a-v2__block.b-031a-v2__block--second.b-031a-v2c__block--second {

				@include breakpoint($breakpoint_desktop) {
					overflow: visible;
				}
			}

			// posun ikony podcast buttonu
			.b-031a-v2c .b-031a-v2__subsection .b-031a-v2__block.b-031a-v2__block--second.b-031a-v2c__block--second .b-019.b-019c {

				@include breakpoint($breakpoint_desktop) {
					margin-top: auto;
				}
			}

		// barva textace u podcast buttonu
		.b-031a-v2c .b-031a-v2__block.b-031a-v2__block--second.b-031a-v2c__block--second .b-019.b-019c .button-listaction:hover {
			text-decoration-color: $color_background-0;
		}
		.b-031a-v2c .b-031a-v2__block.b-031a-v2__block--second.b-031a-v2c__block--second .b-019.b-019c .button-listaction .button-listaction__label-nest {
			color: $color_background-0;
		}


// pokud je box b-031a-v2c v aside, ma pravidla mobilu
.l-grid--4-1,
.l-grid--4-9 {
	.b-031a-v2c {
		.b-031a-v2__subsection {
			overflow: visible;
			@include breakpoint($breakpoint_desktop) {
				flex-wrap: wrap;
			}
		}
	}
	.b-031a-v2c__block--first {
		@include breakpoint($breakpoint_desktop) {
			min-width: 100%;
			max-width: 100%;
		}
	}
	.b-031a-v2c__image--3x2 img {
		@include breakpoint($breakpoint_desktop) {
			height: auto !important;
		}
	}
	.b-031a-v2c__block--first .b-031a-v2c__image--3x2 {
		@include breakpoint($breakpoint_desktop) {
			height: auto;
		}
	}
	.b-031a-v2c__block--first .b-031a-v2c__image--3x2 a {
		@include breakpoint($breakpoint_desktop) {
			position: inherit;
			left: auto;
			width: auto;
		}
	}
	.b-031a-v2c__block--second {
		@include breakpoint($breakpoint_desktop) {
			min-width: auto;
			max-width: 100%;
			width: 100%;
			height: auto;
			overflow: visible;
		}
	}
}

//vars
$b-031a-v2d_block-second_padding: 1.25em;
$b-031a-v2d_height: 200px;
$b-031a-v2d_block-first_width: 300px;
$b-031a-v2d_block-second_width: 450px;
$b-031a-v2d_block-second_width-nopadding: calc(#{$b-031a-v2d_block-second_width} - (2 * #{$b-031a-v2d_block-second_padding}));
$b-031a-v2d_block-second_height-nopadding: calc(#{$b-031a-v2d_height} - (2 * #{$b-031a-v2d_block-second_padding}));
$b-031a-v2d_height: 200px;



.b-031a-v2d {
}

	.b-031a-v2d__subsection {
	}

		.b-031a-v2d__block--first {

			@include breakpoint($breakpoint_desktop) {
				min-width: 112.5px;
				max-width: $b-031a-v2d_block-first_width;
			}

			.b-031a-v2d__image--3x2 {
				@include breakpoint($breakpoint_desktop) {
					position: relative;
					height: $b-031a-v2d_height;
				}

				a {
					@include breakpoint($breakpoint_desktop) {
						position: absolute;
						left: 50%;
						transform: translate(-50%, 0);

						width: $b-031a-v2d_block-first_width; // drupal fix
					}
				}
			}
		}
		.b-031a-v2d__block--first:hover {
			@include breakpoint($breakpoint_desktop) {
				min-width: $b-031a-v2d_block-first_width;
			}
		}
		// gradient
		// TODO nadefinovat breakpoint kdy se ma zobrazovat
		/*.l-grid--8-1 .b-031a-v2d__block--first:hover:before {
			//@include breakpoint($breakpoint_desktop) {
			@media (max-width: 1239px) {
				display: block;
				height: $b-031a-v2d_height;
			}
		}*/


			// TODO k zamysleni: stridani formatu obrazku
			// otazka je je-li toto reseni spravne, pripadne jestli je to vubec v tomhle pripade potreba
			.b-031a-v2d__image--3x2 {

				display: none;

				@include breakpoint($breakpoint_desktop) {
					display: block;
				}
			}
			.b-031a-v2d__image--16x9 {

				display: block;

				@include breakpoint($breakpoint_desktop) {
					display: none;
				}
			}

				.b-031a-v2d__image--3x2 img {

					@include breakpoint($breakpoint_desktop) {
						width: auto;
						height: $b-031a-v2d_height !important; // important je fix proti lazyloadu
					}
				}


		.b-031a-v2d__block--second {
			@include breakpoint($breakpoint_desktop) {
				padding: $b-031a-v2d_block-second_padding;

				width: $b-031a-v2d_block-second_width-nopadding;
				min-width: $b-031a-v2d_block-second_width-nopadding;
				height: $b-031a-v2d_block-second_height-nopadding;

				box-sizing: content-box; // ie11
			}

		}