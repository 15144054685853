//
// Styles - Node Type Atlas
//



.node-type-atlas {

	// title
	/*h1.atlas-type--rostliny,
	h1.atlas-type--ptaci,
	h1.atlas-type--psi*/
	h1.article-type {
		//width: calc(100% - 2.75em); // kvuli soc. buttonu na mobilu

		margin: 1em 0;
		margin-bottom: 0.5em;

		line-height: 1.8rem;

		color: $color_text_accent;

	 	@include breakpoint($breakpoint_desktop) {
			width: 66.66667%; // viz .l-grid--8-1

			padding-right: 25px; // viz .l-grid--8-1

			line-height: 3rem;
		}

		&.article-type--image {
		 	@include breakpoint($breakpoint_desktop) {
				width: 100%;
			}
		}
	}



	// grid
	.l-grid--12-1.item-first {
	}

		// modified, show, top social buttons
		.top-1-0 {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			min-height: 64px; // kvuli soc. buttonu

			@include breakpoint($breakpoint_desktop) {
				display: block;
				flex-direction: initial; // reset
				justify-content: initial; // reset

				min-height: 0;
			}
		}


	// content wrapper - _node.scss
	.inner-content-wrapper {
	}

		// grid
		.l-grid--8-1 {
		}

			// modified, show, top social buttons
			.content-1-1 {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				min-height: 64px; // kvuli soc. buttonu

				@include breakpoint($breakpoint_desktop) {
					display: block;
					flex-direction: initial; // reset
					justify-content: initial; // reset

					min-height: 0;
				}
			}

		// meta
		.content-1-1 {

			// article date
			.field-modified {
				display: none; // nechceme
			}

			// show - v totmo pripade znasilneno na tridu a celed (nebo vedecke zarazeni)
			.field-entity-show {
				display: inline-block;

				width: calc(100% - 2.75em - 1.5em); // kvuli soc. buttonu na mobilu

				margin-bottom: 12px;

				color: $color_text_paragraph;

				a {
					@include link-style($color_decoration_main, none, $color_decoration_main, underline);
				}

				@include breakpoint($breakpoint_desktop) {
					display: inline-block;

					width: auto;

					//padding-left: 0.625em;
					margin-bottom: 16px;

					line-height: 1em;

					//border-left: 1px solid $color_background-4;
				}

				// label and separator
				.category-label,
				.category-separator {
					font-size: 13px;
				}
			}
		}

		// primary image/audio
			.content-1-2 {

				.file-audio {
					margin-left: 0;
				}
			}

			// vertical soc. buttons, perex, body
			.content-1-3 {
			}

			// main audio anchor, authors, bottom soc. buttons
			.content-1-4 {
			}

				// fotka autora - fallback
				.view-display-id-node_author_main.box {
					display: none; // opusteno
				}
				// jmeno autora - fallback
				.view-display-id-node_author_main_2.box {
					display: none; // opusteno
				}



				// expired audio
				.article-type--audio .audio-rights-expired {

					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}



		// grid - aside
		.l-grid--4-9 {
			@include breakpoint($breakpoint_desktop) {
				margin-top: 8em;
			}
		}

			// content boxes, ad
			.sidebar-1-1 {
			}

			// content boxes
			.sidebar-1-2 {
			}



	// atlas rostlin
	.page.atlas-type--rostliny {

		// meta
		/*.content-1-1 {

			// article date
			.field-modified {
				display: none; // nechceme
			}

			// show - v totmo pripade znasilneno na tridu a celed ;)
			.field-entity-show {
				padding-left: 0;
				border-left: none;

				// label and separator
				.category-label,
				.category-separator {
					font-size: 13px;
				}
			}
		}*/

		// primary image/audio
		.content-1-2 {

			.field-image-primary.no-main-audio {
				border: 6px solid $color_background-4;
				border-radius: 6px;

				width: 100%;

				margin: 0;
				//padding-top: 100%; // 1:1 Aspect Ratio
				padding-top: 66.66%; // 3:2 Aspect Ratio
				//padding-top: 75%; // 4:3 Aspect Ratio
				//padding-top: 62.5%; // 8:5 Aspect Ratio
				//padding-top: 56.25%; // 16:9 Aspect Ratio

				position: relative;

				@include breakpoint($breakpoint_desktop) {
					border: 8px solid $color_background-4;
					border-radius: 8px;

					padding-top: 56.25%; // 16:9 Aspect Ratio
				}

				img {
					display: block;
					margin: auto;

					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;

					width: auto;
					height: 100%;
				}
			}

		}

	}

}
