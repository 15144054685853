//
// Caption
//



$caption_font-size_mobile: 12px;
$caption_font-size_desktop: 13px;



.caption {

	// pripadne odsazeni specificky v situaci pouziti, netreba mit obecne
	//margin-top: 0.5em;
	//margin-bottom: 0.5em;

	display: flex;
	flex-direction: row;
	align-items: flex-start;

}

	.caption__icon {

		display: inline-block;
		width: 1em;
		height: 1em;

		background-position: left center;
		background-repeat: no-repeat;
	}

	.caption__label {

		margin: 0;
		padding-left: 0.5em;

		@include typography_font(regular);

		color: $color_text_paragraph;

		@include adjust-font-size-to($caption_font-size_mobile, 0.75);

		@include breakpoint($breakpoint_desktop) {

			@include adjust-font-size-to($caption_font-size_desktop, 0.75);
		}
	}



// specificke varianty takovychto informativnich popisku
// neBEMove pretezovani vnitrku at se neuclassujeme

.caption--info {

	.caption__icon {

		background-image: $file_icon_info;
		background-size: 0.85em 0.85em;
	}
}

.caption--available-till {

	.caption__icon {

		//background-image: $file_icon_sandtimer_colored-009645; // zelena
		background-image: $file_icon_sandtimer_colored-09e9ea9; // seda

		width: 0.75em; // presypacky nejsou ctverec
	}

	.caption__label {
		//color: $color_decoration_success; // zelena
		color: $color_background-5; // seda
	}
}

.caption--public-from,
.caption--public-after {

	.caption__icon {

		background-image: $file_icon_timer;
	}
}
