//
// B-008i Styles
//
// krome dlasich variant b008 blizka podobnost s nejakymi variantami b004
// ale primo odvozovano z nich neni
//



.b-008i {
}

	.b-008i__list {

		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}
	}

		.b-008i__list-item {

			margin-bottom: 2em;

			// IE
			min-height: 1px;

			@include breakpoint($breakpoint_desktop) {

				margin-right: 2em;
				margin-bottom: 0;

				flex-basis: 25%;

				// v desktop variante flexuje vlastni obsah tez
				// nutne pro zachovavani vysky polozky vzhledem k sousedni
				display: flex;
				flex-direction: column;
			}
		}
		.b-008i__list-item:last-child {

			margin-bottom: 0;

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}

			.b-008i__block--image {
				// IE
				min-height: 1px;
			}

				.b-008i__image {
					position: relative;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008i__image .tag {

						position: absolute;
						bottom: 0;
						left: 10px;

						max-inline-size: calc(100% - 20px);
					}


			.b-008i__block--description {

				padding: 0.5em;

				background-color: $color_background-2;


				@include breakpoint($breakpoint_desktop) {

					// pro desktop se snazi rust aby dorostl pripadne vetsiho souseda na radku
					flex-grow: 1;

					// a flexuje vlastni deti aby bylo uskutecneno odsazeni smerem dolu
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}
			}

				.b-008i__subblock--content {
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008i__subblock--content .caption {

						margin-top: 0.5em;
					}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008i__subblock--content h3 {

						margin: 0;
						padding-top: 0.5em;
					}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008i__subblock--content a p {

						margin-top: 0.5em;
						margin-bottom: 0.5em;

						color: $color_text_paragraph;
						@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
					}


				.b-008i__subblock--meta {

					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;

					padding-top: 0.5em;

					border-top: 1px solid $color_divider;
				}

					.b-008i__meta-wrapper {
					}

						.b-008i__meta-line {
							@include adjust-font-size-to($fontsize_box_petit_main);

							@include breakpoint($breakpoint_desktop) {
								@include adjust-font-size-to($fontsize_box_petit_main_desktop);
							}
						}

							.b-008i__meta-label {
								color: $color_text_paragraph;
							}

							.b-008i__meta-value {
								@include typography_font(medium);
							}


					.b-008i__button-play-wrapper {
						margin-left: 2em;
					}




	.b-008i__buttons-wrap {
		text-align: center;
	}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-008i__buttons-wrap .button-box-flat-small {

			display: inline-flex;

			margin-top: 3em;
		}
