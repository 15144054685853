//
// B-001d Styles
//
// markup tohoto boxu je stejny (velmi podobny) s b001a
// zmeny jsou na urovni stylu
// promenne jsou sdilene z b001a
//



$b-001d_item_padding_horizontal: 1em;

$b-001d_item_fontsize_mobile: 16px;
$b-001d_item_fontsize_desktop: 18px;

$b-001d_item_background-ico-size: 24px;



.b-001d {
}

	.b-001d__title {
		// v plabu neni, ale v drupalu tato trida pritomna
	}

	.b-001d__list {
		padding: 0.25em 0;

		background: $color_background-1;
	}

		.b-001d__list-item {
			position: relative; // kvuli bubline;

			//background: $color_background-1;
		}

			.b-001d__link {

				position: relative;

				display: block;
				width: 100%;
				box-sizing: border-box;

				padding: 0.8em $b-001d_item_padding_horizontal;
				padding-right: calc(2 * #{$b-001d_item_padding_horizontal} + #{$b-001d_item_background-ico-size});

				@include typography_font(medium);
				@include adjust-font-size-to($b-001d_item_fontsize_mobile);

				background-image: $file_icon_podcast_colored-main;
				background-repeat: no-repeat;
				background-position: right $b-001d_item_padding_horizontal center;
				background-position-x: right $b-001d_item_padding_horizontal; // IE safe positioning
				background-position-y: center;
				background-size: $b-001d_item_background-ico-size $b-001d_item_background-ico-size;

				@include breakpoint($breakpoint_desktop) {

					padding: 0.5em $b-001d_item_padding_horizontal;
					padding-right: calc(2 * #{$b-001d_item_padding_horizontal} + #{$b-001d_item_background-ico-size});

					@include adjust-font-size-to($b-001d_item_fontsize_desktop, 1.5);
				}
			}

			// oddelovaci pseudo linka...
			.b-001d__list-item > .b-001d__link:before {

				content: "";

				position: absolute;
				left: 0; // fallback pro calc, pokud nefunguje calc sirky, zafunguje 100% a musime zarovnat uplne vlevo)
				left: calc(#{$b-001d_item_padding_horizontal});
				bottom: 0;

				width: 100%; // fallback pro calc
				width: calc(100% - 2 * #{$b-001d_item_padding_horizontal});

				border-bottom: 1px solid $color_divider;
			}
			// ...neni na poslednim miste
			.b-001d__list-item:last-child > .b-001d__link:before {

				//content: initial; // nefunguje v IE
				display: none;
			}


		// bubble subscribe
		.b-001d__list-item .bubble-subscribe {
			position: absolute;
			top: 50%; // calc fallback
			top: calc(50% + 10px); // pulka + 10px sipka
			left: 2em; // calc fallback
			left: calc(50% - #{$bubble-subscribe-width} / 2); //promenna $bubble-subscribe-width je v _bubble.scss
			&:focus {
				outline: transparent;
			}
		}





// vizualni debug - plab only
.patternlab .b-001d {

	@include breakpoint($breakpoint_desktop) {
		// vizualni debug
		// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec (v desktop)
		max-width: 400px;
	}
}
