.discussion-wrapper-d {
	.loading {
		position: absolute;
		background: transparent url('https://aplikace.rozhlas.cz/rozhovory/sites/default/themes/rozhovory/img/ajax-loader.gif') no-repeat 0 0;
		width: 220px;
		height: 19px;
	}
	#cro-discussion div {
		line-height: 1.7;
	}
	#cro-discussion div,
	#cro-discussion textarea,
	#cro-discussion input {
		max-width: 100%;
	}
	#discussion-header, #discussion-content {
		margin-bottom: 20px;
		float: left;
		width: 100%;
		font-size: 13px;
		color: #555;
	}

	#discussion-content {
		overflow-y: auto;
		outline: 1px solid $color_background-4;
    .load-more {
      overflow-y: initial !important;
      max-height: initial !important;
    }
	}

	#discussion-header {
		.info {
			float: left;
			font-size: 14px;
			@include typography_font(medium);
			padding-left: 25px;
			color: #000;
			line-height: 30px;
			background: url('https://aplikace.rozhlas.cz/rozhovory/sites/default/themes/rozhovory/img/ico_info_blue.png') no-repeat 0 4px;
		}

		.message {
			float: left;
			clear: left;
			font-size: 11px;
			width: 100%;
		}

		.status {
			display: table-row;
		}

		iframe.form {
			padding-top: 10px;
			margin-top: 10px;
			border-top: 1px dotted #999;
			width: 100%;
      display: none;
		}
	}

	.question-data {
		float: left;
		width: 100%;
		clear: left;
	}

	#article .question .date {
		float: left;
		margin: 0px;
	}

	.question {
		padding: 10px 0px;
		padding-left: 10px;

		.text-wrapper {
			width: 100%;
			padding-right: 10px;
		}

		.title {
			@include typography_font(medium);
			padding-right: 10px;
			color: #000;
		}

		.name {
			margin-left: 5px;
			background: none;
			color: #000;
			font-size: 11px;
		}


	}

	.question, .answer {
		float: left;
		clear: both;
	}

	.answer {
		background: $color_background-2;
		padding: 10px 0px;

		.photo {
			display: table-cell;
			padding-right: 10px;
			padding-left: 10px;
			img {
				width: auto;
				max-width: none;
			}
		}

		.text {
			display: table-cell;
			vertical-align: top;
			width: 100%;
			padding-right: 10px;
		}

		.name {
			color: #000;
			display: block;
			@include typography_font(medium);
			padding-right: 5px;
		}

	}

	.answer .date {
		margin-left: 5px;
		background: none;
		color: #000;
		font-size: 11px;
	}

	#cro-discussion .button {
		margin-top: 10px;
		padding: 1em;
		border: 0;
		text-transform: uppercase;
		color: $color_text_accent_inverted;
		background-color: $color_decoration_success;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}
	}


	.button.dotaz {
		display: table;
		margin-bottom: 20px;
		margin-top: 20px;
	}
}
