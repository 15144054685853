//
// Shame - aneb kdyz nevim kam s tim..
//



//anonymous -  domain filteru ve views
.form-item-rozhlas-solr-domain-source { display: none; }

// jruz - RWD youtube
div.media-youtube-video {
	position: relative;
	padding-bottom: 56.25%;
	overflow: hidden;

	width: 100%;
	height: auto;
}

iframe.media-youtube-player {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

div.media-facebook-video iframe {
	display: block;
}

// jmar - Expired audio in a-004p
.a-004p .a-004b__list {
	padding-left: 0;
}

.a-004b__link--expired {
	position: relative;

	.a-004b__iterator {
		position: absolute;
		top: 1em;
		left: 1em;
	}

	.audio-rights-expired {
		margin: 0;
		//width: 99.6%;
		width: 100%;
		padding-left: 2.6em;
	}

}

// mvyb - ajax throbber
.ajax-progress.ajax-progress-throbber {
	display: none;
}



/* main */
.main {
	padding-top: 0.1px;
}



// editacni buttony u prihlasenych
// nakonec i u neprihlasenych, viz login screen
.logged-in .tabs.tabs--primary.links--inline,
.tabs.tabs--primary.links--inline {
	margin-top: 2em;
	margin-bottom: 2em;

	border: none;

	@include breakpoint($breakpoint_desktop) {
		width: 66.66667%;
	}

	a,
	a.active,
	a:hover {
		border: none;
	}
}

// pager
ul.pager {
	@include breakpoint($breakpoint_desktop) {
		margin-top: 2em;
	}
}


//
// mvla - reseni pretekajiciho pseudoelementu hlavniho menu v chrome
//
// prevzato z rakouskeho roku a zobecneno, snad s takhle zasadnim pravidlem nebudou potize, resp
// snad nebude nutne na strance nikdy horizontalne scrollovat. zatim jedine funkcni reseni omezeneho designu menu
//
// zaroven reseni pro druhy typ chyby, mobilni zarizeni overflow u body maji tendenci ignorovat
// je treba bud udelat vnitrni obalovaci div, nebo definovat i u tagu html, snad to bude stacit
// http://stackoverflow.com/questions/14270084/overflow-xhidden-doesnt-prevent-content-from-overflowing-in-mobile-browsers
//

html, body {
	overflow-x: hidden;
}
body {
	overflow-y: hidden;
}


// O stanici - Teploměr
.b-027 {
	font-size: em(13px);

	@include breakpoint($breakpoint_tablet) {
		font-size: $base-font-size;
	}

	.b-027__column-name {
		min-width: 54%;

		@include breakpoint($breakpoint_tablet) {
			min-width: 45%;
		}
	}

	.b-027__item-bar {
		min-width: 50%;

		@include breakpoint($breakpoint_tablet) {
			min-width: 40%;
		}
	}

}


// b-018 na strance poradu typu pouze podcast (ma v page tridu podcast)
.page.podcast {

	.b-018 {

		.b-018__timespan,
		.button-box-flat--readmore,
		.b-018__block--no-broadcast,
		.broadcast-times {
			display: none;
		}
	}
}





//
// mvla - plne zneni, vzdy dostatecna vyska pri malem obsahu aby se vesly oba elementy sidebaru
//
// zaveseno primo na grid, pretizeni veci z node-article, kde musi byt uklid tohoto zohlednen
//

// opusteno, protoze rusime duvod proc jsme to potrebovali (vertikalni podvivnosti v aside)
/*.node-type-article {

	.inner-content-wrapper {

		// main article body column
		.l-grid--8-1 {

			@include breakpoint($breakpoint_desktop) {

				min-height: 1200px;
			}
		}

		// sidebar column
		.l-grid--4-9 {

			.sidebar-1-1, .sidebar-1-2 {
				@include breakpoint($breakpoint_desktop) {

					height: auto;
				}
			}
		}
	}
}*/


// minimalni vyska clanku v desktop viewportu, fix aby se neusekavaly vertikalni soc buttony
.node-type-article {

	.inner-content-wrapper {

		// main article body column
		.l-grid--8-1 {

			@include breakpoint($breakpoint_desktop) {

				min-height: 785px;
			}
		}
	}
}


// logged-in
.logged-in .contextual-links-region {

	// clearfix
	&::after {
		clear: both;
		content: "";
		display: table;
	}
}

// pozor, melo negativni dopad na dalsi segmenty
.logged-in .l-grid--12-1 {
	overflow: visible;

	// HACK: resi margin collapsing po nastaveni overflow na visible
	padding-top: 0.1px;
	padding-bottom: 0.1px;
}

// serial content is moved to the right if it's not set like this
.logged-in.node-type-serial .l-grid--12-1,
.logged-in.node-type-serialpart .l-grid--12-1,
.logged-in.node-type-event .l-grid--12-1 {
	//overflow: hidden;
}

/* hotfix, nutno vyresit systemove */
.wysiwyg-asset .contextual-links-wrapper {
	display: none;
}



//
// H1 ve stanicni navigaci + jazykove mutace
//

/* barevna specifika */
.wave {
	.b-016__wrapper {
		h1:before,
		h2:before {
			background-color: $color_wave_main;
		}

		.b-016 {
			background-color: $color_wave_main;
		}
	}
}
.neviditelna {
	.b-016__wrapper {
		h1:before,
		h2:before {
			background-color: $color_radiozurnal_main;
		}

		.b-016 {
			background-color: $color_radiozurnal_main;
		}
	}
}
.digital {
	.b-016__wrapper {
		h1,
		h2 {
			background-color: $color_background-0;
		}
		h1:before,
		h2:before {
			background-color: $color_digital_main;
		}

		.b-016 {
			background-color: $color_digital_main;
		}
	}
}
.program {
	.b-016__wrapper {
		h1,
		h2 {
			background-color: $color_background-0;
			color: $color_text_accent;
		}
		h1:before,
		h2:before {
			background-color: $color_background-3;
		}

		.b-016 {
			background-color: $color_background-3;

			.b-016__list .b-016__list-item--more {
				> a {
					// ico hamburger
					//background-image: $file_icon_hamburger_colored-555561;

					&:hover,
					&.expanded {
						// ico hamburger
						//background-image: $file_icon_hamburger_colored-white;
					}
				}
			}
		}
	}
}

.b-016__wrapper {
	position: relative; // misto .b016__list

	display: flex;
	//justify-content: flex-end; // misto space-between, viz dite h1

	//margin-top: 8px; // 0.5em z 16px // opusteno, preneseno do .b-015

	h1,
	h2 {
		//flex-shrink: 0; // nakonec opoustime kvuli mob verzi

		position: relative;
		display: inline-block;
		overflow: hidden;

		margin-top: 0;
		//margin-right: auto; // zarovnani doleva vs justify-content: flex-end jeho rodice
		margin-bottom: 0;
		//margin-left: 0; // prebiji hodnotu pro h1 v node (.node-type-article .page h1)

		padding: 8px 10px;
		padding-right: 20px; // kvuli sikmemu efektu

		//width: auto; // prebiji hodnotu pro h1 v node (.node-type-article .page h1)
		max-width: 350px; // omezení proti extrémě dlouhým textům

		background-color: $color_background-4;
		color: $color_text_accent;

		@include typography_font(regular);
		font-size: 20px;
		line-height: 24px;
		white-space: nowrap;
		text-transform: unset; // prebiji hodnotu pro h2

		@include breakpoint($breakpoint_desktop) {
			padding: 8px 40px;

			font-size: 19px;
		}
	}

	h1:before,
	h2:before {
		position: absolute;
		top: -10px;
		right: -40px;

		transform: rotate(-15deg);

		display: block;
		content: '';

		width: 50px;
		height: 50px;

		background-color: $color_background-9;
	}

	h1 span,
	h2 span {
		position: relative;
		display: block;

		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		z-index: 1;
	}

	.b-016 {
		display: flex; // ie fix
		//justify-content: flex-end;
		flex-grow: 1;
		//flex: 1; // flex-grow: 1, flex-shrink: 1, flex-basis: 0% // nefunguje s ie

		margin-top: 0;
		margin-bottom: 0;

		.b-016__list {
			flex-grow: 1; // ie11 fix
			position: inherit; // prebiji hodnoty _b-016.scss, tady chceme nejblizsiho relativne pozicovanyho parenta az .b-016__wrapper

			height: $b-016_menu-bar-height_mobile;

			@include breakpoint($breakpoint_desktop) {
				height: $b-016_menu-bar-height_desktop;
			}
		}

		.b-016__list--localization {
			flex-grow: 0;

			@include breakpoint($breakpoint_desktop) {
				// odsazeni vsech polozek menu z leva
				// pro desktop primo ovlivneno vztahem sirky page > main
				//padding-left: $layout_main_horizontal-padding_desktop - $b-016_menu-item-horizontal-padding_desktop;
				padding-left: 0;
				padding-right: $layout_main_horizontal-padding_desktop - $b-016_menu-item-horizontal-padding_desktop;
			}
		}

			// link - lokalizace
			.b-016__link--localization {
				display: flex;
				align-items: center;

				height: 100%;

				@include breakpoint($breakpoint_desktop) {
					font-size: $fontsize_menu_main_localization_desktop;
					//@include typography_font(medium);
					text-transform: uppercase;
				}
			}

				// label itemu
				.b-016__link-label {
					display: none;

					@include breakpoint($breakpoint_desktop) {
						display: inline;
						margin-right: 10px;
					}
				}

				// ikona itemu
				.b-016__link-emblem {
				}

				// ikona itemu - jazyk
				.b-016__link-emblem--localization {
					background-repeat: no-repeat;
					background-size: 100% 100%; // kvuli deformaci

					width: 20px;
					height: 15px;

					@include breakpoint($breakpoint_desktop) {
						width: 30px;
						height: 20px;
					}
				}
				.b-016__list-item--czech .b-016__link-emblem--localization {
					background-image: $file_flag_cze
				}
				.b-016__list-item--english .b-016__link-emblem--localization {
					background-image: $file_flag_eng;
				}


			.b-016__list-item a.active {
				display: none;
			}


		.b-016__list-more {
			top: auto;
			left: auto; // opusteno, potrebujem jen kdyz nema byt mobilni nav rozbalena pres celou sirku
			left: 0; // nova hodnota viz vyse
			right: 0;
			width: auto;
			padding: 0.5em 0;

			@include breakpoint($breakpoint_desktop) {
				left: auto; // jen v pripade ze na mobilu left: 0;
			}
		}
	}

	// pokud neni prvni dite, tzn. je pred nim h1
	.b-016:not(:first-child) {
		.b-016__list:not(.b-016__list--localization) {
			@include breakpoint($breakpoint_desktop) {
				padding-left: ($layout_main_horizontal-padding_desktop - $b-016_menu-item-horizontal-padding_desktop) / 2; // kvuli sikmemu efektu
			}
		}
	}
}




/* sledujte nas */
// plosna zmena, je zatim tady protoze hrozi navrat k puvodni myslence
.b-032 .b-032b__list {
	display: flex; // opustena myslenka ze v moblni verzi je misto vsech soc. buttonu jeden s bublinou
}
.b-032 .button-social--share-bubble__wrapper {
	display: none; // opustena myslenka ze v moblni verzi je misto vsech soc. buttonu jeden s bublinou
}
// bgcol varianta
.b-032 .b-032b__list .button-social--facebook.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_facebook; // opustena myslenka jine barvy ikon na desktopu
	}
}
// bgcol varianta
.b-032 .button-social--instagram.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_instagram; // opustena myslenka jine barvy ikon na desktopu
	}
}
// bgcol varianta
.b-032 .button-social--x.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_x; // opustena myslenka jine barvy ikon na desktopu
	}
}
// bgcol varianta
.b-032 .button-social--googleplus.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_googleplus; // opustena myslenka jine barvy ikon na desktopu
	}
}
// bgcol varianta
.b-032 .button-social--youtube.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_youtube; // opustena myslenka jine barvy ikon na desktopu
	}
}
// bgcol varianta
.b-032 .button-social--linkedin.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_linkedin; // opustena myslenka jine barvy ikon na desktopu
	}
}
// bgcol varianta
.b-032 .button-social--rss.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_rss; // opustena myslenka jine barvy ikon na desktopu
	}
}
// bgcol varianta
.b-032 .button-social--newsletter.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_newsletter; // opustena myslenka jine barvy ikon na desktopu
	}
}

// bgcol varianta
.b-032 .button-social--telegram.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_telegram; // opustena myslenka jine barvy ikon na desktopu
	}
}

.w-forecast {
  margin-top: 3em;
}

// ?? prosim strucny popis proc, kde, atd. at muzu pripadne opravit
.b-024 .button {
	margin-top: 0.5em;
}
.b-008b p {
	margin-bottom: 0;
}



// debug only
//.messages.messages--error { display: none; }
