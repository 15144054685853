//
// Radio Prague Int. - Hledej
//



#{$radiopraha_selector_body-hledej},
#{$radiopraha_selector_body-search},
#{$radiopraha_selector_body-suchen},
#{$radiopraha_selector_body-busqueda},
#{$radiopraha_selector_body-rechercher},
#{$radiopraha_selector_body-poisk} {

	// fulltext
	.view-filters form {
		&.views-exposed-form-rcz-elastic-search-b-022a .views-exposed-form .views-exposed-widgets .views-submit-button-wrapper{
			padding: 12px;
			margin-top: 0;
			background-color: $color_background-2;
		}
		&.views-exposed-form-rcz-elastic-search-b-022a .views-exposed-form .views-exposed-widgets .views-sort-options {
			display: flex;
			justify-content: flex-end;
			align-items: baseline;

			* {
				padding: 0.5rem;
			}

			.active {
				color: #ca003a;
			}

		}
		&.views-exposed-form-rcz-elastic-search-b-022a .views-exposed-form .views-exposed-widgets .views-submit-button-wrapper .views-widget {
			width: 100%;
		}
		&.views-exposed-form-rcz-elastic-search-b-022a .views-exposed-form .views-exposed-widgets .views-submit-button-wrapper .views-widget .form-item-combine input[type=text] {
			background-color: $color_background-0;
			height: 1.75em;
			font-size: 20px;
			line-height: 1.3;

		}
		&.views-exposed-form-rcz-elastic-search-b-022a .views-exposed-form .views-exposed-widgets .views-exposed-widget {
			width: auto;
		}
		&.views-exposed-form-rcz-elastic-search-b-022a .views-exposed-form .views-exposed-widgets .views-submit-button-wrapper .views-submit-button input[type=submit] {
			background-color: $color_background-0;
			background-size: 20px 20px;
			padding: 0;
			width: 35px;
			height: 1.75em;
			font-size: 20px;
			line-height: 1.3;
		}
	}
}
