//
// B-040 Styles
//



.b-040 {

	h2 {
		text-transform: uppercase;
	}

	.b-040__list {

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.b-040__list-item {

			// trik jak 2 itemy radku + jejich margin roztahnout na 100%
			// ale ne pokud jich neni na radek plny pocet
			flex-basis: 49%; // toto neni polovina, ale vychozi velikost ktera se roztahne
			flex-grow: 1; // roztahne se pomoci grow do petin
			max-width: 49%; // (fallback pro no-calc pro nasledujici radek)
			max-width: calc(50% - 0.75em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
			// proc - 0.75em? protoze je to 1.5 * 1 / 2 viz (*)

			box-sizing: border-box;

			margin-right: 1.5em; // (*)
			margin-bottom: 2em;

			&:nth-child(2n) {

				margin-right: 0;
			}


			@include breakpoint($breakpoint_desktop) {
				// trik jak 5 itemu radku + jejich margin roztahnout na 100%
				// ale ne pokud jich neni na radek plny pocet
				flex-basis: 19%; // toto neni petina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do petin
				max-width: 19%; // (fallback pro no-calc pro nasledujici radek)
				max-width: calc(20% - 1.2em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 1.2em? protoze je to 1.5 * 4 / 5 viz (*)

				box-sizing: border-box;

				margin-right: 1.5em; // (*)
				margin-bottom: 2em;

				&:nth-child(2n) {

					margin-right: 1.5em;
				}

				&:nth-child(5n) {

					margin-right: 0;
				}
			}

			.b-040__block-logo-wrapper {
				position: relative;
				padding-top: 100%; /* 1:1 Aspect Ratio */
				overflow: hidden;

				width: 100%;
				height: auto;
			}

				.b-040__block-logo {
					display: flex;
					flex-direction: row;
					justify-content: center;

					position: absolute;
					top: 0;
					left: 0;

					width: 100%;
					height: 100%;

					.b-040__block-logo--middle {
						flex-grow: 1;

						display: flex;
						flex-direction: column;
						justify-content: center;

						padding: 1em 0;

						.image {
							position: relative;
						}
					}

					.b-040__block-logo--left,
					.b-040__block-logo--right {
						flex-shrink: 0;
						width: 1.25em;
						border: 1px solid $color_divider;
					}

					.b-040__block-logo--left {
						border-right: none;
					}

					.b-040__block-logo--right {
						border-left: none;
					}
				}


			.b-040__link {
				@include link-style($color_background-5, none, $color_background-5, underline);

				.b-040__block-text p,
				.b-040__block-text h3 {
					font-size: 14px;
					line-height: 20px;
					@include typography_font(medium);
					text-align: center;

					color: $color_background-5;

					margin-top: 1.25em;
					margin-bottom: 0;
				}
			}

			.b-040__block-text p,
			.b-040__block-text h3 {
				font-size: 14px;
				line-height: 20px;
				@include typography_font(medium);
				text-align: center;

				color: $color_background-5;

				margin-top: 1.25em;
				margin-bottom: 0;
			}
		}
	}
}



// pokud je v aside ma pravidla mobilu
.l-grid--4-1,
.l-grid--4-9 {
	.b-040 {
		.b-040__list {
			.b-040__list-item {

				// desktop s pravidly mobilu
				@include breakpoint($breakpoint_desktop) {

					// trik jak 2 itemy radku + jejich margin roztahnout na 100%
					// ale ne pokud jich neni na radek plny pocet
					flex-basis: 49%; // toto neni polovina, ale vychozi velikost ktera se roztahne
					flex-grow: 1; // roztahne se pomoci grow do petin
					max-width: 49%; // (fallback pro no-calc pro nasledujici radek)
					max-width: calc(50% - 0.75em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
					// proc - 0.75em? protoze je to 1.5 * 1 / 2 viz (*)

					box-sizing: border-box;

					margin-right: 1.5em; // (*)
					margin-bottom: 2em;

					&:nth-child(2n) {

						margin-right: 0;
					}
				}
			}
		}
	}
}
