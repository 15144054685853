//
// B-006i Styles
//



.b-006i {
}

	.b-006i__list {

		// oba listy zaroven sami flexuji vlastni obsah
		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {

			flex-direction: row;
			flex-wrap: wrap;
		}
	}

		// obecny item (vlastnosti v toku vlevo i vpravo)
		.b-006i__list-item {

			margin-bottom: 2em;

			// IE
			min-height: 1px;

			@include breakpoint($breakpoint_desktop) {

				// trik jak 3 itemy radku + jejich margin roztahnout na 100%
				// ale ne pokud jich neni na radek plny pocet
				flex-basis: 30%; // toto neni tretina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do tretin
				max-width: 32%; // (fallback pro no-calc pro nasledujici radek)
				max-width: calc(33.33% - 1.34em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 1.34em? protoze je to 2 * 2 / 3 viz (*)

				box-sizing: border-box;

				margin-right: 2em; // (*)
			}
		}

		.b-006i__list-item:nth-child(3n) {

			@include breakpoint($breakpoint_desktop) {

				margin-right: 0;
			}
		}
		
		// spodni odsazeni boxu fix
		.b-006i__list-item:last-child {
			margin-bottom: 0;
		}
		.b-006i__list-item:nth-last-child(-n+3) {
			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 0;
			}
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-006i__list-item h3 {

				margin: 0;
				padding-top: 0.5em;
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-006i__list-item a p {

				margin-top: 0.5em;
				margin-bottom: 0;

				color: $color_text_paragraph;
				@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
			}

			// muze byt i mimo <a>
			.b-006i__list-item p {
				margin-top: 0.5em;
				margin-bottom: 0;

				color: $color_text_paragraph;
			}
