//
// B-029 Styles
//



.b-029 {
}

	// TODO z duvodu atomu zatim ne pres specifickou tridu
	.b-029 .button-box-flat--dropdown {
		
		@include adjust-font-size-to($fontsize_form_flat_button_desktop);
		
		@include breakpoint($breakpoint_desktop) {
			display: inline-flex;
		}
	}
		
		.b-029 .button-box-flat__icon--arrow-south {
			
			// TODO chybejici ikona (ve variables je dosazena opacna a otocena viz. _button-box-flet.scss)
			background-image: $file_button_icon_arrow_east;
			
			@include breakpoint($breakpoint_desktop) {
						
				transform: none;
			}
		}
		
	
	.b-029 .b-029__section-form {
		
		background-color: $color_background-2;
		
		@include breakpoint($breakpoint_desktop) {
		
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
	}
		
		.b-029 .b-029__section-inputs {
			
			padding: 1em;
			
			@include breakpoint($breakpoint_desktop) {
		
				flex-basis: 50%;
				
				box-sizing: border-box;
				
				padding: 2em;
			}
		}
		
			.b-029 .b-029__list--inputs {
			}
			
				.b-029 .b-029-inputs__list-item {
					
					margin-bottom: 0.5em;
				}
					
					.b-029 .b-029-inputs__list-item label {
						
						@include adjust-font-size-to($fontsize_form_input_label_desktop);
					}
			
			.b-029 .b-029__list--textarea {
			}
			
				.b-029 .b-029-textarea__list-item {
					
					margin-bottom: 0.5em;
				}
					
					.b-029 .b-029-textarea__list-item label {
						
						@include adjust-font-size-to($fontsize_form_input_label_desktop);
					}
		
		
		.b-029 .b-029__section-checkboxes {
			
			margin: 0 1em;
			padding: 1em 0;
			
			border-top : 1px solid $color_divider;
			
			@include breakpoint($breakpoint_desktop) {
		
				flex-basis: 50%;
				
				box-sizing: border-box;
				
				margin: 2em 0;
				padding: 0 2em;
				
				border-top: none;
				border-left : 1px solid $color_divider;
			}
		}
			
			.b-029 .b-029__section-checkboxes label {
				
				@include adjust-font-size-to($fontsize_form_input_label_desktop);
			}
			
			.b-029 .b-029__list--checkboxes {
				
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
			}
			
				.b-029 .b-029-checkboxes__list-item {
					
					margin-right: 4em;
					margin-bottom: 0.5em;
					
					//flex-basis: 50%;
				}
					
					.b-029 .b-029-checkboxes__list-item label {
						
						@include adjust-font-size-to($fontsize_form_input_label_desktop);
					}
		
		
		.b-029 .b-029__section-buttons {
			
			padding: 1em;
			
			@include breakpoint($breakpoint_desktop) {
		
				flex-basis: 50%;
				
				box-sizing: border-box;
				
				padding: 2em;
			}
			
		}
			
			.b-029 .button-form-flat {
				
				margin-bottom: 1em;
				
				@include breakpoint($breakpoint_desktop) {

					display: inline-block;
					
					width: auto;
					
					margin-right: 1em;
					margin-bottom: 0;
					
				}
			}
			
			.b-029 .button-form-flat:last-child {
				
				margin-bottom: 0;
				
				@include breakpoint($breakpoint_desktop) {

					margin-right: 0;
				}
			}




//
// B-029a Styles
//



.b-029a {
}
	





//
// B-029b Styles
//



.b-029b {
}

	.b-029 .b-029__section-inputs {
			
		@include breakpoint($breakpoint_desktop) {
			
			flex-basis: 50%;
		}
	}
	
	.b-029 .b-029__section-buttons {
		
		@include breakpoint($breakpoint_desktop) {
			
			flex-basis: 100%;
		}
	}