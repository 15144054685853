//
// CRo Background Images - Logos
//



// hlavni stanicni/korporatni loga dedena od rodice
// obecna trida ktera zajisti ze prvek se osadi pozadim dle webu (trida u body?) kde je umisten
.bg-image-logo-main {

	.corporate & { background-image: $file_logo_corporate_main; }

	.radiozurnal & { background-image: $file_logo_radiozurnal_colored-white; }
	.dvojka & { background-image: $file_logo_dvojka_colored-white; }
	.vltava & { background-image: $file_logo_vltava_colored-white; }
	.wave & { background-image: $file_logo_wave_colored-white; }
	.d-dur & { background-image: $file_logo_d-dur_colored-white; }
	.jazz & { background-image: $file_logo_jazz_colored-white; }
	.plus & { background-image: $file_logo_plus_colored-white; }
	.junior & { background-image: $file_logo_junior_colored-white; }

	//.region & { background-image: $file_logo_corporate_main; }
	.brno & { background-image: $file_logo_brno_colored-white; }
	.budejovice & { background-image: $file_logo_budejovice_colored-white; }
	.hradec & { background-image: $file_logo_hradec_colored-white; }
	.liberec & { background-image: $file_logo_liberec_colored-white; }
	.olomouc & { background-image: $file_logo_olomouc_colored-white; }
	.ostrava & { background-image: $file_logo_ostrava_colored-white; }
	.pardubice & { background-image: $file_logo_pardubice_colored-white; }
	.plzen & { background-image: $file_logo_plzen_colored-white; }
	/*.regina & { background-image: $file_logo_regina_colored-white; }*/
	.regina & { background-image: $file_logo_dab_colored-white; } // nove Radio DAB
	.sever & { background-image: $file_logo_sever_colored-white; }
	.strednicechy & { background-image: $file_logo_region_colored-white; } // ma byt region, ale trida uz je obsazena
	.vary & { background-image: $file_logo_vary_colored-white; }
	.vysocina & { background-image: $file_logo_vysocina_colored-white; }
	.zlin & { background-image: $file_logo_zlin_colored-white; }
	.regiony & { background-image: $file_logo_regiony_colored-white; } // radio vaseho kraje

	.program & { background-image: $file_logo_program_colored-white; }
	.rada & { background-image: $file_logo_rada_colored-white; }
	.svet & { background-image: $file_logo_svet_colored-white; }

	.radiopraha & { background-image: $file_logo_radiopraha_colored-white; }
}

// vyjimky
.bg-image-logo-main {
  .b-015 & {
    .radiopraha & { background-image: $file_logo_radioprague-int_colored-white; }
  }
}


// hlavni stanicni/korporatni loga definovane tridou primo
// zatim to pouzite neni, ale mohlo by se hodit na prime volani pozadoveho obrazku loga
// pro pripady umisteni kdekoliv na stranku. je to tu take aby byla dodrzena analogie s main barvami
// ktere se takto primo daji tridou volat tez
.bg-image-logo-main-corporate { background-image: $file_logo_corporate_main; }

.bg-image-logo-main-radiozurnal { background-image: $file_logo_radiozurnal_main; }
.bg-image-logo-main-dvojka { background-image: $file_logo_dvojka_main; }
.bg-image-logo-main-vltava { background-image: $file_logo_vltava_main; }
.bg-image-logo-main-wave { background-image: $file_logo_wave_main; }
.bg-image-logo-main-d-dur { background-image: $file_logo_d-dur_main; }
.bg-image-logo-main-jazz { background-image: $file_logo_jazz_main; }
.bg-image-logo-main-plus { background-image: $file_logo_plus_main; }
.bg-image-logo-main-junior { background-image: $file_logo_junior_main; }

//.bg-image-logo-main-region { background-image: $file_logo_corporate_main; }
.bg-image-logo-main-brno { background-image: $file_logo_brno_main; }
.bg-image-logo-main-budejovice { background-image: $file_logo_budejovice_main; }
.bg-image-logo-main-hradec { background-image: $file_logo_hradec_main; }
.bg-image-logo-main-vary { background-image: $file_logo_vary_main; }
.bg-image-logo-main-liberec { background-image: $file_logo_liberec_main; }
.bg-image-logo-main-olomouc { background-image: $file_logo_olomouc_main; }
.bg-image-logo-main-ostrava { background-image: $file_logo_ostrava_main; }
.bg-image-logo-main-pardubice { background-image: $file_logo_pardubice_main; }
.bg-image-logo-main-plzen { background-image: $file_logo_plzen_main; }
/*.bg-image-logo-main-regina { background-image: $file_logo_regina_main; }*/
.bg-image-logo-main-regina { background-image: $file_logo_dab_main; } // nove Radio DAB
//body.regina .bg-image-logo-main-regina { background-image: $file_logo_dab_colored-corporate; } // nove Radio DAB, na vlastnim webu jina barevnost nez defaulni regionalni
.bg-image-logo-main-sever { background-image: $file_logo_sever_main; }
.bg-image-logo-main-strednicechy { background-image: $file_logo_region_main; } // ma byt region, ale trida uz je obsazena
.bg-image-logo-main-vysocina { background-image: $file_logo_vysocina_main; }
.bg-image-logo-main-zlin { background-image: $file_logo_zlin_main; }
.bg-image-logo-main-regiony { background-image: $file_logo_regiony_main; } // radio vaseho kraje

//.bg-image-logo-main-program { background-image: $file_logo_program_main; }
