//
// Roletka pro vyber stanic (checkbox hack)
//
// samostatna komponenta, jelikoz se muze pouzit i v budoucnu
//
// zatim pouzito u:
// - celorozhlasoveho programu
// - celodennich zaznamu
//


$custom_color: #efeff2;
$custom_color_opacity: rgba(255, 255, 255, .3);


// defaultni stav (menu je skryte)
.mobilemenu {
	display: none;
	width: 200px;
	height: 100px;
	color: white;
	&__item {
		display: flex;
		align-items: center;
		font-size: .8rem;
		padding: 20px 20px 20px 60px;
		color: $color_background-0 !important;
		background: $file_logo_R_white no-repeat 20px center;
		background-color: $color_corporate_main;
		background-size: 22px 22px;
		line-height: 1;
		position: relative;
		&:hover {
			text-decoration: none;
		}
		&:after {
			content: '';
			background: $file_icon_arrow_single_east_colored-white;
			display: block;
			width: 9px;
			height: 12px;
			position: absolute;
			top: 22px;
			right: 15px;
		}
		&--radiozurnal {
			background-color: $color_radiozurnal_main;
		}
		&--dvojka {
			background-color: $color_dvojka_main;
		}
		&--plus {
			background-color: $color_plus_main;
		}
		&--vltava {
			background-color: $color_vltava_main;
		}
		&--radiowave {
			background-color: $color_wave_main;
		}
		&--d-dur {
			background-color: $color_d-dur_main;
		}
		&--jazz {
			background-color: $color_jazz_main;
		}
		&--radiojunior {
			background-color: $color_corporate_main;
		}
		&--regional {
			background-color: $color_region_main;
			border-bottom: 1px solid $custom_color_opacity;
		}
	}
	&__station-name {
		font-size: .95rem;
		@include typography_font(medium);
		color: $custom_color;
		&:first-letter {
			text-transform: uppercase;
		}
		&:after {
			content: '';
			display: table;
			clear: both;
		}
	}
	&__stations {
		font-size: .9rem;
		padding: 5px 20px;
		@include typography_font(medium);
		color: $color_background-6;
		background-color: $color_background-3;
		@include no-select;
	}
	&__now-playing {
		color: $custom_color;
	}
}


// chceckbox je zachecknuty (menu je zobrazene)
.hidden-checkbox:checked ~ .mobilemenu {
	display: flex;
	flex-direction: column;
	width: 100vw;
	min-height: 100vh;
	padding: 0 10px;
	position: absolute;
	top: 145px;
	left: -10px;
	color: $color_background-9;
	z-index: 10;
	@include breakpoint($breakpoint_tablet) {
		display: none;
	}
}


// korekce zobrazeni u celodennich zaznamu
.audioarchive {
	.mobilemenu {
		&__item {
			padding-top: 13px;
			padding-bottom: 13px;
		}
	}
	.hidden-checkbox:checked ~ .mobilemenu {
		padding-left: 0;
		padding-right: 0;
		@include breakpoint($breakpoint_tablet) {
			display: flex;
		}
	}
}
