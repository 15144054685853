//
// CRo Background Colors
//



// !important je zde z důvodu, že má jít vždy o nejsilnější třídu, a to i v případě, že má element vlastní barvu přes silnější selektor

.bg-color-1 { background-color: $color_background-1 !important; }
.bg-color-2 { background-color: $color_background-2 !important; }
.bg-color-3 { background-color: $color_background-3 !important; }
.bg-color-4 { background-color: $color_background-4 !important; }
.bg-color-5 { background-color: $color_background-5 !important; }
.bg-color-6 { background-color: $color_background-6 !important; }
.bg-color-7 { background-color: $color_background-7 !important; }
.bg-color-8 { background-color: $color_background-8 !important; }
.bg-color-9 { background-color: $color_background-9 !important; }


// hlavni stanicni/korporatni barva dedena od rodice
// obecna trida ktera zajisti ze prvek se obarvi dle webu (trida u body?) kde je umisten
.bg-color-main {
	.corporate &,
	.corporate:not(body) & { background-color: $color_corporate_main !important; }

	.irozhlas &,
	.irozhlas:not(body) & { background-color: $color_irozhlas_main !important; }

	.radiozurnal &,
	.radiozurnal:not(body) & { background-color: $color_radiozurnal_main !important; }
	.dvojka &,
	.dvojka:not(body) & { background-color: $color_dvojka_main !important; }
	.vltava &,
	.vltava:not(body) & { background-color: $color_vltava_main !important; }
	.plus &,
	.plus:not(body) & { background-color: $color_plus_main !important; }
	.wave &,
	.radiowave &,
	.wave:not(body) &,
	.radiowave:not(body) & { background-color: $color_wave_main !important; }
	.d-dur &,
	.ddur &,
	.d-dur:not(body) &,
	.ddur:not(body) & { background-color: $color_d-dur_main !important; }
	.jazz &,
	.jazz:not(body) & { background-color: $color_jazz_main !important; }
	.junior &,
	.junior:not(body) & { background-color: $color_junior_main !important; }

	.region &,
	.region:not(body) & { background-color: $color_region_main !important; } // obecna, tridu maji vsechny regiony
	.brno &,
	.brno:not(body) & { background-color: $color_region_main !important; }
	.cb &,
	.cb:not(body) & { background-color: $color_region_main !important; }
	.hradec &,
	.hradec:not(body) & { background-color: $color_region_main !important; }
	.kv &,
	.kv:not(body) & { background-color: $color_region_main !important; }
	.liberec &,
	.liberec:not(body) & { background-color: $color_region_main !important; }
	.olomouc &,
	.olomouc:not(body) & { background-color: $color_region_main !important; }
	.ostrava &,
	.ostrava:not(body) & { background-color: $color_region_main !important; }
	.pardubice &,
	.pardubice:not(body) & { background-color: $color_region_main !important; }
	.plzen &,
	.plzen:not(body) & { background-color: $color_region_main !important; }
	.regina &,
	.regina:not(body) & { background-color: $color_region_main !important; }
	//body.regina & { background-color: $color_regina_main !important; } // nove Radio DAB, na vlastnim webu jina barevnost nez defaulni regionalni
	.regiony &,
	.regiony:not(body) & { background-color: $color_region_main !important; }
	.sever &,
	.sever:not(body) & { background-color: $color_region_main !important; }
	.strednicechy &,
	.strednicechy:not(body) & { background-color: $color_region_main !important; } // ma byt region, ale trida uz je obsazena
	.vysocina &,
	.vysocina:not(body) & { background-color: $color_region_main !important; }
	.zlin &,
	.zlin:not(body) & { background-color: $color_region_main !important; }

	.archiv &,
	.archiv:not(body) & { background-color: $color_corporate_main !important; }

	.cro7 &,
	.cro7:not(body) &,
	.radiopraha &,
	.radiopraha:not(body) & { background-color: $color_corporate_main !important; } // pro web cro pouzivame korporatni
	body.radiopraha & { background-color: $color_radiopraha_main !important; } // vlastnim webu radio.cz jina barevnost nez korporatni

	.informace &,
	.informace:not(body) & { background-color: $color_informace_main !important; }

	.program &,
	.program:not(body) & { background-color: $color_program_main !important; }

	.rada &,
	.rada:not(body) & { background-color: $color_corporate_main !important; }

	.radiojunior &,
	.radiojunior:not(body) & { background-color: $color_corporate_main !important; }

	.junior &,
	.junior:not(body) & { background-color: $color_junior_main !important; }

	.svet &,
	.svet:not(body) & { background-color: $color_corporate_main !important; }

	.webik &,
	.webik:not(body) & { background-color: $color_corporate_main !important; }

	.zelenavlna &,regina
	.zelenavlna:not(body) & { background-color: $color_zelenavlna_main !important; }
}


// hlavni stanicni/korporatni barvy definovane tridou primo
// uziti treba na stitky apod
.bg-color-main- { background-color: $color_corporate_main !important; } // fallback kdyz system nedoplni stanici

.bg-color-main-corporate { background-color: $color_corporate_main !important; }

.bg-color-main-irozhlas { background-color: $color_irozhlas_main !important; }

.bg-color-main-radiozurnal { background-color: $color_radiozurnal_main !important; }
.bg-color-main-dvojka { background-color: $color_dvojka_main !important; }
.bg-color-main-vltava { background-color: $color_vltava_main !important; }
.bg-color-main-plus { background-color: $color_plus_main !important; }
.bg-color-main-wave,
.bg-color-main-radiowave { background-color: $color_wave_main !important; }
.bg-color-main-d-dur,
.bg-color-main-ddur { background-color: $color_d-dur_main !important; }
.bg-color-main-jazz { background-color: $color_jazz_main !important; }
.bg-color-main-junior { background-color: $color_junior_main !important; }

.bg-color-main-region { background-color: $color_region_main !important; } // obecna, tridu maji vsechny regiony
.bg-color-main-brno { background-color: $color_region_main !important; }
.bg-color-main-cb,
.bg-color-main-budejovice { background-color: $color_region_main !important; }
.bg-color-main-hradec { background-color: $color_region_main !important; }
.bg-color-main-kv,
.bg-color-main-vary { background-color: $color_region_main !important; }
.bg-color-main-liberec { background-color: $color_region_main !important; }
.bg-color-main-olomouc { background-color: $color_region_main !important; }
.bg-color-main-ostrava { background-color: $color_region_main !important; }
.bg-color-main-pardubice { background-color: $color_region_main !important; }
.bg-color-main-plzen { background-color: $color_region_main !important; }
.bg-color-main-regina { background-color: $color_region_main !important; }
//body.regina .bg-color-main-regina { background-color: $color_regina_main !important; }  // nove Radio DAB, na vlastnim webu jina barevnost nez defaulni regionalni
.bg-color-main-regiony { background-color: $color_region_main !important; }
.bg-color-main-sever { background-color: $color_region_main !important; }
.bg-color-main-strednicechy { background-color: $color_region_main !important; } // ma byt region, ale trida uz je obsazena
.bg-color-main-vysocina { background-color: $color_region_main !important; }
.bg-color-main-zlin { background-color: $color_region_main !important; }

.bg-color-main-cro7,
.bg-color-main-radiopraha { background-color: $color_corporate_main !important; } // pro web cro pouzivame korporatni
body.radiopraha .bg-color-main-cro7,
body.radiopraha .bg-color-main-radiopraha { background-color: $color_radiopraha_main !important; } // vlastnim webu radio.cz jina barevnost nez korporatni

.bg-color-main-archiv { background-color: $color_corporate_main !important; }
.bg-color-main-informace { background-color: $color_informace_main !important; }
.bg-color-main-program { background-color: $color_program_main !important; }
.bg-color-main-rada { background-color: $color_corporate_main !important; }
.bg-color-main-radiojunior { background-color: $color_corporate_main !important; }
.bg-color-main-junior { background-color: $color_junior_main !important; }
.bg-color-main-svet { background-color: $color_corporate_main !important; }
.bg-color-main-webik { background-color: $color_corporate_main !important; }
.bg-color-main-zelenavlna { background-color: $color_zelenavlna_main !important; }

.bg-color-main-radiozurnal-sport { background-color: $color_radiozurnal_main !important; }


// TODO barvy nejsou definovany ve veriables/colors - pak je teprve mozne povolit
.bg-color-main-darker {
	.corporate & { background-color: $color_corporate_main_darker !important;}

	.radiozurnal & { background-color: $color_radiozurnal_main_darker !important;}
	.dvojka & { background-color: $color_dvojka_main_darker !important; }
	.vltava & { background-color: $color_vltava_main_darker !important; }
	.plus & { background-color: $color_plus_main_darker !important; }
	.wave &,
	.radiowave & { background-color: $color_wave_main_darker !important; }
	.d-dur &,
	.ddur & { background-color: $color_d-dur_main_darker !important; }
	.jazz & { background-color: $color_jazz_main_darker !important; }
	.junior & { background-color: $color_junior_main_darker !important; }
	//body.regina & { background-color: $color_regina_main_darker !important; } // nove Radio DAB, na vlastnim webu jina barevnost nez defaulni regionalni

	.region & { background-color: $color_region_main_darker !important; } // obecna, tridu maji vsechny regiony

	.cro7 &,
	.radiopraha & { background-color: $color_radiopraha_main_darker !important;}

	.archiv & { background-color: $color_informace_main_darker !important; }
	.informace & { background-color: $color_informace_main_darker !important; }
	.program & { background-color: $color_program_main_darker !important; }
	.rada & { background-color: $color_corporate_main_darker !important;}
	.radiojunior & { background-color: $color_corporate_main_darker !important;}
	.junior & { background-color: $color_junior_main_darker !important;}
	.svet & { background-color: $color_corporate_main_darker !important;}
	.webik & { background-color: $color_corporate_main_darker !important;}
	.zelenavlna & { background-color: $color_zelenavlna_main_darker !important; }
}



.bg-color-add-1 {
	.corporate & { background-color: $color_corporate_add-1 !important;}

	.radiozurnal & { background-color: $color_radiozurnal_add-1 !important;}
	.dvojka & { background-color: $color_dvojka_add-1 !important; }
	.vltava & { background-color: $color_vltava_add-1 !important; }
	.plus & { background-color: $color_plus_add-1 !important; }
	.wave &,
	.radiowave & { background-color: $color_wave_add-1 !important; }
	.d-dur &,
	.ddur & { background-color: $color_d-dur_add-1 !important; }
	.jazz & { background-color: $color_jazz_add-1 !important; }
	//body.regina & { background-color: $color_regina_add-1 !important; } // nove Radio DAB, na vlastnim webu jina barevnost nez defaulni regionalni

	.region & { background-color: $color_region_add-1 !important; } // obecna, tridu maji vsechny regiony

	.junior & { background-color: $color_junior_add-1 !important;}
	//.zelenavlna & { background-color: $color_zelenavlna_add-1 !important; } nedefinovano
}



.bg-color-add-2 {
	.corporate & { background-color: $color_corporate_add-2 !important;}

	.radiozurnal & { background-color: $color_radiozurnal_add-2 !important;}
	.dvojka & { background-color: $color_dvojka_add-2 !important; }
	.vltava & { background-color: $color_vltava_add-2 !important; }
	.plus & { background-color: $color_plus_add-2 !important; }
	.wave &,
	.radiowave & { background-color: $color_wave_add-2 !important; }
	.d-dur &,
	.ddur & { background-color: $color_d-dur_add-2 !important; }
	.jazz & { background-color: $color_jazz_add-2 !important; }
	//body.regina & { background-color: $color_regina_add-2 !important; } // nove Radio DAB, na vlastnim webu jina barevnost nez defaulni regionalni

	.region & { background-color: $color_region_add-2 !important; } // obecna, tridu maji vsechny regiony

	.junior & { background-color: $color_junior_add-2 !important;}
	//.zelenavlna & { background-color: $color_zelenavlna_add-2 !important; } nedefinovano
}



.bg-color-add-3 {
	.corporate & { background-color: $color_corporate_add-3 !important;}

	.radiozurnal & { background-color: $color_radiozurnal_add-3 !important;}
	.dvojka & { background-color: $color_dvojka_add-3 !important; }
	.vltava & { background-color: $color_vltava_add-3 !important; }
	.plus & { background-color: $color_plus_add-3 !important; }
	.wave &,
	.radiowave & { background-color: $color_wave_add-3 !important; }
	.d-dur &,
	.ddur & { background-color: $color_d-dur_add-3 !important; }
	.jazz & { background-color: $color_jazz_add-3 !important; }
	//body.regina & { background-color: $color_regina_add-3 !important; } // nove Radio DAB, na vlastnim webu jina barevnost nez defaulni regionalni

	.region & { background-color: $color_region_add-3 !important; } // obecna, tridu maji vsechny regiony

	.zelenavlna & { background-color: $color_zelenavlna_add-3 !important; }
}



// specicke barvy Radia Junior
.bg-color-add-2-dark {
	.junior & { background-color: $color_junior_add-2-dark !important;}
}

.bg-color-add-2-medium {
	.junior & { background-color: $color_junior_add-2-medium !important;}
}

.bg-color-add-2-light {
	.junior & { background-color: $color_junior_add-2-light !important;}
}



.bg-color-add-3-dark {
	.junior & { background-color: $color_junior_add-3-dark !important;}
}

.bg-color-add-3-medium {
	.junior & { background-color: $color_junior_add-3-medium !important;}
}

.bg-color-add-3-light {
	.junior & { background-color: $color_junior_add-3-light !important;}
}



.bg-color-add-4-dark {
	.junior & { background-color: $color_junior_add-4-dark !important;}
}

.bg-color-add-4-medium {
	.junior & { background-color: $color_junior_add-4-medium !important;}
}

.bg-color-add-4-light {
	.junior & { background-color: $color_junior_add-4-light !important;}
}



.bg-color-add-5-dark {
	.junior & { background-color: $color_junior_add-5-dark !important;}
}

.bg-color-add-5-medium {
	.junior & { background-color: $color_junior_add-5-medium !important;}
}

.bg-color-add-5-light {
	.junior & { background-color: $color_junior_add-5-light !important;}
}



.bg-color-add-6-dark {
	.junior & { background-color: $color_junior_add-6-dark !important;}
}

.bg-color-add-6-medium {
	.junior & { background-color: $color_junior_add-6-medium !important;}
}

.bg-color-add-6-light {
	.junior & { background-color: $color_junior_add-6-light !important;}
}



.bg-color-add-7-dark {
	.junior & { background-color: $color_junior_add-7-dark !important;}
}

.bg-color-add-7-medium {
	.junior & { background-color: $color_junior_add-7-medium !important;}
}

.bg-color-add-7-light {
	.junior & { background-color: $color_junior_add-7-light !important;}
}



.bg-color-add-8-dark {
	.junior & { background-color: $color_junior_add-8-dark !important;}
}

.bg-color-add-8-medium {
	.junior & { background-color: $color_junior_add-8-medium !important;}
}

.bg-color-add-8-light {
	.junior & { background-color: $color_junior_add-8-light !important;}
}



.bg-color-add-9-dark {
	.junior & { background-color: $color_junior_add-9-dark !important;}
}

.bg-color-add-9-medium {
	.junior & { background-color: $color_junior_add-9-medium !important;}
}

.bg-color-add-9-light {
	.junior & { background-color: $color_junior_add-9-light !important;}
}
