//
// Progress bar
//



$progress-bar_timeline_height_mobile: 8px;
$progress-bar_timeline_height_desktop: 6px;




.progress-bar {
	
	flex-grow: 1;
	align-self: center;
}
	
	.progress-bar__duration {
		
		width: 100%;
		height: $progress-bar_timeline_height_mobile;
		
		background-color: $color_background_0;
		
		
		@include breakpoint($breakpoint_desktop) {
			height: $progress-bar_timeline_height_desktop;
		}
	}
		
		.progress-bar__position {
			
			height: $progress-bar_timeline_height_mobile;
			
			// k pretizeni specifickou stanicni barvou
			background-color: $color_decoration_main;
			
			
			@include breakpoint($breakpoint_desktop) {
				height: $progress-bar_timeline_height_desktop;
			}
		}