//
// b-053a Styles
//



$b-053a-transition-time: .15s;
$b-053a-transition-time-slow: .95s;
$b-053a-alphabet-bg-color: $color_decoration_main;
$b-053a-alphabet-bg-color-hover: darken($color_decoration_main, 15%);
$b-053a-alphabet-letter-width: 28px;


.b-053a {
	display: flex;
	flex-wrap: wrap;
	position: relative;
}

	// taby: wrapper
	.b-053a__tabs-wrapper {
		position: relative;

		width: 100%;
		min-height: 30px;
	}

	// linka
	.b-053a__tabs-wrapper::before {
		content: '';

		position: absolute;
		bottom: 0;

		width: 100%;
		height: 1px;

		display: block;

		background-color: $color_divider;

		@include breakpoint($breakpoint_desktop) {
			z-index: 10;
		}
	}

		// taby: list
		.b-053a__tabs {
			display: flex;
			flex-direction: column;

			position: absolute;
			top: 0;
			left: 0;

			z-index: 1;

			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;

				position: relative;

				z-index: auto;
			}
		}


			// taby: item
			.b-053a__tab-item {
				//color: $color_background-6;
				background-color: $color_background-4;

				font-size: .85rem;

				@include no-select;
				cursor: pointer;

				z-index: 20;

				order: 2;

				@include breakpoint($breakpoint_desktop) {
					border-right: 1px solid $color_background-0;
					order: 1;
				}
			}

			.b-053a__tab-item:hover {
				background-color: $color_background-6;
				z-index: 21;
			}

				.b-053a__tab-item:hover a {
					color: $color_background-0;
					text-decoration: none;
				}

			.b-053a__tab-item:last-child {
				@include breakpoint($breakpoint_desktop) {
					border-right: 0;
				}
			}

				.b-053a__tab-item a {
					display: block;

					padding: 4px 10px;

					color: $color_background-6;
					text-decoration: none;
					white-space: nowrap;
				}


			// taby: selected item
			.b-053a__tab-item--selected {
				display: block;

				background-color: $color_background-2;

				@include typography_font(medium);

				order: 1;

				@include breakpoint($breakpoint_desktop) {
					cursor: default;
					pointer-events: none;

					order: 1;
				}
			}

				.b-053a__tab-item--selected a {
					color: $color_text_paragraph_node;
					text-decoration: none;

					background-image: $file_icon_arrow_single_south_colored-decoration-main;
					background-repeat: no-repeat;
					background-position: center right +10px;
					background-size: 10px;

					padding-right: 30px;

					@include breakpoint($breakpoint_desktop) {
						background-image: none;
						padding-right: 10px;
					}
				}


			.b-053a__tab-item--selected:hover {
				color: $color_text_paragraph_node;
				background-color: $color_background-2;
			}

				.b-053a__tab-item--selected:hover a {
					color: $color_text_paragraph_node;
					text-decoration: none;
				}


			.b-053a__tab-item--opened {
				border-bottom: none;
			}

				.b-053a__tab-item--opened a {
					background-image: $file_icon_arrow_single_north_colored-decoration-main;

					@include breakpoint($breakpoint_desktop) {
						background-image: none;
					}
				}


	// filtr
	.b-053a__filter {
		display: none;
		padding: 20px;
		background-color: $color_background-2;
	}

	// informacni hlaska
	.b-053a__filter .filter-message {
		background-image: $file_icon_info;
		background-repeat: no-repeat;
		background-position: 0px 1px;
		background-size: 13px;

		margin-top: 20px;
		padding-left: 20px;

		font-size: 14px;
		line-height: 1.2;

		color: $color_background-6;

		@include breakpoint($breakpoint_desktop) {
			background-position: 0px 2px;
			background-size: 14px;
			font-size: 15px;
		}
	}

	// filtr: varianta s radio buttonama
	.b-053a__filter--radios {
	}

	// filtr: list
	.b-053a__filter-wrap,
	.b-053a__filter--radios .form-radios.bef-select-as-radios {
		display: flex;
		flex-wrap: wrap;
		justify-content: normal;
	}

	.b-053a .form-radios.bef-select-as-radios {
		margin-bottom: 10px;
	}

	// filtr: item
	.b-053a__filter-item,
	.b-053a__filter--radios .form-item.form-type-radio {
		flex-basis: 100%;
		flex-grow: 1;

		@include breakpoint($breakpoint_desktop) {
			display: flex;
			// trik jak 3 itemy radku + jejich margin roztahnout na 100%
			// ale ne pokud jich neni na radek plny pocet
			flex-basis: 30%; // toto neni tretina, ale vychozi velikost ktera se roztahne
			flex-grow: 1; // roztahne se pomoci grow do tretin
			max-width: 32%; // (fallback pro no-calc pro nasledujici radek)
			max-width: calc(33.33% - 0.67em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
			// proc - 0,67em? protoze je to 1 * 2 / 3 viz (*)

			box-sizing: border-box;

			margin-right: 1em; // (*)
		}

		select {
			width: 100%;
		}

		.form-item.form-type-select {
			margin-bottom: 0;
		}
	}

	// filtr: item-select
	.b-053a__filter .form-item.form-type-select {
		margin-bottom: 20px;
	}

	// filtr: item-radio
	.b-053a__filter--radios .form-item.form-type-radio {
		margin-bottom: 10px;
	}

	.b-053a__filter-item:nth-child(3n) {
		@include breakpoint($breakpoint_desktop) {
			margin-right: 0;
		}
	}
	.b-053a__filter-item .views-exposed-widget {
		width: 100%;
	}

	// obal pro tlacitka a vyhledavani
	.b-053a__filter-buttons {
		display: flex;
		flex-wrap: wrap;
		flex-basis: 100%;
		margin-right: 0;
		//margin-bottom: 20px;

		@include breakpoint($breakpoint_desktop) {
			justify-content: space-between;
		}
	}

		// tlacitka
		.b-053a__filter-buttons .views-submit-button {
			display: flex;
			flex-basis: 100%;
			flex-direction: column;
			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;
				//margin-bottom: 10px;
				flex-basis: calc(100% - 260px);
			}
		}

			// tlacitko confirm
			.b-053a__filter-buttons .views-submit-button {
				@include breakpoint($breakpoint_desktop) {
					flex-basis: auto;
					width: auto;
				}
			}

			// tlacitko reset
			.b-053a__filter-buttons .views-submit-button .views-reset-button {
				flex-basis: 100%;
				margin-top: 10px;

				@include breakpoint($breakpoint_desktop) {
					flex-basis: auto;

					margin-top: 0;
					margin-left: 1em;
				}
			}

		.b-053a__filter-buttons .views-submit-button .views-reset-button input {
			width: 100%;
			text-align: center;
			background-color: $color_background-6;
		}

		// vyhledavani
		.b-053a__filter-buttons .views-widget {
			flex-basis: 100%;

			margin-top: 1em;
			margin-bottom: 0;

			@include breakpoint($breakpoint_desktop) {
				flex-basis: auto;
				margin-top: 0;
			}
		}

			.b-053a__filter-buttons .views-widget .form-type-textfield {
				margin-bottom: 0;
			}

				.b-053a__filter-buttons .views-widget .form-type-textfield input[type=text] {
					border: 0;
					box-shadow: none;
					box-sizing: border-box;

					width: 100%;
					height: 2.25em;

					padding-left: .75em;
					padding-right: 2.5em;

					font-size: 18px;

					background-image: $file_icon_search;
					background-position: center right +0.5em;
					background-repeat: no-repeat;
					background-size: 1.5em 1.5em;

					@include breakpoint($breakpoint_desktop) {
						max-width: 250px;
						min-height: 50px;
					}
				}

		.b-053a__filter-buttons .button.button-form-flat.button-form-flat--confirm {
			width: 100%;
			height: auto;
			flex-basis: 100%;
			@include breakpoint($breakpoint_desktop) {
				flex-basis: auto;
				width: auto;
			}
		}
		.b-053a__filter-buttons .search-combo {
			flex-basis: 100%;
			margin-bottom: 0;
			@include breakpoint($breakpoint_desktop) {
				flex-basis: auto;
				margin-top: 0;
			}
		}


	// checkbox 'podrobne hledani'
	.b-053a__show-more {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: 7px;
		margin-top: -24px;
		@include breakpoint($breakpoint_desktop) {
			margin-top: -30px;
		}
	}
		.b-053a__show-more-input {
			display: none;
		}
		.b-053a__show-more-label {
			align-self: flex-end;
			color: $color_background-6;
			z-index: 20;
			text-transform: inherit;
			position: relative;
			padding-left: 26px;
			cursor: pointer;
			line-height: 2;
			transition: color $b-053a-transition-time ease-in-out, background-color $b-053a-transition-time-slow;
			@include no-select;
			@include breakpoint($breakpoint_desktop) {
				background-color: $color_background-0_alpha-low;
				min-height: 29px;
			}
		}
		.b-053a__show-more-label:hover {
			color: $color_background-7;
			@include breakpoint($breakpoint_desktop) {
				background-color: $color_background-0;
			}
		}
		.b-053a__show-more-label::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 24px;
			height: 24px;
			display: block;
			background: $file_icon_arrow_single_south_colored-decoration-main no-repeat center;
			background-size: 12px 12px;
			transform: rotate(0deg);
			transition: transform $b-053a-transition-time ease-in-out;
			@include breakpoint($breakpoint_desktop) {
				top: 2px;
			}
		}
		.b-053a__show-more-input:checked ~ .b-053a__filter {
			display: block;
		}
		.b-053a__show-more-input:checked + .b-053a__show-more-label::before {
			transform: rotate(180deg);
		}


	//
	// abeceda
	//
	.b-053a__alphabet {
		display: flex;
		flex-wrap: wrap;
		margin-top: 20px;
		flex-basis: 100%;
		margin-left: -2px;
		margin-right: -2px;
	}

		.b-053a__alphabet-input {
			display: none;
		}

		.b-053a__alphabet-letter {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0px 2px 4px 2px;
			min-height: 26px;
			color: $color_background-0;
			background-color: $b-053a-alphabet-bg-color;
			cursor: pointer;
			font-size: .9rem;
			transition: background-color $b-053a-transition-time;
			width: $b-053a-alphabet-letter-width;
			max-width: $b-053a-alphabet-letter-width;
			@include no-select;
			@include breakpoint($breakpoint_desktop) {
				flex-basis: 10%;
			}
		}

		.b-053a__alphabet-letter a {
				@include link-style($color_text_link_inverted, none, $color_text_link_inverted, none);
				width: 100%;
				height: 100%;
				text-align: center;
				line-height: 2;
			}

			.b-053a__alphabet-input:disabled + .b-053a__alphabet-letter a {
				@include link-style($color_background-6, none, $color_background-6, none);
			}

		// barva pismenka abecedy - zamereni mysi
		.b-053a__alphabet-letter:hover {
			background-color: $b-053a-alphabet-bg-color-hover;
		}

		// barva pismenka abecedy - vybrane pismenko
		.b-053a__alphabet-input:checked + .b-053a__alphabet-letter {
			background-color: $color_decoration_success;
		}

		// barva pismenka abecedy - disabled pismenko (zadne vysledky pod danym pismenkem)
		.b-053a__alphabet-input:disabled + .b-053a__alphabet-letter {
			color: $color_background-6;
			background-color: $color_background-2;
			cursor: default;
		}

		// vse
		.b-053a__alphabet label[for="letter-all"],
		.b-053a__alphabet input[value="letter-all"] + label {
			min-width: calc(2 * #{$b-053a-alphabet-letter-width + 2px}); // 2px, ktere se pricitaji, jsou margin letteru
		}
