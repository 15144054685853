//
// Button Social Netvor
//
//
// dve urovne modifikatoru:
//
// 1) dle soc site/sluzby
//    button-social--facebook, button-social--instagram apod
//
// 2) dle vizualu
//
//    a) bez modifikatoru
//       tmave sede, bily hover, zabarvujici se soc ikona, viz napr. paticka
//
//    b) button-social--bgcol
//       svetle sede, barevny hover dle sluzby, soc ikona zustava bila
//       v mobile je vybarvene jako vychozi stav
//
//    c) button-social--outdeco
//       outline a ikona barvy identicka s color-decoration-main
//       vyrabeno pro popup sdiledlo v b032a
//



.button-social {

	background-position: center;
	background-repeat: no-repeat;

	background-size: auto 1em; // pozor, nekde specificky pretezovano dle obrazku

	display: block;

	margin: 0;
	padding: 0;


	// toto si pak pretizi specificke pouziti
	width: 4em;
	height: 4em;

	background-color: $color_background-8_alpha-mid;


	span {
		display: none;
	}
}
.button-social:hover {
	background-color: $color_background-0;
}




//
// skiny
//

// obecny bgcol
.button-social--bgcol {
}
// obecny outdeco
.button-social--outdeco {

	box-sizing: border-box;
	border: 1px solid $color_background-4;

	background-color: transparent;

	background-size: 1.5em 1.5em; // pozor, nekde specificky pretezovano dle obrazku
}
.button-social--outdeco:hover {

	background-color: $color_background-2_alpha-mid;
}



//
// specificke varianty
//

// obecne

.button-social--share {
	background-image: $file_button_social_share;
}

.button-social--newsletter {
	background-image: $file_button_social_newsletter;
}
.button-social--newsletter:hover {
	background-image: $file_button_social_newsletter_colored-73737f;
}
// bgcol varianta
.button-social--newsletter.button-social--bgcol {
	background-color: $color_extsub_social_newsletter;
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_background-4;
	}
}
.button-social--newsletter.button-social--bgcol:hover {
	background-image: $file_button_social_newsletter;
	background-color: $color_extsub_social_newsletter;
}
// outdeco varianta
.button-social--newsletter.button-social--outdeco,
.button-social--newsletter.button-social--outdeco:hover {
	background-image: $file_button_social_newsletter_colored-decoration-main;
	background-size: auto 1.5em;
}

.button-social--rss {
	background-image: $file_button_social_rss;
}
.button-social--rss:hover {
	background-image: $file_button_social_rss_colored-73737f;
}
// bgcol varianta
.button-social--rss.button-social--bgcol {
	background-color: $color_extsub_social_rss;
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_background-4;
	}
}
.button-social--rss.button-social--bgcol:hover {
	background-image: $file_button_social_rss;
	background-color: $color_extsub_social_rss;
}
// outdeco varianta
.button-social--rss.button-social--outdeco,
.button-social--rss.button-social--outdeco:hover {
	background-image: $file_button_social_rss_colored-decoration-main;
	background-size: auto 1.5em;
}

// socialni site a sluzby

.button-social--facebook {
	background-image: $file_button_social_facebook;
	background-size: auto 1.25em;
}
.button-social--facebook:hover {
	background-image: $file_button_social_facebook_colored;
}
// bgcol varianta
.button-social--facebook.button-social--bgcol {
	background-color: $color_extsub_social_facebook;
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_background-4;
	}
}
.button-social--facebook.button-social--bgcol:hover {
	background-image: $file_button_social_facebook;
	background-color: $color_extsub_social_facebook;
}
// outdeco varianta
.button-social--facebook.button-social--outdeco,
.button-social--facebook.button-social--outdeco:hover {
	background-image: $file_button_social_facebook_colored-decoration-main;
	background-size: auto 1.5em;
}


.button-social--instagram {
	background-image: $file_button_social_instagram;
	background-size: auto 1.25em;
}
.button-social--instagram:hover {
	background-image: $file_button_social_instagram_colored;
}
// bgcol varianta
.button-social--instagram.button-social--bgcol {
	background-color: $color_extsub_social_instagram;
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_background-4;
	}
}
.button-social--instagram.button-social--bgcol:hover {
	background-image: $file_button_social_instagram;
	background-color: $color_extsub_social_instagram;
}
// outdeco varianta
.button-social--instagram.button-social--outdeco,
.button-social--instagram.button-social--outdeco:hover {
	background-image: $file_button_social_instagram_colored-decoration-main;
	background-size: auto 1.5em;
}


.button-social--x {
	background-image: $file_button_social_x;
}
.button-social--x:hover {
	background-image: $file_button_social_x_colored;
}
// bgcol varianta
.button-social--x.button-social--bgcol {
	background-color: $color_extsub_social_x;
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_background-4;
	}
}
.button-social--x.button-social--bgcol:hover {
	background-image: $file_button_social_x;
	background-color: $color_extsub_social_x;
}
// outdeco varianta
.button-social--x.button-social--outdeco,
.button-social--x.button-social--outdeco:hover {
	background-image: $file_button_social_x_colored-decoration-main;
}


.button-social--googleplus {
	background-image: $file_button_social_googleplus;
}
.button-social--googleplus:hover {
	background-image: $file_button_social_googleplus_colored;
}
// bgcol varianta
.button-social--googleplus.button-social--bgcol {
	background-color: $color_extsub_social_googleplus;
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_background-4;
	}
}
.button-social--googleplus.button-social--bgcol:hover {
	background-image: $file_button_social_googleplus;
	background-color: $color_extsub_social_googleplus;
}
// outdeco varianta
.button-social--googleplus.button-social--outdeco,
.button-social--googleplus.button-social--outdeco:hover {
	background-image: $file_button_social_googleplus_colored-decoration-main;
	background-size: auto 1.25em;
}


.button-social--youtube {
	background-image: $file_button_social_youtube;
}
.button-social--youtube:hover {
	background-image: $file_button_social_youtube_colored;
}
// bgcol varianta
.button-social--youtube.button-social--bgcol {
	background-color: $color_extsub_social_youtube;
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_background-4;
	}
}
.button-social--youtube.button-social--bgcol:hover {
	background-image: $file_button_social_youtube;
	background-color: $color_extsub_social_youtube;
}
// outdeco varianta
.button-social--youtube.button-social--outdeco,
.button-social--youtube.button-social--outdeco:hover {
	background-image: $file_button_social_youtube_colored-decoration-main;
	background-size: auto 1.25em;
}


.button-social--linkedin {
	background-image: $file_button_social_linkedin;
}
.button-social--linkedin:hover {
	background-image: $file_button_social_linkedin_colored;
}
// bgcol varianta
.button-social--linkedin.button-social--bgcol {
	background-color: $color_extsub_social_linkedin;
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_background-4;
	}
}
.button-social--linkedin.button-social--bgcol:hover {
	background-image: $file_button_social_linkedin;
	background-color: $color_extsub_social_linkedin;
}
// outdeco varianta
.button-social--linkedin.button-social--outdeco,
.button-social--linkedin.button-social--outdeco:hover {
	background-image: $file_button_social_linkedin_colored-decoration-main;
	background-size: auto 1.25em;
}

.button-social--telegram {
	background-image: $file_button_social_telegram;
}
.button-social--telegram:hover {
	background-image: $file_button_social_telegram_colored;
}
// bgcol varianta
.button-social--telegram.button-social--bgcol {
	background-color: $color_extsub_social_telegram;
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_background-4;
	}
}
.button-social--telegram.button-social--bgcol:hover {
	background-image: $file_button_social_telegram;
	background-color: $color_extsub_social_telegram;
}
// outdeco varianta
.button-social--telegram.button-social--outdeco,
.button-social--telegram.button-social--outdeco:hover {
	background-image: $file_button_social_telegram_colored-decoration-main;
}