//
// B-020 Styles
//



.b-020 {
	
	// vizualni debug
	// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec
	max-width: 400px;
	
}
	
	.b-020__subsection {
		
		padding: 1em;
		
		background-color: $color_background-2;
		
	}
		
		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-020 .broadcast-times {
			
			margin-top: 0;
		}
			
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-020 .broadcast-times h5 {
				
				margin-top: 0;
			}
