//
// B-060 Styles
//



.b-060 {
}

	.b-060__list {
		background-color: $color_background-1;

		padding: 1em;
	}

		.b-060__list-item {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;

			margin: 0;
			padding-top: 1em;
			padding-bottom: 1em;

			border-bottom: 1px solid $color_divider;
		}

			.b-060__label {
				flex-grow: 1;
				color: $color_text_paragraph;

				margin-right: 1em;

				font-size: 16px;
			}

			.b-060__value {
				flex-shrink: 0;

				color: $color_text_accent;

				@include typography_font(medium);
				font-size: 18px;
			}

			.b-060__list-item--total .b-060__value {
				@include typography_font(medium);
				font-size: 22px;
			}


	.b-060__list-item:first-of-type {
		padding-top: 0;
	}

	.b-060__list-item:last-of-type {
		padding-bottom: 0;
	}

	.b-060__list-item--total {
		margin-top: 0.25em;

		border-top: 1px solid $color_divider;
		border-bottom: none;
	}





// vizualni debug - plab only
.patternlab .b-060 {
	max-width: 400px;
}
