//
// B-002i Styles
//
// markup tohoto boxu je velmi podobny b002b
//



$b-002i_element-spacing_mobile: 2em;
$b-002i_element-spacing_desktop: 2em;


.b-002i {
}

	// obecny seznam
	.b-002i__list {
	}

		// obecny element
		.b-002i__list-item {
		}

			.b-002i__block {
				position: relative;
			}

				.b-002i__image {
				}

					// ikonove priznaky
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-002i__image .badge {
						position: absolute;
						top: 0;
						right: 0;
					}


				.b-002i__overlay {
					position: absolute;
					bottom: 0;
					left: 10px;
					right: 10px;
				}

					.b-002i__overlay-content {
					}


			// obecne vlastnosti nadpisu prvniho elementu
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-002i__list-item p {
				display: inline-block;
				margin: 0.5em 0 0 0;
				color: $color_text_paragraph;
				@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
			}

			// obecne vlastnosti nadpisu prvniho elementu
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-002i__list-item h3 {
				margin: 0;
				padding: 0.5em 0 0 0;
			}


	// pseudoseznam s jednim (prvnim) elementem
	.b-002i__list--primary {
	}

		// specifika: prvni velky element
		.b-002i__list-item--list-primary {

			flex-basis: 100%;
			margin-bottom: $b-002i_element-spacing_mobile;

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: calc(#{$b-002i_element-spacing_desktop} - 0.5em);
			}
		}

			// obecne vlastnosti nadpisu prvniho elementu
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-002i__list-item--list-primary h3 {
			}

			.b-002i__overlay--item-list-primary {
			}


	// seznam nasledujicich elementu
	.b-002i__list--following {

		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}
	}

	// specifika: nasledujici elementy (vsechny)
	.b-002i__list-item--list-following {
		margin-bottom: $b-002i_element-spacing_mobile;

		@include breakpoint($breakpoint_desktop) {
			flex-basis: 50%;
			margin-bottom: 0;
			margin-right: $b-002i_element-spacing_desktop;
		}
	}
	.b-002i__list-item--list-following:last-child {
		margin-bottom: 0;

		@include breakpoint($breakpoint_desktop) {
			margin-right: 0;
		}
	}





// vizualni debug - plab only
.patternlab .b-002i {
	
	@include breakpoint($breakpoint_desktop) {
		// vizualni debug
		// tenhle box stejne neni urceny na vetsi sirku nez main sloupec (v desktop)
		max-width: 800px;
	}
}