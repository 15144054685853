//
// Styles - Node Type Page, Node Type Recipe
//



.node-type-page,
.node-type-recipe {

	// title
	h1.page-type,
	h1.recipe-type {
		//width: calc(100% - 2.75em); // kvuli soc. buttonu na mobilu

		margin: 1em 0;
		margin-bottom: 0.5em;
		margin-bottom: -20px; // hack pro variantu s nebo bez h1

		line-height: 1.8rem;

		color: $color_text_accent;

	 	@include breakpoint($breakpoint_desktop) {
			width: 66.66667%; // viz .l-grid--8-1

			margin-bottom: -29px; // hack pro variantu s nebo bez h1
			padding-right: 25px; // viz .l-grid--8-1

			line-height: 3rem;
		}

		&.article-type--image {
		 	@include breakpoint($breakpoint_desktop) {
				width: 100%;
			}
		}
	}



	// messages
	.messages {
		margin-top: calc(20px + #{$node_messages_margin_mobile}); // fix hacku pro variantu s nebo bez h1

		@include breakpoint($breakpoint_desktop) {
				margin-top: calc(29px + #{$node_messages_margin}); // fix hacku pro variantu s nebo bez h1
			}
	}



	// content wrapper - _node.scss
	.inner-content-wrapper {
		margin-top: 32px; // hack pro variantu s nebo bez h1

		@include breakpoint($breakpoint_desktop) {
			margin-top: 48px; // hack pro variantu s nebo bez h1
		}
	}

		// grid
		.l-grid--8-1 {
		}

			// modified, show, top social buttons
			.content-1-1 {
			}

		   // primary image/audio
			.content-1-2 {

				.file-audio {
					margin-left: 0;
				}
			}

			// vertical soc. buttons, perex, body
			.content-1-3 {
			}

			// main audio anchor, authors, bottom soc. buttons
			.content-1-4 {
			}

				// fotka autora - fallback
				.view-display-id-node_author_main.box {
					display: none; // opusteno
				}
				// jmeno autora - fallback
				.view-display-id-node_author_main_2.box {
					display: none; // opusteno
				}



				// expired audio
				.article-type--audio .audio-rights-expired {

					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}



		// grid - aside
		.l-grid--4-9 {
			@include breakpoint($breakpoint_desktop) {
				margin-top: 8em;
			}
		}

			// content boxes, ad
			.sidebar-1-1 {
			}

			// content boxes
			.sidebar-1-2 {
			}


	.field-perex {
		margin-top: 2em;
	}

	.b-027 {
		margin-top: 0;

		@include breakpoint($breakpoint_desktop) {
			margin-left: 70px;
			padding-left: 30px;
		}
	}
}





/*
 * Pokud nema hlavni obrazek/audio/video
 */
.node-type-page.no-main-media {

	// content wrapper - _node.scss
	.inner-content-wrapper {
	}

		// grid
		.l-grid--8-1 {
		}

			// modified, show, top social buttons
			// modified, show, top social buttons
			.content-1-1 {
				min-height: 0; // kvuli soc. buttonu
			}


		// grid - aside
		.l-grid--4-9 {
			@include breakpoint($breakpoint_desktop) {
				margin-top: 0;
			}
		}


	.field-perex {
		margin-top: 0;
	}

	.b-027 {
		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
			padding-left: 0;
		}
	}
}
