//
// A-004b Styles
//
// system oddelovaci pseudolisty je odvozen z b001b,
// ne tak zobrazeni sipky vpravo. to je v b001b udelano s prioritou zachovani jednoducheho markupu b001a
// toto reseni s elementem navic je jednodussi a bez zbytecneho paddingovani praveho okraje
//


$a-004b_list_padding-left: 0.25em;
$a-004b_item_padding_horizontal: 1em;
$a-004b_item_background-arrow-size: 14px;



.a-004b {
}

	.a-004b__list {

		border: 1px solid $color_divider;

		padding-left: $a-004b_list_padding-left;
	}

		.a-004b__list-item {
		}

			.a-004b__link {

				position: relative;

				display: flex;
				flex-direction: row;
				flex-wrap: no-wrap;

				width: 100%;
				box-sizing: border-box;

				padding: 1em $a-004b_item_padding_horizontal;

				@include link-style($color_text_link, none, $color_text_link, none);

				@include breakpoint($breakpoint_desktop) {

					padding: 1.25em $a-004b_item_padding_horizontal;
				}
			}

				.a-004b__iterator {

					display: inline-block;

					color: $color_text_paragraph;

					margin-right: $a-004b_item_padding_horizontal;

					// ala h3, u nasledujici h3 pokracuje
					@include adjust-font-size-to($fontsize_h3);
					@include breakpoint($breakpoint_desktop) {
						@include adjust-font-size-to($fontsize_h3_desktop);
					}
				}

				// vnoreny atom a tedy ne pres specifickou tridu
				.a-004b__link > h3 {

					flex-grow: 1;

					margin: 0;

					// extra specifikace aby prezilo vlozeni do plneho zneni, ktere si h3 prebiji samo
					@include adjust-font-size-to($fontsize_h3);
					@include breakpoint($breakpoint_desktop) {
						@include adjust-font-size-to($fontsize_h3_desktop);
					}
				}
				// konstrukt hoveru celeho bloku menici zobrazeni h3
				.a-004b__link:hover > h3 {

					text-decoration: underline;
				}

				.a-004b__icon-arrow {

					flex-grow: 0;
					flex-shrink: 0;
					align-self: center;

					width: $a-004b_item_background-arrow-size;
					height: $a-004b_item_background-arrow-size;

					margin-left: $a-004b_item_padding_horizontal;

					background-image: $file_icon_arrow_single_east_colored-decoration-main;
					background-repeat: no-repeat;
					background-position: right center;
					background-position-y: center; // IE safe positioning
				}


			// oddelovaci pseudo linka...
			.a-004b__list-item > .a-004b__link:before {

				content: "";

				position: absolute;
				left: 0; // fallback pro calc, pokud nefunguje calc sirky, zafunguje 100% a musime zarovnat uplne vlevo)
				left: calc(#{$a-004b_item_padding_horizontal});
				bottom: 0;

				width: 100%; // fallback pro calc
				width: calc(100% - 2 * #{$a-004b_item_padding_horizontal});

				border-bottom: 1px solid $color_divider;
			}
			// ...neni na poslednim miste
			.a-004b__list-item:last-child > .a-004b__link:before {

				//content: initial; // nefunguje v IE
				display:none;
			}
