//
// Svatebky epizody
//

$svatebky-custom-color: rgba(237,222,88, 0.9);
$svatebky-font-size: .9rem;
$svatebky-font-size-desktop: 1.2rem;
$svatebky-font-size-availability: .75rem;


// epizody
.b-100b {

}

	.b-100b .b-100b__svatebky-episode {
		display: flex;
		flex-wrap: wrap;
	}

		.b-100b .b-100b__svatebky-episode-wrap {
			flex-basis: 40%;
			flex-grow: 1;
			margin-right: 15px;
			margin-bottom: 15px;
			position: relative;
			@include breakpoint($breakpoint_desktop) {
				flex-basis: calc(25% - 30px);
				margin-right: 30px;
				margin-bottom: 30px;
			}
		}

		.b-100b .b-100b__svatebky-episode-wrap:nth-child(2n) {
			margin-right: 0;
			@include breakpoint($breakpoint_desktop) {
				margin-right: 30px;
			} 
		} 

		.b-100b .b-100b__svatebky-episode-wrap:nth-child(4n) {
			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}

		.b-100b .b-100b__svatebky-episode-wrap:hover a.b-100b__svatebky-episode-item .b-100b__svatebky-episode-name-wrap h3 {
			text-decoration: underline;
			background-color: $color_background-8;
		}

			.b-100b .b-100b__svatebky-episode-item {
				display: flex;
			}
				.b-100b .b-100b__svatebky-episode-item img {
					display: block;
				}

			.b-100b .b-100b__svatebky-episode-name-wrap {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 0px;
				right: 0;
				bottom: 0;
				left: 0;
				overflow: hidden;
			}

				.b-100b .b-100b__svatebky-episode-name-wrap h3 {
					color: $color_background-0;
					margin: 0;
					padding: 5px 10px;
					max-width: 90%;
					background-color: $color_background-8_alpha-low;
					line-height: 1.2;
					font-size: $svatebky-font-size;
					transition: background-color .4s;
					text-align: center;
					@include no-select;
					@include breakpoint($breakpoint_desktop) {
						font-size: $svatebky-font-size-desktop;
					}
				}

					.b-100b .b-100b__svatebky-episode-name-wrap h3 a {
						line-height: 1.2;
						font-size: $svatebky-font-size;
						color: $color_background-0;
					}

			.b-100b .b-100b__svatebky-episode-available {
				margin: 0;
				padding: 5px 10px;
				position: absolute;
				bottom: 0;
				width: 100%;
				background-color: $svatebky-custom-color;
				text-transform: uppercase;
				text-align: center;
				font-size: $svatebky-font-size-availability;
				@include no-select;
				@include breakpoint($breakpoint_desktop) {
					padding: 3px 10px;
				} 
			}
			.b-100b .b-100b__desktop-only {
				display: none;
				@include breakpoint($breakpoint_desktop) {
					display: inline-block;
				}
			}
