//
// Files
//

$cache_buster: unique_id();
@function url_t($url) {
    @return url($url + '?timestamp=' + $cache_buster);
}

// Top menu
$file_icon_hamburger:url_t('../images/icon/ico_hamburger.svg');
$file_icon_hamburger_colored-73737f:url_t('../images/icon/ico_hamburger_colored-73737f.svg');
$file_icon_hamburger_colored-555561:url_t('../images/icon/ico_hamburger_colored-555561.svg');
$file_icon_hamburger_colored-white:url_t('../images/icon/ico_hamburger_colored-white.svg');
$file_icon_hamburger_colored-black:url_t('../images/icon/ico_hamburger_colored-black.svg');
$file_icon_hamburger_colored-bg2:url_t('../images/icon/ico_hamburger_colored-white.svg');

$file_icon_search:url_t('../images/icon/ico_search.svg');
$file_icon_search_colored-white:url_t('../images/icon/ico_search_colored-white.svg'); // white
$file_icon_search_colored-black:url_t('../images/icon/ico_search_colored-black.svg'); // black
$file_icon_search_colored-bg2:url_t('../images/icon/ico_search_colored-bg2.svg');
$file_icon_search_colored-bg5:url_t('../images/icon/ico_search_colored-bg5.svg');


// Main logos
$file_logo_corporate_main:url_t('../images/logo/rozhlas_colored-73737f.svg'); // je tam sede logo, main by asi melo byt cosi jineho?
$file_logo_corporate_colored-73737f:url_t('../images/logo/rozhlas_colored-73737f.svg'); // seda, napr. v hlavni nav liste
$file_logo_corporate_colored-white:url_t('../images/logo/rozhlas_colored-white.svg'); // bila, napr. v paticce
$file_logo_corporate_colored-bg2:url_t('../images/logo/rozhlas_colored-bg2.svg'); // bg2, napr. v hlavni nav liste Portal

// Main logos EN
$file_logo_corporate_en_main:url_t('../images/logo/czech-radio_colored-73737f.svg'); // je tam sede logo, main by asi melo byt cosi jineho?
$file_logo_corporate_en_colored-73737f:url_t('../images/logo/czech-radio_colored-73737f.svg'); // seda, napr. v hlavni nav liste
$file_logo_corporate_en_colored-white:url_t('../images/logo/czech-radio_colored-white.svg'); // bila, napr. v paticce
$file_logo_corporate_en_colored-bg2:url_t('../images/logo/czech-radio_colored-bg2.svg'); // bg2, napr. v hlavni nav liste Portal

// iRozhlas & iRozhlas.cz
$file_logo_irozhlascz_colored-main:url_t('../images/logo/irozhlascz_colored-main.svg'); // main barva iRozhlas.cz
$file_logo_irozhlascz_colored-white:url_t('../images/logo/irozhlascz_colored-white.svg'); // iRozhlas.cz bila
$file_logo_irozhlas_colored-white:url_t('../images/logo/irozhlas_colored-white.svg'); // iRozhlas bila

// mujRozhlas
$file_logo_mujrozhlas_colored-main:url_t('../images/logo/mujrozhlas_colored-main.svg'); // main barva mujRozhlas
$file_logo_mujrozhlas_colored-white:url_t('../images/logo/mujrozhlas_colored-white.svg'); // mujRozhlas bila
$file_logo_mujrozhlas_colored-grey:url_t('../images/logo/mujrozhlas_colored-grey.svg'); // mujRozhlas seda

// Radiozurnal
$file_logo_radiozurnal_main:url_t('../images/logo/radiozurnal_colored-main.svg'); // main barva stanice
$file_logo_radiozurnal_colored-white:url_t('../images/logo/radiozurnal_colored-white.svg'); // bila

// Dvojka
$file_logo_dvojka_main:url_t('../images/logo/dvojka_colored-main.svg'); // main barva stanice
$file_logo_dvojka_colored-white:url_t('../images/logo/dvojka_colored-white.svg'); // bila

// Vltava
$file_logo_vltava_main:url_t('../images/logo/vltava_colored-main.svg'); // main barva stanice
$file_logo_vltava_colored-white:url_t('../images/logo/vltava_colored-white.svg'); // bila

// Wave
$file_logo_wave_main:url_t('../images/logo/wave_colored-main.svg'); // main barva stanice
$file_logo_wave_colored-white:url_t('../images/logo/wave_colored-white.svg'); // bila

// D-dur
$file_logo_d-dur_main:url_t('../images/logo/ddur_colored-main.svg'); // main barva stanice
$file_logo_d-dur_colored-white:url_t('../images/logo/ddur_colored-white.svg'); // bila

// Jazz
$file_logo_jazz_main:url_t('../images/logo/jazz_colored-main.svg'); // main barva stanice
$file_logo_jazz_colored-white:url_t('../images/logo/jazz_colored-white.svg'); // bila

// Junior
$file_logo_junior_main:url_t('../images/logo/junior_colored-main.svg'); // main barva stanice
$file_logo_junior_colored-white:url_t('../images/logo/junior_colored-white.svg'); // bila

// Plus
$file_logo_plus_main:url_t('../images/logo/plus_colored-main.svg'); // main barva stanice
$file_logo_plus_colored-white:url_t('../images/logo/plus_colored-white.svg'); // bila

// Brno
$file_logo_brno_main:url_t('../images/logo/brno_colored-main.svg'); // main barva stanice
$file_logo_brno_colored-white:url_t('../images/logo/brno_colored-white.svg'); // bila

// Budejovice
$file_logo_budejovice_main:url_t('../images/logo/budejovice_colored-main.svg'); // main barva stanice
$file_logo_budejovice_colored-white:url_t('../images/logo/budejovice_colored-white.svg'); // bila

// Hradec
$file_logo_hradec_main:url_t('../images/logo/hradec_colored-main.svg'); // main barva stanice
$file_logo_hradec_colored-white:url_t('../images/logo/hradec_colored-white.svg'); // bila

// Liberec
$file_logo_liberec_main:url_t('../images/logo/liberec_colored-main.svg'); // main barva stanice
$file_logo_liberec_colored-white:url_t('../images/logo/liberec_colored-white.svg'); // bila

// Olomouc
$file_logo_olomouc_main:url_t('../images/logo/olomouc_colored-main.svg'); // main barva stanice
$file_logo_olomouc_colored-white:url_t('../images/logo/olomouc_colored-white.svg'); // bila

// Ostrava
$file_logo_ostrava_main:url_t('../images/logo/ostrava_colored-main.svg'); // main barva stanice
$file_logo_ostrava_colored-white:url_t('../images/logo/ostrava_colored-white.svg'); // bila

// Pardubice
$file_logo_pardubice_main:url_t('../images/logo/pardubice_colored-main.svg'); // main barva stanice
$file_logo_pardubice_colored-white:url_t('../images/logo/pardubice_colored-white.svg'); // bila

// Plzen
$file_logo_plzen_main:url_t('../images/logo/plzen_colored-main.svg'); // main barva stanice
$file_logo_plzen_colored-white:url_t('../images/logo/plzen_colored-white.svg'); // bila

// Regina
$file_logo_regina_main:url_t('../images/logo/regina_colored-main.svg'); // main barva stanice
$file_logo_regina_colored-white:url_t('../images/logo/regina_colored-white.svg'); // bila

// Radio DAB
$file_logo_dab_main:url_t('../images/logo/dab_colored-main.svg'); // main barva stanice
$file_logo_dab_colored-white:url_t('../images/logo/dab_colored-white.svg'); // bila
$file_logo_dab_colored-corporate:url_t('../images/logo/dab_colored-corporate.svg'); // korporatni modra

// Sever
$file_logo_sever_main:url_t('../images/logo/sever_colored-main.svg'); // main barva stanice
$file_logo_sever_colored-white:url_t('../images/logo/sever_colored-white.svg'); // bila

// Vary
$file_logo_vary_main:url_t('../images/logo/vary_colored-main.svg'); // main barva stanice
$file_logo_vary_colored-white:url_t('../images/logo/vary_colored-white.svg'); // bila

// Vysocina
$file_logo_vysocina_main:url_t('../images/logo/vysocina_colored-main.svg'); // main barva stanice
$file_logo_vysocina_colored-white:url_t('../images/logo/vysocina_colored-white.svg'); // bila

// Zlin
$file_logo_zlin_main:url_t('../images/logo/zlin_colored-main.svg'); // main barva stanice
$file_logo_zlin_colored-white:url_t('../images/logo/zlin_colored-white.svg'); // bila

// Region (Stredni Cechy)
$file_logo_region_main:url_t('../images/logo/region_colored-main.svg'); // main barva stanice
$file_logo_region_colored-white:url_t('../images/logo/region_colored-white.svg'); // bila

// Regiony (Radio vaseho kraje)
$file_logo_regiony_main:url_t('../images/logo/regiony_colored-main.svg'); // main barva stanice
$file_logo_regiony_colored-white:url_t('../images/logo/regiony_colored-white.svg'); // bila

// Radio Retro
$file_logo_retro_main:url_t('../images/logo/retro_colored-main.svg'); // main barva stanice
$file_logo_retro_colored-white:url_t('../images/logo/retro_colored-white.svg'); // bila

// Sport
$file_logo_sport_main:url_t('../images/logo/sport_colored-main.svg'); // main barva stanice
$file_logo_sport_colored-white:url_t('../images/logo/sport_colored-white.svg'); // bila

// Radiozurnal Sport
$file_logo_radiozurnalsport_main:url_t('../images/logo/radiozurnalsport_colored-main.svg'); // main barva stanice
$file_logo_radiozurnalsport_colored-white:url_t('../images/logo/radiozurnalsport_colored-white.svg'); // bila

// Radio Praha
//$file_logo_radiopraha_main:url_t('../images/logo/praha_colored-main.svg'); // main barva stanice
//$file_logo_radiopraha_colored-white:url_t('../images/logo/praha_colored-white.svg'); // bila
$file_logo_radiopraha_main:url_t('../images/logo/prague_colored-main.svg'); // main barva stanice
$file_logo_radiopraha_colored-white:url_t('../images/logo/prague_colored-white.svg'); // bila
$file_logo_radioprague-int_main:url_t('../images/logo/prague-int_colored-main.svg'); // main barva stanice
$file_logo_radioprague-int_colored-white:url_t('../images/logo/prague-int_colored-white.svg'); // bila

// Radiozurnal
//$file_logo_radioteka_main:url_t('../images/logo/radioteka_colored-main.svg'); // main barva
$file_logo_radioteka_colored-white:url_t('../images/logo/radioteka_colored-white.svg'); // bila

// Program
//$file_logo_program_main:url_t('../images/logo/program_colored-main.svg'); // main barva
$file_logo_program_colored-white:url_t('../images/logo/program_colored-white.svg'); // bila

// Rada
$file_logo_rada_main:url_t('../images/logo/rada_colored-main.svg'); // main barva
$file_logo_rada_colored-white:url_t('../images/logo/rada_colored-white.svg'); // bila

// SOCR
$file_logo-long_socr_desktop_colored-white:url_t('../images/logo/socr_long_desktop_colored-white.svg'); // bila
$file_logo-long_socr_mobile_colored-white:url_t('../images/logo/socr_long_mobile_colored-white.svg'); // bila
$file_logo-long_prso_desktop_colored-white:url_t('../images/logo/prso_long_desktop_colored-white.svg'); // bila
$file_logo-long_prso_mobile_colored-white:url_t('../images/logo/prso_long_mobile_colored-white.svg'); // bila

// Svet rozhlasu
$file_logo_svet_main:url_t('../images/logo/svet_colored-main.svg'); // main barva
$file_logo_svet_colored-white:url_t('../images/logo/svet_colored-white.svg'); // bila

// R
$file_logo_R_main:url_t('../images/logo/R_main.svg');
$file_logo_R_vltava:url_t('../images/logo/R_vltava.svg');
$file_logo_R_plus:url_t('../images/logo/R_plus.svg');
$file_logo_R_wave:url_t('../images/logo/R_wave.svg');
$file_logo_R_region:url_t('../images/logo/R_region.svg');
$file_logo_R_digital:url_t('../images/logo/R_digital.svg');
$file_logo_R_white:url_t('../images/logo/R_white.svg');


// Header icons
$file_icon_search:url_t('../images/icon/ico_search.svg');
$file_icon_menu:url_t('../images/icon/ico_hamburger.svg');



// Article icons
$file_icon_timer:url_t('../images/icon/ico_timer.svg');
$file_icon_timer_colored-main:url_t('../images/icon/ico_timer_colored-main.svg');
$file_icon_quotation:url_t('../images/icon/ico_citace.svg');
$file_icon_tag:url_t('../images/icon/ico_tag.svg');

$file_icon_calendar:url_t('../images/icon/ico_cal.svg');
$file_icon_calendar_colored-white:url_t('../images/icon/ico_cal_colored-white.svg'); // white
$file_icon_calendar_colored-black:url_t('../images/icon/ico_cal_colored-black.svg'); // black
$file_icon_calendar-junior:url_t('../images/icon/ico_cal-junior.svg');



// Flag icons
$file_flag_cze:url_t('../images/icon/flag_cz.svg');
$file_flag_eng:url_t('../images/icon/flag_eng.svg');
$file_flag_ger:url_t('../images/icon/flag_de.svg');
$file_flag_esp:url_t('../images/icon/flag_esp.svg');
$file_flag_fra:url_t('../images/icon/flag_fr.svg');
$file_flag_rus:url_t('../images/icon/flag_ru.svg');



// Podcast icons
$file_icon_podcast_colored-main:url_t('../images/icon/ico_podcast_colored-decoration-main.svg'); // main color
$file_icon_mujrozhlas:url_t('../images/icon/ico_subscribe_mujrozhlas.svg'); // black
$file_icon_mujrozhlas_colored-white:url_t('../images/icon/ico_subscribe_mujrozhlas_colored-white.svg'); // white
$file_icon_apple:url_t('../images/icon/ico_subscribe_apple.svg'); // black
$file_icon_apple_colored-white:url_t('../images/icon/ico_subscribe_apple_colored-white.svg'); // white
$file_icon_android:url_t('../images/icon/ico_subscribe_android.svg'); // black
$file_icon_android_colored-white:url_t('../images/icon/ico_subscribe_android_colored-white.svg'); // white
$file_icon_yt:url_t('../images/icon/ico_subscribe_yt.svg'); // black
$file_icon_yt_colored-white:url_t('../images/icon/ico_subscribe_yt_colored-white.svg'); // white
$file_icon_spotify:url_t('../images/icon/ico_subscribe_spotify.svg'); // black
$file_icon_spotify_colored-white:url_t('../images/icon/ico_subscribe_spotify_colored-white.svg'); // white
$file_icon_rss:url_t('../images/icon/ico_subscribe_rss.svg'); // black
$file_icon_rss_colored-white:url_t('../images/icon/ico_subscribe_rss_colored-white.svg'); // white



// Poplatek icons
$file_icon_households_colored-white:url_t('../images/icon/ico_households_colored-white.svg'); // white
$file_icon_businessmen_colored-white:url_t('../images/icon/ico_bussinesmen_colored-white.svg'); // white
$file_icon_legalentities_colored-white:url_t('../images/icon/ico_legalentities_colored-white.svg'); // white

// Badge icons
// vizualni vlastnost v nahledu
// obrazkove priznaky - muze se zarovnavat k obrazku nebo titulku apod
$file_icon_badge_gallery:url_t('../images/icon/minico_cam.svg'); // clanek obsahuje fotogalerii
$file_icon_badge_audio:url_t('../images/icon/minico_sound.svg'); // clanek obsahuje audio
$file_icon_badge_audio_colored-main:url_t('../images/icon/minico_sound_colored-decoration-main.svg');
$file_icon_badge_audio_colored-fff3dd:url_t('../images/icon/minico_sound_colored-fff3dd.svg'); // bezova, napr. junior
$file_icon_badge_video:url_t('../images/icon/minico_video.svg'); // clanek obsahuje video
$file_icon_badge_facebook:url_t('../images/icon/socico_facebook_colored-73737f.svg'); // clanek obsahuje facebook
$file_icon_badge_x:url_t('../images/icon/socico_twitter_colored-73737f.svg'); // clanek obsahuje twitter
$file_icon_badge_youtube:url_t('../images/icon/socico_youtube_colored-73737f.svg'); // clanek obsahuje youtube
$file_icon_badge_instagram:url_t('../images/icon/socico_instagram_colored-73737f.svg'); // clanek obsahuje instagram
$file_icon_badge_audio-play_colored-black:url_t('../images/icon/ico_play_audio-colored-black.svg'); // clanek obsahuje audio (ikona play)
$file_icon_badge_video-play_colored-black:url_t('../images/icon/ico_play_video-colored-black.svg'); // clanek obsahuje audio (ikona play)
$file_icon_badge_serial-play_colored-black:url_t('../images/icon/ico_play_serial_colored-black.svg'); // clanek obsahuje audio serial (ikona play + hamburger)
$file_icon_badge_audio-play_colored-white:url_t('../images/icon/ico_play_audio-colored-white.svg'); // clanek obsahuje audio (ikona play)
$file_icon_badge_serial-play_colored-white:url_t('../images/icon/ico_play_serial_colored-white.svg'); // clanek obsahuje audio serial (ikona play + hamburger)
$file_icon_badge_audio-play_colored-efeff2:url_t('../images/icon/ico_play_audio-colored-efeff2.svg'); // clanek obsahuje audio (ikona play)
$file_icon_badge_serial-play_colored-efeff2:url_t('../images/icon/ico_play_serial_colored-efeff2.svg'); // clanek obsahuje audio serial (ikona play + hamburger)
$file_icon_badge_audio-play_colored-1a1a1e:url_t('../images/icon/ico_play_audio-colored-1a1a1e.svg'); // clanek obsahuje audio (ikona play)
$file_icon_badge_serial-play_colored-1a1a1e:url_t('../images/icon/ico_play_serial_colored-1a1a1e.svg'); // clanek obsahuje audio serial (ikona play + hamburger)
$file_icon_badge_audio-play_colored-0a9ae4:url_t('../images/icon/ico_play_audio-colored-0a9ae4.svg'); // clanek obsahuje audio (ikona play), napr. junior
$file_icon_badge_serial-play_colored-0a9ae4:url_t('../images/icon/ico_play_serial_colored-0a9ae4.svg'); // clanek obsahuje audio serial (ikona play + hamburger), napr. junior



// Playback icons
$file_button_play:url_t('../images/button/btn_play_sprite.svg'); // atlas
$file_button_play_colored-white:url_t('../images/button/btn_play_sprite_colored-white.svg'); // atlas, white
$file_button_play_launcher:url_t('../images/button/btn_play_launcher_sprite.svg'); // atlas
// Playback icons Radio Junior
$file_button_play_launcher_colored-fff3dd:url_t('../images/button/btn_play_launcher_sprite_colored-fff3dd.svg'); // atlas
$file_button_play_colored-fff3dd:url_t('../images/button/btn_play_sprite_colored-fff3dd.svg'); // atlas
$file_button_play_colored-0a9ae4:url_t('../images/button/btn_play_sprite_colored-0a9ae4.svg'); // atlas



// Other icons
//$file_icon_arrow_double_east:url_t('../images/icon/ico_arr_double.svg'); // dvojita sipka vpravo
$file_icon_arrow_single_east:url_t('../images/icon/ico_arr_single.svg'); // sipka vlevo
// junior
$file_icon_arrow_single-junior_west_colored-white:url_t('../images/icon/ico_arr_single-junior_colored-white.svg'); // sipka vlevo
$file_icon_arrow_single-junior_west_colored-0a9ae4:url_t('../images/icon/ico_arr_single-junior_colored-0a9ae4.svg'); // sipka vlevo
$file_icon_arrow_cta-junior_south_colored-white:url_t('../images/icon/ico_arr_cta-junior_colored-white.svg'); // sipka dolu
$file_icon_arrow_cta-junior_south_colored-fff3dd:url_t('../images/icon/ico_arr_cta-junior_colored-fff3dd.svg'); // sipka dolu
// Colored variations
$file_icon_arrow_double_east_colored-white:url_t('../images/icon/ico_arr_double_colored-white.svg'); // sipka dvojita vpravo bila
$file_icon_arrow_double_east_colored-decoration-main:url_t('../images/icon/ico_arr_double_colored-decoration-main.svg'); // sipka dvojita vpravo obarvena
$file_icon_arrow_double_east_colored-decoration-grey:url_t('../images/icon/ico_arr_double_colored-decoration-grey.svg'); // sipka dvojita vpravo obarvena
$file_icon_arrow_single_east_colored-white:url_t('../images/icon/ico_arr_single_east_colored-white.svg'); // sipka vlevo obarvena
$file_icon_arrow_single_east_colored-decoration-main:url_t('../images/icon/ico_arr_single_east_colored-decoration-main.svg'); // sipka vlevo obarvena
$file_icon_arrow_single_north_colored-decoration-main:url_t('../images/icon/ico_arr_single_north_colored-decoration-main.svg'); // sipka nahoru obarvena
$file_icon_arrow_single_south_colored-decoration-main:url_t('../images/icon/ico_arr_single_south_colored-decoration-main.svg'); // sipka dolu obarvena
$file_icon_arrow_single_south_colored-white:url_t('../images/icon/ico_arr_single_south_colored-white.svg'); // sipka dolu obarvena
$file_icon_arrow_single_south_colored-grey:url_t('../images/icon/ico_arr_single_south_colored-grey.svg'); // sipka dolu obarvena

$file_icon_sandtimer:url_t('../images/icon/ico_sandtimer.svg'); // presypacky, default zelene
$file_icon_sandtimer_colored-009645:url_t('../images/icon/ico_sandtimer_colored-009645.svg'); // presypacky, zelene
$file_icon_sandtimer_colored-09e9ea9:url_t('../images/icon/ico_sandtimer_colored-9e9ea9.svg'); // presypacky, sede
$file_icon_sandtimer_colored-white:url_t('../images/icon/ico_sandtimer_colored-white.svg'); // presypacky, bile

$file_icon_note_double:url_t('../images/icon/ico_double_note.svg'); // noticky
$file_icon_note_double_colored-white:url_t('../images/icon/ico_double_note_colored-white.svg');
$file_icon_triangle_south:url_t('../images/icon/ico_arr_more.svg'); // vyplneny trojuhelnik ukazujici dolu
$file_icon_triangle_south_colored-white:url_t('../images/icon/ico_arr_more_colored-white.svg'); // vyplneny trojuhelnik ukazujici dolu, white
$file_icon_triangle_south_colored-black:url_t('../images/icon/ico_arr_more_colored-black.svg'); // vyplneny trojuhelnik ukazujici dolu, black
$file_icon_triangle_south_colored-bg2:url_t('../images/icon/ico_arr_more_colored-bg2.svg'); // vyplneny trojuhelnik ukazujici dolu, bg2
$file_icon_close_colored-519fd7:url_t('../images/icon/ico_close_colored-519fd7.svg'); // zaviraci X
$file_icon_close_colored-white:url_t('../images/icon/ico_close_colored-white.svg'); // zaviraci X bile
$file_icon_upscale_colored-white:url_t('../images/icon/ico_upscale_colored-white.svg'); // upscale tlacitko
$file_icon_zoomin_colored-white:url_t('../images/icon/ico_zoom_in_colored-white.svg'); // zoom in lupa
$file_icon_zoomout_colored-white:url_t('../images/icon/ico_zoom_out_colored-white.svg'); // zoom out lupa
$file_icon_gallery_colored-white:url_t('../images/icon/ico_pic_gallery_colored-white.svg'); // gallery
$file_icon_gallery_colored-73737f:url_t('../images/icon/ico_pic_gallery_colored-73737f.svg'); // gallery
$file_icon_audio_expired:url_t('../images/icon/ico_alert_fff.svg');
$file_icon_audio_expired_line:url_t('../images/icon/ico_alert_fff_line_d6d6de.svg');
$file_icon_info:url_t('../images/icon/ico_info.svg'); // icko v dutem kolecku
$file_icon_star_colored-white:url_t('../images/icon/ico_star_colored-white.svg'); // icko hvezdicka, white
$file_icon_star_colored-ffd400:url_t('../images/icon/ico_star_colored-ffd400.svg'); // icko hvezdicka, yellow

$file_icon_mail:url_t('../images/icon/ico-1-mial.svg'); // mail ikona v kolecku
$file_icon_message:url_t('../images/icon/ico-2-messages.svg'); // sms ikona v kolecku
$file_icon_twitter:url_t('../images/icon/ico-3-twitter.svg'); // twitter ikona v kolecku
$file_icon_facebook:url_t('../images/icon/ico-4-facebook.svg'); // face ikona v kolecku

$file_icon_amazon_alexa:url_t('../images/icon/ico_assist_amazon_alexa.svg'); // ikona pro hlasove asistenty - Amazon Alexa
$file_icon_google_home:url_t('../images/icon/ico_assist_google_home.svg'); // ikona pro hlasove asistenty - Google Home


// Store buttons
$file_button_store_apple:url_t('../images/button/btn_store-apple.svg');
$file_button_store_apple_rounded:url_t('../images/button/btn_store-apple_rounded.svg');
$file_button_store_google:url_t('../images/button/btn_store-google.svg');
$file_button_store_google_rounded:url_t('../images/button/btn_store-google_rounded.svg');
$file_button_store_amazon_rounded:url_t('../images/button/btn_store-amazon_rounded.svg');



// Social buttons
$file_button_social_share:url_t('../images/icon/socico_share.svg');
$file_button_social_sharing_colored-decoration-main:url_t('../images/button/btn_sharing_colored-decoration-main.svg'); // obarveny velky button share

$file_button_social_newsletter:url_t('../images/icon/socico_newsletter.svg');
$file_button_social_rss:url_t('../images/icon/socico_rss.svg');

$file_button_social_newsletter_colored-73737f:url_t('../images/icon/socico_newsletter_colored-73737f.svg');
$file_button_social_rss_colored-73737f:url_t('../images/icon/socico_rss_colored-73737f.svg');

$file_button_social_newsletter_colored-decoration-main:url_t('../images/icon/socico_newsletter_colored-decoration-main.svg');
$file_button_social_rss_colored_decoration-main:url_t('../images/icon/socico_rss_colored-decoration-main.svg');

$file_button_social_facebook:url_t('../images/icon/socico_facebook.svg');
$file_button_social_googleplus:url_t('../images/icon/socico_gplus.svg');
$file_button_social_instagram:url_t('../images/icon/socico_instagram.svg');
$file_button_social_linkedin:url_t('../images/icon/socico_linkedin_colored-white.svg');
$file_button_social_x:url_t('../images/icon/socico_x.svg');
$file_button_social_youtube:url_t('../images/icon/socico_youtube.svg');
$file_button_social_telegram:url_t('../images/icon/socico_telegram.svg');

$file_button_social_facebook_colored:url_t('../images/icon/socico_facebook_colored.svg');
$file_button_social_googleplus_colored:url_t('../images/icon/socico_gplus_colored.svg');
$file_button_social_instagram_colored:url_t('../images/icon/socico_instagram_colored.svg');
$file_button_social_linkedin_colored:url_t('../images/icon/socico_linkedin_colored-007bb6.svg');
$file_button_social_x_colored:url_t('../images/icon/socico_x_colored.svg');
$file_button_social_youtube_colored:url_t('../images/icon/socico_youtube_colored.svg');
$file_button_social_telegram_colored:url_t('../images/icon/socico_telegram_colored.svg');

$file_button_social_facebook_colored-decoration-main:url_t('../images/icon/socico_facebook_colored-decoration-main.svg');
$file_button_social_googleplus_colored-decoration-main:url_t('../images/icon/socico_gplus_colored-decoration-main.svg');
$file_button_social_instagram_colored-decoration-main:url_t('../images/icon/socico_instagram_colored-decoration-main.svg');
$file_button_social_linkedin_colored-decoration-main:url_t('../images/icon/socico_linkedin_colored-decoration-main.svg');
$file_button_social_x_colored-decoration-main:url_t('../images/icon/socico_x_colored-decoration-main.svg');
$file_button_social_youtube_colored-decoration-main:url_t('../images/icon/socico_youtube_colored-decoration-main.svg');
$file_button_social_telegram_colored-decoration-main:url_t('../images/icon/socico_telegram_colored-decoration-main.svg');


// Services
$file_button_service_google:url_t('../images/button/btn_plst_google_sprite.svg'); // atlas
$file_button_service_spotify:url_t('../images/button/btn_plst_spotify_sprite.svg'); // atlas
$file_button_service_youtube:url_t('../images/button/btn_plst_youtube_sprite.svg'); // atlas



// Action buttons
$file_button_action_play:url_t('../images/button/btn_play_sprite.svg'); // atlas
$file_button_action_play_colored-fff3dd:url_t('../images/button/btn_play_sprite_colored-fff3dd.svg'); // atlas
$file_button_action_pause:url_t('../images/button/btn_pause_sprite.svg'); // atlas
$file_button_action_pause_colored-fff3dd:url_t('../images/button/btn_pause_sprite_colored-fff3dd.svg'); // atlas, napr. junior
$file_button_action_stop_colored-fff3dd:url_t('../images/button/btn_stop_sprite_colored-fff3dd.svg'); // atlas
$file_button_action_download:url_t('../images/button/btn_download_sprite.svg'); // atlas
$file_button_action_podcast:url_t('../images/button/btn_podcast_sprite.svg'); // atlas
$file_button_action_share:url_t('../images/button/btn_share_sprite.svg'); // atlas
$file_button_action_share_colored-white:url_t('../images/button/btn_share_sprite_colored-white.svg'); // atlas
$file_button_action_print:url_t('../images/button/btn_print.svg'); // atlas



// Box buttons
$file_button_close:url_t('../images/button/btn_close.svg');

// Box movement icons (soupani pro slidery apod)
$file_button_icon_arrow_east:url_t('../images/button/btn_arr.svg'); // sipka vpravo bila
$file_button_icon_arrow_east_colored-blue:url_t('../images/button/btn_arr_colored-decoration-main.svg'); // sipka vpravo modra

$file_button_icon_arrow_east_colored-white:url_t('../images/button/btn_arr_colored-white.svg');
$file_button_icon_arrow_west:url_t('../images/button/btn_arr.svg'); // sipka vlevo - TODO chybi
$file_button_icon_arrow_north:url_t('../images/button/btn_arr.svg'); // sipka vlevo - TODO chybi
$file_button_icon_arrow_south:url_t('../images/button/btn_arr.svg'); // sipka vlevo - TODO chybi
// Box movement icons with ending
$file_button_icon_arrow_east_end:url_t('../images/button/btn_arr_end.svg'); // sipka vpravo se stopkou




// Checkbox buttons
$file_checkbox_checked:url_t('../images/button/btn_checkbox_checked.svg'); // checkbox symbol zaskrtnuty
$file_checkbox_unchecked:url_t('../images/button/btn_checkbox_unchecked.svg'); // checkbox symbol nezaskrtnuty (jen sedy ctverec)
$file_checkbox_unchecked-2:url_t('../images/button/btn_checkbox_unchecked-2.svg'); // checkbox symbol nezaskrtnuty (sedy ctverec s sedou fajfkou)
