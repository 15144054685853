//
// Zkouskovy epizody
//

$zkouskovy-custom-color: rgba(237,222,88, 0.9);
$zkouskovy-font-size: .9rem;
$zkouskovy-font-size-desktop: 1.2rem;
$zkouskovy-font-size-availability: .75rem;
$zkouskovy-margin-mobile: 16px;
$zkouskovy-margin-desktop: 3.5%;


// epizody
.b-100b {

}

	.b-100b .b-100b__zkouskovy-episode {
		display: flex;
		flex-wrap: wrap;
	}

		.b-100b .b-100b__zkouskovy-episode-wrap {
			flex-basis: calc(50% - 8px);
			margin-right: $zkouskovy-margin-mobile;
			margin-bottom: $zkouskovy-margin-mobile;
			position: relative;
			@include breakpoint($breakpoint_desktop) {
				flex-basis: 31%; // 31 * 3 = 93, zbyva 7% pro tri itemy, ale mame pouze dve mezery, jelikoz mezera 3n prvku je 0, coz vychazi na 3.5%, soucet 100%
				margin-right: $zkouskovy-margin-desktop;
				margin-bottom: $zkouskovy-margin-desktop;
			}
		}

		.b-100b .b-100b__zkouskovy-episode-wrap:nth-child(2n) {
			margin-right: 0;
			@include breakpoint($breakpoint_desktop) {
				margin-right: $zkouskovy-margin-desktop;
			}
		}

		.b-100b .b-100b__zkouskovy-episode-wrap:nth-child(3n) {
			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}

		.b-100b .b-100b__zkouskovy-episode-wrap:hover a.b-100b__zkouskovy-episode-item .b-100b__zkouskovy-episode-name-wrap h3 {
			text-decoration: underline;
			background-color: $color_background-8;
		}

			.b-100b .b-100b__zkouskovy-episode-item {
				display: flex;
			}
				.b-100b .b-100b__zkouskovy-episode-item img {
					display: block;
				}

			.b-100b .b-100b__zkouskovy-episode-name-wrap {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 0px;
				right: 0;
				bottom: 0;
				left: 0;
				overflow: hidden;
			}

				.b-100b .b-100b__zkouskovy-episode-name-wrap h3 {
					color: $color_background-0;
					margin: 0;
					padding: 5px 10px;
					max-width: 90%;
					background-color: $color_background-8_alpha-low;
					line-height: 1.2;
					font-size: $zkouskovy-font-size;
					transition: background-color .4s;
					text-align: center;
					@include no-select;
					@include breakpoint($breakpoint_desktop) {
						font-size: $zkouskovy-font-size-desktop;
					}
				}

					.b-100b .b-100b__zkouskovy-episode-name-wrap h3 a {
						line-height: 1.2;
						font-size: $zkouskovy-font-size;
						color: $color_background-0;
					}

			.b-100b .b-100b__zkouskovy-episode-available {
				margin: 0;
				padding: 5px 10px;
				position: absolute;
				bottom: 0;
				width: 100%;
				background-color: $zkouskovy-custom-color;
				text-transform: uppercase;
				text-align: center;
				font-size: $zkouskovy-font-size-availability;
				@include no-select;
				@include breakpoint($breakpoint_desktop) {
					padding: 3px 10px;
				}
			}

			.b-100b .b-100b__desktop-only {
				display: none;
				@include breakpoint($breakpoint_desktop) {
					display: inline-block;
				}
			}
