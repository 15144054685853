//
// Exposed filters Styles
//



// vars
$view-filters_tab_border: 1px solid $color_decoration_main;
$view-filters_tab_padding-horizontal_desktop: 2.25em;
$view-filters_tab_padding-vertical_mobile: 0.25em;
$view-filters_tab_padding-vertical_desktop: 0.10em;
$view-filters_tab_font-size: 15px;
$view-filters_tab_line-height: 24px;

$view-filters_search-submit-button_width_mobile: 38px;
$view-filters_search-submit-button_width_desktop: 56px;
$view-filters_search-submit_background-icon-size_mobile: 18px;
$view-filters_search-submit_background-icon-size_desktop: 22px;



@mixin view-filters_tab() {

	display: block;

	border: $view-filters_tab_border;
	border-bottom: none; // mobile specificke

	padding: $view-filters_tab_padding-vertical_mobile 0;

	background-color: white;

	font-size: $view-filters_tab_font-size;
  line-height: $view-filters_tab_line-height;
	text-align: center;

	color: $color_decoration_main;
	@include link-style($color_decoration_main, none, $color_decoration_main, none);

	@include no-select();
	cursor: pointer;


	@include breakpoint($breakpoint_desktop) {

		padding: $view-filters_tab_padding-vertical_desktop $view-filters_tab_padding-horizontal_desktop;
		//border-right: none;
		border-bottom: $view-filters_tab_border;
		margin: 0 -1px 0 0;
	}
}
@mixin view-filters_tab--lastchild() {

	border-bottom: $view-filters_tab_border;

	@include breakpoint($breakpoint_desktop) {
		//border-right: $view-filters_tab_border;
	}
}
@mixin view-filters_tab--active() {

	opacity: 0.8;
}
@mixin view-filters_tab--checked() {

	background-color: $color_decoration_main;

	color: $color_background-0;
	@include link-style($color_background-0, none, $color_background-0, none);
}



// tady se na vahu selektoru kasle
// s markupem se stejne v teto fazi neda hnout, tak at v tom neni uplnej border klidne zanoruju
.view-filters {

	margin-bottom: 1em;


	// nema svoji tridu na kterou by se dalo obecne chytit
	// zaroven je potreba resetovat zde pravidla z 05_html_elements
	// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
	form {

		// reset default vlastnosti
		padding: 0;
		background-color: transparent;


		.views-exposed-form {

			.views-exposed-widgets {

				.views-exposed-widget {
					width: 100%; // IE fix
				}


				// reset D
				// obecne jak pro radu checkboxu, tak pro sekci search inputu a submitu
				.views-exposed-widget {

					float: initial;
					clear: both;
					padding: 0;
				}



				/* nevyuzita struktura obalovaci > views-widget > form-type-select > form-checkboxes */
				// filtrovaci checkboxy
				.bef-checkboxes {

					display: flex;
					flex-direction: column;

					@include breakpoint($breakpoint_desktop) {

						flex-direction: row;
						flex-wrap: wrap;
					}

					/* obecny - checkbox item */
					.form-item {

						// border collapse hack - po zalomeni u desktopu
						@include breakpoint($breakpoint_desktop) {

							margin-bottom: -1px;
						}

						input[type='checkbox'] {

							// reset default vlastnosti z default 05_html_elements
							// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
							margin: 0;
							position: initial;
							top: auto;
							width: auto;

							// stylovani
							display: none;
						}

						input[type='checkbox'] + label {

							// reset default vlastnosti z default 05_html_elements
							// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
							@include typography_font(regular);
							text-transform: none;

							// stylovani
							@include view-filters_tab();
						}
						/* last */
						&.form-item:last-child input[type='checkbox'] + label {

							@include view-filters_tab--lastchild();
						}
						/* active */
						input[type='checkbox'] + label:active,
						input[type='checkbox']:checked + label:active {

							@include view-filters_tab--active();
						}
						/* checked */
						input[type='checkbox']:checked + label {

							@include view-filters_tab--checked();
						}
					}
					/* specificke "vse" */
					.form-item.form-item-all {

						a {

							@include view-filters_tab();
						}
						a:active {

							@include view-filters_tab--active();
						}

						&.highlight a {
							background-color: $color_decoration_main;
							color: $color_background-0;
						}
					}
					.form-item.form-item-edit-field-term-theme-all--checked {

						a {

							@include view-filters_tab--checked();
						}
					}
				}




				// kombo search inputu a submitu
				.views-submit-button-wrapper {

					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: stretch;

					margin-top: 1.5em;

					width: 100%; // IE fix


					// search input
					.views-widget {

						flex-grow: 1;
						width: auto;


						@include breakpoint($breakpoint_desktop) {

							flex-grow: 0;
							width: 30%;
						}


						.form-item-combine {

							width: 100%;



							// input editbox
							input[type='text'] {

								// reset default vlastnosti z default 05_html_elements
								// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
								border: 0;
								box-shadow: none;

								// stylovani
								box-sizing: border-box;
								width: 100%;
								height: 2.25em;
								padding-left: 0.5em;

								font-size: 18px;

								background-color: $color_background-2;


								@include breakpoint($breakpoint_desktop) {

									height: 2.25em;

									font-size: 22px;
								}

								&:focus {
									border: 0;
									box-shadow: none;
								}
							}
						}
					}


					// submit
					.views-submit-button {

						padding: 0;

						input[type='submit'] {

							// reset default vlastnosti z default 05_html_elements
							// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
							// - zde netreba vse je nasledne specificky prebito

							// stylovani
							width: $view-filters_search-submit-button_width_mobile;
							height: 2.25em;

							margin-top: 0;
							padding: 1em;

							border: 0;

							text-indent:-9999px;
							color: transparent;
							// IE schovavani textu
							//font-size: 0;
							//line-height: 0;

							font-size: 18px;

							background-color: $color_background-2;

							background-image: $file_icon_search_colored-bg5;
							background-repeat: no-repeat;
							background-position: center center;
							background-size: $view-filters_search-submit_background-icon-size_mobile $view-filters_search-submit_background-icon-size_mobile;

							cursor: pointer;


							@include breakpoint($breakpoint_desktop) {

								width: $view-filters_search-submit-button_width_desktop;
								height: 2.25em;

								font-size: 22px;

								background-size: $view-filters_search-submit_background-icon-size_desktop $view-filters_search-submit_background-icon-size_desktop;
							}
						}
						// TODO pseudohover nedefinovany nikde jak ma vypadat
						input:hover {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

// odrazeni v ruznych pripadech
.b-050e .view-filters, // portal/podcasty
.b-004f .view-filters, // temata/priroda
.page-node-7969971 .b-022a .view-filters { // node 7969971 - vylety
	margin-bottom: 2em;

	@include breakpoint($breakpoint_desktop) {
		margin-bottom: 3em;
	}
}



// v Node nechceme
// nove kvuli boxum b-022a na strance poradu chceme
.page-node:not(.node-type-show) {
	.view-filters {
		.views-exposed-form {
			.views-exposed-widgets {
				.views-submit-button-wrapper {
					display: none;
				}
			}
		}
	}
}



// varianta selectbox + fulltext + mapa
// zatim omezeno na konkretni node, kazda varianta (checkboxy + fulltext, selectbox + fulltext) bude muset mit svoji tridu
.page-node-7969971 {

	.view-filters {

		form {

			// reset default vlastnosti
			padding: 1em;
			background-color: $color_background-2;


			.views-exposed-form {

				.views-exposed-widgets {

					margin-bottom: 0;

					@include breakpoint($breakpoint_desktop) {

						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
						//align-items: center; // kdyz nema select label tak nechceme
						justify-content: space-between;
					}

					.views-exposed-widget {

						width: auto;
					}


					// reset D
					// obecne jak pro radu selectu, tak pro sekci search inputu a submitu
					.views-exposed-widget {

						@include breakpoint($breakpoint_desktop) {

							flex-shrink: 0;

							//width: 100%;

							float: none;
							clear: none;
							padding: 0;

							width: 50%;
						}

						label {

							display:  block;

							@include typography_font(regular);
							text-transform: none;

							margin-bottom: 0.5em;
						}

						.views-widget {

							.form-type-select {

								select {

									width: 100%;

									@include breakpoint($breakpoint_desktop) {

										width: auto;
										min-width: 75%;
									}
								}
							}
						}
					}


					.views-submit-button-wrapper {

						display: flex;

						@include breakpoint($breakpoint_desktop) {

							justify-content: flex-end;
							margin-top: 0;
						}

						.views-widget {

							@include breakpoint($breakpoint_desktop) {

								width: auto;
								margin-left: 2em;
							}

							.form-item-combine {

								input[type=text] {

									background-color: $color_background-0;
								}
							}
						}

						.views-submit-button {

								input[type=submit] {

									background-color: $color_background-0;
								}
						}

						.views-submit-button {

							@include breakpoint($breakpoint_desktop) {

								width: auto;
							}
						}
					}
				}

				.views-exposed-map {

					display: none;

					@include breakpoint($breakpoint_desktop) {

						display: block;
						margin-top: 1.5em;
						//padding-top: 1.5em; // jen s variantou s borderem, ales border nechce

						// border-top: 1px solid $color_background-4; // ales border nechce
					}
        }

					.views-exposed-map svg {}

						.views-exposed-map svg .map-region {
							fill: $color_region_main;
						}

						.views-exposed-map svg .map-link:hover .map-region {
							fill: $color_region_main_darker;
						}

						.views-exposed-map svg .map-link--selected .map-region {
							fill: $color_region_add-1;
						}

						.views-exposed-map svg .map-region,
						.views-exposed-map svg .map-city,
						.views-exposed-map svg .map-city-text {
							fill-rule: evenodd;
						}

						.views-exposed-map svg .map-city {
							fill: $color_background-4;
						}

						.views-exposed-map svg .map-city-text {
							fill: $color_text_accent_inverted;
						}
			}


			.views-exposed-widgets.clearfix::after {

				display: none;
			}
		}
	}
}
