//
// B-010c Styles
// B-010d Styles
// B-010cd Styles
//


$b-010cd_h3_font-size_mobile: 14px;
$b-010cd_h3_line-height_mobile: 18px;

$b-010cd_h3_font-size_desktop: 15px;
$b-010cd_h3_line-height_desktop: 21px;

$b-010cd_item_padding_horizontal_mobile: 10px;
$b-010cd_item_padding_horizontal_desktop: 10px;
$b-010cd_item_padding_vertical_mobile: 8px;
$b-010cd_item_padding_vertical_desktop: 8px;



.b-010c,
.b-010d {
}

	.b-010c__block,
	.b-010d__block {
		position: relative; // kvuli absolutnimu pozicovani gradientu

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;

		height: 90px; // viz img 135 x 90

		overflow: hidden;

		@include breakpoint($breakpoint_desktop) {
			height: 100px; // viz img 150 x 100
		}
	}
	// gradient
	.b-010c__block:before,
	.b-010d__block:before {
		content: '';

		position: absolute;
		right: 0;
		bottom: 0;

		width: calc(100% - 135px); // minus sirka obrazku
		height: 25%;

		background-image: linear-gradient(180deg, rgba($color_background-7, 0), $color_background-7);

		z-index: 2;

		@include breakpoint($breakpoint_desktop) {
			width: calc(100% - 150px); // minus sirka obrazku
		}
	}

		.b-010c__block .image,
		.b-010d__block .image {
			position: relative;

			flex-shrink: 0;

			width: 135px; // 135 x 90

			@include breakpoint($breakpoint_desktop) {
				width: 150px; // 150 x 100
			}
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-010c__block .image .button-play {
			position: absolute;
			left: $b-010cd_item_padding_horizontal_mobile;
			bottom: $b-010cd_item_padding_horizontal_mobile;

			width: 2em;
			height: 2em;

			@include breakpoint($breakpoint_desktop) {
				left: $b-010cd_item_padding_horizontal_desktop;
				bottom: $b-010cd_item_padding_horizontal_desktop;

				width: 2em;
				height: 2em;
			}
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-010c__block h3,
		.b-010d__block h3 {
			flex-grow: 1;

			padding: $b-010cd_item_padding_vertical_mobile $b-010cd_item_padding_horizontal_mobile;
			margin: 0;

			font-size: $b-010cd_h3_font-size_mobile;
			line-height: $b-010cd_h3_line-height_mobile;

			background-color: $color_background-7;

			@include breakpoint($breakpoint_desktop) {
				padding: $b-010cd_item_padding_vertical_desktop $b-010cd_item_padding_horizontal_desktop;

				font-size: $b-010cd_h3_font-size_desktop;
				line-height: $b-010cd_h3_line-height_desktop;
			}
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-010c__block h3 a,
		.b-010d__block h3 a  {
			@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);
		}


	.b-010c__list,
	.b-010d__list {
	}

		.b-010c__list-item,
		.b-010d__list-item  {
			position: relative;

			padding-top: 1.25em;

			@include breakpoint($breakpoint_desktop) {
				padding-top: 1.25em;
			}
		}
		.b-010c__list-item:first-child,
		.b-010d__list-item:first-child  {
			padding-top: 1em;

			@include breakpoint($breakpoint_desktop) {
				padding-top: 1em;
			}
		}

			// play button obal
			.b-010c__item-button-wrapper {
				float: left;
				//margin-right: 0.5em;
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-010c__item-button-wrapper .button-play {
					width: 1.25em;
					height: 1.25em;

					@include breakpoint($breakpoint_desktop) {
						width: 1.25em;
						height: 1.25em;
					}
				}


			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-010c__list-item h3,
			.b-010d__list-item h3 {
				display: block;
				margin: 0;
				margin-left: 2em;

				@include typography_font(regular);
				font-size: $b-010cd_h3_font-size_mobile;
				line-height: $b-010cd_h3_line-height_mobile;

				@include breakpoint($breakpoint_desktop) {
					margin-left: 2em;

					font-size: $b-010cd_h3_font-size_desktop;
					line-height: $b-010cd_h3_line-height_desktop;
				}
			}
			.b-010d__list-item h3 {
				margin-left: 0;

				@include breakpoint($breakpoint_desktop) {
					margin-left: 0;
				}
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-010c__item-title h3 a,
				.b-010d__item-title h3 a {
				}


		// clearfix
		.b-010c__list-item:after,
		.b-010d__list-item:after {
			clear: both;
			content: "";
			display: table;
		}
		// last item
		.b-010c__list-item:last-child,
		.b-010d__list-item:last-child {
			//padding-bottom: 0;
		}



// varianta kdy itemy jsou jednotlive boxy
.b-010cd {
	display: flex;
	flex-direction: column;

	@include breakpoint($breakpoint_desktop) {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.b-010c,
	.b-010d {
		margin-top: 0;
		margin-bottom: 2em;

		@include breakpoint($breakpoint_desktop) {
			margin-bottom: 0;

			// trik jak 3 itemy radku + jejich margin roztahnout na 100%
			// ale ne pokud jich neni na radek plny pocet
			flex-basis: 30%; // toto neni tretina, ale vychozi velikost ktera se roztahne
			flex-grow: 1; // roztahne se pomoci grow do tretin
			max-width: 32%; // (fallback pro no-calc pro nasledujici radek)
			max-width: calc(33.33% - 2em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
			// proc - 2em? protoze je to 3 * 2 / 3 viz (*)

			box-sizing: border-box;

			margin-right: 3em; // (*)
		}
	}

	.b-010c:last-child,
	.b-010d:last-child {
		margin-bottom: 0;

		@include breakpoint($breakpoint_desktop) {
			margin-right: 0;
		}
	}
}





// vizualni debug - plab only
.patternlab .b-010c,
.patternlab .b-010d {

	@include breakpoint($breakpoint_desktop) {
		max-width: 390px;
	}
}
