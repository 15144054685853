//
// Header - megapromo, promo a special
//



/**
 * promo a special hlavicky
 */

$header-promo_variables_list: (

	// portal
	//("portal-radiozurnal", ".portal", "../images/bg/portal/portal-radiozurnal-1939_desktop.jpg", "450px", 1, 2, 3),
	//("portal-wave", ".portal", "../images/bg/portal/portal-wave-rano_desktop.jpg", "450px", 1, 2, 3),
	//("portal-dvojka", ".portal", "../images/bg/portal/portal-dvojka-nadvojcesivyberete_desktop.jpg", "450px", 1, 2, 3),
	//("portal-irozhlas-vinohradska12", ".portal", "../images/bg/portal/portal-irozhlas-vinohradska12_desktop.jpg", "450px", 1, 2, 3),
	//("portal-irozhlas-mshokej2019", ".portal", "../images/bg/portal/portal-irozhlas-mshokej2019_desktop.jpg", "450px", 1, 2, 3),
	//("portal-irozhlas-eurovolby2019", ".portal", "../images/bg/portal/portal-irozhlas-eurovolby2019_desktop.jpg", "450px", 1, 2, 3),
	//("portal-informace-p2p", ".portal", "../images/bg/portal/portal-informace-p2p_desktop.jpg", "450px", 1, 2, 3),
	//("portal-radiozurnal-experiment", ".portal", "../images/bg/portal/portal-radiozurnal-experiment_desktop.jpg", "450px", 1, 2, 3),
	//("portal-dvojka-vrazedneleto", ".portal", "../images/bg/portal/portal-dvojka-vrazedneleto_desktop.jpg", "450px", 1, 2, 3),
	//("portal-radiozurnal-svetluska", ".portal", "../images/bg/portal/portal-radiozurnal-svetluska_desktop.jpg", "450px", 1, 2, 3),
	//("portal-wave-svatebky", ".portal", "../images/bg/portal/portal-wave-svatebky_desktop.jpg", "450px", 1, 2, 3),
	//("portal-irozhlas-vinohradska12", ".portal", "../images/bg/portal/portal-irozhlas-vinohradska12-2_desktop.jpg", "620px", 1, 2, 3),
	("podcasty", ".portal", "../images/bg/portal/portal-portal-podcasty_desktop.jpg", "188px", 1, 2, 3),
	//("portal-dvojka-jekyllhyde", ".portal", "../images/bg/portal/portal-dvojka-jekyllhyde_desktop.jpg", "560px", 1, 2, 3),
	//("portal-radiozurnal-rozdelenisvobodou", ".portal", "../images/bg/portal/portal-radiozurnal-rozdelenisvobodou_desktop.jpg", "560px", 1, 2, 3),
	("vyber", ".portal", "../images/bg/portal/portal-portal-vyber_desktop.jpg", "700px", 1, 2, 3),

	// stanice
	//("radiozurnal-mshokej2019", ".radiozurnal", "../images/bg/radiozurnal/radiozurnal-mshokej2019_desktop.jpg", "435px", 3, 1, 2),
	//("radiozurnal-eurovolby2019", ".radiozurnal", "../images/bg/radiozurnal/radiozurnal-eurovolby2019_desktop.jpg", "435px", 3, 1, 2),
	//("radiozurnal-experiment", ".radiozurnal", "../images/bg/radiozurnal/radiozurnal-experiment_desktop.jpg", "440px", 3, 1, 2),
	//("radiozurnal-svetluska", ".radiozurnal", "../images/bg/radiozurnal/radiozurnal-svetluska_desktop.jpg", "440px", 3, 1, 2),
	("radiozurnal-romove", ".radiozurnal", "../images/bg/radiozurnal/radiozurnal-romove_desktop.jpg", "660px", 3, 1, 2),
	//("dvojka-nadvojcesivyberete", ".dvojka", "../images/bg/dvojka/dvojka-nadvojcesivyberete_desktop.jpg", "435px", 3, 1, 2),
	//("dvojka-vrazedneleto", ".dvojka", "../images/bg/dvojka/dvojka-vrazedneleto_desktop.jpg", "440px", 3, 1, 2),
	//("plus-eurovolby2019", ".plus", "../images/bg/plus/plus-eurovolby2019_desktop.jpg", "435px", 3, 1, 2),
	//("wave-rano", ".wave", "../images/bg/wave/wave-rano_desktop.jpg", "435px", 3, 1, 2),
	//("wave-mikrovlnky", ".wave.page-wavenews, .wave.kratka-zprava", "../images/bg/wave/wave-mikrovlnky_desktop.jpg", "530px", 3, 1, 2),
	//("regiony-xaver", ".brno, .budejovice, .hradec, .liberec, .olomouc, .ostrava, .pardubice, .plzen, .regiony, .sever, .strednicechy, .vary, .vysocina, .zlin", "../images/bg/regiony/regiony-xaver_desktop.jpg", "600px", 3, 1, 2), // vsechny regiony krome reginy
	("wave-svatebky", ".wave.page-svatebky", "../images/bg/wave/wave-svatebky_desktop.jpg", "550px", 3, 1, 2),
	("wave-zkouskovy", ".wave.page-zkouskovy", "../images/bg/wave/wave-zkouskovy_desktop.jpg", "700px", 3, 1, 2),

	// junior
	("junior-default", ".junior", "../images/bg/junior/junior_desktop.jpg", "440px", 3, 1, 2),
	("junior-vsudykuk", ".junior", "../images/bg/junior/junior-vsudykuk2_desktop.jpg", "440px", 3, 1, 2),

	// nestanicni weby
	("concertino", ".concertino, .concertinoen", "../images/bg/concertino/concertino_desktop.jpg", "360px", 1, 2, 3),
	("digital", ".digital", "../images/bg/digital/digital_desktop.jpg", "400px", 1, 2, 3),
	("drds", ".drds", "../images/bg/drds/drds_desktop.jpg", "100vh", 1, 2, 3),
	("poplatek", ".poplatek", "../images/bg/poplatek/poplatek_desktop.png", "224px", 1, 2, 3),
	("presspektivy", ".presspektivy", "../images/bg/presspektivy/presspektivy_desktop.jpg", "360px", 1, 2, 3),
	("prixbohemia", ".prixbohemia", "../images/bg/prixbohemia/prixbohemia_desktop.jpg", "400px", 1, 2, 3),
	("prso", ".prso", "../images/bg/socr/socr_desktop.jpg", "440px", 1, 2, 3),
	("socr", ".socr", "../images/bg/socr/socr_desktop.jpg", "440px", 1, 2, 3),
	("svetluska", ".svetluska", "../images/bg/svetluska/svetluska_desktop.png", "224px", 1, 2, 3),
	("svitis", ".svitis", "../images/bg/svitis/svitis_desktop.jpg", "224px", 1, 2, 3),
	("zelenavlna", ".zelenavlna", "../images/bg/zelenavlna/zelenavlna_desktop.jpg", "200px", 1, 2, 3),

	// porady
	//("ceskakronika", ".plus.ceska-kronika", "../images/bg/ceskakronika/ceskakronika_desktop.jpg", "435px", 3, 1, 2),
	//("historiezlocinu", ".dvojka.page-node-6945272", "../images/bg/historiezlocinu/historiezlocinu_desktop.jpg", "435px", 3, 1, 2),
	//("letosvernem", ".dvojka.leto-s-vernem", "../images/bg/letosvernem/letosvernem_desktop.jpg", "435px", 3, 1, 2),
	//("opuvoduprijmeni", ".dvojka.page-node-7231789", "../images/bg/opuvoduprijmeni/opuvoduprijmeni_desktop.jpg", "435px", 3, 1, 2),
	//("toulkyceskouminulosti", ".dvojka.toulky-ceskou-minulosti", "../images/bg/toulkyceskouminulosti/toulkyceskouminulosti_desktop.jpg", "440px", 3, 1, 2),

	// udalosti
	("event-1939", ".radiozurnal.page-node-7762836, .dvojka.page-node-7762836, .vltava.page-node-7762836, .plus.page-node-7762836, .wave.page-node-7762836, .ddur.page-node-7762836, .jazz.page-node-7762836, .region.page-node-7762836", "../images/bg/1939/1939-stanice_desktop.jpg", "435px", 3, 1, 2),
	("hradozameckeleto", ".region.hradozamecke-leto", "../images/bg/hradozameckeleto/hradozameckeleto_desktop.jpg", "435px", 3, 1, 2),
	("pochoutkovyrok", ".region.pochoutkovy-rok", "../images/bg/pochoutkovyrok/pochoutkovyrok_desktop.jpg", "435px", 3, 1, 2),
	("wave-czeching", ".wave.page-czeching, .wave.section-czeching, .wave.czeching", "../images/bg/wave/wave-czeching_desktop.jpg", "435px", 3, 1, 2),

	// formaty
	("format-kviz", ".radiozurnal.format-kviz, .dvojka.format-kviz, .vltava.format-kviz, .plus.format-kviz, .wave.format-kviz, .ddur.format-kviz, .jazz.format-kviz, .junior.format-kviz, .region.format-kviz", "../images/bg/temata/stanice_kvizy_desktop.jpg", "550px", 3, 1, 2),


	// web informace
	("informace", ".informace", "../images/bg/informace/informace_desktop.jpg", "360px", 1, 2, 3),
	("informace", ".informace.page-informace-hp", "../images/bg/informace/informace-hp_desktop.jpg", "550px", 1, 2, 3),
	("informace", ".informace.page-galerie", "../images/bg/informace/informace-galerie_desktop.jpg", "360px", 1, 2, 3),
	("informace", ".informace.page-ombudsman", "../images/bg/informace/informace-ombudsman_desktop.jpg", "360px", 1, 2, 3),
	("informace", ".informace.page-dps", "../images/bg/informace/informace-dps_desktop.jpg", "360px", 1, 2, 3),

	// web czech radio
	("czechradio", ".czechradio", "../images/bg/informace/informace_desktop.jpg", "360px", 1, 2, 3),

	// web temata
	("temata", ".temata", "../images/bg/temata/temata-jaro_desktop.jpg", "360px", 1, 2, 3), // temata-jaro_desktop.jpg je vyska 360px, temata-zima_desktop.jpg je vyska 450px
	("temata", ".temata.page-temata-hp", "../images/bg/temata/temata-jaro_desktop.jpg", "148px", 1, 2, 3),
	("temata", ".temata.page-historie", "../images/bg/temata/historie_desktop.jpg", "360px", 1, 2, 3),
	("temata", ".temata.page-komentare", "../images/bg/temata/komentare_desktop.jpg", "360px", 1, 2, 3),
	("temata", ".temata.page-kultura", "../images/bg/temata/kultura_desktop.jpg", "360px", 1, 2, 3),
	("temata", ".temata.page-nabozenstvi", "../images/bg/temata/nabozenstvi_desktop.jpg", "360px", 1, 2, 3),
	("temata", ".temata.page-priroda", "../images/bg/temata/priroda_desktop.jpg", "360px", 1, 2, 3),
	("temata", ".temata.page-veda", "../images/bg/temata/veda_desktop.jpg", "360px", 1, 2, 3),
	("temata", ".temata.page-styl", "../images/bg/temata/zivotnistyl_desktop.jpg", "360px", 1, 2, 3),
	("temata", ".temata.page-schodycasu", "../images/bg/schodycasu/schodycasu_desktop.jpg", "310px", 1, 2, 3),
	("temata", ".temata.page-node-7638015", "../images/bg/100letrepubliky/100letrepubliky_desktop.jpg", "310px", 1, 2, 3),
	("temata", ".temata.page-node-7762836", "../images/bg/1939/1939_desktop.jpg", "310px", 1, 2, 3),
	("temata", ".temata.page-node-5998047", "../images/bg/miroslavzikmund/miroslavzikmund_desktop.jpg", "310px", 1, 2, 3),
	("temata", ".temata.page-hry-a-cetba", "../images/bg/temata/hryacetba_desktop.jpg", "360px", 1, 2, 3),
	("temata", ".temata.page-rozhovory", "../images/bg/temata/rozhovory_desktop.jpg", "360px", 1, 2, 3),
	("temata", ".temata.page-node-7963631", "../images/bg/temata/josefcapek_desktop.jpg", "450px", 1, 2, 3),
	("temata", ".temata.page-node-7963682", "../images/bg/temata/bozenanemcova_desktop.jpg", "460px", 1, 2, 3),
	("temata", ".temata.page-historie-bitva-o-rozhlas", "../images/bg/temata/bitvaorozhlas_desktop.jpg", "700px", 1, 2, 3),
	("temata", ".temata.page-historie-bitva-o-rozhlas-pametni-deska", "../images/bg/temata/bitvaorozhlas_desktop.jpg", "700px", 1, 2, 3),
	("temata", ".temata.page-historie-bitva-o-rozhlas-archiv", "../images/bg/temata/bitvaorozhlas_desktop.jpg", "700px", 1, 2, 3),
	("temata", ".temata.page-koronavirus", "../images/bg/temata/coronavirus_desktop.jpg", "550px", 1, 2, 3),
	("temata", ".temata.page-archiv, .temata.page-archiv-fotogalerie, .temata.page-archiv-specialy, .temata.page-archiv-video, .temata.page-hlasyslavnych", "../images/bg/temata/archiv_desktop.jpg", "360px", 1, 2, 3),
	("temata", ".temata.page-kvizy, .temata.format-kviz", "../images/bg/temata/kvizy_desktop.jpg", "360px", 1, 2, 3),

	// default pro hlavicky typu promo a special
	("promo", ".promo", "../images/bg/header/default_desktop.png", "360px", 3, 1, 2),
	("special", ".special", "../images/bg/header/default_desktop.png", "360px", 3, 1, 2)
);




/**
 * megapromo hlavicky
 */

$header-megapromo_variables_list: (

	// test
	("test", ".test", "../images/bg/header/default_desktop.png", $color_background-9, 1, 2, 3),

	// default pro hlavicky typu megapromo
	("megapromo", ".megapromo", "../images/bg/header/default_desktop.png", $color_background-9, 1, 2, 3)
);



// init
.header__promo-wrapper,
.header__promo-wrapper::before {
	display: none;
}



/**
 * promo a special hlavicky
 */

@each $i in $header-promo_variables_list {

	#{nth($i, 2)} { // list: class

		// init
		.header__promo-wrapper.header__promo-wrapper--#{nth($i, 1)} { // list: campaign name
			display: block;
		}

		header {
			display: flex; // kvuli razeni elementu uvnitr
			flex-wrap: nowrap;
			flex-direction: column;
		}

		// top nav
		.b-014a {
			order: 1;
		}

		// top nav bubble
		.b-014b {
			order: 2;
		}

		// header wrapper
		.header__wrapper {
			order: 3;

			position: relative;

			display: flex; // kvuli razeni elementu uvnitr
			flex-wrap: nowrap;
			flex-direction: column;
		}

			// promo header
			.header__promo-wrapper {
				order: #{nth($i, 5)}; // list: promo order
			}

			// station header
			.b-015 {
				order: #{nth($i, 6)}; // list: player order
			}

			// stanicni nav
			.b-016__wrapper {
				order: #{nth($i, 7)}; // list: nav order
			}


		// top nav
		.b-014a {
			z-index: 1;
		}

		// top subnav
		.b-014b {
			z-index: 1;
		}

		// header
		// before: gradient
		// after: bg
		.header__promo-wrapper {
		}
		.header__promo-wrapper--#{nth($i, 1)}::before { // list: campaign name
			@include breakpoint($breakpoint_desktop) {
				content: "";

				position: absolute;
				top: 0;
				left: 0;

				width: 100vw;
				height: #{nth($i, 4)}; // list: bg height

				// gradient v Safari: https://stackoverflow.com/questions/55594942/transparent-gradient-not-working-in-safari
				//background-image: linear-gradient(to top, #fff, transparent, transparent);
				background-image: linear-gradient(to top, $color_background-0, rgba(#ffffff, 0) 65%); // list: gradient color

				z-index: -1;
			}

			// HACK: nez se poprve pohne desktop grid
			@media screen and (min-width: $layout_page_max-width) {
				left: calc(((100vw - #{$layout_page_max-width}) / 2) * -1);
			}
		}
		.header__promo-wrapper--#{nth($i, 1)}::after { // list: campaign name
			content: "";

			background-repeat: no-repeat;
			background-size: 100%;
			background-attachment: scroll;
			background-position: center top;

			position: absolute;
			top: 0;
			left: 0;

			width: 100vw;
			height: 100vh;

			z-index: -2;

			@include breakpoint($breakpoint_desktop) {
				background-image: url('#{nth($i, 3)}'); // list: url
				background-size: 100%;
				background-attachment: fixed;

				position: absolute;

				height: #{nth($i, 4)}; // list: bg height
			}

			// HACK: nez se poprve pohne desktop grid
			@media screen and (min-width: $layout_page_max-width) {
				left: calc(((100vw - #{$layout_page_max-width}) / 2) * -1);
			}
		}

		// stanicni header
		.b-015 {
		}

		// stanicni nav
		.b-016__wrapper {
			margin-bottom: 0;
		}

		// proklikavaci header promo
		.header__promo {}

		.header__promo--desktop {
			display: none;

			@include breakpoint($breakpoint_desktop) {
				display: block;
				flex-basis: 100%;
			}

			img {}
		}
		.header__promo--mobile {
			display: block;
			flex-basis: 100%;

			@include breakpoint($breakpoint_desktop) {
				display: none;
			}

			img {}
		}
		.header__promo--desktop img,
		.header__promo--mobile img {
			display: block;
		}

		.main {
			padding-top: 0.1px;
			margin-top: 0;
		}
		// FIX: kdyz .main nema .clearfix
		.main::after {
			clear: both;
			content: "";
			display: table;
		}

		footer {
			background-color: $color_background-0;
		}

	}

}



/**
 * megapromo hlavicky
 */

@each $i in $header-megapromo_variables_list {

	#{nth($i, 2)} { // list: class

		// init
		.header__promo-wrapper.header__promo-wrapper--#{nth($i, 1)} { // list: campaign name
			display: flex;
		}

		header {
			display: flex; // kvuli razeni elementu uvnitr
			flex-wrap: nowrap;
			flex-direction: column;
		}

		// top nav
		.b-014a {
			order: 1;
		}

		// top nav bubble
		.b-014b {
			order: 2;
		}

		// header wrapper
		.header__wrapper {
			order: 3;

			position: relative;

			display: flex; // kvuli razeni elementu uvnitr
			flex-wrap: nowrap;
			flex-direction: column;
		}

			// promo header
			.header__promo-wrapper {
				order: #{nth($i, 5)}; // list: promo order
			}

			// station header
			.b-015 {
				order: #{nth($i, 6)}; // list: player order
			}

			// stanicni nav
			.b-016__wrapper {
				order: #{nth($i, 7)}; // list: nav order
			}


		// top nav
		.b-014a {
			z-index: 1;
		}

		// top subnav
		.b-014b {
			z-index: 1;
		}

		// header
		// before: gradient
		// after: bg
		.header__promo-wrapper {
			flex-direction: column;
			justify-content: space-between;
		}
		.header__promo-wrapper--#{nth($i, 1)} { // list: campaign name
			height: calc(100% - #{$b-016_menu-bar-height_mobile}); // 100% - vyska navigace, viz 02_drupal/08_components/_B-016.scss

			@include breakpoint($breakpoint_desktop) {
				height: calc(100% - #{$b-016_menu-bar-height_desktop}); // 100% - vyska navigace, viz 02_drupal/08_components/_B-016.scss
				min-height: 460px;
			}
		}
		.header__promo-wrapper--#{nth($i, 1)}::before { // list: campaign name
			content: "";

			// gradient v Safari: https://stackoverflow.com/questions/55594942/transparent-gradient-not-working-in-safari
			//background-image: linear-gradient(to right, #{nth($i, 4)}, transparent, transparent); // list: gradient color
			background-image: linear-gradient(to right, #{nth($i, 4)}, rgba(#000000, 0) 50%); // list: gradient color

			position: fixed;
			top: 0;
			left: 0;
			right: 0;

			width: 100vw;
			//height: calc(100% - #{$b-016_menu-bar-height_mobile}); // 100% - vyska navigace, viz 02_drupal/08_components/_B-016.scss
			height: 100vh;

			z-index: -1;

			@include breakpoint($breakpoint_desktop) {
				position: absolute;
				height: calc(100% - #{$b-016_menu-bar-height_desktop}); // 100% - vyska navigace, viz 02_drupal/08_components/_B-016.scss
				min-height: 460px;
			}

			// HACK: nez se poprve pohne desktop grid
			@media screen and (min-width: $layout_page_max-width) {
				left: calc(((100vw - #{$layout_page_max-width}) / 2) * -1);
			}
		}
		.header__promo-wrapper--#{nth($i, 1)}::after { // list: campaign name
			content: "";

			background-image: url('#{nth($i, 3)}'); // list: url
			background-repeat: no-repeat;
			//background-size: 100%;
			background-size: cover;
			background-attachment: scroll;
			background-position: center top;

			position: fixed;
			top: 0;
			left: 0;

			width: 100vw;
			//height: calc(100% - #{$b-016_menu-bar-height_mobile}); // 100% - vyska navigace, viz 02_drupal/08_components/_B-016.scss
			height: 100vh;

			z-index: -2;

			@include breakpoint($breakpoint_desktop) {
				background-attachment: fixed;
				position: absolute;
				height: calc(100% - #{$b-016_menu-bar-height_desktop}); // 100% - vyska navigace, viz 02_drupal/08_components/_B-016.scss
				min-height: 460px;
			}

			// HACK: nez se poprve pohne desktop grid
			@media screen and (min-width: $layout_page_max-width) {
				left: calc(((100vw - #{$layout_page_max-width}) / 2) * -1);
			}
		}

			// logo
			.header__promo-logo {
				margin-top: 0.75em;
				margin-bottom: 0.75em;
				margin-left: $layout_main_horizontal-padding_mobile;
				margin-right: $layout_main_horizontal-padding_mobile;

				@include breakpoint($breakpoint_desktop) {
					margin-top: 3em;
					margin-bottom: 3em;
					margin-left: 40px;
					margin-right: 0;
				}
			}

				// logo link
				.header__promo-logo-link {
					display: inline-block;
					height: 4em;

					@include breakpoint($breakpoint_desktop) {
						height: 2.5em;
					}
				}

					// logo img
					.header__promo-logo-img {
						width: auto; // default img ma width 100%
						height: 4em;

						@include breakpoint($breakpoint_desktop) {
							height: 2.5em;
						}
					}

					// logo img mobile
					.header__promo-logo-img--mobile {
						display: inline-block;

						@include breakpoint($breakpoint_desktop) {
							display: none;
						}
					}

					// logo img desktop
					.header__promo-logo-img--desktop {
						display: none;

						@include breakpoint($breakpoint_desktop) {
							display: inline-block;
						}
					}


				// logo text
				.header__promo-logo-textcontent {
						display: none;
				}


			// metadata: titulek, perex, button
			.header__promo-metadata {
				border-top: 4px solid $color_corporate_add-1;

				margin-top: 84px; // 5.25em (84px) + 0.75em (12px) logo margin-bottom = 6em (96px)
				margin-bottom: 35px;
				margin-left: 25px;
				margin-right: 75px;

				overflow: hidden;

				@include breakpoint($breakpoint_desktop) {
					max-width: 430px;

					margin-left: 60px;
					margin-top: 48px; // 3em (48px) + 3em (48px) logo margin-bottom = 6em (96px)
					margin-bottom: 75px;
				}
			}

				.header__promo-metadata a {
					@include link-style($color_text_link_inverted, none, $color_text_link_inverted, none);
				}


				// titulek
				.header__promo-metadata h3 {
					color: white;

					@include typography_font(medium);
					font-size: 22px;
					line-height: 28px;

					margin-top: 0.25em;
					margin-bottom: 0;

					@include breakpoint($breakpoint_desktop) {
						font-size: 30px;
						line-height: 36px;

						margin-top: 0.5em;
					}
				}

				// perex
				.header__promo-metadata p {
					color: white;

					@include typography_font(medium);
					font-size: 14px;
					line-height: 20px;

					margin-top: 0.25em;
					margin-bottom: 0.25em;

					@include breakpoint($breakpoint_desktop) {
						font-size: 16px;
						line-height: 22px;

						margin-top: 0.5em;
						margin-bottom: 0.5em;
					}
				}

				// button
				.header__promo-metadata .button-box-flat--readmore {
					display: inline-flex;
					margin-top: 0.25em;

					@include breakpoint($breakpoint_desktop) {
						margin-top: 0.5em;
					}
				}
				.header__promo-metadata .button-box-flat--negative {
					background-color: $color_corporate_add-1;
				}


		// stanicni header
		.b-015 {
		}

		// stanicni nav
		.b-016__wrapper {
			margin-bottom: 0;
		}

		.main {
			padding-top: 0.1px;
			margin-top: 0;
		}
		// FIX: kdyz .main nema .clearfix
		.main::after {
			clear: both;
			content: "";
			display: table;
		}

		footer {
			background-color: $color_background-0;
		}

	}

}



/**
 * specifika
 */

// portal
.portal {

	// before: nekonecna bila linka
	.header__promo {
		position: relative;
	}
	.header__promo--desktop::before,
	.header__promo--mobile::before {
		display: block;

		content: "";

		position: absolute;
		left: 0;

		width: 100vw;
		height: 6px;

		background-color: $color_background-0;
		background-image: none;

		// HACK: nez se poprve pohne desktop grid
		@media screen and (min-width: $layout_page_max-width) {
			left: calc(((100vw - #{$layout_page_max-width}) / 2) * -1);
		}
	}

	// gradient: povoleni
	.header__promo-wrapper--vyber::before {
		@include breakpoint($breakpoint_desktop) {
			display: block;
		}
	}
}

// drds
.drds {

	.header__promo-wrapper--drds::after {
		@include breakpoint($breakpoint_desktop) {
			position: fixed;
		}

		// HACK: nez se poprve pohne desktop grid
		@media screen and (min-width: $layout_page_max-width) {
			left: 0;
		}
	}
}

// junior
.junior {

	// FIX: pozadova barva u prihlasenych + adminimal
	&.admin-menu.adminimal-menu::before {
		height: auto;
	}

	// default header
	.header__promo-wrapper--junior-default {

		.header__promo--desktop {
				height: 100px;
		}

		.header__promo--mobile {}
	}

	// gradient: povoleni
	.header__promo-wrapper::before {
		@include breakpoint($breakpoint_desktop) {
			display: block;
			// gradient v Safari: https://stackoverflow.com/questions/55594942/transparent-gradient-not-working-in-safari
			background-image: linear-gradient(to top, $color_junior_add-1, rgba(#ffffff, 0) 65%);
		}
	}

	// footer
	footer {
		background-color: $color_junior_add-1;
	}
}

// prso/socr
.prso,
.socr {
	// gradient: povoleni
	.header__promo-wrapper--prso::before,
	.header__promo-wrapper--socr::after {
		@include breakpoint($breakpoint_desktop) {
			display: block;
		}
	}
}

// radiozurnal/romove
.radiozurnal {
	// gradient: povoleni
	.header__promo-wrapper--radiozurnal-romove::before {
		@include breakpoint($breakpoint_desktop) {
			display: block;
		}
	}
}

// stanice/format-kviz
.radiozurnal,
.dvojka,
.vltava,
.plus,
.wave,
.ddur,
.jazz,
.region {
	// gradient: povoleni
	.header__promo-wrapper--format-kviz::before {
		@include breakpoint($breakpoint_desktop) {
			display: block;
		}
	}
}

// junior/format-kviz
.junior.format-kviz {
	// default header: zakazani
	.header__promo-wrapper--junior-default { display: none; }

	// gradient: povoleni
	.header__promo-wrapper--format-kviz::before {
		@include breakpoint($breakpoint_desktop) {
			display: block;
			// gradient v Safari: https://stackoverflow.com/questions/55594942/transparent-gradient-not-working-in-safari
			background-image: linear-gradient(to top, $color_junior_add-1, rgba(#ffffff, 0) 65%);
		}
	}

	// footer
	footer {
		background-color: $color_junior_add-1;
	}
}

// temata - chceme pouze pri zimnim motivu,
// u profilu Josefa Capka (.page-node-7963631),
// u profilu Bozena Nemcova (.page-node-7963682)
.temata {
	// vsude krome zde
	&:not(.page-temata-hp):not(.page-historie):not(.page-komentare):not(.page-kultura):not(.page-nabozenstvi):not(.page-priroda):not(.page-veda):not(.page-styl):not(.page-schodycasu):not(.page-node-7638015):not(.page-node-7762836):not(.page-node-5998047):not(.page-hry-a-cetba):not(.page-rozhovory),
	// tady prebijime :not(.page-historie) pro tato subtemata /historie/bitva..
	&.page-historie.page-historie-bitva-o-rozhlas, &.page-historie.page-historie-bitva-o-rozhlas-pametni-deska, &.page-historie.page-historie-bitva-o-rozhlas-archiv {
		// gradient: povoleni
		.header__promo-wrapper--temata::before {
			@include breakpoint($breakpoint_desktop) {
				display: block;
			}
		}
	}
}

// wave hp
.wave.page-wave-hp {

	footer {
		background-color: transparent;
	}
}

// wave svatebky, Zkouskovy
.wave.page-svatebky,
.wave.page-zkouskovy {

	// gradient: povoleni
	.header__promo-wrapper::before {
		@include breakpoint($breakpoint_desktop) {
			display: block;
		}
	}
}

.header-langs {
	position: relative;
	
    .header-langs__list {
		position: absolute;
		right: 0;
		padding-right: 10px;
		text-align: right;
		padding-top: 0.3em;

		@include breakpoint($breakpoint_desktop) {
		}
		
		.header-langs__list-item {
			display: inline-block;
			vertical-align: bottom;
			font-size: 19px;
		}

		.header-langs__list-item a {
			color: $color_background-7;
		}

		.header-langs__list-item:not(:last-child)::after {
			content: " | ";
			color: $color_background-9;
		}

	}

}