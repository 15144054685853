//
// Button Play - Launcher (b-015)
//



.button-play--launcher {
	
	position: relative;
	
	display: block;
	width: 3.5em;
	height: 3.5em;
	
	margin: 0;
	padding: 0;
	
	background-image: $file_button_play_launcher;
	background-size: 200%;
	background-position: 0;
	
	// cely span popisek je ve vychozim stavu skryt tridou 'hidden' a slouzi jen jako fallback pro obrazek
	.button__label {
	}
}
.button-play--launcher::after {
	
	content: '';
	
	position: absolute;
	width: 100%;
	height: 100%;
	
	background-image: $file_button_play_launcher;
	background-size: 200%;
	background-position: 100%;
	
	opacity: 0;
	transition: opacity 0.2s;
}
.button-play--launcher:hover::after {
	
	opacity: 1;
	
}