//
// Styles - Node Type Article
//



.node-type-article {

	// title
	/*h1.article-type--text,
	h1.article-type--image,
	h1.article-type--audio,
	h1.article-type--video,*/
	h1.article-type {
		//width: calc(100% - 2.75em); // kvuli soc. buttonu na mobilu

		margin: 1em 0;
		margin-bottom: 0.5em;

		line-height: 1.8rem;

		color: $color_text_accent;

	 	@include breakpoint($breakpoint_desktop) {
			width: 66.66667%; // viz .l-grid--8-1

			padding-right: 25px; // viz .l-grid--8-1

			line-height: 3rem;
		}

		&.article-type--image {
		 	@include breakpoint($breakpoint_desktop) {
				width: 100%;
			}
		}
	}



	// grid
	.l-grid--12-1.item-first {
	}

		// modified, show, top social buttons
		.top-1-0 {
		}


	// content wrapper - _node.scss
	.inner-content-wrapper {
	}

		// grid
		.l-grid--8-1 {
		}

			// modified, show, top social buttons
			.content-1-1 {
			}

		   // primary image/audio
			.content-1-2 {

				.file-audio {
					margin-left: 0;
				}
			}

			// vertical soc. buttons, perex, body
			.content-1-3 {
			}

			// main audio anchor, authors, bottom soc. buttons
			.content-1-4 {
			}

				// fotka autora - fallback
				.view-display-id-node_author_main.box {
					display: none; // opusteno
				}
				// jmeno autora - fallback
				.view-display-id-node_author_main_2.box {
					display: none; // opusteno
				}



				// expired audio
				.article-type--audio .audio-rights-expired {
					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}



		// grid - aside
		.l-grid--4-9 {
			@include breakpoint($breakpoint_desktop) {
				margin-top: 8em;
			}
		}

			// content boxes, ad
			.sidebar-1-1 {
			}

			// content boxes
			.sidebar-1-2 {
			}

}
