//
// article assets photoswipe
//

$photoswipe-gallery_ml: .75%;
$photoswipe-gallery_mr: .75%;
$photoswipe-gallery_mb: 1.5%;

.page-node,
.b-047 {

	// FIX: pouze v hlavnim sloupci
	.l-grid--8-1.item-first {

		// fotogalerie - photoswipe u clanku bez hl. audia
		.no-main-audio .photoswipe-gallery {
			margin-left: 0;
			margin-right: 0;
		}

		// fotogalerie - photoswipe
		.field.body .photoswipe-gallery {
			margin-top: 1em;
			margin-bottom: 1em;
			margin-left: -$photoswipe-gallery_ml;
			margin-right: -$photoswipe-gallery_mr;

			@include breakpoint($breakpoint_desktop) {
				margin-top: 1.5em;
				margin-bottom: 1.5em;
				margin-left: calc(-#{$node_field_padding-left} - #{$photoswipe-gallery_ml});
				margin-right: calc(-#{$node_field_padding-right} - #{$photoswipe-gallery_mr});
			}


			// obal
			.field-type-image {

				display: flex;
				flex-direction: row;
				flex-wrap: wrap;

				// jednotliva fotka <a>
				.photoswipe {
					flex-basis: calc(50% - (#{$photoswipe-gallery_ml} + #{$photoswipe-gallery_mr}));
					max-width: 48.5%;
					line-height: 0; // zruseni klasickeho zolu img
					margin-bottom: $photoswipe-gallery_mb;
					margin-left: $photoswipe-gallery_ml;
					margin-right: $photoswipe-gallery_mr;
					@include breakpoint($breakpoint_tablet) {
						flex-basis: calc(33.33% - 2 * #{$photoswipe-gallery_ml});
						max-width: 32%;
					}
					@include breakpoint($breakpoint_desktop) {
						font-size: 18px;
						flex-basis: calc(25% - 2 * #{$photoswipe-gallery_ml});
						max-width: 23.5%;
					}
					& > img {
						transition: opacity .2s;
						-webkit-transform: translateZ(0);
						&:hover {
							opacity: 0.9;
						}
					}
					&:not(.cro-show-all):nth-of-type(n + 8) {
						display: none;
					}
					@include breakpoint($breakpoint_desktop) {
						&:not(.cro-show-all):nth-of-type(n + 6) {
							display: block;
						}
						&:not(.cro-show-all):nth-of-type(n + 8) {
							display: none;
						}
					}
				}
				.cro-show-all {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-content: center;
					align-items: center;
					border: 1px solid $color_divider;
					color: $color_decoration_main;
					flex-basis: calc(50% - (#{$photoswipe-gallery_ml} + #{$photoswipe-gallery_mr}));
					max-width: 48.5%;
					line-height: 0; // zruseni klasickeho zolu img
					margin-bottom: $photoswipe-gallery_mb;
					margin-left: $photoswipe-gallery_ml;
					margin-right: $photoswipe-gallery_mr;
					font-size: $base-font-size;
					@include breakpoint($breakpoint_tablet) {
						flex-basis: calc(33.33% - 2 * #{$photoswipe-gallery_ml});
						max-width: 32%;
					}
					@include breakpoint($breakpoint_desktop) {
						font-size: 18px;
						flex-basis: calc(25% - 2 * #{$photoswipe-gallery_ml});
						max-width: 23.5%;
					}
					&:hover {
						text-decoration: none;
					}
					&:hover:after {
						transform: rotate(-90deg);
					}
					&:after {
						content: '';
						margin-top: 8px;
						display: flex;
						flex-basis: 100%;
						background: $file_icon_arrow_single_east_colored-decoration-main no-repeat center;
						width: 16px;
						height: 16px;
						transition: transform .12s ease-in-out;
					}
					.gallery-text-wrap {
						width: 80px;
						line-height: 1.2;
						text-align: center;
					}
				}
			}
		}
	}
}





/*
 * Pokud nema hlavni obrazek/audio/video
 */
.page-node.no-main-media,
.b-047.no-main-media {

	// FIX: pouze v hlavnim sloupci
	.l-grid--8-1.item-first {

		// fotogalerie - photoswipe
		.field.body .photoswipe-gallery {

			@include breakpoint($breakpoint_desktop) {
				margin-left: -$photoswipe-gallery_ml;
				margin-right: -$photoswipe-gallery_mr;
			}
		}
	}
}

.page-node {

	.photogallery-node-wrapper {

		img {
			max-width: 163px;
			margin: 5px 5px 0 0;
		}

	}
}
