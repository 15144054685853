//
// em
//



em {
	font-style: italic;
}

em strong {
	font-style: italic;
}