//
// audioplayer generated by JavaScript
//


$font-size: 1rem;
$line-height: 1.2;
$lines-to-show: 2;
$lines-to-show-desktop: 1;

$filename_fontsize_mobile: .85rem;
$filename_fontsize_desktop: 1rem;

$color_background_volume: #eeeff3;
$color_background_audio: #efeff2;
$color_background_now_plying: #e4e4e9;
$color_background_player_custom_alpha: rgba($color_background-0, .9);
$color_background_player_mujrozhlas_banner_custom_alpha: rgba($color_background-9, .6);
$color_background_player_mujrozhlas_banner_custom_alpha_junior: rgba($color_background-0, .45);

$transition-speed: 333ms;

$junior_player_radius: 8px;
$junior_player_radius-player: 20px;



.page-node .file-audio,
.b-047 .file-audio,
.b-timeline .file-audio,
.audio-serial-player,
.view-cestyzavodou-hp,
.page-1968-hp,
.b-200 .file-audio {
	border: 1px solid $color_background-4;
	@include breakpoint($breakpoint_desktop) {
		margin-left: 4.375rem;
	}
	.sm2-playlist-wrapper {
		ul li .sm2-row {
			display: flex;
			a {
				max-width: 95%;
				overflow: hidden;
				text-overflow: ellipsis;
				@include breakpoint($breakpoint_tablet) {
					max-width: 90%;
				}
				@include breakpoint($breakpoint_desktop) {
					max-width: 85%;
				}
				.filename {
					overflow: hidden;
					&__text {
						@include typography_font(regular);
						transition: color $transition-speed;
						line-height: 1.2;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						font-size: 1rem;
					}
				}
				.title-end {
					display: none;
				}
			}
		}
	}
	.file.file-audio {
		padding: 0;
		border: 0;
	}
	.selected {
		.sm2-row {
			// rychlost prolnuti barvy podbarveni u prave hraneho dilu serialu
			transition: background-color $transition-speed;
		}
	}
	.playing .selected,
	.paused .selected {
		.sm2-row {
			// podbarveni u prave hraneho dilu serialu
			background-color: $color_background_now_plying;
		}
	}
	.sm2-inline-button.play-pause {
	    @include no-touch-select;
		&:active,
		&:focus,
		&:hover {
			outline: none;
		}
	}
	.sm2-bar-ui {
		min-width: unset;
		max-width: unset;
		display: block;
		font-size: 1.25rem;
		transition: all 1s;
		// IE11 styles fix
		@include ie-only() {
			min-width: 100%;
			max-width: 100%;
		}
	}
	.sm2-playlist {
		color: $color_decoration_main;
		display: block;
		ul {
			width: 100%;
			font-size: $fontsize_h3;
			min-height: 2.5em;
			@include breakpoint($breakpoint_desktop) {
				font-size: $fontsize_h3_desktop;
			}
			li {
				text-align: left;
				font-size: 100%;
				height: 100%;
				min-height: 3em;
				margin-left: 0;

				.load-error {
					display: none;
				}
			}
			.filename {
				display: flex;
				align-items: flex-end;
				height: 100%;
				&__text {
					max-width: 100%;
					display: block; /* Fallback for non-webkit */
					display: -webkit-box;
					height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
					font-size: $font-size;
					line-height: $line-height;
					-webkit-line-clamp: $lines-to-show;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: normal;
					@include no-select;
					@include breakpoint($breakpoint_desktop) {
						max-width: calc(100% - 5em);
						font-size: 1.2rem;
						-webkit-line-clamp: $lines-to-show-desktop;
						white-space: nowrap;
						display: block;
					}
				}
			}
			.title-end {
				display: none;
			}
		}
	}
	.sm2-bar-ui .sm2-inline-element {
		display: block;
		border: none;
		float: left;
		overflow: hidden;
		width: 60px;
		@include breakpoint($breakpoint_desktop) {
			width: 50px;
		}
		&:hover {
			background: none;
		}
	}
	.sm2-bar-ui .bd,
	.sm2-bar-ui .sm2-extra-controls {
		background-color: transparent;
		color: $color_background-9;
	}
	.sm2-bar-ui .sm2-controls-wrapper {
		min-height: 75px;
		min-width: calc(100% - 80px);
		font-size: $fontsize_h3;
		float: left;
		margin: 0 0 0 15px;
		padding: 0;
		@include breakpoint($breakpoint_desktop) {
			margin: 0 0 0 30px;
		}
	}
	.sm2-main-controls {
		padding: 9px;
		@include breakpoint($breakpoint_desktop) {
			padding: 20px 19px 0 19px;
		}
	}
	.sm2-bar-ui .sm2-main-controls .button.button-listaction {
		position: absolute;
		bottom: -57px;
		right: 40px;
		width: 2.5rem;
		height: 2.5rem;
		@include breakpoint($breakpoint_desktop) {
			top: 11px;
		}
		&--share {
			bottom: -57px;
			right: -4px;
		}
	}
	.sm2-bar-ui.playing .sm2-main-controls .button.button-listaction,
	.sm2-bar-ui.paused .sm2-main-controls .button.button-listaction {
		bottom: -90px;
		&--share {
			bottom: -90px;
		}
	}
	.sm2-progress {
		display: none;
		float: left;
		width: 100%;
		clear: both;
		position: relative;
		top: 20px;
		@include breakpoint($breakpoint_desktop) {
			top: 28px;
			width: 100%;
		}
		.sm2-row {
			display: block !important;
		}
		.sm2-progress-bd {
			padding: 0;
		}
	}
	.sm2-time-wrapper {
		.delitko {
			margin: 0;
		}
		.delitko,
		.sm2-inline-time {
			display: none;
		}
		.sm2-inline-time,
		.delitko,
		.sm2-inline-duration {
			@include no-select;
			color: $color_text_paragraph_node;
			letter-spacing: -.01em;
		}
		.caption {
			&__label {
				font-size: .7rem;
				color: $color_background-6;
			}
			&__icon {
				font-size: .7rem;
				background-image: $file_icon_sandtimer_colored-09e9ea9;
				background-size: 8px;
				width: 8px;
				height: 16px;
				position: relative;
				top: 4px;
			}
			&--available-till {
				display: inline !important;
				white-space: nowrap;
			}
			&__desktop-only {
				display: none;
				@include breakpoint($breakpoint_desktop) {
					display: inline;
				}
			}
		}
	}
	.sm2-bar-ui .sm2-time-wrapper {
		float: left;
		clear: both;
		color: $color_text_paragraph;
		margin: -1.7em 0 0 4.7em;
		font-size: $base-font-size;
		@include breakpoint($breakpoint_desktop) {
			font-size: $fontsize_h3_desktop;
			margin: -1.9em 0 0 4.5em;
		}
		div {
			font-size: .75em;
			@include breakpoint($breakpoint_desktop) {
				font-size: .9em;
			}
		}
	}
	.sm2-progress .sm2-progress-track,
	.sm2-progress .sm2-progress-ball,
	.sm2-progress .sm2-progress-bar {
		border-radius: 0;
	}
	.sm2-progress-ball {
		height: .065em;
		width: 0;
	}
	.sm2-progress .sm2-progress-track {
		background: $color_background-5_alpha-high;
	}
	.sm2-progress .sm2-progress-bar {
		background: $color_decoration_main;
	}
	.play-pause {
		background-image: $file_button_action_play !important;
		background-size: 200%;
		background-position: 0;
		&:hover {
			background-position: 100%;
			background-size: 200%;
		}
	}
	.playing .play-pause {
		background-image: $file_button_action_pause !important;
		background-size: 200%;
		background-position: 0;
		&:hover {
			background-position: 100%;
		}
	}
	.sm2-bar-ui .sm2-volume {
		float: right;
		border-radius: 0;
		min-height: 1em;
		height: 1em;
		margin-top: .3em;
		width: 8.25em;
		background: transparent $file_icon_badge_audio_colored-main top left no-repeat;
		background-size: 24px 20px;
		padding-left: 1.8em;
		cursor: pointer;
		@include breakpoint($breakpoint_desktop) {
			margin: .4em 0 0 0;
		}
		&:hover {
			background: transparent $file_icon_badge_audio_colored-main top left no-repeat;
			background-size: 24px 20px;
		}
		.sm2-button-bd {
			background: $color_background_volume;
			min-height: 8px;
			margin-top: 5px;
		}
		.sm2-volume-control {
			background-image: none;
			background-size: 100%;
			height: 8px;
			background-color: $color_decoration_main;
		}
		.volume-shade {
			background-image: none;
			-webkit-filter: none;
		    opacity: 1;
			background-color: $color_background-1_alpha-high;
		}
	}
	.sm2-bar-ui .sm2-volume {
		display: none;
	}
	.sm2-bar-ui .sm2-playlist-wrapper {
		background-color: transparent;
		padding: 0;
		margin-bottom: -2px;
		ul li {
			border: 0;
			overflow: hidden;
			.filename {
				font-size: 1rem;
				@include breakpoint($breakpoint_desktop) {
					font-size: 1.3rem;
				}
			}
			a {
				background-color: transparent;
				color: $color_decoration_main;
				padding-left: 0;
				padding-right: 0;
				display: flex;
				align-items: flex-start;
				@include breakpoint($breakpoint_desktop) {
					align-items: center;
					padding-bottom: 1.2em;
				}
				&:before {
					content: '';
					background-image: $file_button_action_play;
					background-size: 80px;
					background-position: 0;
					display: inline-block;
					float: left;
					min-width: 40px;
					width: 40px;
					height: 40px;
					padding: 0;
					margin: 0 16px;
					@include breakpoint($breakpoint_desktop) {
						background-size: 60px;
						min-width: 30px;
						width: 30px;
						height: 30px;
						margin: 0 20px;
					}
				}
				&:hover:before {
					background-position: 100%;
				}
			}
		}
	}
	// stavy prehravace
	// playing/paused
	.sm2-bar-ui {
		&.playing,
		&.paused {
			.sm2-controls-wrapper {
				min-height: 120px;
			}
		}
	}
	// paused
	.paused {
		ul li.selected a:before {
			background-image: $file_button_action_play;
		}
	}
	// playing
	.playing {
		ul li.selected a:before {
			background-image: $file_button_action_pause;
		}
	}
	.playing,
	.paused {
		.selected .filename__text {
			color: darken($color_background-6, 10%);
		}
		.sm2-progress {
			display: block;
			top: 35px;
			@include breakpoint($breakpoint_desktop) {
				top: 30px;
			}
		}
		.sm2-inline-time,
		.delitko,
		.sm2-inline-duration {
			display: inline;
		}
		.sm2-time-wrapper {
			position: relative;
			top: -55px;
			@include breakpoint($breakpoint_desktop) {
				top: -10px;
			}
		}
		@include breakpoint($breakpoint_desktop) {
			.sm2-volume {
				display: block;
				position: absolute;
				right: 16px;
				bottom: 15px;
				max-width: 140px;
			}
		}
		&.sm2-bar-ui {
			height: auto;
		}
		.caption {
			&--available-till {
				position: absolute;
				bottom: -47px;
				left: -76px;
				@include breakpoint($breakpoint_desktop) {
					position: relative;
					bottom: 1px;
					left: 10px;
				}
			}
		}
	}
	.cro-playlist-el-duration {
		display: none;
		position: absolute;
		left: 88px;
		bottom: 4px;
		@include breakpoint($breakpoint_tablet) {
			left: auto;
			right: 20px;
			bottom: 18px;
		}
	}
	.sm2-playlist-wrapper ul li a .cro-playlist-el-duration {
		display: inline-block;
		font-size: .78rem;
		color: $color_background-7;
		@include breakpoint($breakpoint_tablet) {
			font-size: $base-font-size;
		}
	}
}

.page-node .a-004p .file-audio,
.b-047 .a-004p .file-audio,
.view-cestyzavodou-hp,
.page-1968-hp,
.b-200 .file-audio {
	padding: 0;
	border: none;
	width: 100%;
	overflow: hidden;
	@include breakpoint($breakpoint_desktop) {
		margin-left: 0;
		padding: 0;
	}
	.sm2-bar-ui {
		.sm2-inline-element {
			min-height: auto;
		}
		.sm2-controls-wrapper {
			line-height: 1;
			min-height: 70px;
			margin: 0;
			min-width: calc(100% - 90px);
		}
		.sm2-button-element.sm2-play-pause-wrapper .sm2-button-bd {
			width: 1.8em;
			height: 1.8em;
			min-width: 1.8em;
			min-height: 1.8em;
		}
		.sm2-playlist-target {
			height: 100%;
		}
		.sm2-playlist-bd {
			@include typography_font(medium);
			font-size: $fontsize_h3;
			height: 100%;
			@include breakpoint($breakpoint_desktop) {
				font-size: $fontsize_h3_desktop;
			}
			li {
				font-size: 100%;
				margin-left: 0;
				> .load-error {
					display: none;
				}
			}
			.filename {
				max-width: 100%;
				display: block; /* Fallback for non-webkit */
				display: -webkit-box;
				height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
				font-size: $font-size;
				line-height: $line-height;
				-webkit-line-clamp: $lines-to-show;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: normal;
				@include breakpoint($breakpoint_desktop) {
					font-size: 1.3rem;
					-webkit-line-clamp: $lines-to-show-desktop;
					white-space: nowrap;
					display: block;
				}
			}
			.title-end {
				display: none;
			}
		}
		.sm2-time-wrapper {
			margin: .3em 0 0 3.5em;
			font-size: $base-font-size;
			position: relative;
			top: 0;
			@include no-select;
			@include breakpoint($breakpoint_desktop) {
				font-size: $fontsize_h3_desktop;
				margin: .3em 0 0 3.1em;
			}
			.delitko {
				margin: 0;
			}
		}
		.sm2-volume {
			margin-top: 8px;
			margin-right: 0;
		}
	}
}


// obrazkovy clanek s hlavnim audiem
// video clanek s hlavnim audiem
.page.article-type--image,
.page.article-type--video {
	.sm2-bar-ui {
		.sm2-playlist ul .filename__text {
			@include breakpoint($breakpoint_desktop) {
				line-height: 2.5;
			}
		}
		.sm2-volume {
			right: 19px;
			bottom: 20px;
		}
		.sm2-volume .sm2-button-bd {
			height: 8px;
		}
		.sm2-playlist-drawer {
			display: none !important; // skryti playlistu
		}
		.sm2-main-controls {
			// korekce polohy buttonu
			.button.button-listaction {
				bottom: -54px;
				&--share {
					bottom: -54px;
				}
			}
		}
		// velikost ikon
		.sm2-main-controls .button.button-listaction {
			width: 32px;
			height: 32px;
			&--download {
				right: 32px;
			}
			&--podcast {
				right: 74px;
			}
		}
	}
	.main-audio-without-main-image {
		// velikost playitka
		.file-audio .sm2-bar-ui .sm2-inline-element.sm2-play-pause-wrapper {
			height: 60px;
			@include breakpoint($breakpoint_desktop) {
				height: 50px;
			}
		}
	}
}





// novy audioplayer
.audio-article-player,
.audio-serial-player,
.page-1968-hp .audio-article-player {
	margin-top: -88px;
	&.expired,
	&.future {
		margin-top: -13px;
	}
	@include breakpoint($breakpoint_desktop) {
		margin-top: -90px;
	}
	&.future,
	&.expired {
		margin-top: 0;
		@include breakpoint($breakpoint_desktop) {
			margin-top: -16px;
		}
	}
	.sm2-play-pause-wrapper {
		z-index: 10;
		&:active,
		&:focus {
			outline: none;
			background-color: transparent;
		}
	}
	.sm2-time-wrapper {
		white-space: nowrap;
		display: inline !important;
		top: -25px !important;
		@include breakpoint($breakpoint_desktop) {
			top: -40px !important;
		}
		.delitko {
			margin: 0;
		}
		.sm2-inline-time,
		.delitko,
		.sm2-inline-duration {
			display: inline !important;
		}
		div {
			@include breakpoint($breakpoint_desktop) {
				font-size: .75em !important;
			}
		}
	}
	.sm2-progress {
		max-height: 30px;
		@include breakpoint($breakpoint_desktop) {
			max-width: calc(100% - 185px);
			max-height: 47px;
		}
		.sm2-progress-track {
			background: $color_background-5_alpha-high;
			@include breakpoint($breakpoint_desktop) {
				height: 8px !important;
			}
		}
		.sm2-progress-bar {
			@include breakpoint($breakpoint_desktop) {
				height: 8px !important;
			}
		}
	}
	.sm2-volume {
		opacity: 0 !important;
		display: inline !important;
		@include breakpoint($breakpoint_desktop) {
			position: absolute;
			right: 16px;
			top: 28px;
			max-width: 100% !important;
		}
		.sm2-button-bd {
			background-color: $color_background-5_alpha-high !important;
			width: 100%;
			max-height: 8px;
		}
	}
	.sm2-main-controls {
		width: 80px !important;
		max-height: 78px;
		overflow: hidden;
		display: block;
		border-left: 1px solid $color_background-8_alpha-high;
		@include breakpoint($breakpoint_desktop) {
			width: 90px !important;
			max-height: 88px;
		}
	}
	.sm2-controls-wrapper {
		min-height: 70px !important;
	}
	.sm2-controls-wrapper,
	.sm2-time-wrapper,
	.sm2-playlist {
		opacity: 0;
		@include no-select;
	}
	.sm2-bar-ui {
		position: relative;
		background: $color_background_player_custom_alpha;
		width: 80px;
		border-radius: 0;
		transition: width $transition-speed ease !important;
		margin-left: 10px;
		margin-right: 10px;
		height: 5rem !important;
		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
			margin-right: 0;
			width: 90px;
			height: 5.6rem !important;
		}
		&.playing,
		&.paused {
			width: calc(100% - 20px);
			@include breakpoint($breakpoint_desktop) {
				width: 100%;
			}
		}
		.sm2-button-element.sm2-play-pause-wrapper .sm2-button-bd {
			width: 60px;
			height: 60px;
			min-width: 60px;
			min-height: 60px;
			@include breakpoint($breakpoint_desktop) {
				width: 50px;
				height: 50px;
				min-width: 50px;
				min-height: 50px;
			}
		}
	}
	.playing,
	.paused {
		.sm2-main-controls {
			width: 100% !important;
			border-right: 1px solid $color_background-8_alpha-high;
		}
		.sm2-volume {
			transition: opacity $transition-speed ease !important;
		}
		.sm2-controls-wrapper,
		.sm2-time-wrapper,
		.sm2-volume {
			transition: opacity $transition-speed ease !important;
			opacity: 1 !important;
			display: inline;
		}
	}
	.file-audio {
		.playing,
		.paused {
			.sm2-progress {
				top: 18px;
				@include breakpoint($breakpoint_desktop) {
					top: -4px;
				}
			}
		}
	}

	// misc fixes
	.file-audio .sm2-bar-ui .sm2-playlist-wrapper {
		&:before {
			background-color: transparent;
		}
	}
	.page-node .file-audio .paused.sm2-bar-ui {
		height: 5.5rem;
	}
	.field-main-audio {
		margin-bottom: -2px;
	}

	// podcast position fix (2 or more icons displayed)
	.bubble-subscribe {
		position: absolute;
		top: 56px;
		left: -11px;
		outline: none;
		@include breakpoint($breakpoint_tablet) {
			top: 43px;
			left: -110px;
		}
		@include breakpoint($breakpoint_desktop) {
			top: 48px;
			left: -87px;
		}
		&::before {
			left: 71px;
			top: -8px;
			@include breakpoint($breakpoint_tablet) {
				left: 158px;
			}
			@include breakpoint($breakpoint_desktop) {
				left: 135px;
			}
		}
	}

	// only podcast button position fix
	.button-listaction--only-podcast + .bubble-subscribe {
		left: -11px;
		@include breakpoint($breakpoint_tablet) {
			left: -188px;
			width: 240px;
		}
		&::before {
			left: 22px;
			@include breakpoint($breakpoint_tablet) {
				left: 195px;
			}
		}
	}
	// alerts
	&.future,
	&.expired {
		.audio-rights-expired,
		.audio-rights-future {
			cursor: default;
			@include breakpoint($breakpoint_desktop) {
				margin-left: 0 !important;
			}
		}
	}
}

// embed audioplayer
.page-node.soundmanager-embed {
	.audio-article-player,
	.audio-serial-player {
		&.expired,
		&.future {
			margin-top: -20px;
		}
	}
	// Radio Junior - embed
	&.junior {
		.file .content {
			border-radius: $junior_player_radius;
			overflow: hidden;
		}
		.audio-article-player .field-main-audio,
		.audio-serial-player .field-main-audio {
			margin-bottom: 0;
		}
		&:before {
			background-color: transparent;
		}
		// prebarveni listy playeru
		.view-cro-serial .sm2-bar-ui .sm2-main-controls {
			background-color: $color_junior_main-tag_alpha-low !important;
		}
	}
	.sm2-bar-ui .sm2-button-element.sm2-play-pause-wrapper .sm2-button-bd {
		min-width: 60px;
		min-height: 60px;
		@include breakpoint($breakpoint_desktop) {
			min-width: 50px;
			min-height: 50px;
		}
	}
	.sm2-bar-ui .sm2-time-wrapper div.delitko {
		margin-right: 2px;
		margin-left: 2px;
	}
	.box.view-cro-serial {
		margin-top: 0;
	}
	.audio-serial-player {
		border: 0;
		margin-top: -84px !important;
		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
			margin-top: -90px !important;
		}
		.sm2-main-controls {
			transition: width $transition-speed ease;
			padding: 9px;
			border-left: 0;
			border-right: 0;
			&.bd {
				background-color: $color_background_player_custom_alpha !important;
			}
		}
	}
	.sm2-playlist-wrapper {
		margin-top: -17px !important;
		&:before {
			width: 100% !important;
			margin: 0 !important;
		}
	}
	.audio-rights-expired,
	.audio-rights-future {
		background: $color_background_player_custom_alpha;
		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
		}
	}
	.file-audio {
		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
		}
	}
	.field.field-zpravy-need-crop {
		display: none;
	}
	.audio-article-player {
		.sm2-progress {
			width: 100%;
		}
	}
	.sm2-bar-ui {
		margin-left: 0;
		margin-right: 0;
		height: auto !important;
		border: 0 !important;
		&.playing,
		&.paused {
			width: 100%;
		}
	}
	.audio-serial-player {
		.sm2-bar-ui {
			width: 100%;
		}
	}
	.filename {
		&__text {
			height: 20px !important;
			@include breakpoint($breakpoint_desktop) {
				height: 25px !important;
			}
		}
	}
	.caption {
		&--available-till {
			position: absolute;
			bottom: 20px;
			left: 150px;
			@include breakpoint($breakpoint_desktop) {
				bottom: 18px;
			}
		}
	}
	.playing,
	.paused {
		.sm2-progress {
			top: 15px;
			@include breakpoint($breakpoint_desktop) {
				top: -4px;
			}
		}
		.sm2-controls-wrapper {
			min-height: 75px !important;
		}
		.sm2-progress-track {
			top: 0 !important;
		}
		.sm2-time-wrapper {
			top: -30px !important;
			@include breakpoint($breakpoint_desktop) {
				top: 0 !important;
			}
		}
		.sm2-volume {
			bottom: 10px;
			display: none !important;
			@include breakpoint($breakpoint_desktop) {
				display: block !important;
				right: 19px;
			}
			.sm2-button-bd {
				background-color: transparent;
			}
		}
		.caption {
			&--available-till {	;
				bottom: 0;
				right: -100px;
				left: auto;
				@include breakpoint($breakpoint_desktop) {
					right: -50px;
				}
			}
		}
	}
	// expiracni hlasky
	.audio-rights-expired,
	.audio-rights-future {
		width: 100% !important;
		max-width: 100% !important;
	}
	.sm2-playlist-bd {
		&:before {
			position: inherit !important;
		}
	}
	.audio-serial-player .box.a-004p .sm2-bar-ui {
		width: 100% !important;
	}
}


// radio Junior
// embed
.soundmanager-embed.junior {
	.file.file-audio {
		background-color: $color_junior_main;
		border: none;
		.sm2-playlist ul .filename__text {
			color: $color_junior_add-1;
		}
	}
	.sm2-bar-ui .sm2-button-element .sm2-button-bd {
		min-height: 60px;
		@include breakpoint($breakpoint_desktop) {
			min-height: 50px;
		}
	}
	.file-audio .sm2-time-wrapper .delitko {
		margin: 0 2px;
	}
}


// asset player
.wysiwyg-asset.asset-type-audio {
	.audio-rights-expired.audio-rights-expired--asset,
	.audio-rights-future.audio-rights-future--asset {
		cursor: pointer;
		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
		}
	}
	.file-audio {
		margin-bottom: 1em;
		@include breakpoint($breakpoint_desktop) {
			margin-bottom: inherit;
		}
	}
	.sm2-bar-ui {
		// velikost ikony play/pause
		.sm2-button-element.sm2-play-pause-wrapper .sm2-button-bd {
			width: 60px;
			height: 60px;
			@include breakpoint($breakpoint_desktop) {
				width: 50px;
				height: 50px;
			}
		}
		.sm2-main-controls {
			// odsazeni nazvu souboru
			.sm2-controls-wrapper {
				@include breakpoint($breakpoint_desktop) {
					margin-left: 17px;
					width: calc(100% - 70px);
				}
			}
			// velikost ikon
			.button.button-listaction {
				width: 32px;
				height: 32px;
				// pozice ikon
				&--download {
					right: 32px;
				}
				&--podcast {
					right: 74px;
				}
			}
			// max-delka nazvu audia a jeho umisteni
			.sm2-playlist ul .filename__text {
				@include breakpoint($breakpoint_desktop) {
					max-width: calc(100% - 5.5em);
					line-height: 2.5;
				}
			}
			// delitko
			.sm2-time-wrapper > div {
				margin-left: 2px;
				margin-right: 2px;
			}
			// pozadi u volume control
			.sm2-volume {
				.sm2-button-bd {
					background-color: transparent;
				}
				.volume-shade {
					background-color: $color_background-5_alpha-high;
					background-image: none;
					opacity: 1;
				}
			}
		}
		// pozice volume control
		&.playing .sm2-volume,
		&.paused .sm2-volume {
			right: 22px;
		}
		// odsazeni u metadat
		.sm2-time-wrapper {
			@include breakpoint($breakpoint_desktop) {
				margin-top: -31px;
				margin-left: 68px;
			}
		}
	}
	// pozice progressbaru
	.paused .sm2-progress,
	.playing .sm2-progress {
		top: 40px;
		@include breakpoint($breakpoint_desktop) {
			top: 30px;
		}
	}
}


// radio Junior
// asset player
// obrazkovy clanek s hlavnim audiem
// video clanek s hlavnim audiem
.junior.page-node {
	.asset-type-audio,
	.article-type--image,
	.article-type--video {
		.file-audio {
			background-color: $color_junior_main;
			border: none;
			margin-top: 1.5em;
			border-radius: $junior_player_radius-player;
			.sm2-playlist ul li {
				@include breakpoint($breakpoint_desktop) {
					justify-content: center;
					display: flex;
					flex-direction: column;
				}
				// nazev audia
				.filename__text {
					color: $color_junior_add-1;
					font-family: $font-junior-medium;
					font-size: 1rem;
					max-width: 100%;
					display: block; /* Fallback for non-webkit */
					display: -webkit-box;
					height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
					font-size: $font-size;
					line-height: $line-height;
					-webkit-line-clamp: $lines-to-show;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: normal;
					@include no-select;
					@include breakpoint($breakpoint_desktop) {
						max-width: calc(100% - 5em);
						font-size: 1.3rem;
						-webkit-line-clamp: $lines-to-show-desktop;
						white-space: nowrap;
						display: block;
						height: 100%;
					}
				}
				// delitko mezera
				.sm2-time-wrapper .delitko {
					margin: 0 2px;
				}
			}
			// zruseni borderu u ikon
			.button-listaction--download,
			.button-listaction--podcast {
				border: none;
			}
			// zmena barevnosti ikony
			.button-listaction--share {
				background-image: $file_button_action_share_colored-white;
			}
			// zmena barevnosti u dostupnosti audia
			.sm2-time-wrapper {
				.caption__icon
				.caption__label {
					color: $color_junior_add-1;
				}
			}
		}
	}
}


// radio Junior
// obrazkovy clanek s hlavnim audiem
// video clanek s hlavnim audiem
.junior.page-node {
	.article-type--image,
	.article-type--video {
		.field-main-audio .file-audio {
			@include breakpoint($breakpoint_desktop) {
				margin-left: 0; // nechceme odsazeni
			}
			// fix u volume
			.sm2-volume .sm2-button-bd {
				background-color: transparent;
			}
		}
	}
}

// serials
.view-cro-serial {
	.page-node .file-audio .asset-type-audio .title-end {
		display: none;
	}
	.page-node.soundmanager-embed .content > div {
		margin-left: 0;
		text-align: left;
	}
	.sm2-inline-duration {
		display: none;
	}
	.filename__text {
		line-height: 1;
		@include breakpoint($breakpoint_desktop) {
			line-height: 2.1 !important;
			max-width: 100% !important;
		}
	}
	.sm2-bar-ui {
		.button.button-listaction {
			width: 2.5rem;
			height: 2.5rem;
			@include breakpoint($breakpoint_tablet) {
				width: 2rem;
				height: 2rem;
			}
		}
		.sm2-controls-wrapper {
			min-height: 40px !important;
		}
		&.playing,
		&.paused {
			display: inline-block;
			.sm2-controls-wrapper {
				min-height: 56px !important;
			}
			.sm2-progress {
				top: 42px !important;
				@include breakpoint($breakpoint_desktop) {
					top: 25px !important;
				}
			}
			.sm2-volume,
			.sm2-volume:active {
				top: 32px;
				right: 20px;
				background: transparent $file_icon_badge_audio_colored-main top left no-repeat;
			}
		}
	}
}

// mujrozhlas audio banner
.mujrozhlas-audio {
	width: 100%;
	height: 30px;
	position: absolute;
	right: 0;
	box-shadow: inset 0 0 10px $color_background-9_alpha-mid, 0px 10px 13px -7px $color_background_player_mujrozhlas_banner_custom_alpha;
	transition: box-shadow $transition-speed;
	background-color: $color_background_player_mujrozhlas_banner_custom_alpha;
	// logo mujRozhlas
	&::before {
		content: '';
		background: $file_logo_mujrozhlas_colored-main no-repeat center 100%;
		width: 87px;
		height: 18px;
		position: absolute;
		top: 5px;
		right: 10px;
	}
	// zamereni mysi
	&:hover {
		text-decoration: none;
		box-shadow: inset 0 0 10px $color_background-9_alpha-high, 0px 10px 13px -7px $color_background_player_mujrozhlas_banner_custom_alpha;
		&::before {
			// vymena loga mujRozhlas za bile
			@include breakpoint($breakpoint_desktop) {
				background: $file_logo_mujrozhlas_colored-white no-repeat center 100%;
			}
		}
		// vymena zdvojene sipky za bilou
		.mujrozhlas-audio__text-desktop::before {
			@include breakpoint($breakpoint_desktop) {
				background: $file_icon_arrow_double_east_colored-white no-repeat 0 0;
			}
		}
	}
	// textace odkazu
	&__text-mobile,
	&__text-desktop {
		margin-right: 100px;
		padding-left: 30px;
		height: 30px;
		max-width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		font-size: .75rem;
		color: $color_text_link_inverted;
		line-height: 2.5;
		@include breakpoint($breakpoint_desktop) {
			font-size: .85rem;
		}
		// ikona dvojite sipky modre barvy
		&::before {
			content: '';
			background: $file_icon_arrow_double_east_colored-decoration-main no-repeat 0 0;
			width: 10px;
			height: 10px;
			display: block;
			position: absolute;
			top: 12px;
			left: 10px;
		}
	}
	// textace mobil
	&__text-mobile {
		display: block;
		@include breakpoint($breakpoint_desktop) {
			display: none;
		}
	}
	// textace desktop
	&__text-desktop {
		display: none;
		@include breakpoint($breakpoint_desktop) {
			display: block;
		}
	}
}

// modifikace mujRozhlas promobanneru pro radio Junior
.junior .mujrozhlas-audio {
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	overflow: hidden;
	background-color: $color_background_player_custom_alpha;
	box-shadow: 0px 10px 13px -7px $color_background_player_mujrozhlas_banner_custom_alpha_junior;
	&:hover::before {
		@include breakpoint($breakpoint_desktop) {
			background: $file_logo_mujrozhlas_colored-grey no-repeat center 100%;
		}
	}

	// modificace barev pro Junior
	.mujrozhlas-audio__text-desktop,
	.mujrozhlas-audio__text-mobile {
		color: $color_background-6;
		background-color: transparent;
		line-height: 2.7;
		@include breakpoint($breakpoint_desktop) {
			line-height: 2.5;
		}
	}

	// zmeny ikon pro Junior
	&:hover .mujrozhlas-audio__text-mobile::before,
	&:hover .mujrozhlas-audio__text-desktop::before {
		// vymena zdvojene sipky za sedou
		@include breakpoint($breakpoint_desktop) {
			background: $file_icon_arrow_double_east_colored-decoration-grey no-repeat 0 0;
		}
	}
}

// serial banner
// serialpart banner
.node-type-serial:not(.junior) .mujrozhlas-audio,
.node-type-serialpart:not(.junior) .mujrozhlas-audio {
	@include breakpoint($breakpoint_desktop) {
		left: 4.375rem;
		width: calc(100% - 4.375rem);
	}
}


// alert messages
.page-node .audio-article-player .audio-rights-expired,
.page-node .audio-article-player .audio-rights-future {
	background: $color_background-0_alpha-low;
	margin: 0 0 -50px 0;
	position: relative;
	top: -50px;
	margin-bottom: -50px;
	display: flex;
	align-items: center;
}

.page-node .asset-type-audio .audio-rights-expired__title > .filename {
	max-width: 100%;
}

.page-node .sm2-playlist-wrapper .sm2-playlist-bd .audio-rights-expired h3,
.page-node .sm2-playlist-wrapper .sm2-playlist-bd .audio-rights-future h3 {
	font-size: .75rem !important;
	line-height: 1.2;
	color: $color_background-6;
	margin-left: 4.1em;
	@include breakpoint($breakpoint_tablet) {
		line-height: 2.2;
	}
	@include breakpoint($breakpoint_desktop) {
		margin-left: 4.4em;
		font-size: .95rem !important;
	}
}

// zmeny pro radio Junior
.page-node.junior .sm2-playlist-wrapper .audio-rights-expired .audio-rights-expired__title,
.page-node.junior .sm2-playlist-wrapper .audio-rights-future .audio-rights-future__title {
	font-family: $font-junior-medium !important;
}
.page-node.junior .sm2-playlist-wrapper .audio-rights-expired .audio-rights-expired__title .filename,
.page-node.junior .sm2-playlist-wrapper .audio-rights-future .audio-rights-future__title .filename {
	color: $color_background-7;
}
.page-node .sm2-playlist-wrapper .sm2-playlist-bd .audio-rights-expired h3,
.page-node .sm2-playlist-wrapper .sm2-playlist-bd .audio-rights-future h3 {
	@include breakpoint($breakpoint_desktop) {
		margin-left: 3.4em;
	}
}


// u audii u assetu nedava smysl zobrazovat posledni slovo z nazvu audio souboru -> proto skryto
.page-node .file-audio .asset-type-audio .title-end {
	display: none;
	.page-node.soundmanager-embed .file-audio {
		margin-left: 0;
	}
	textarea.clipboard-copyline__code {
		resize: none;
	}
}

// no resize textarea pop-up
textarea.clipboard-copyline__code {
	resize: none;
}

// no border for audio
.page-node .audio-article-player .file-audio {
	border: 0 !important;
}

.expired .b-041k__metadata .b-041k__audio-title,
.future .b-041k__metadata .b-041k__audio-title {
	color: $color_background-6;
}

// do not display
.a_004p .button-listaction--share,
.a_004p .button-listaction--download,
.a_004p .button-listaction--podcast {
	display: none;
}
.page-node .sm2-playlist-drawer {
	display: block !important;
}

// fix prehravace, pokud je umisten na HP
body:not(.page-node) {
	.main {
		.audio-article-player {
			.sm2-bar-ui {
				margin-left: 0;
				margin-right: 0;
				&.playing,
				&.paused {
					width: 100%;
				}
				.sm2-main-controls {
					margin-bottom: 0;
				}
				.sm2-time-wrapper {
					top: -58px !important;
					@include breakpoint($breakpoint_desktop) {
						top: -79px !important;
					}
				}
			}
		}
	}
}

// dil serialu = BEZ PLAYLISTU
.node-type-serialpart {
	#repeat.field.field-image-primary {
		picture img {
			margin: 0 10px;
			width: calc(100% - 20px);
			@include breakpoint($breakpoint_desktop) {
				margin: 0;
				width: 100%;
			}
		}
	}
	.file-audio {
		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
		}
	}
}

// serial = S PLAYLISTEM
.node-type-serial,
.page-node.soundmanager-embed {
	// korekce pozice fotky u prehravace
	#repeat.field.field-image-primary {
		picture img {
			margin: 0 10px;
			width: calc(100% - 20px);
			@include breakpoint($breakpoint_desktop) {
				margin: 0;
				width: 100%;
			}
		}
	}
	// styly prehravace a playlistu
	.audio-serial-player {
		margin-left: 0;
		border: 0;
		width: 100%;
		margin-top: -78px;
		@include breakpoint($breakpoint_desktop) {
			margin-top: -90px;
		}
		.box.a-004p {
			margin-top: 0;
			margin-left: 0;
			.sm2-bar-ui {
				width: calc(100% - 20px);
				height: 100% !important;
				background-color: transparent;
				@include breakpoint($breakpoint_desktop) {
					width: 100%;
				}
				// audio je spustene
				&.playing {
					.play-pause {
						background-image: $file_button_action_pause !important;
						background-position: 100%;
						background-size: 200%;
						&:hover {
							background-position: 100%;
						}
					}
				}
				// audio je spustene nebo pauznute
				&.playing,
				&.paused {
					.sm2-controls-wrapper {
						display: flex !important;
					}
				}
				// komponenty playeru
				.sm2-main-controls {
					background-color: $color_background_player_custom_alpha;
					transition: width $transition-speed ease !important;
					position: relative;
					max-height: 90px;
					@include no-touch-select;
					@include breakpoint($breakpoint_desktop) {
						padding: 20px 19px 20px 19px;
					}
					.sm2-time-wrapper {
						top: -16px !important;
						@include breakpoint($breakpoint_desktop) {
							top: -25px !important;
						}
					}
					.sm2-volume {
						display: none !important;
						@include breakpoint($breakpoint_desktop) {
							display: flex !important;
						}
					}
					.play-pause {
						background-image: $file_button_action_play;
						background-size: 200%;
						background-position: 0;
						&:hover {
							background-position: 100%;
						}
					}
					.sm2-controls-wrapper {
						display: none;
					}
				}
				.sm2-playlist-drawer {
					height: calc(100% + 1px); // 1px border
					opacity: 1;
					display: block;
					border: 1px solid $color_background-4;
					border-top: 0;
					// metadata
					.b-041k {
						&__metadata {
							margin-left: 0;
							max-width: 100%;
							border: 0;
						}
						&__item {
						}
						&__description-value {
							a {
								color: $color_decoration_main;
								&:hover {
									text-decoration: underline;
								}
							}
						}
					}
					// playlist
					.sm2-playlist-wrapper {
						margin-top: -20px;
						@include breakpoint($breakpoint_tablet) {
							margin-top: -10px;
						}
						@include breakpoint($breakpoint_desktop) {
							margin-top: -20px;
						}
						.sm2-playlist-bd {
							max-height: 100%;
							overflow-x: hidden;
								&:before {
									content: '';
									height: 1px;
									width: calc(100% - 30px);
									margin: 0 15px;
									display: inline-block;
									background-color: $color_background-4;
									position: relative;
									top: 3px;
								}
								.filename {
									&__text {
										font-size: $filename_fontsize_mobile;
										@include breakpoint($breakpoint_desktop) {
											font-size: $filename_fontsize_desktop;
										}
									}
								}
								// delka audia + k poslechu jeste
								.audio-info-wrap {
								position: absolute;
								left: 71px;
								bottom: 14px;
								display: flex;
								width: calc(100% - 110px);
								@include breakpoint($breakpoint_tablet) {
									bottom: 10px;
								}
								@include breakpoint($breakpoint_desktop) {
									bottom: 5px;
								}
								// delka audia
								.playlist-audio-length {
									color: $color_background-6;
									font-size: .85rem;
									@include typography_font(medium);
									@include breakpoint($breakpoint_desktop) {
										font-size: .95rem;
										position: absolute;
										right: 0;
										bottom: 19px;
									}
								}
								// k poslechu jeste
								.playlist-audio-time-to-expire {
									order: 1;
									color: $color_background-6;
									font-size: .85rem;
									padding-left: 30px;
									position: relative;
									@include breakpoint($breakpoint_desktop) {
										order: initial;
										padding-left: 16px;
									}
									// pomlcka
									&:before {
										content: '-';
										position: relative;
										left: -23px;
										color: $color_background-7;
										@include breakpoint($breakpoint_desktop) {
											content: '';
										}
									}
									// ikona
									&:after {
										content: '';
										font-size: .7rem;
										background-image: $file_icon_sandtimer_colored-09e9ea9;
										background-size: 8px;
										width: 8px;
										height: 14px;
										position: absolute;
										top: 2px;
										left: 20px;
										@include breakpoint($breakpoint_desktop) {
											left: 0;
										}
									}
									// skryti casti textu na mobilu
									.caption__desktop-only {
										display: none;
										@include breakpoint($breakpoint_desktop) {
											display: inline;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		// expiracni hlasky
		.expired-audio-playlist {
			.sm2-row {
				display: flex;
				& > div {
					transition: background-color ease-in-out 250ms;
				}
			}
			// podbarveni rozkliknute error hlasky v playlistu
			&.active {
				box-shadow: none;
				background-color: transparent;
				.sm2-row > div {
					background-color: $color_background_audio;
				}
			}
			.audio-rights-expired,
			.audio-rights-future {
				width: calc(100vw - 22px);
				max-width: calc(100vw - 22px);
				background-color: $color_background-0;
				@include breakpoint($breakpoint_desktop) {
					margin-left: 0;
					max-width: 100%;
				}
				&__title > .filename {
					max-width: 100%;
					text-overflow: ellipsis;
					overflow-x: hidden;
					color: $color_background-6;
					font-size: $filename_fontsize_mobile;
					line-height: 1.5;
					padding-top: 8px;
					@include breakpoint($breakpoint_desktop) {
						font-size: $filename_fontsize_desktop;
						padding-top: 0;
					}
				}
				&__title > p {
					font-size: .75rem;
					@include breakpoint($breakpoint_desktop) {
						font-size: .8rem;
					}
				}
			}
			.audio-rights-future {
				background-color: $color_background_audio;
				cursor: default;
				@include breakpoint($breakpoint_desktop) {
					padding-top: 8px;
				}
			}
		}
	}
}


// Radio Junior
// clanek s jednim audiem
.junior.page-node {
	// zakulaceni rohu u obrazku hl. audia
	.field-image-primary.has-main-audio img {
		border-top-left-radius: $junior_player_radius;
		border-top-right-radius: $junior_player_radius;
		overflow: hidden;
	}
	// prebarveni listy playeru
	.audio-article-player .sm2-bar-ui {
		background-color: $color_junior_main-tag_alpha-low;
	}
	// prograss bar, volume bar - pozadi
	.file-audio .sm2-progress .sm2-progress-track,
	.file-audio .sm2-bar-ui .sm2-volume .sm2-button-bd .volume-shade{
		border-radius: $junior_player_radius;
		overflow: hidden;
		background-color: $color_background-0_alpha-high !important;
	}
	// volume bar - popredi
	.file-audio .sm2-bar-ui .sm2-volume .sm2-volume-control {
		border-radius: $junior_player_radius;
		background-color: $color_junior_add-1;
		background-image: none;
	}
	// barva indikace progressbaru
	.file-audio .sm2-progress .sm2-progress-bar {
		background-color: $color_junior_add-1;
	}
	// prebarveni delky audia
	.file-audio .sm2-time-wrapper .sm2-inline-time,
	.file-audio .sm2-time-wrapper .delitko,
	.file-audio .sm2-time-wrapper .sm2-inline-duration {
		color: $color_junior_add-1;
		font-family: $font-junior-medium;
	}
	.file-audio .sm2-time-wrapper .delitko {
		margin: 0 -3px 0 -3px;
	}
	.file-audio .sm2-time-wrapper div {
		font-size: .78rem !important;
	}
	// zmena symbolu volume na barvu junior fff3dd
	.file-audio .sm2-bar-ui .sm2-volume,
	.file-audio .sm2-bar-ui .sm2-volume:hover {
		background-image: $file_icon_badge_audio_colored-fff3dd;
	}
	// volume background
	.file-audio .sm2-bar-ui .sm2-volume .volume-shade {
		background-image: none;
		background-color: transparent;
	}
	// zmena play ikony
	.file-audio .play-pause,
	.file-audio .paused .play-pause {
		background-image: $file_button_action_play_colored-fff3dd !important;
	}
	// zmena pause ikony
	.file-audio .playing .play-pause {
		background-image: $file_button_action_pause_colored-fff3dd !important;
	}
}


// upravy playeru na nestanicnich webech
.page-1968-hp,
.view-cestyzavodou-hp {
	.audio-article-player .sm2-bar-ui {
		.sm2-controls-wrapper {
			margin-left: 20px;
		}
		.sm2-time-wrapper {
			margin-left: 5em;
			@include breakpoint($breakpoint_desktop) {
				margin-left: 4em;
			}
		}
	}
}


// Radio Junior
// clanek s vice audii (serial)
.junior.node-type-serial,
.soundmanager-embed.junior {
	// kulate rohy obrazku
	.content .has-main-audio img {
		border-top-left-radius: $junior_player_radius;
		border-top-right-radius: $junior_player_radius;
		overlow: hidden;
	}
	// barva podbarveni itemu v playlistu
	.sm2-bar-ui.playing,
	.sm2-bar-ui.paused {
		.selected {
			.sm2-row {
				background-color: $color_junior_main_darker;
			}
			.filename__text {
				color: $color_junior_add-1;
			}
		}
	}
	// zruseni ramecku, pridan border radius pro kontejner
	.audio-serial-player .box.a-004p .sm2-bar-ui .sm2-playlist-drawer {
		border: none;
		border-bottom-left-radius: $junior_player_radius;
		border-bottom-right-radius: $junior_player_radius;
		background-color: $color_junior_main;
		overflow: hidden;
	}
	// prebarveni listy playeru
	.audio-serial-player .box.a-004p .sm2-bar-ui .sm2-main-controls {
		background-color: $color_junior_main-tag_alpha-low;
	}
	// playitko Junior
	.box.a-004p .sm2-bar-ui .sm2-main-controls .play-pause,
	.box.a-004p .sm2-bar-ui.paused .sm2-main-controls .play-pause {
		background-image: $file_button_action_play_colored-fff3dd !important;
	}
	// playitko Junior - pause
	.box.a-004p .sm2-bar-ui.playing .sm2-main-controls .play-pause {
		background-image: $file_button_action_pause_colored-fff3dd !important;
	}
	// prograss bar, volume bar - pozadi
	.sm2-progress .sm2-progress-track,
	.sm2-bar-ui .sm2-volume .sm2-button-bd {
		border-radius: $junior_player_radius;
		overflow: hidden;
		background-color: $color_background-0_alpha-high !important;
	}
	// volume bar - popredi
	.sm2-bar-ui .sm2-volume .sm2-volume-control {
		border-radius: $junior_player_radius;
		background-color: $color_junior_add-1;
		background-image: none;
	}
	.sm2-bar-ui .sm2-volume .sm2-volume-control.volume-shade {
		background-color: $color_background-1_alpha-high;
	}
	// barva indikace progressbaru
	.sm2-progress .sm2-progress-bar {
		background-color: $color_junior_add-1;
	}
	// zmena symbolu volume na barvu junior fff3dd
	.sm2-bar-ui .sm2-volume,
	.sm2-bar-ui .sm2-volume:active,
	.sm2-bar-ui .sm2-volume:hover {
		background-image: $file_icon_badge_audio_colored-fff3dd;
	}
	// barva odkazu
	.audio-serial-player .box.a-004p .sm2-bar-ui .sm2-playlist-drawer .b-041k__description-value a {
		color: $color_junior_add-1;
	}
	// kulate rohy na konci playlistu
	.sm2-bar-ui .sm2-playlist-wrapper .sm2-playlist-bd:after {
		content: '';
		width: 100%;
		height: 10px;
		display: block;
		background-color: $color_junior_main;
	}
	// junior playitko
	.sm2-bar-ui .sm2-playlist-wrapper ul li a:before {
		background-image: $file_button_action_play_colored-fff3dd;
	}
	// podbarveni itemu v playlistu
	.audio-serial-player .sm2-playlist-wrapper ul li {
		background-color: $color_junior_main;
	}
	// barevnost prave hrajici audio
	.audio-serial-player .selected {
		background-color: $color_junior_main_darker !important;
		color: $color_junior_add-1;
	}
	.audio-serial-player .selected div a,
	.audio-serial-player .sm2-bar-ui .sm2-playlist-wrapper ul li a {
		background-color: transparent !important;
		color: $color_junior_add-1;
		font-family: $font-junior-medium;
	}
	// prebarveni delky audia
	.sm2-time-wrapper .sm2-inline-time,
	.sm2-time-wrapper .delitko,
	.sm2-time-wrapper .sm2-inline-duration {
		color: $color_junior_add-1;
		font-family: $font-junior-medium;
	}
	.sm2-time-wrapper .delitko {
		margin: 0 -3px 0 -3px;
	}
	// pozadi u volume
	.sm2-bar-ui .sm2-volume .sm2-button-bd {
		background-color: transparent !important;
		min-height: auto;
    	max-height: 8px;
	}
	// korekce odsazeni na tabletu
	.audio-serial-player .box.a-004p .sm2-bar-ui .sm2-playlist-drawer .sm2-playlist-wrapper {
		@include breakpoint($breakpoint_tablet) {
			margin-top: -20px;
		}
	}
	// delka audia
	// expirace audia audia
	// default stav
	.audio-serial-player .box.a-004p .sm2-bar-ui .sm2-playlist-drawer .sm2-playlist-wrapper .sm2-playlist-bd .audio-info-wrap .playlist-audio-length,
	.audio-serial-player .box.a-004p .sm2-bar-ui .sm2-playlist-drawer .sm2-playlist-wrapper .sm2-playlist-bd .audio-info-wrap .playlist-audio-time-to-expire {
		color: $color_junior_add-1;
		font-family: $font-junior-medium;
	}
	// expirace audia audia
	// prebarveni symbolu pomlcky
	// prebarveni ikony presypacich hodin
	.audio-serial-player .box.a-004p .sm2-bar-ui .sm2-playlist-drawer .sm2-playlist-wrapper .sm2-playlist-bd .audio-info-wrap .playlist-audio-time-to-expire {
		&:before {
			color: $color_junior_add-1;
		}
		&:after {
			background-image: $file_icon_sandtimer_colored-white;
		}
	}
	.audio-serial-player .expired-audio-playlist .audio-rights-expired,
	.audio-serial-player .expired-audio-playlist .audio-rights-future {
		width: 100%;
		max-width: 100%;
	}
}

// serial player - rozbalovatko pro nedostupne epizody
.node-type-serial .cro-audioplayer-serial {
	// element slouzici pro rozbaleni/sbaleni obsahu
	&__button	{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 15px;
		width: 100%;
		font-size: .95rem;
		font-family: inherit;
		border: 0;
		text-align: left;
		background-color: $color_background-5;
		color: $color_background-0;
		&:hover,
		&:focus,
		&:active {
			outline: none;
		}
		&:hover {
			.cro-audioplayer-serial__button-text-toggle-show {
				text-decoration: underline;
			}
		}
		&::after {
			content: '';
			display: inline-block;
			background-image: $file_icon_arrow_single_south_colored-white;
			background-size: 12px 12px;
			min-width: 12px;
			width: 12px;
			height: 12px;
			transition: 300ms ease;
			transform: rotate(180deg);
		}
		&.is-collapsed {
			// rotace ikony
			&::after {
				transform: rotate(0deg);
			}
			// skryvani expirovanych audii
			& + .cro-audioplayer-serial__episodes {
				display: none;
			}
		}
	}
	&__button-text {
		margin-right: 5px;
		@include breakpoint($breakpoint_tablet) {
			margin-right: 0;
		}
	}
	&__button-text-toggle-show {
		display: none;
		@include breakpoint($breakpoint_tablet) {
			display: flex;
			flex-grow: 1;
			justify-content: flex-end;
			margin-right: 8px;
		}
	}
	// epizody
	&__episodes {
		display: block;
	}
	&__episodes--hidden {
		display: none;
	}
}

// velikost obrazku u playeru na mobilu u clanku obsahujici hlavni audio
.field.has-main-audio {
	picture img {
		margin: 0 10px;
		width: calc(100% - 20px);
		@include breakpoint($breakpoint_desktop) {
			margin: 0;
			width: 100%;
		}
	}
}
