// obecne styly - pro vsechny formulare
form {

	// radio.cz
	.radiopraha {
		.cro-web-form-newsletter {
			> div {
				@include breakpoint($breakpoint_desktop) {
					display: flex;
				}
				.form-item {
					@include breakpoint($breakpoint_desktop) {
						margin-bottom: 0;
						flex-basis: 40%;
					}
				}
				.form-actions {
					margin-top: 0;
					@include breakpoint($breakpoint_desktop) {
						flex-basis: 60%;
						margin-left: 20px;
					}
					p {
						margin-top: 0;
					}
					input[type="submit"] {
						margin-top: 1em;
						margin-right: 0;
						margin-left: 0;
						order: 2;
						white-space: normal; // zalomeni pro pripadnou delsi textaci co je v graf. navrhu
						@include breakpoint($breakpoint_desktop) {
							margin-top: 0;
							margin-left: 10px;
							height: auto;
						}
					}
				}
			}
		}
	}
}
