
//
// B-019 Styles
//

$b-019_item_background-arrow-size: 10px;


.b-019 {
	&__list-item a {
		color: $color_background-6 !important; // prepisuje defaultni cernou
		&:before {
			content: '';
			position: absolute;
			right: 0;
			display: inline-block;
			opacity: .7;
			width: 30px;
			height: 24px;
			background-image: $file_icon_arrow_single_east;
			background-repeat: no-repeat;
			background-position-x: right $b-001_item_padding_horizontal; // IE safe positioning
			background-position-y: center;
			background-size: $b-019_item_background-arrow-size $b-019_item_background-arrow-size;
			@include breakpoint($breakpoint_desktop) {
				@include adjust-font-size-to($b-001_item_fontsize_desktop, 1.5);
			}
		}
		&:hover,
		&:active {
			color: $color_background-2 !important; // prepisuje $color_background-6
			background-color: $color_background-6;
			&:before {
				background-image: $file_icon_arrow_single_east_colored-white;
			}
		}
	}
}





//
// B-019a Styles
//



.b-019a {

}

	.b-019a .b-019__list {
		background-color: $color_background-1;
		padding: 0.5em 0;
	}

		.b-019a .b-019__list-item {
		}

			.b-019a .b-019__list-item a {
				display: block;
				align-items: center;
				position: relative;
				background-repeat: no-repeat;
				background-position: left 1.5em center;
				background-size: 2.2em 2.2em;
				height: 100%;
				padding: 0.6em 1em;
				padding-left: 4.5em;
				font-size: .8rem;
				@include link-style ($color_text_accent, none, $color_text_accent_inverted, none);
			}


			.b-019a .b-019__list-item--mujrozhlas a {
				background-image: $file_icon_mujrozhlas;
			}
				.b-019a .b-019__list-item--mujrozhlas a:hover {
					background-image: $file_icon_mujrozhlas_colored-white;
				}


			.b-019a .b-019__list-item--ios a {
				background-image: $file_icon_apple;
			}
				.b-019a .b-019__list-item--ios a:hover {
					background-image: $file_icon_apple_colored-white;
				}


			.b-019a .b-019__list-item--yt a {
				background-image: $file_icon_yt;
			}
				.b-019a .b-019__list-item--yt a:hover {
					background-image: $file_icon_yt_colored-white;
				}

			.b-019a .b-019__list-item--android a {
				background-image: $file_icon_android;
			}
				.b-019a .b-019__list-item--android a:hover {
					background-image: $file_icon_android_colored-white;
				}


			.b-019a .b-019__list-item--spotify a {
				background-image: $file_icon_spotify;
			}
				.b-019a .b-019__list-item--spotify a:hover {
					background-image: $file_icon_spotify_colored-white;
				}


			.b-019a .b-019__list-item--rss a {
				background-image: $file_icon_rss;
			}
				.b-019a .b-019__list-item--rss a:hover {
					background-image: $file_icon_rss_colored-white;
				}





//
// B-019b Styles
//



.b-019b {
	position: relative;

}

	.b-019b .bubble-subscribe {
		display: none;
		position: absolute;
		top: 50%; // calc fallback
		top: calc(50% + 10px); // pulka + 10px sipka
		left: 2em; // calc fallback
		left: calc(50% - #{$bubble-subscribe-width} / 2); //promenna $bubble-subscribe-width je v _bubble.scss
	}

.b-019c {
	margin-top: 0.3em;
}





//
// B-019c Styles
//



.b-019c {
	position: relative;
}

	.b-019c .bubble-subscribe {
		display: none;
		position: absolute;
		top: -20px;
		left: 54px;
		width: 15em;
		outline: none;
		@include breakpoint($breakpoint_tablet) {
			top: -23px;
			left: 47px;
		}
	}
	.b-019c .bubble-subscribe::before {
		top: 29px;
		left: -9px;
		transform: rotate(-45deg);
	}





//
// B-019d Styles
//



.b-019d {
}
	.b-019d:hover a {
		text-decoration-color: $color_decoration_main;
	}

	.b-019d .b-019d__img {
		display: flex;
		img {
			height: 100%;
		}
	}

	.b-019d .button-box-flat__label-nest {
		text-align: left;
		font-size: 1rem;
		line-height: 1.3;
		color: $color_decoration_main;
		text-decoration-color: $color_decoration_main;

		@include breakpoint($breakpoint_desktop) {
			font-size: 1.13rem;
		}
	}

	.b-019d .button-box-flat__label::before {
		content: '';
		background: $file_button_social_facebook #3b5998 no-repeat center;
		background-size: 24px 24px;
		min-width: 24px;
		width: 24px;
		height: 24px;
		display: inline-block;
		margin-right: 10px;
		padding: 6px;
		@include breakpoint($breakpoint_desktop) {
			margin-right: 18px;
			padding: 8px;
		}
	}



// vizualni debug - plab only
.patternlab .b-019b .bubble-subscribe,
.patternlab .b-019c .bubble-subscribe {
	display: inline-block;
}
