//
// A-001 Styles
//



.a-001 {
}

	.a-001__block {
		display: flex;
		flex-direction: column;

		// IE
		min-height: 1px;
	}

		.a-001__overlay {
			position: relative;

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.image {
				display: block;
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.tag {
				position: relative;
				bottom: auto;
				left: 10px;

				max-inline-size: calc(100% - 20px);

				color: $color_text_accent_inverted;
				// defaultni barva stitku, bude pretizena barvou stanice
				background-color: $color_background-3_alpha-low;

				text-transform: uppercase;

				@include breakpoint($breakpoint_desktop) {
					position: absolute;
					bottom: 0;
				}

				a {
					@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);
				}

				span {
					color: $color_text_accent_inverted;
				}
			}
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.a-001__block h3 {
			margin: 0;
			padding: 0.5em;

			// default, k pretizeni stanicni barvou
			background-color: $color_background-9;
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.a-001__block h3 a {
			display: block;

			color: $color_text_accent_inverted;

			@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);
		}

.a-001.irozhlas .tag {
  text-transform: none;
}


//
// A-001a Styles
//
.a-001a {
}

	// TODO z duvodu atomu zatim ne pres specifickou tridu
	.a-001a h2 {
	}

	.a-001a__block {}

		.a-001a__overlay {
			order: 2;

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.image {}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.tag {
				display: none;
			}
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.a-001a__block h3 {
			order: 1;
		}





//
// A-001b Styles
//
.a-001b {
}

	// TODO z duvodu atomu zatim ne pres specifickou tridu
	.a-001b h2 {
	}

	.a-001b__block {}

		.a-001b__overlay {
			order: 2;

			display: none;

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.image {
				display: none;
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.tag {
				display: none;
			}
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.a-001b__block h3 {
			order: 1;
		}





//
// A-001c Styles
//
.a-001c {
}

	// TODO z duvodu atomu zatim ne pres specifickou tridu
	.a-001c h2 {
		display: none;
	}





//
// A-001d Styles
//
.a-001d {
}

	// TODO z duvodu atomu zatim ne pres specifickou tridu
	.a-001d h2 {
		display: none;
	}

	.a-001d__block {}

		.a-001d__overlay {
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.image {
				display: none;
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.tag {
				position: relative;
				bottom: auto;
			}
		}





// vizualni debug - plab only
.patternlab .a-001 {

	@include breakpoint($breakpoint_desktop) {
		max-width: 400px;
	}
}
