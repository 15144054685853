//
// Button Subscribe
//



.button-subscribe {
	position: relative;

	display: block;

	align-items: center;

	background-repeat: no-repeat;
	background-position: left .75em center;
	background-size: 2.2em 2.2em;

	height: 100%;

	padding: 0.6em 3.6em;

	font-size: 0.8rem;
	white-space: nowrap;

	@include link-style ( $color_background-6, none,$color_background-2, none);

	&:before {
		content: '';

		position: absolute;
		right: 0;

		display: inline-block;

		width: 30px;
		height: 24px;

		background-image: $file_icon_arrow_single_east;
		background-repeat: no-repeat;
		background-position-y: center;
		background-size: 10px 10px;

		opacity: 0.7;
	}

	&:hover,
	&:active {
		background-color: $color_background-6;
		&:before {
			background-image: $file_icon_arrow_single_east_colored-white;
		}
	}
}


.button-subscribe--mujrozhlas {
	background-image: $file_icon_mujrozhlas;
}
	.button-subscribe--mujrozhlas:hover {
		background-image: $file_icon_mujrozhlas_colored-white;
	}


.button-subscribe--ios {
	background-image: $file_icon_apple;
}
	.button-subscribe--ios:hover {
		background-image: $file_icon_apple_colored-white;
	}

.button-subscribe--yt {
	background-image: $file_icon_yt;
}
	.button-subscribe--yt:hover {
		background-image: $file_icon_yt_colored-white;
	}


.button-subscribe--android {
	background-image: $file_icon_android;
}
	.button-subscribe--android:hover {
		background-image: $file_icon_android_colored-white;
	}

.button-subscribe--spotify {
	background-image: $file_icon_spotify;
}
	.button-subscribe--spotify:hover {
		background-image: $file_icon_spotify_colored-white;
	}

.button-subscribe--rss {
	background-image: $file_icon_rss;
}
	.button-subscribe--rss:hover {
		background-image: $file_icon_rss_colored-white;
	}
