//
// A-002 Styles
//



.a-002 {
	position: relative;
}

// vertikalni linka
.a-002::before {
	content: '';

	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;

	width: 10px;

	background-color: $color_decoration_main; // defaultni barva, v drupalu bude pretizena barvou stanice
}

	.a-002__block {
		padding: 0.25em 0;
		margin-left: 1.5em;
	}

		.a-002__row {
			margin: 0;

			font-size: 14px !important;
			line-height: 1.6;

			color: $color_text_paragraph;
		}

			.a-002__row strong {
				color: $color_text_accent;
			}





// vizualni debug - plab only
.patternlab .a-002 {

	@include breakpoint($breakpoint_desktop) {
		max-width: 400px;
	}
}
