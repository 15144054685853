//
// Specificke stanicni barveni elementu boxu
//
// nahrazuje bloky pretezovani ve stanicnich stylopisech
// opakuje se - pretezuji se shodne prvky
//



// prebarvovani obecne stranky
@mixin station_element-coloring($param_color_main, $param_color_main-darker) {

	.b-016__list-item a.active::after,
	.b-016__list-item a.expanded,
	.b-016__list-item a:hover,
	.b-016__list-more {
		background-color: $param_color_main;
	}
	.b-016__list-more .b-016__list-item a:hover {
		background-color: $param_color_main-darker;
	}

	.box h2 {
		color: $param_color_main;
		a {
			@include link-style($param_color_main, none, $param_color_main, underline);
		}
	}

	.b-006h__webcam-thumbnail.selected > h3 {
		color: $param_color_main;
	}
	.b-006h__webcam-thumbnail.selected > .b-006h__webcam-thumbnail-image::after {
		background-color: $param_color_main;
	}
}



// prebarvovani node plneho zneni
@mixin station_element-coloring_node-type-article($param_color_main) {

	// defaultni barveni dle webu
	.asset h2 {
		color: $param_color_main;
		a {
			@include link-style($param_color_main, none, $param_color_main, underline);
		}
	}
	.asset .a-001a__block h3,
	.asset .a-001b__block h3 {
		background-color: $param_color_main;
	}
	.asset.a-002::before {
		background-color: $param_color_main;
	}

	// asset Ctete take (stary)
	.cteteTake,
	.body .cteteTake {
		> strong {
			color: $param_color_main;
		}
		> a {
			background-color: $param_color_main;
		}
	}
	// stejne tak asset Clanek ktery z predchazejiciho vizualne vychazi
	.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-article,
	.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-event,
	.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-recipe,
	.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-serial,
	.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-serialpart,
	.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-show {
		> h2::before {
			color: $param_color_main;
		}
		> h2 > a {
			background-color: $param_color_main;
		}
	}
}
