//
// Button Simple
//



// struktura obecneho simple tlacitka
.button-simple {
	display: inline-flex;

	@include link-style($color_decoration_main, none, $color_decoration_main, underline);

	@include adjust-font-size-to($fontsize_simple_button);

	@include breakpoint($breakpoint_desktop) {
		@include adjust-font-size-to($fontsize_simple_button_desktop);
	}

}
// TODO docasne vizualni reseni nedefinovaneho hover efektu
.button-simple:hover {
	opacity: 0.8;
}

	.button-simple__label {
		flex-grow: 1;
	}

		.button-simple__label-nest {
			flex-grow: 1;

			display: block;
		}


	.button-simple__icon {
		flex-shrink: 0;

		width: 0.75em;
		margin-left: 0.5em;

		background-repeat: no-repeat;
		//background-position: left center;
		background-position: left 8px;
	}

		.button-simple__icon-textcontent {
			display: none;
		}





// inverzni varianta obecneho tlacitka
.button-simple--negative {
	@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, underline);
}
// TODO docasne vizualni reseni nedefinovaneho hover efektu
.button-simple--negative:hover {
	opacity: 0.8;
}





// TODO docasne vizualni reseni nedefinovaneho hover efektu
.button-simple--negative:hover {
	opacity: 0.8;
}





// sipky
.button-simple__icon--arrow-double-east {
	background-image: $file_icon_arrow_double_east_colored-decoration-main;
}
// obecna negativni varianta ikony
.button-simple--negative .button-simple__icon--arrow-double-east {
	background-image: $file_icon_arrow_double_east_colored-white;
}

.button-simple__icon--arrow-south {
	background-image: $file_icon_arrow_double_east_colored-decoration-main;

	// TODO chybejici ikona (ve variables je dosazena opacna)
	transform: rotate(90deg);
}

.button-simple__icon--arrow-single-south {
	background-image: $file_icon_arrow_single_south_colored-decoration-main;
}
	.expanded .button-simple__icon--arrow-single-south,
	.button-simple__icon--arrow-single-south.expanded {

		// TODO chybejici ikona north, tak otacime a zarovnavame
		background-position: right center;
		transform: rotate(180deg);
	}
// obecna negativni varianta ikony
.button-simple--negative .button-simple__icon--arrow-single-south {
	background-image: $file_icon_arrow_single_south_colored-white;
}

.button-simple__icon--arrow-single-east {
	background-image: $file_icon_arrow_single_east_colored-decoration-main;

	// TODO cele tohle barveni svg je haluzni hack
	//filter: invert(0.5) sepia(1) hue-rotate(145deg);
}
// obecna negativni varianta ikony
.button-simple--negative .button-simple__icon--arrow-single-east {
	background-image: $file_icon_arrow_single_east_colored-white;

	// TODO cele tohle barveni svg je haluzni hack
	//filter: none;
}



// specificke modifikatory obecneho buttonu - neboli specificka tlacitka
// obcas neBEMove pretezovani protoze classovat to dle typu boxu by bylo silenstvi

// readmore
.button-simple--readmore {
}

// dropdown
.button-simple--dropdown {

	.button-simple__label {
		padding: 0.15em 0.5em;

		border-right: 0;

		text-align: left;
	}

}
