//
// Radio Prague Int. - B-014a Styles
//


#{$radiopraha_selector_body} {

	&.en,
	&.de,
	&.fr,
	&.es,
	&.ru {
		.b-014a__logo a {
			width: 6em;

			background-image: $file_logo_corporate_en_colored-73737f;

			@include breakpoint($breakpoint_desktop) {

				width: 7.5em;
				// resetuje haluzni hodnotu ceskeho logotypu kdyz  R v logu neni ve vertikalnim centru, takze pozicujeme na haluzni hodnotu rucne
				// aby sedelo na lince s polozkami menu
				//background-position: left center;
			}
		}
	}

}
