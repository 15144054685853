//
// B-008d Styles
//
// krome dlasich variant b008 blizka podobnost s nejakymi variantami b004
// ale primo odvozovano z nich neni
//



.b-008d {
}
	
	.b-008d__list {
		
		display: flex;
		flex-direction: column;
		
		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}
		
		.b-008d__list-item {
			
			margin-bottom: 2em;
			
			// IE
			min-height: 1px;
			
			@include breakpoint($breakpoint_desktop) {
				
				// vlastnosti jedne polozky
				flex-basis: 50%;
				box-sizing: border-box;
				max-width: 50%;
				
				// v desktop variante flexuje vlastni obsah tez
				// nutne pro zachovavani vysky polozky vzhledem k sousedni
				display: flex;
				flex-direction: column;
			}
		}
		.b-008d__list-item:nth-child(odd) {
			
			@include breakpoint($breakpoint_desktop) {
				padding-right: 1em;
			}
		}
		.b-008d__list-item:nth-child(even) {
			
			@include breakpoint($breakpoint_desktop) {
				padding-left: 1em;
			}
		}
			
			.b-008d__block--image {
				// IE
				min-height: 1px;
			}
				
				.b-008d__image {
					position: relative;
				}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008d__image .tag {
						
						position: absolute;
						bottom: 0;
						left: 10px;
						
						max-inline-size: calc(100% - 20px);
					}
			
			
			.b-008d__block--description {
				
				padding: 0.5em;
				
				background-color: $color_background-2;
				
				
				@include breakpoint($breakpoint_desktop) {
					
					// pro desktop se snazi rust aby dorostl pripadne vetsiho souseda na radku
					flex-grow: 1;
					
					// a flexuje vlastni deti aby bylo uskutecneno odsazeni smerem dolu
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}
			}
				
				.b-008d__subblock--content {
				}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008d__subblock--content h3 {
						
						margin: 0;
						margin-bottom: 0.5em;
					}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008d__subblock--content a p {
						
						margin: 0;
						margin-bottom: 0.5em;
						
						color: $color_text_paragraph;
						@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
					}
				
				
				.b-008d__subblock--meta {
					
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					
					padding-top: 0.5em;
					
					border-top: 1px solid $color_divider;
				}
					
					.b-008d__meta-wrapper {
					}
						
						.b-008d__meta-line {
							@include adjust-font-size-to($fontsize_box_petit_main);
							
							@include breakpoint($breakpoint_desktop) {
								@include adjust-font-size-to($fontsize_box_petit_main_desktop);
							}
						}
							
							.b-008d__meta-label {
								color: $color_text_paragraph;
							}
							
							.b-008d__meta-value {
							}
					
					
					.b-008d__button-play-wrapper {
						margin-left: 2em;
					}
	
	
	
	
	.b-008d__buttons-wrap {
		text-align: center;
	}
		
		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-008d__buttons-wrap .button-box-flat-small {
			
			display: inline-flex;
			
			margin-top: 3em;
		}