//
// Radio Prague Int. - footer legal styles
//



#{$radiopraha_selector_body} {

	.footer-legal__logo {

		a {

			background-image: $file_logo_radiopraha_colored-white;
		}
	}

}
