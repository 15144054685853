//
// B-037 Styles
//



.b-037 {
}

	.b-037__subsection {
		padding: 0.75em;
		background-color: $color_background-2;
	}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-037__subsection p {
			color: $color_text_paragraph_node;
		}

		.b-037__subsection p:first-of-type {
			margin-top: 0;
		}

		.b-037__subsection p:last-of-type {
			margin-bottom: 0;
		}

		// image metadata
		.b-037__subsection .img-metadata {
			display: inline-block;

			margin-top: 0.25em;

			font-size: 12px;
			line-height: 1.3;

			color: $color_text_accent;
		}

			.b-037__subsection .img-metadata a {
				@include link-style($color_decoration_main, none, $color_decoration_main, underline);
			}


		.b-037__subsection .img-metadata__title {
			color: $color_text_paragraph;
		}

		.b-037__subsection .img-metadata__spacer {
			margin: 0.5em;
		}

		.b-037__subsection .img-metadata__spacer--comma {
			margin: 0.5em;
			margin-left: 0;
		}

		.b-037__subsection .img-metadata__comma {
			margin-right: 0.25em;
		}

		.b-037__subsection .img-metadata__authoring-info {
		}





// vizualni debug - plab only
.patternlab {

	.b-037 {

		@include breakpoint($breakpoint_desktop) {
			max-width: 400px;
		}
	}
}
