//
// Program - hlasovaci aplikace
//


$item-margin: 10px;
$checkbox-ico-unchecked: url('../images/button/btn_checkbox_unchecked.svg') center no-repeat;
$checkbox-ico-checked: url('../images/button/btn_checkbox_checked.svg') center no-repeat;

.cro-common-best-image-1-entityform-edit-form,
.cro-common-best-image-2-entityform-edit-form,
.cro-common-best-img-in-cat-1-entityform-edit-form,
.cro-common-best-img-in-cat-2-entityform-edit-form,
.cro-program-soutez-neviditelny-h-entityform-edit-form,
.cro-common-best-node-1-entityform-edit-form,
.cro-common-best-node-2-entityform-edit-form,
.cro-common-best-nodes-1-entityform-edit-form,
.cro-common-best-nodes-2-entityform-edit-form {
	margin-bottom: 100px;

	// form item styles
	.field-type-email.field-name-field-form-email {
		@include breakpoint($breakpoint_tablet) {
			margin-right: 2%;
		}
	}
	.field-type-email.field-name-field-form-email,
	.field-type-text.field-name-field-form-mobile {
		@include breakpoint($breakpoint_tablet) {
			width: 49%;
			float: left;
		}
	}
	label[for=edit-field-form-checkboxes-und] {
		margin-bottom: 10px;
	}

	// checkboxes
	.form-checkboxes,
	.form-radios {
		display: flex;
		flex-wrap: wrap;
		@include clearfix;
		.form-item.form-type-checkbox,
		.form-item.form-type-radio {
			transition: background-color 250ms;
			margin-bottom: 20px;
			flex-basis: 40%;
			flex-grow: 1;
			max-width: 49%;
			max-width: calc(50% - #{$item-margin});
			box-sizing: border-box;
			cursor: pointer;
			&:nth-child(1n) {
				margin-right: $item-margin;
			}
			&:nth-child(2n) {
				margin-left: $item-margin;
				margin-right: 0;
			}
			@include breakpoint($breakpoint_desktop) {
				flex-basis: 30%;
				flex-grow: 1;
				max-width: 32%;
				max-width: calc(33% - (#{$item-margin} / 3));
				&:nth-child(2n) {
					margin-left: 0;
					margin-right: $item-margin;
				}
				&:nth-child(3n) {
					margin-left: 0;
					margin-right: 0;
				}
			}
			&:hover {
				@include breakpoint($breakpoint_desktop) {
					.f-001__image {
						border: 5px solid $color_decoration_main;
						&:after {
							content: '';
							background: $checkbox-ico-checked;
						}
						img {
							transform: scale(1.05);
						}
					}
				}
			}
		}
		.form-item.form-type-checkbox [class^='f-001__'],
		.form-item.form-type-radio [class^='f-001__'] {
			text-transform: initial;
		}
		.form-item.form-type-checkbox input[type='checkbox'] + label,
		.form-item.form-type-radio input[type='radio'] + label {
			cursor: pointer;
		}
		input[type='checkbox'],
		input[type='radio'] {
			display: none;
		}
		input[type=checkbox]:checked + label,
		input[type=radio]:checked + label {
			.f-001__image {
				border: 5px solid $color_decoration_main;
				&:after {
					content: '';
					background: $checkbox-ico-checked;
				}
				img {
					transform: scale(1.05);
				}
			}
			.f-001__name {
				color: $color_decoration_main;
			}
		}
	}

	// other styles
	.f-001 {
		&__link {
			color: $color_decoration_main;
		}
		&__name {
			margin-top: 15px;
			margin-bottom: 10px;
		}
		&__role,
		&__hra,
		&__autor,
		&__rezie,
		&__lokace,
		&__votes {
			@include typography_font(medium);
			display: grid;
			grid-template-columns: 40px 1fr;
			@include breakpoint($breakpoint_desktop) {
				grid-template-columns: 50px 1fr;
			}
			&--label {
				color: $color_background-5;
				@include typography_font(regular);
			}
		}
		&__image {
			border: 5px solid $color_background-4;
			overflow: hidden;
			position: relative;
			display: grid;
			background-color: $color_background-9;
			&:after {
				content: '';
				position: absolute;
				display: block;
				width: 32px;
				height: 32px;
				right: -5px;
				bottom: -5px;
				background: $checkbox-ico-unchecked;
			}
			img {
				transition: all 400ms ease-in-out;
			}
		}
		&__button-fake {
			padding: .35em 0;

			a {
				padding: .4em .7em;
				text-transform: uppercase;
				color: $color_text_accent_inverted;
				background-color: $color_decoration_success;
				cursor: pointer;

				&:hover {
					opacity: 0.8;
					text-decoration: none;
				}
			}
		}
	}

	// form footer
	.form-actions {
		display: flex;
		flex-wrap: wrap;
		input[type='submit'] {
			order: 2;
			flex-basis: 100%;
			margin-top: 18px;
			@include breakpoint($breakpoint_tablet) {
				margin-top: 0;
				flex-basis: auto;
			}
			@include breakpoint($breakpoint_desktop) {
				margin-top: 18px;
				margin-right: 20px;
				height: 50px;
				align-self: center;
			}
		}
		.form-disclaimer {
			margin-top: .5rem;
			margin-bottom: .5rem;
			line-height: 1.2;
			@include breakpoint($breakpoint_desktop) {
				line-height: 3;
			}
			a {
				color: $color_decoration_main;
			}
		}
	}
}

/**
 * Overrides
 */
#cro-common-best-node-1-entityform-edit-form,
#cro-common-best-node-2-entityform-edit-form {

	.form-item.form-type-radios.form-item-field-form-best-node-1-und > label {
		position: relative;
		display: inline-flex;
		flex-direction: column;

		&::after {
			content: '*';
			color: $color_decoration_main;
			position: absolute;
			top: 0;
			left: 18.3em;

			@include breakpoint($breakpoint_desktop) {
				left: 18em;
			}
		}

		.form-disclaimer + .form-required {
			display: none;
		}

		.form-disclaimer {
			color: $color_text_paragraph;
			@extend %form-submit-button-and-disclaimer;
			a {
				margin-left: 4px;
			}
		}
	}

	.form-disclaimer {
		margin-bottom: 1rem;
		text-transform: none;
	}

	.f-001 {
		&__autor,
		&__lokace,
		&__votes {
			display: block;
		}
	}

	.f-001__image {
		display: block;
		position: relative;

		&:after {
			display: none;
		}

		img {
			display: block;
		}
	}

	.f-001__link {
		position: absolute;
		bottom: 0;
		right: 0;
		color: $color_decoration_main;
		padding: .3em 1em .1em;
		background-color: $color_background-4;
	}

}

#cro-common-best-node-1-entityform-edit-form {
	.form-actions {
		visibility: hidden;
		height: 0;
	}

	.form-disclaimer {
		margin-top: 0;
		line-height: 1.2;
	}
}


/**
 * Web forms error messages
 */
.js-error-text {
	color: #b33630;
	background: #f9eae9;
	border: 1px solid #d97570;
	padding: .5em;
}

#pbr-form-apply-entityform-edit-form,
#pbr-form-apply-en-entityform-edit-form {
	.js-error {
		border: 2px solid #b33630;
	}
}

/* hotfix stitku hlasovaciho formulare */
#cro-common-best-node-1-entityform-edit-form .f-001__link { position: relative; display: block; line-height: 16px; }
@media (min-width: 960px) { #cro-common-best-node-1-entityform-edit-form .f-001__link { position: absolute; display: inline-block; line-height: 24px; } }
#cro-common-best-node-2-entityform-edit-form .f-001__link { position: relative; display: block; line-height: 16px; }
@media (min-width: 960px) { #cro-common-best-node-2-entityform-edit-form .f-001__link { position: absolute; display: inline-block; line-height: 24px; } }
