//
// B-002b Styles
//
// markup tohoto boxu je stejny (velmi podobny) s b002c
// zmeny jsou na urovni stylu
//



$b-002b_primary-heading_fontsize_mobile: 24px;
$b-002b_primary-heading_fontsize_desktop: 28px;

$b-002b_primary-heading_maxwidth_desktop: 60%;

$b-002b_element-spacing_desktop: 2em; // podle alese ma byt 30px, ale coz



.b-002b {
}

	// obecny seznam
	.b-002b__list {
	}

		// obecny element
		.b-002b__list-item {
		}

			.b-002b__block {
				position: relative;
			}

				.b-002b__image {
				}

					// ikonove priznaky
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-002b__image .badge {
						position: absolute;
						top: 0;
						right: 0;
					}

					.b-002b__image .badge.badge-video-play {
						position: absolute;
						top: 0;
						left: 0.75em;
						right: auto;
					}

				.b-002b__overlay {
					position: absolute;
					bottom: 0;
					left: 10px;
					right: 10px;
				}

					.b-002b__overlay-content {
					}



	// pseudoseznam s jednim (prvnim) elementem
	.b-002b__list--primary {
	}

		// specifika: prvni velky element
		.b-002b__list-item--list-primary {

			flex-basis: 100%;

			margin-bottom: 1em;

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: $b-002b_element-spacing_desktop;
			}
		}

			// obecne vlastnosti nadpisu prvniho elementu
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-002b__list-item--list-primary h3 {

				margin: 0;
				padding: 0.5em;

				background-color: $color_background-2;

				@include adjust-font-size-to($b-002b_primary-heading_fontsize_mobile, 1.25);

				@include breakpoint($breakpoint_desktop) {

					clear: both;
					display: inline-block;
					float: left;

					@include adjust-font-size-to($b-002b_primary-heading_fontsize_desktop, 1.25);
				}
			}

			.b-002b__overlay--item-list-primary {
			}

				// nadpis uvnitr overlay - pouze pro desktop
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-002b__overlay--item-list-primary > .b-002b__overlay-content > h3 {

					display: none;

					@include breakpoint($breakpoint_desktop) {

						display: block;

						max-width: $b-002b_primary-heading_maxwidth_desktop;
						padding: 0.75em 1em;

						background-color: $color_background-2_alpha-low;
					}
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-002b__overlay--item-list-primary .tag {

					display: inline-block;
					float: left;
				}


			// druha instance nadpisu mimo overlay - pouze pro mobile
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-002b__list-item--list-primary > h3 {

				@include breakpoint($breakpoint_desktop) {
					display: none;
				}
			}



	// seznam nasledujicich elementu
	.b-002b__list--following {

		display: flex;

		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}
	}

		// specifika: nasledujici elementy (vsechny)
		.b-002b__list-item--list-following {

			margin-bottom: 1em;

			@include breakpoint($breakpoint_desktop) {

				flex-basis: 33.33%;

				margin-bottom: 0;
				margin-right: $b-002b_element-spacing_desktop;
			}
		}
		.b-002b__list-item--list-following:last-child {

			margin-bottom: 0;

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}

			.b-002b__overlay--item-list-following {
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-002b__list-item--list-following h3 {

					margin: 0;
					padding: 0.5em 0.5em;

					@include breakpoint($breakpoint_desktop) {
						padding: 0.5em 0 0 0;
					}
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-002b__list-item--list-following .tag {
				}
