//
// Trifork
//
// trojity rozcestnik primarne pro docasne sluzby audioarchivu
// realizovan obecne, polozky (ikony) dospecifikovany BEM modifikatory
//
// prezije i dve nebo jednu polozku (nemusi byt vsechny pritomny)
//



$trifork_list_padding_desktop: 1em;

$trifork_list-item_spacing-vertical_mobile: 4em;
$trifork_list-item_spacing-horizontal_desktop: 4em;

$trifork_icon_size: 5.5em;
$trifork_icon_position-top: 1em;

$trifork_heading_font-size_mobile: 17px;
$trifork_heading_font-size_desktop: 24px;



.trifork {
	// ala obecny box
	margin-top: 2em;
	// neni obecne pravidlo a je zde zbytecne a nesystemove, ale pokud bude trojnoha
	// samojedina na strance jak je tomu doted, tak tohle je stale nejlepsi misto
	// jak ji definovat vzduch nez zacne pokracovat paticka webu
	//margin-bottom: 2em;

	@include breakpoint($breakpoint_desktop) {
		// ala obecny box
		margin-top: 3em;

		// radial bg gradient. jako fakt?
		//background: radial-gradient(ellipse closest-side, $color_background-2, $color_background-0);
	}
}

	.trifork__list {
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}
	}

		.trifork__list-item {
			margin-bottom: $trifork_list-item_spacing-vertical_mobile;
			text-align: center;

			transition: background-color 1s ease;

			@include breakpoint($breakpoint_desktop) {
				// flexuje jako dite
				flex-basis: 33%;

				margin-bottom: 0;
				margin-right: $trifork_list-item_spacing-horizontal_desktop;
			}

			&:hover {

				@include breakpoint($breakpoint_desktop) {
					background-color: $color_background-1;
				}
			}

			a {
				display: block;

				padding-top: $trifork_icon_size+$trifork_icon_position-top;

				background-image: $file_icon_close_colored-519fd7; // default k prebiti modifikatorem
				background-repeat: no-repeat;
				background-position: center $trifork_icon_position-top;
				background-position-x: center; // IE safe positioning
				background-position-y: $trifork_icon_position-top;
				background-size: auto $trifork_icon_size;

				@include breakpoint($breakpoint_desktop) {
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					padding-left: $trifork_list_padding_desktop;
					padding-right: $trifork_list_padding_desktop;
					padding-bottom: $trifork_list_padding_desktop;

					height: 100%;
				}

				&:hover {
					text-decoration: none;
				}
			}
		}
		.trifork__list-item:last-child {
			margin-bottom: 0;

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.trifork__list-item h3 {
				margin-top: 1em;
				margin-bottom: 0.25em;

				@include adjust-font-size-to($trifork_heading_font-size_mobile, 1.25);

				@include breakpoint($breakpoint_desktop) {
					@include adjust-font-size-to($trifork_heading_font-size_desktop, 1.25);
				}
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.trifork__list-item p {
				margin-top: 0.5em;
				margin-bottom: 1em;

				color: $color_text_paragraph;

				@include breakpoint($breakpoint_desktop) {
					margin-bottom: 2em;
				}
			}

			.trifork__button-readmore {
				display: inline-block;
				margin: 0 auto;
				width: auto;
			}



//
// ikonove varianty pomoci modifikatoru
//

.trifork__list-item--audioarchive a {
	background-image: $file_icon_badge_audio_colored-main;
}

.trifork__list-item--audiolog a {
	background-image: $file_icon_timer_colored-main;
}

.trifork__list-item--podcast a {
	background-image: $file_icon_podcast_colored-main;
}





$trifork-poplatek_list_padding_mobile: 0.5em;
$trifork-poplatek_list_padding_desktop: 1em;

$trifork-poplatek_list-item_spacing-vertical_mobile: 2em;
$trifork-poplatek_list-item_spacing-horizontal_mobile: 0.5em;
$trifork-poplatek_list-item_spacing-horizontal_desktop: 3em;

$trifork-poplatek_list-item_radius_mobile: 25px;
$trifork-poplatek_list-item_radius_desktop: 50px;

$trifork-poplatek_icon_size_mobile: 4em;
$trifork-poplatek_icon_size_desktop: 8em;
$trifork-poplatek_icon_position-top_mobile: 1.5em;
$trifork-poplatek_icon_position-top_desktop: 2.5em;

$trifork-poplatek_heading_font-size_mobile: 11px;
$trifork-poplatek_heading_font-size_desktop: 26px;

$trifork-poplatek_font-size_mobile: 16px;
$trifork-poplatek_font-size_desktop: 14px;


// trifork varianta poplatek
.trifork--poplatek {
}

	.trifork--poplatek .trifork__list {
		display: flex;
		flex-direction: row;
		//justify-content: center;
	}

		.trifork--poplatek .trifork__list-item {
			display: flex;
			// trik jak 3 itemy radku + jejich margin roztahnout na 100%
			// ale ne pokud jich neni na radek plny pocet
			flex-basis: 30%; // toto neni tretina, ale vychozi velikost ktera se roztahne
			flex-grow: 1; // roztahne se pomoci grow do tretin
			max-width: 32%; // (fallback pro no-calc pro nasledujici radek)
			max-width: calc(33.33% - 0.34em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
			// proc - 0,67em? protoze je to 0.5 * 2 / 3 viz (*)

			box-sizing: border-box;

			margin-right: $trifork-poplatek_list-item_spacing-horizontal_mobile; // (*)
			margin-bottom: 0;

			border-radius: $trifork-poplatek_list-item_radius_mobile;
			text-align: center;

			transition: opacity 1s ease;

			@include breakpoint($breakpoint_desktop) {
				max-width: calc(33.33% - 2em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 2em? protoze je to 3 * 2 / 3 viz (*)

				margin-right: $trifork-poplatek_list-item_spacing-horizontal_desktop; // (*)

				border-radius: $trifork-poplatek_list-item_radius_desktop;
			}

			&:hover,
			&:focus,
			&:active {

				@include breakpoint($breakpoint_desktop) {
					opacity: 0.8;
				}
			}

			a {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex-grow: 1;

				padding-top: $trifork-poplatek_icon_size_mobile+$trifork-poplatek_icon_position-top_mobile;
				padding-left: $trifork-poplatek_list_padding_mobile;
				padding-right: $trifork-poplatek_list_padding_mobile;
				padding-bottom: $trifork-poplatek_list_padding_mobile;

				background-image: $file_icon_close_colored-519fd7; // default k prebiti modifikatorem
				background-repeat: no-repeat;
				background-position: center $trifork-poplatek_icon_position-top_mobile;
				background-position-x: center; // IE safe positioning
				background-position-y: $trifork-poplatek_icon_position-top_mobile;
				background-size: auto $trifork-poplatek_icon_size_mobile;

				width: 100%;
				height: 100%;

				@include breakpoint($breakpoint_desktop) {
					padding-top: $trifork-poplatek_icon_size_desktop+$trifork-poplatek_icon_position-top_desktop;
					padding-left: $trifork-poplatek_list_padding_desktop;
					padding-right: $trifork-poplatek_list_padding_desktop;
					padding-bottom: $trifork-poplatek_list_padding_desktop;

					background-position: center $trifork-poplatek_icon_position-top_desktop;
					background-position-x: center; // IE safe positioning
					background-position-y: $trifork-poplatek_icon_position-top_desktop;
					background-size: auto $trifork-poplatek_icon_size_desktop;

				}

				&:hover,
				&:focus,
				&:active {
					text-decoration: none;
				}
			}
		}
		.trifork--poplatek .trifork__list-item:last-child {
			margin-right: 0;
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.trifork--poplatek .trifork__list-item h3 {
				margin-top: 1em;
				margin-bottom: 0.25em;

				color: $color_text_accent_inverted;

				//@include adjust-font-size-to($trifork-poplatek_heading_font-size_desktop, 1.25);
				font-size: $trifork-poplatek_heading_font-size_mobile;
				line-height: 1.3;
				word-wrap: break-word; // jen na ultra uzkych viewportech
				text-transform: uppercase;

				@include breakpoint($breakpoint_desktop) {
					//@include adjust-font-size-to($trifork-poplatek_heading_font-size_desktop, 1.5);
					font-size: $trifork-poplatek_heading_font-size_desktop;
					line-height: 1.3;
				}
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.trifork--poplatek .trifork__list-item p {
				display: none;

				margin-top: 0;
				margin-bottom: 0.25em;

				color: $color_text_accent_inverted;

				//@include adjust-font-size-to($trifork-poplatek_font-size_mobile, 1);
				font-size: $trifork-poplatek_font-size_mobile;
				line-height: 1.3;

				@include breakpoint($breakpoint_desktop) {
					display: block;
					margin-bottom: 0.5em;

					//@include adjust-font-size-to($trifork-poplatek_font-size_desktop, 1);
					font-size: $trifork-poplatek_font-size_desktop;
					line-height: 1.3;
				}
			}

			.trifork--poplatek .trifork__button-readmore {
				display: inline-block;
				margin: 0 auto;
				width: auto;
			}

				.trifork--poplatek .trifork__button-readmore .button-box-flat--readmore {
					background-color: initial;
					border: none;
					height: 25px;

					@include breakpoint($breakpoint_desktop) {
						height: 30px;
					}
				}

					.trifork--poplatek .trifork__button-readmore .button-box-flat__label {
						display: none;
					}

					.trifork--poplatek .trifork__button-readmore .button-box-flat__icon {
						background-image: $file_icon_arrow_double_east_colored-white;
						margin: 0;
						width: 12px;

						@include breakpoint($breakpoint_desktop) {
							width: 18px;
						}
					}



//
// barvy a ikonove varianty pomoci modifikatoru
//

.trifork--poplatek .trifork__list-item--households {
	background-color: $color_corporate_add-2;
}

.trifork--poplatek .trifork__list-item--bussinesmen {
	background-color: $color_corporate_add-1;
}

.trifork--poplatek .trifork__list-item--legalentities {
	background-color: $color_corporate_main;
}

.trifork--poplatek .trifork__list-item--households a {
	background-image: $file_icon_households_colored-white;
}

.trifork--poplatek .trifork__list-item--bussinesmen a {
	background-image: $file_icon_businessmen_colored-white;
}

.trifork--poplatek .trifork__list-item--legalentities a {
	background-image: $file_icon_legalentities_colored-white;
}
