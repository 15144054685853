//
// Pager (d7 default markup)
//



$pager_bar-height: 3.5em;
$pager_margin-top_mobile: 1.5em;



// zakladni Drupal stylovani prenesene do Patternlabu, na kterem se bude stavet dal
.pager {
	clear: both;
	padding: 0;
	text-align: center;
	list-style-image: none;
}

	.pager__item {
		background-image: none;
		display: inline;
		list-style-type: none;
		padding: 0.5em;
	}

	.pager__item--current {
		@include typography_font(medium);
	}



//
// stylovani pretizenim
//
// tady se na vahu selektoru kasle
// s markupem se stejne v teto fazi neda hnout, tak at v tom neni uplnej border klidne zanoruju
// navic treba buttony mame atomizovane, takze idealne by se pouzily hotove, ale markup nesedi, takze znovu a jinak
.pager {

	display: flex;
	align-items: stretch;

	justify-content: space-between;

	height: $pager_bar-height;
	margin-top: $pager_margin-top_mobile;

	@include breakpoint($breakpoint_desktop) {

		justify-content: center;

		margin-top: 2em;
	}
}

	// item obecny (text i tlacitko)
	.pager__item {

		// mobile first - obecny item je zakazany - vsechno je schovane
		display: none;
		align-items: center;
		justify-content: center;

		// na desktopu jsou videt vsechny
		@include breakpoint($breakpoint_desktop) {
			display: flex;
		}

		margin: 0;


		// odkaz ve vsech pripadech vylije celou oblas itemu a centruje svuj text
		a {
			display: inline-flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: center;
			width: 100%;
			height: 100%;

			@include link-style($color_decoration_main, none, $color_decoration_main, none);
		}
	}



	// itemy obecne tlacitkove
	// itemy obecne textove (cisla) svuj modifier nemaji, takze se odvozuji od itemu obecneho
	.pager__item--first,
	.pager__item--previous,
	.pager__item--next,
	.pager__item--last {
		background-color: $color_background-6;
		width: $pager_bar-height;

		// maji skryty text
		a {
			text-indent: 100%;
			white-space: nowrap;
			overflow: hidden;
		}
		// a pripravu na obrazky
		position: relative; // pro :after
		a:after {
			content: "";
			position: absolute;
			left: 0.5em;
			top: 0.5em;
			width: $pager_bar-height - 1em;
			height: $pager_bar-height - 1em;
		}


		// a zadny padding
		padding: 0em;
	}
	.pager__item--first:hover,
	.pager__item--previous:hover,
	.pager__item--next:hover,
	.pager__item--last:hover {
		opacity: 0.85;
	}


	// itemy konkretni specificke
	.pager__item--first {
		// desktop only
		@include breakpoint($breakpoint_desktop) {
			display: flex;
		}

		margin-right: 0.5em;

		a:after {
			background-image: $file_button_icon_arrow_east_end;
		}
		// hack pro rotaci obrazku
		transform: rotate(180deg);
	}

	.pager__item--previous {
		// viditelne v mobile i desktop
		display: flex;

		margin-right: 0.5em;

		@include breakpoint($breakpoint_desktop) {
			margin-right: 2em;;
		}

		a:after {
			background-image: $file_button_icon_arrow_east;
		}
		// hack pro rotaci obrazku
		transform: rotate(180deg);
	}

	.pager__item--current {
		// viditelne v mobile i desktop
		display: flex;

		// na mobilu sezere vsechno dostupne misto uprostred a oramuje se
		flex-grow: 1;
		border: 1px solid $color_background-4;

		// na desktopu normal
		@include breakpoint($breakpoint_desktop) {
			border: 0;
			flex-grow: 0;
		}
	}

	.pager__item--next {
		// viditelne v mobile i desktop
		display: flex;

		margin-left: 0.5em;

		@include breakpoint($breakpoint_desktop) {
			margin-left: 2em;;
		}

		a:after {
			background-image: $file_button_icon_arrow_east;
		}
	}

	.pager__item--last {
		// desktop only
		@include breakpoint($breakpoint_desktop) {
			display: flex;
		}

		margin-left: 0.5em;

		a:after {
			background-image: $file_button_icon_arrow_east_end;
		}
	}
