//
// B-050a Styles
//




.b-050a {

	h3 {
		margin:0;
	}

	background: $color_background-2;
	padding: 1.5em;

	p {
		margin: .3em 0;
		color: $color_text-paragraph;
	}

	ol {
		@include typography_font(medium);
		@include adjust-font-size-to($fontsize_h3);
		color: $color_text-accent;

		@include breakpoint($breakpoint_desktop) {
			@include adjust-font-size-to($fontsize_h3_desktop);
		}

		li {
			list-style-type: decimal;
			list-style-position:  inside;
			margin-bottom: .3em;
		}

		span {
			@include typography_font(regular);
			color: $color_text-paragraph;
			font-size: 1rem;
		}
	}

	.button {
		margin-top: .5em;
	}

	.button-box-flat {
		display: inline-flex;
	}
}
