//
// B-022 Styles
//



$b-022_image-width-basis: 14em;



.b-022 {
}

	.b-022__list {
	}

		.b-022__list-item {
			display: flex;
			flex-direction: column;

			margin-bottom: 1em;

			@include breakpoint($breakpoint_desktop) {

				flex-direction: row;

				margin-bottom: 2em;
			}
		}

			.b-022__block {
			}

			.b-022__block--image {

				@include breakpoint($breakpoint_desktop) {
					flex-basis: $b-022_image-width-basis;
					flex-shrink: 0;
				}
			}

				.b-022__image {
					position: relative;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022__image .tag {
						position: absolute;
						bottom: 0;
						left: 10px;

						max-inline-size: calc(100% - 20px);
					}


			.b-022__block--description {
				flex-grow: 1;

				@include breakpoint($breakpoint_desktop) {
					padding-left: 1em;
				}
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-022__block--description h3 {
					margin-top: 1em;
					margin-bottom: 0;

					@include breakpoint($breakpoint_desktop) {
						margin-top: 0;
					}
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-022__block--description p {
					margin-top: 0.5em;
					margin-bottom: 0.5em;

					color: $color_text_paragraph;
				}

				.b-022__actions {
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022__actions .button-listaction {
						margin-right: 0.25em;
					}





//
// B-022a Styles
//


.b-022a {
}

	.b-022a__list {
	}

		.b-022a__list-item {
		}

			.b-022a__block {
			}

			.b-022a__block--image {
			}

				.b-022a__image {
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022a__image .tag {
						@include breakpoint($breakpoint_desktop) {
							display: none;
						}
					}


			.b-022a__block--description {
				padding: 0 10px;
				padding-top: 5px;

				@include breakpoint($breakpoint_desktop) {
					padding: 0;
					padding-left: 1em;
				}
			}

				.b-022a__description-row {
					display: flex;
					align-items: center;

					margin-bottom: 0.25em;

					@include breakpoint($breakpoint_desktop) {
						margin-bottom: 0.5em;
					}
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022a__description-row .tag {
						display: none;

						@include breakpoint($breakpoint_desktop) {
							display: block;

							margin-right: 1em;
						}
					}

					.b-022a__description-row .b-022__timestamp {
						color: $color_text_paragraph;
						font-size: 12px;
					}

						.b-022a__description-row .b-022__timestamp--date {
							margin-right: 0.75em;
						}

						.b-022a__description-row .b-022__timestamp--time {
						}


				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-022a__block--description h3 {
					margin-top: 0;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-022a__block--description p {
				}

				.b-022a__actions {
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022a__actions .button-listaction {
					}





//
// B-022b Styles
//


.b-022b {
}

	.b-022b__timestamp {
		border-bottom: 1px solid $color_background-9;
		//color: $color_text_accent;

		padding-bottom: 0.25em;
		margin-bottom: 2em;

		font-size: 14px;
		line-height: 1.3;
	}

	.b-022b__list {
	}

		.b-022b__list-item {
			border-bottom: 1px solid $color_divider;
			padding-bottom: 1.5em;
			margin-bottom: 2em;
		}

			.b-022b__block {
			}

			.b-022b__block--image {
				margin-right: 0;
				margin-bottom: 1em;

				@include breakpoint($breakpoint_desktop) {
					flex-basis: 12em;
					margin-right: 1em;
					margin-bottom: 0;
				}
			}

				.b-022b__image {
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022b__image .tag {
						@include breakpoint($breakpoint_desktop) {
							display: none;
						}
					}


			.b-022b__block--description {
				padding: 0 10px;

				@include breakpoint($breakpoint_desktop) {
					padding: 0;
				}
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-022b__block--description h3 {
					margin-top: 0;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-022b__block--description p {
				}

				.b-022b__actions {
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022b__actions .button-simple--readmore {

						@include breakpoint($breakpoint_desktop) {
							//font-size: 1em;
							//line-height: 1.3;
						}
					}
