//
// Styles - Node Type Show
//



.node-type-show {
}






//
// Page About show
//




.page-node-o-poradu {

	// title
	h1.show-type {
		//width: calc(100% - 2.75em); // kvuli soc. buttonu na mobilu

		margin: 1em 0;
		//margin-bottom: 0.5em;

		line-height: 1.8rem;

		color: $color_text_accent;

	 	@include breakpoint($breakpoint_desktop) {
			width: 66.66667%; // viz .l-grid--8-1

			padding-right: 25px; // viz .l-grid--8-1

			line-height: 3rem;
		}

		&.article-type--image {
		 	@include breakpoint($breakpoint_desktop) {
				width: 100%;
			}
		}
	}



	// content wrapper - _node.scss
	.inner-content-wrapper {
	}

		// grid
		.l-grid--8-1 {
		}

			// modified, show, top social buttons
			.content-1-1 {
			}

		   // primary image/audio
			.content-1-2 {

				.file-audio {
					margin-left: 0;
				}
			}

			// vertical soc. buttons, perex, body
			.content-1-3 {
			}

			// main audio anchor, authors, bottom soc. buttons
			.content-1-4 {
			}

				// fotka autora - fallback
				.view-display-id-node_author_main.box {
					display: none; // opusteno
				}
				// jmeno autora - fallback
				.view-display-id-node_author_main_2.box {
					display: none; // opusteno
				}



				// expired audio
				.article-type--audio .audio-rights-expired {

					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}



		// grid - aside
		.l-grid--4-9 {
			@include breakpoint($breakpoint_desktop) {
				margin-top: 8em;
			}
		}

			// content boxes, ad
			.sidebar-1-1 {
			}

			// content boxes
			.sidebar-1-2 {
			}



	.field-perex {
		margin-top: 2em;
	}

	.pane-title {
		margin: 0 0 0 70px;
		padding-left: 50px;
	}

	.field-historie {
		font-size: $base-font-size;
		line-height: 1.6em;
		color: $color_text_paragraph;
		margin-left: 70px;
		padding-left: 30px;

		@include breakpoint($breakpoint_desktop) {
			font-size: 20px;
		}
	}
}

//starter
.page-node-5183274.node-type-show {
  .body {
    @include breakpoint($breakpoint_desktop) {
      margin: 0;
      padding: 0;
    }
  }

  .l-grid--8-1 .button-box-flat--readmore {
    width: 48%;
  }
}
