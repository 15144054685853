//
// Radio Prague Int. - obecne vlastnosti vsech stranek
//



$radiopraha_selector_body: 'body.radiopraha';
$radiopraha_selector_body-hp: $radiopraha_selector_body + '.page-radiopraha-hp'; // hp

$radiopraha_selector_body-hledej: $radiopraha_selector_body + '.page-hledej'; // hledej
$radiopraha_selector_body-search: $radiopraha_selector_body + '.page-search'; // search
$radiopraha_selector_body-suchen: $radiopraha_selector_body + '.page-suchen'; // suchen
$radiopraha_selector_body-busqueda: $radiopraha_selector_body + '.page-busqueda'; // busqueda
$radiopraha_selector_body-rechercher: $radiopraha_selector_body + '.page-rechercher'; // rechercher
$radiopraha_selector_body-poisk: $radiopraha_selector_body + '.page-poisk'; // poisk


#{$radiopraha_selector_body} {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_radiopraha_main, $color_radiopraha_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-institution,
	&.page-node.node-type-profil,
	&.page-node.node-type-serial,
	&.page-node.node-type-serialpart,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_radiopraha_main);
	}

	// box
	.box {

		// titulek
		h2 {
			color: $color_background-6;

			a {
				@include link-style ($color_background-6, none, $color_background-6, underline);
			}
		}
	}

	// stanicni lista
	.b-015 {

		.b-015__list-item {}

		.b-015__list-item--left {}

		.b-015__list-item--right {

			.b-015__launcher-wrapper {

				.b-015__launcher {

					.b-015__launcher-info {

						.b-015__launcher-info-list {

							@include breakpoint($breakpoint_desktop) {
								align-items: flex-end;
							}

							.b-015__launcher-info-title {}

							.b-015__launcher-info-timing {

								.b-015__launcher-info-timing-timespan {
									margin-right: 0;
								}
							}
						}
					}

					.b-015__launcher-button {
						padding-top: 17.5px;
					}
				}
			}
		}
	}

	// box Newsletter subscribe
	// opusteno, nastyluje Radek v ramci default formu
	/*footer {

		.b-newsletter-subscribe {
			margin: 5em 0 -3em 0;

			@include breakpoint($breakpoint_desktop) {
				margin: 5em 40px -5em 40px;
			}
		}
	}

	.b-newsletter-subscribe {

		form {
			padding: 10px;

			// wrapper
			> div {
				display: flex;
				flex-direction: column;

				@include breakpoint($breakpoint_desktop) {
					flex-direction: row;
					flex-wrap: nowrap;
				}

				// email
				.form-item-email {
					display: flex;
					flex-direction: column;
					justify-content: center;

					@include breakpoint($breakpoint_desktop) {
						margin-right: 1em;
						margin-bottom: 0;
					}

					label {
						display: none;
					}

					input {
						width: 100%;

						@include breakpoint($breakpoint_desktop) {
							max-width: 350px;
						}
					}
				}

				// submit
				.form-actions.form-wrapper {
					display: flex;
					flex-direction: column;

					margin-top: 0;

					@include breakpoint($breakpoint_desktop) {
						flex-direction: row;
						flex-wrap: nowrap;
						flex-grow: 1;
					}

					input {
						flex-basis: 100%;
						align-self: initial;

						margin-right: 0;

						@include breakpoint($breakpoint_desktop) {
							order: 2;
							flex-basis: 0;

							padding: 5px 15px;
							height: 34px;
						}
					}

					p {
						flex-basis: 100%;
						align-self: initial;

						margin-top: 1em;

						@include breakpoint($breakpoint_desktop) {
							order: 1;
							align-self: center;
							margin-top: 0;
							margin-right: 1em;
						}
					}
				}
			}
		}
	}*/

}
