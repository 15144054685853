//
// B-006 Styles
//



.b-006 {
	
}

	.b-006__subsection {
		position: relative;

		background-color: $color_background-9;
	}

		.b-006__block {
			// neexituje vlastnost obecneho bloku
			// 1. blok je obsah (video a text)
			// 2. blok je wrapper tlacitek
		}

			// list a list-item pridan kvuli karuselu
			.b-006__list {
				display: flex;
				flex-direction: row;

				overflow: hidden;
			}

				.b-006__list-item {
					position: relative;

					min-width: 100%;
				}

					.b-006__block--content {
						display: flex;
						flex-direction: column;

						padding-bottom: 4.5em;

						overflow: auto;

						@include breakpoint($breakpoint_desktop) {
							flex-direction: row;
							align-items: center;

							padding: 4.5em 8.5em;
						}
					}

						.b-006__subblock--player {

							@include breakpoint($breakpoint_desktop) {
								flex-grow: 1;
							}
						}

						.b-006__subblock--description {

							@include breakpoint($breakpoint_desktop) {
								flex-basis: 32%;
								flex-shrink: 1;

								display: flex;
								flex-direction: column;
								justify-content: center;

								margin-left: 2em;
							}
						}

							// TODO z duvodu atomu zatim ne pres specifickou tridu
							.b-006__subblock--description h3 {
								color: $color_text_paragraph_inverted;

								margin-top: 1.5em;
								margin-bottom: 1em;

								padding-left: 1em;
								padding-right: 1em;

								//color: $color_text_accent_inverted;
								// presunuto na vnorene <a>

								text-align: center;

								@include breakpoint($breakpoint_desktop) {
									font-size: 26px;
									line-height: 1.25em;

									padding-left: 0.5em;
									margin-top: 0;
									margin-bottom: 0;
									text-align: initial;
								}
							}

								// TODO z duvodu atomu zatim ne pres specifickou tridu
								.b-006__subblock--description h3 a {
									@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, none);
								}


							// TODO z duvodu atomu zatim ne pres specifickou tridu
							.b-006__subblock--description a {
								@include link-style($color_text_paragraph_inverted, none, $color_text_paragraph_inverted, none);
							}

							// TODO z duvodu atomu zatim ne pres specifickou tridu
							.b-006__subblock--description p,
							.b-006__subblock--description a p {
								margin: 0;
								padding-left: 1em;
								padding-right: 1em;

								//color: $color_text_paragraph_inverted;
								// presunuto na vnorene <a>

								text-align: center;

								@include breakpoint($breakpoint_desktop) {
									margin-top: 0.25em;
									text-align: initial;
								}
							}

							// muze byt i mimo <a>
							.b-006__subblock--description p {
								color: $color_text_paragraph_inverted;
							}


					.b-006__block--slider-buttons {
						position: absolute;
						bottom: -1.5em;
						width: 100%;

						display: flex;
						flex-direction: row;
						justify-content: center;

						@include breakpoint($breakpoint_desktop) {
							// mobile reset v nic
							height: 100%;
							bottom: initial;

							top: 0;
							left: 0;

							pointer-events: none;

							align-items: center;
							justify-content: space-between;
						}
					}

						// oba buttony
						// TODO z duvodu atomu zatim ne pres specifickou tridu
						.b-006__block--slider-buttons .button-box-flat {
							// obnoveni neklikatelnosti rodice
							pointer-events: auto;
						}

						// TODO z duvodu atomu zatim ne pres specifickou tridu
						.b-006__block--slider-buttons .button-box-flat--slider-left {
							margin-right: 1em;

							@include breakpoint($breakpoint_desktop) {
								margin: 0;
								margin-left: -0.5em;
							}
						}

						// TODO z duvodu atomu zatim ne pres specifickou tridu
						.b-006__block--slider-buttons .button-box-flat--slider-right {

							@include breakpoint($breakpoint_desktop) {
								margin-right: -0.5em;
							}
						}