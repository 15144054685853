//
// Pseudo Exposed Filter - Colored
// filtry pro SOCR - prizpusobeni Drupal markupu
//


// colors
$color-filters-selected: #9C9FAB;
$color-rudolfinum: #0090B4;
$color-premium: #B32844;
$color-horizonts: #F6CE3C;
$color-classical: #333378;

// settings
$transition-speed: .125s;



.socr {}
	.socr .view-filters {}
		.socr .view-filters .views-exposed-widgets {}
			.socr .view-filters .views-exposed-widgets .views-widget{}
				.socr .view-filters .views-exposed-widgets .views-widget .bef-select-as-radios {
					display: flex;
					flex-wrap: wrap;
					flex-basis: 100%;

					margin-bottom: 1.5em;

					@include breakpoint($breakpoint_desktop) {
						margin-top: 1em;
						margin-bottom: 2.5em;
					}
				}

					// item
					.socr .view-filters .views-exposed-widgets .views-widget .bef-select-as-radios .form-item {
						display: flex;
						flex-basis: 100%;

						@include breakpoint($breakpoint_desktop) {
							flex-basis: auto;
						}
					}

						// skryti inputu
						.socr .view-filters .views-exposed-widgets .views-widget .bef-select-as-radios .form-item input {
							display: none;
						}

						// label: defaultni stav - nezaskrtnuty checkbox
						.socr .view-filters .views-exposed-widgets .views-widget .bef-select-as-radios .form-item label {
							font-size: $fontsize_paragraph;
							text-transform: inherit;

							background-color: $color_corporate_main;
							color: $color_background-0;

							padding: 2px 10px;
							margin-bottom: 1px;

							width: 100%;

							transition: background-color $transition-speed;

							cursor: pointer;

							@include no-select;

							@include breakpoint($breakpoint_desktop) {
								padding: 5px 12px;
								margin-right: 10px;
								margin-bottom: 10px;
							}
						}

						// lebel: zaskrtnuty checkbox
						.socr .view-filters .views-exposed-widgets .views-widget .bef-select-as-radios .form-item input[type=radio]:checked + label {
							color: $color_background-0;
							background-color: $color-filters-selected;
							text-decoration: underline;
						}

						// :hover
						.socr .view-filters .views-exposed-widgets .views-widget .bef-select-as-radios .form-item input + label:hover {
							@include breakpoint($breakpoint_desktop) {
								text-decoration: underline;
							}
						}

						// barvy
						.socr .view-filters .views-exposed-widgets .views-widget .bef-select-as-radios .form-item label[for='edit-term-node-tid-depth-all'] {
							background-color: $color_corporate_main;
						}
						.socr .view-filters .views-exposed-widgets .views-widget .bef-select-as-radios .form-item label[for='edit-term-node-tid-depth-7644452'] {
							background-color: $color-rudolfinum;
						}
						.socr .view-filters .views-exposed-widgets .views-widget .bef-select-as-radios .form-item label[for='edit-term-node-tid-depth-7644453'] {
							background-color: $color-premium;
						}
						.socr .view-filters .views-exposed-widgets .views-widget .bef-select-as-radios .form-item label[for='edit-term-node-tid-depth-7644454'] {
							background-color: $color-horizonts;
						}
						.socr .view-filters .views-exposed-widgets .views-widget .bef-select-as-radios .form-item label[for='edit-term-node-tid-depth-7644456'] {
							background-color: $color-classical;
						}
