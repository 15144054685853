//
// B-001c Styles
//



.b-001c {
}

	.b-001c__block--stations {
		padding: 1em 1em 0 1em;

		@include breakpoint($breakpoint_desktop) {
			padding: 0;
		}
	}

		// list: celoplosne stanice
		.b-001c__list-item {}

		// list: regionalni stanice
		.b-001c__list-regional {}

			.b-001c__list-item {
				display: flex;
				margin-bottom: 10px;
			}

				.b-001c__image {
					display: none;

					@include breakpoint($breakpoint_desktop) {
						flex-shrink: 0;

						display: inline-block;

						width: 100px;
						max-height: 67px;

						margin-right: 5px;

						border-radius: 3px;
						overflow-y: hidden;
					}
				}

				// metadata: top, mid, bottom
				.b-001c__metadata {
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					width: 100%;

					@include breakpoint($breakpoint_desktop) {
						width: calc(100% - 105px); // b-001c__image je 100px + 5px margin-right obrazku uvnitr
					}
				}

					// metadata-top: logo, play
					.b-001c__metadata-top {
						display: flex;
						flex-shrink: 0;
						justify-content: space-between;
						align-items: center;
					}

						// logo
						.b-001c__banner {
							background-color: $color_corporate_main;
							padding: 6px;
							@include no-select;
						}

						.b-001c__banner:after {
							display: block;
							content: "";
							height: 16px;
							background-repeat: no-repeat;
						}

							.b-001c__banner span {
								display: none;
							}


						// logo: celoplosne stanice
						.b-001c__list-item--radiozurnal .b-001c__banner:after {
							background-image: $file_logo_radiozurnal_colored-white;
							width: 107px;
						}

						.b-001c__list-item--dvojka .b-001c__banner {
							padding-bottom: 4px; // prepozicovani kvuli j v logu
						}

						.b-001c__list-item--dvojka .b-001c__banner:after {
							background-image: $file_logo_dvojka_colored-white;
							width: 67px;
							height: 18px; // zvetseni kvuli j v logu
						}

						.b-001c__list-item--vltava .b-001c__banner:after {
							background-image: $file_logo_vltava_colored-white;
							width: 63px;
						}

						.b-001c__list-item--plus .b-001c__banner:after {
							background-image: $file_logo_plus_colored-white;
							width: 50px;
						}

						.b-001c__list-item--radiowave .b-001c__banner:after {
							background-image: $file_logo_wave_colored-white;
							width: 106px;
						}

						.b-001c__list-item--d-dur .b-001c__banner:after {
							background-image: $file_logo_d-dur_colored-white;
							width: 60px;
						}

						.b-001c__list-item--jazz .b-001c__banner:after {
							background-image: $file_logo_jazz_colored-white;
							width: 52px;
						}

						.b-001c__list-item--radiojunior .b-001c__banner:after {
							background-image: $file_logo_junior_colored-white;
							width: 113px;
						}

						.b-001c__list-item--archiv .b-001c__banner:after {
							background-image: $file_logo_retro_colored-white;
							width: 108px;
						}

						.b-001c__list-item--sport .b-001c__banner {
							padding-bottom: 4px; // prepozicovani kvuli p v logu
						}

						.b-001c__list-item--sport .b-001c__banner:after {
							background-image: $file_logo_sport_colored-white;
							width: 58px;
							height: 18px; // zvetseni kvuli p v logu
						}

						.b-001c__list-item--radiozurnal-sport .b-001c__banner {
							padding-bottom: 4px; // prepozicovani kvuli p v logu
						}

						.b-001c__list-item--radiozurnal-sport .b-001c__banner:after {
							background-image: $file_logo_radiozurnalsport_colored-white;
							width: 151px;
							height: 18px; // zvetseni kvuli p v logu
						}

						// regionalni stanice
						.b-001c__list-item--brno .b-001c__banner:after {
							background-image: $file_logo_brno_colored-white;
							width: 53px;
						}

						.b-001c__list-item--cb .b-001c__banner {
							padding-bottom: 4px; // prepozicovani kvuli j v logu
						}

						.b-001c__list-item--cb .b-001c__banner:after {
							background-image: $file_logo_budejovice_colored-white;
							width: 150px;
							height: 18px; // zvetseni kvuli j v logu
						}

						.b-001c__list-item--hradec .b-001c__banner:after {
							background-image: $file_logo_hradec_colored-white;
							width: 134px;
						}

						.b-001c__list-item--kv .b-001c__banner {
							padding-bottom: 4px; // prepozicovani kvuli y v logu
						}

						.b-001c__list-item--kv .b-001c__banner:after {
							background-image: $file_logo_vary_colored-white;
							width: 110px;
							height: 18px; // zvetseni kvuli y v logu
						}

						.b-001c__list-item--liberec .b-001c__banner:after {
							background-image: $file_logo_liberec_colored-white;
							width: 74px;
						}

						.b-001c__list-item--olomouc .b-001c__banner:after {
							background-image: $file_logo_olomouc_colored-white;
							width: 86px;
						}

						.b-001c__list-item--ostrava .b-001c__banner:after {
							background-image: $file_logo_ostrava_colored-white;
							width: 76px;
						}

						.b-001c__list-item--pardubice .b-001c__banner:after {
							background-image: $file_logo_pardubice_colored-white;
							width: 94px;
						}

						.b-001c__list-item--plzen .b-001c__banner:after {
							background-image: $file_logo_plzen_colored-white;
							width: 58px;
						}

						/*.b-001c__list-item--regina .b-001c__banner {
							padding-bottom: 4px; // prepozicovani kvuli g v logu
						}

						.b-001c__list-item--regina .b-001c__banner:after {
							background-image: $file_logo_regina_colored-white;
							width: 152px;
							height: 18px; // zvetseni kvuli g v logu
						}*/

						.b-001c__list-item--regina .b-001c__banner:after { // nove Radio DAB
							background-image: $file_logo_dab_colored-white;
							width: 147px;
						}

						.b-001c__list-item--strednicechy .b-001c__banner {
							padding-bottom: 4px; // prepozicovani kvuli y v logu
						}

						.b-001c__list-item--strednicechy .b-001c__banner:after {
							background-image: $file_logo_region_colored-white;
							width: 68px;
							height: 18px; // zvetseni kvuli y v logu
						}

						.b-001c__list-item--sever .b-001c__banner:after {
							background-image: $file_logo_sever_colored-white;
							width: 61px;
						}

						.b-001c__list-item--vysocina .b-001c__banner {
							padding-bottom: 4px; // prepozicovani kvuli y v logu
						}

						.b-001c__list-item--vysocina .b-001c__banner:after {
							background-image: $file_logo_vysocina_colored-white;
							width: 82px;
							height: 18px; // zvetseni kvuli y v logu
						}

						.b-001c__list-item--zlin .b-001c__banner:after {
							background-image: $file_logo_zlin_colored-white;
							width: 45px;
						}

						.b-001c__list-item--cro7 .b-001c__banner {
							padding-bottom: 4px; // prepozicovani kvuli g v logu
						}
						.b-001c__list-item--cro7 .b-001c__banner:after {
							background-image: $file_logo_radiopraha_colored-white;
							width: 144px;
							height: 18px; // zvetseni kvuli g v logu
						}

						// play
						.b-001c__play {
							display: block;

							background-image: $file_button_action_play;
							background-repeat: no-repeat;
							background-position: 0 0;
							background-size: 64px 32px;

							width: 32px;
							height: 32px;

							z-index: 1;

							@include breakpoint($breakpoint_desktop) {
								background-size: 48px 24px;

								width: 24px;
								height: 24px;

								padding: 0;
							}
						}

						.b-001c__play:hover {
							background-position: 100%;
						}


					// metadata-middle: cas, porad, delka
					.b-001c__metadata-mid {
						font-size: .75rem;

						margin-top: 5px;

						color: $color_background-6;
						@include link-style($color_background-6, none, $color_background-6, none);
					}

						// cas
						.b-001c__time {
							display: inline;
							margin-right: 5px;
							color: $color_background-5;
						}

							// wrappper poradu
							.b-001c__wrap {
								display: inline-flex;
								max-width: calc(100% - 110px);
							}

								// porad
								.b-001c__show-name {
									@include typography_font(medium);
									white-space: nowrap;
									text-overflow: ellipsis;
									overflow: hidden;
								}

								// delka
								.b-001c__duration {
									display: inline;
									margin-left: 5px;
									@include typography_font(medium);
								}


					// metadata-bottom: progress bar
					.b-001c__metadata-bottom {
						margin-top: 4px;
					}

						// progress bar
						.progress-bar {}

							.progress-bar__duration {
								background-color: $color_background-3;
								height: 6px;
							}

								.progress-bar__position {
									background-color: $color_corporate_main;
									height: 6px;
								}


		// block-buttons: prepinani stanic (celoplosne vs regionalni), dalsi odkazy (stanice, archiv, podcasty)
		.b-001c__block--buttons {
			padding: 0 1em;
			padding-bottom: 1em;

			@include breakpoint($breakpoint_desktop) {
				padding: 0;
			}
		}

			// wrapper prepinace stanic
			.b-001c__toggler-wrap {
				position: relative;

				width: 100%;

				margin-bottom: 20px;
				padding: 0;

				background-color: transparent;
			}

			.b-001c__toggler-wrap:after {
				content: '';
				display: inline-block;

				position: absolute;
				right: 0;
				top: calc(50% - 6px);

				width: 16px;
				height: 16px;

				background-image: $file_icon_arrow_single_east_colored-decoration-main;
				background-repeat: no-repeat;
				background-position: 0 0;
				background-size: 10px 13px;

			}

			.b-001c__toggler-wrap:focus {
				outline:0;
			}

				.b-001c__toggler {
					display: inline-flex;
					jusify-content: flex-start;

					padding: 7px 6px 4px 6px;

					color: $color_background-0;
					background-color: $color_region_main;

					@include typography_font(medium);
					font-size: 16px;

					float: left;

					transition: background-color 180ms ease;
				}

				.b-001c__toggler:hover {
					background-color: darken($color_region_main, 5%);
				}

				.b-001c__toggler--global {
					background-color: $color_corporate_main;
				}

				.b-001c__toggler--global:hover {
					background-color: lighten($color_corporate_main, 12%);
				}

				.b-001c__toggler-wrap:hover .b-001c__toggler {
					background-color: darken($color_region_main, 5%);
				}

				.b-001c__toggler-wrap:hover .b-001c__toggler--global {
					background-color: lighten($color_corporate_main, 12%);
				}


			// tlacitka s odkazy
			.b-001c__link {
				display: flex;
				border: 1px solid $color_divider;
				@include breakpoint($breakpoint_desktop) {
					border: none;
				}
			}

				.b-001c__link-item {
					flex-grow: 1;

					position: relative;

					background-color: $color_background-0;

					@include link-style($color_decoration_main, none, $color_decoration_main, underline);

					margin: 0;
					padding: 10px;

					font-size: .75rem;
					text-align: center;
					white-space: nowrap;

					transition: background-color 180ms ease;

					@include breakpoint($breakpoint_desktop) {
						background-color: $color_background-2;
						font-size: .9rem;
					}
				}

				.b-001c__link-item:hover {
					text-decoration: underline;
					color: $color_decoration_main;
					background-color: $color_background-1;
				}

				.b-001c__link-item:before {
					content: '';
					display: block;

					position: absolute;
					top: 14px;
					right: 0;

					width: 1px;
					height: 16px;

					background-color: $color_background-4;
				}

				.b-001c__link-item:after {
					content: '';
					background-image: $file_icon_arrow_double_east_colored-decoration-main;
					background-repeat: no-repeat;
					background-position: 6px 3px;
					background-size: 12px 9px;
					width: 18px;
					height: 12px;
					display: inline-block;
				}

				.b-001c__link-item:last-child:before {
					content: '';
					width: 0;
				}





// v mobilnim wrapperu, tj jako bublina hlavni navigace
.b-001c__placeholder--mobile {
	order: 2; // poradi viz *_bg.scss

	.b-001c { background-color: $color_background-2; } // pozadi bubliny

	.b-001c.box { margin-top: 0; } // reset default box margin-top

		.b-001c h2 { display: none; } // v bubline h2 nechceme

		.b-001c__block--stations {}

			// list: celoplosne stanice
			.b-001c__list-item {}

			// list: regionalni stanice
			.b-001c__list-regional {}

				// metadata: top, mid, bottom
				.b-001c__metadata {}

					// metadata-bottom: progress bar
						.b-001c__metadata-bottom {}

							// progress bar
							.progress-bar {}

								.progress-bar__duration {
									background-color: $color_background-4;
								}

}





// temp
.b-001c {
	.b-001c__list-item--webik {
		display: none;
	}
}





// vizualni debug - plab only
.patternlab .b-001c {

	@include breakpoint($breakpoint_desktop) {
		// vizualni debug
		// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec (v desktop)
		max-width: 400px;
	}
}
