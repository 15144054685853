//
// b-080 Styles
//



$b-080_image-width-basis: 14em;



.b-080 {
}

	.b-080__list {
	}

		.b-080__list-item {
			display: flex;
			flex-direction: column;
			border-bottom: 1px solid $color_divider;
			padding-bottom: 0.5em;
			margin-bottom: 2em;

			@include breakpoint($breakpoint_desktop) {
				border-bottom: none;
				flex-direction: row;
				margin-bottom: 2em;
			}
		}

			.b-080__block {
			}

			.b-080__block--image {

				@include breakpoint($breakpoint_desktop) {
					flex-basis: $b-080_image-width-basis;
					flex-shrink: 0;
				}
			}

				.b-080__image {
					position: relative;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-080__image .tag {
						position: absolute;
						bottom: 0;
						left: 10px;

						max-inline-size: calc(100% - 20px);
					}


			.b-080__block--description {
				flex-grow: 1;
				margin-top: 1em;

				@include breakpoint($breakpoint_desktop) {
					padding-left: 1em;
				}
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-080__block--description h3 {
					margin-bottom: 0.5em;
					margin-top: 0.5em;

					@include breakpoint($breakpoint_desktop) {
						margin-top: 0;
					}
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-080__block--description p {
					margin-top: 0;
					margin-bottom: 0.5em;
					color: $color_text_paragraph;
				}

				.b-080__block--description .b-080__description-row {
					.field-authors {
						display: inline-block;
						li {
							color: $color_text_paragraph;
							a {
								color: $color_text_paragraph;
							}
							display: inline-block;
						}
					}

					.field-date {
						color: $color_text_paragraph;
						padding-right: 0.3em;
					}
					
				}

				.b-080__block--description .tag {
					margin-bottom: 0.5em;
				}

				.b-080__actions {
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-080__actions .button-listaction {
						margin-right: 0.25em;
					}