//
// Styles - Node Type Serial
//



.node-type-serial,
.node-type-serialpart {

	// grid
	.l-grid--12-1.item-first {
	}
	// clearfix
	.l-grid--12-1.item-first:after {
		clear: both;
		content: "";
		display: table;
	}

		// title
		.l-grid--12-1.item-first h1 { // proc nema tridu jako u article?!
			//width: calc(100% - 2.75em);

			margin: 1em 0;
			margin-bottom: 0.5em;

			line-height: 1.8rem;

			color: $color_text_accent;

			@include breakpoint($breakpoint_desktop) {
				width: calc(66.66667% - 4.375rem);

				padding-left: 1.875rem;
				padding-right: 25px;

				line-height: 3rem;

				float: left;
			}
		}



	// content wrapper - _node.scss
	.inner-content-wrapper {
	}



		// grid
		.l-grid--8-1 {
		}

			// modified, show, top social buttons
			.content-1-0 {
				@include breakpoint($breakpoint_desktop) {
					margin-left: 4.375rem;
					padding-left: 1.875em;
				}
			}

			// vertical soc. buttons, primary image/audio, serial pager, serial link, perex, body
			.content-1-1 {

			}

			// main audio anchor
			.content-1-2 {
			}

			// authors, bottom soc. buttons
			.content-1-3 {
			}


			// ...
			.content-1-4 {
			}



		// grid - aside
		.l-grid--4-9 {
			@include breakpoint($breakpoint_desktop) {
				margin-top: 8em;
			}
		}

			// content boxes, ad
			.sidebar-1-1 {
			}

			// content boxes
			.sidebar-1-2 {
			}



	.field-entity-serial {
		margin-top: 0.5em;
		color: $node_color_text_medium;

		a {
			color: $node_color_text_medium;
			display: inline-block;
		}

		@include breakpoint($breakpoint_desktop) {
			//font-size: 1.5rem;
			//position: absolute;
			//left: $node_left-margin+$node_padding;
			//top: 1.6rem;

			width: calc(66.66667% - 4.375rem);

			padding-left: 1.875rem;
			padding-right: 25px;
			margin-top: 1em;
			margin-bottom: -1.5em;

			font-size: 1.5rem;
			line-height: 1.3;

			float: left;
		}
	}

	.serial-link {
		margin-top: 1em;

		@include breakpoint($breakpoint_desktop) {
			padding: 0 $node_padding;
			margin-top: 1.8em;
			margin-left: $node_left-margin;
		}

		h2 {
			color: $color_text_paragraph;
			display: inline;

			@include typography_font(regular);
			font-size: 1rem;
			line-height: 1.3;

			margin: 0;
			padding: 0;
		}

		.block__content {
			display: inline;
			overflow: hidden;
		}

	 .field-entity-serial {
			display: inline;

			a {
				color: $color_decoration_main;
				display: inline;
			}

			@include breakpoint($breakpoint_desktop) {
				//display: inline;
				//font-size: 1rem;
				//position: relative;
				//top: 0;
				//left: 0;

				display: inline;
				width: auto;

				padding-left: 0;
				padding-right: 0;
				margin-top: 0;
				margin-bottom: 0;

				font-size: 1rem;
				line-height: 1.3;

				float: none;
			}
		}
	}

	.img-metadata,
	.node-image-metadata {
		@include breakpoint($breakpoint_desktop) {
			margin-left: $node_left-margin;
		}
	}

	.field.body .img-metadata,
	.field.body .node-image-metadata {
		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
		}
	}

	.b-027 {
		margin-top: 0;
		@include breakpoint($breakpoint_desktop) {
			margin-left: $node_left-margin;
			padding-left: 1.875em;
		}
	}

	.field-perex {
		margin-top: 2em;
	}

	.field-image-primary {
		@include breakpoint($breakpoint_desktop) {
			padding-left: $node_left-margin;
		}
	}



	.tabs {
		@include breakpoint($breakpoint_desktop) {
			margin-left: $node_left-margin;
		}
	}

	.l-grid--8-1 .content-1-2 .box {
		@include breakpoint($breakpoint_desktop) {
			margin-left: $node_left-margin;
		}

		h2 {
			font-size: 1.7rem;
			margin-bottom: .6rem;
		}
	}

	.box.a-004b,
	.box.a-004p {
		@include breakpoint($breakpoint_desktop) {
			margin-left: $node_left-margin;
		}

		.a-004b--list-item--empty {
			display: none;
		}
	}


	// view a-004p
	.a-004p .a-004b__iterator {
		margin-top: .4em;
	}

	.a-004p .a-004b__link--expired,
	.a-004p .a-004b__link--future {
		//background: $color_background-2;
		background: none;
		padding: 0;
		min-height: 3.5em;

		.a-004b__iterator {
			margin: 0;
			position: absolute;
			left: 1em;
			top: 1em;
		}

		.audio-rights-expired,
		.audio-rights-future {
			background: none;
			margin: 0 0 0 2em;
			padding: 0 0 0 1em;
			width: calc(100% - 2em);

			@include breakpoint($breakpoint_desktop) {
				width: 93.5%;
			}
		}
	}

	.a-004p .a-004b__link--future,
	.a-004p .a-004b__link--background {
		background: $color_background-2;
	}
}



// serial part
.node-type-serialpart {

	// grid
	.l-grid--12-1.item-first {
		position: relative; // kvuli pozicovani .field-entity-serial
	}

		// title
		.l-grid--12-1.item-first h1 {

			@include breakpoint($breakpoint_desktop) {
				margin-top: 1.2em;
			}
		}



	// content wrapper - _node.scss
	.inner-content-wrapper {
	}



		// grid
		.l-grid--8-1 {
		}

			// modified, show, top social buttons
			.content-1-0 {
			}

			// vertical soc. buttons, primary image/audio, serial pager, serial link, perex, body
			.content-1-1 {
			}

				// serial pager
				.box.a-004e {
					@include breakpoint($breakpoint_desktop) {
						margin-top: 2rem;
						margin-left: $node_left-margin;
						padding: 0 $node_padding;
					}

					.a-004e {
						width: 100%;

						.button_series__empty {
							background-color: $color_background-4;
							color: white;

							&:hover {
								text-decoration: none;
							}
						}
					}
				}

				// ?
				.node_attachment a {
					margin-left: 0;
		}

}
