//
// Styles - Node Type Event
//



.node-type-event {

	// grid
	.l-grid--12-1.item-first {
		margin-bottom: -20px; // hack pro variantu s nebo bez h1

		@include breakpoint($breakpoint_desktop) {
			margin-bottom: -29px; // hack pro variantu s nebo bez h1
		}
	}
	// clearfix
	.l-grid--12-1.item-first:after {
		clear: both;
		content: "";
		display: table;
	}

		// title
		.l-grid--12-1.item-first h1 { // proc nema tridu jako u article?!
			//width: calc(100% - 2.75em);

			margin: 1em 0;
			margin-bottom: 0.5em;
			margin-bottom: 0px; // hack pro variantu s nebo bez h1

			line-height: 1.8rem;

			color: $color_text_accent;

			@include breakpoint($breakpoint_desktop) {
				width: calc(66.66667% - 4.375rem);

				margin-bottom: 0.5em; // hack pro variantu s nebo bez h1
				padding-left: 1.875rem;
				padding-right: 25px;

				line-height: 3rem;

				float: left;
			}
		}



	// content wrapper - _node.scss
	.inner-content-wrapper {
		margin-top: 32px; // hack pro variantu s nebo bez h1

		@include breakpoint($breakpoint_desktop) {
			margin-top: 48px; // hack pro variantu s nebo bez h1
		}
	}



		// grid
		.l-grid--8-1 {
		}

			// modified, show, top social buttons
			.content-1-0 {
			}

			// vertical soc. buttons, primary image/audio, serial pager, serial link, perex, body
			.content-1-1 {

			}

			// main audio anchor
			.content-1-2 {
			}

			// authors, bottom soc. buttons
			.content-1-3 {
			}


			// ...
			.content-1-4 {
			}



		// grid - aside
		.l-grid--4-9 {
			@include breakpoint($breakpoint_desktop) {
				margin-top: 8em;
			}
		}

			// content boxes, ad
			.sidebar-1-1 {
			}

			// content boxes
			.sidebar-1-2 {
			}



	// triangle
	.node-trianle {
		@include breakpoint($breakpoint_desktop) {
			//margin-top: 0;
		}
	}

	// metadata box
	.event-metadata {
		display: block; // table

		margin: 1.2em -10px; // -10px je odecteni .main paddingu
		padding: 0.5em 10px; // v px protozoze ma byt stejne jako .main padding

		border: 1px solid $color_background-4;
		border-left: none;
		border-right: none;

		font-size: 14px;

		@include breakpoint($breakpoint_desktop) {
			margin: 1.7em 0;
			margin-left: $node_left-margin;
			padding: 0.75em $node_padding;

			background-color: $color_background-1;
			border: 1px solid $color_background-4;

			font-size: 16px;
		}

		// organizer
		.event-metadata__organizer {
			.event-metadata__organizer--label {}
			.event-metadata__organizer--title {}
		}

		// date
		.event-metadata__date {
			.event-metadata__date--label {}
			.event-metadata__date--date {}
		}

		// location
		.event-metadata__location {
			.event-metadata__location--label {}
			.event-metadata__location--location {}
		}

		// tr
		// organizer, date, location
		.event-metadata__organizer,
		.event-metadata__date,
		.event-metadata__place {
		}

		// td labels
		// organizer, date, location
		.event-metadata__organizer .event-metadata__organizer--label,
		.event-metadata__date .event-metadata__date--label,
		.event-metadata__location .event-metadata__location--label {
			padding-right: 1em;
			text-align: right;
			vertical-align: top;

			@include breakpoint($breakpoint_desktop) {
				padding-right: 1.5em;
			}
		}

		// td values
		// organizer, date, location
		.event-metadata__organizer .event-metadata__organizer--title,
		.event-metadata__date .event-metadata__date--date,
		.event-metadata__location .event-metadata__location--location {
			color: $color_text_paragraph;
		}

		// web
		.event-metadata__organizer .event-metadata__organizer--web {
			display: inline-block;

			a {
				@include link-style($color_decoration_main, none, $color_decoration_main, none);
			}
		}

		// spacer
		.event-metadata__organizer .event-metadata__organizer--spacer {
			margin: 0 0.5em;
			color: $color_text_paragraph;
		}

		// od - do
		.event-metadata__date--from,
		.event-metadata__date--to {
			//padding: 0 0.5em;
		}
	}

	.field.body .img-metadata,
	.field.body .node-image-metadata {
		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
		}
	}

	.img-metadata,
	.node-image-metadata {
		@include breakpoint($breakpoint_desktop) {
			margin-left: $node_left-margin;
		}
	}

	.field-entity-organizer,
	.field-event-start,
	.field-odkaz {
		@include breakpoint($breakpoint_desktop) {
			margin: 0.2rem 0 0 $node_left-margin;
			padding: 0 $node_padding;
		}
	}

	.field-entity-organizer,
	.field.body {
		//@include typography_font(medium);
		//font-size: 1em;
		//line-height: 1.6em;
		//color: $color_text_accent;

		@include breakpoint($breakpoint_desktop) {
			//font-size: 1.250rem;
		}
	}

	.field-entity-organizer {
		@include typography_font(medium);
		font-size: 1em;
		line-height: 1.6em;
		color: $color_text_accent;

		@include breakpoint($breakpoint_desktop) {
			font-size: 1.250rem;
		}
	}

	.field-event-start {
		color: $color_text_paragraph;
	}

	.field-odkaz {
		a {
			@include link-style($color_decoration_main, none, $color_decoration_main, underline);
		}
		font-size: .9em;
	}

	.b-027 {
		margin-top: 0;
		@include breakpoint($breakpoint_desktop) {
			margin-left: $node_left-margin;
			padding-left: 1.875em;
		}
	}

	.field-perex {
		margin-top: 2em;
	}

	.field-image-primary {
		@include breakpoint($breakpoint_desktop) {
			padding-left: $node_left-margin;
		}
	}

	.field-image-primary.view-type-image {
		clear: both;
		padding-top: 1.75rem;

		@include breakpoint($breakpoint_desktop) {
			padding-left: 0;
		}
	}

	.field-entity-show-wrapper {
		display: inline-block;
	}

	.field-term-event {
		margin: 0 0 .5em 0;
		display: inline-block;
		//color: $color_decoration_main;
		color: $color_text_paragraph;

		a {
			@include link-style($color_decoration_main, none, $color_decoration_main, underline);
		}

		@include breakpoint($breakpoint_desktop) {
			display: inline-block;
			border-left: 1px solid #aaa;
			padding: 0 0 0 0.625em;
			height: 1em;
			line-height: 1em;
			margin: .5em 0;
		}
	}

	.tabs {
		@include breakpoint($breakpoint_desktop) {
			margin-left: $node_left-margin;
		}
	}

	.l-grid--8-1 .content-1-2 .box {
		margin-left: $node_left-margin;

		h2 {
			font-size: 1.7rem;
			margin-bottom: .6rem;
		}
	}
}
