//
// Styles - Node Pageflow
//

$pageflow_font_size_p: .9rem;
$pageflow_font_size_p_tablet: 1rem;
$pageflow_font_size_p_desktop: 1.2rem;


.node-type-pageflow {
}
	.node-type-pageflow .pf-container {
	}
		.node-type-pageflow .pf-container .pf-slide {
		}
			.node-type-pageflow .pf-container .pf-slide {
			}

			// prvni slide
			.node-type-pageflow .pf-container .pf-slide .pf-slide-start {
			}
				.node-type-pageflow .pf-container .pf-slide .pf-bg {
				}
					.node-type-pageflow .pf-container .pf-slide .pf-bg .pf-bg__image {
					}

					.node-type-pageflow .pf-container .pf-slide .pf-content {
					}

						// nadpis sekce
						.node-type-pageflow .pf-container .pf-slide .pf-content h1 {
						}

						// odstavec sekce
						.node-type-pageflow .pf-container .pf-slide .pf-content p {
							font-size: $pageflow_font_size_p;
							@include breakpoint($breakpoint_tablet) {
								font-size: $pageflow_font_size_p_tablet;
							}
							@include breakpoint($breakpoint_desktop) {
								font-size: $pageflow_font_size_p_desktop;
							}
						}
						// odstavec sekce - bold
						.node-type-pageflow .pf-container .pf-slide .pf-content p strong {
							@include typography_font(bold);
						}
						// odstavec sekce - hypertextovy odkaz
						.node-type-pageflow .pf-container .pf-slide .pf-content p a {
							color: $color_decoration_main;
							text-decoration: underline;
							&:hover {
								text-decoration: none;
							}
						}
