//
// B-015 Styles
//


$b-015_launcher_height: 75px; // puvodne 6em (pak 95px), ale nutno v px aby sass mohl delat vypocty s dalsimi promennymi v px
$b-015_launcher-width_desktop: 490px; // pro mobilni je responsive 100%

$b-015_logo-wrapper_height_mobile: 3em;
// vyska pruhu s logem pro desktop je definovana vyskou launcheru
// ale tomu chvilku trva nez se vubec zpritomni javasciptem, takze proto ne "auto"
$b-015_logo-wrapper_height_desktop: $b-015_launcher_height;

$b-015_launcher_fontsize-info-title_mobile: 16px;
$b-015_launcher_fontsize-info-title_desktop: 16px;
$b-015_launcher_fontsize-info-timing: 12px;
$b-015_launcher_fontsize-info-description: 12px;

$b-015_launcher_lineheight: 0.8;

$b-015_launcher_timing-progressbar-height_desktop: 0.35em;
$b-015_launcher_timing-progressbar-height_mobile: 0.5em;

$b-015_launcher_button-size_mobile: 58px;
$b-015_launcher_button-size_desktop: 60px;

$b-015_services_fontsize_mobile: 12px;
$b-015_services_fontsize_desktop: 12px;
$b-015_services_lineheight: 20px;



.b-015 {
	display: flex;
	flex-direction: column;

	margin-bottom: 8px; // preneseno z .b-016__wrapper

	// ciste debug default barva pozadi pro zobrazeni samostatneho atomu nebo navigace, pokud
	// nema rodicovsky obal, ktery jej kontextove obarvi tridou .bg-color-main
	background-color: $color_background-6;

	@include breakpoint($breakpoint_desktop) {
		flex-direction: row;
	}
}

	// obecne vlastnosti sloupce (v pripade mobile radku)
	.b-015__list-item {
		display: block;
	}



	// levy sloupec - misto pro logo
	.b-015__list-item--left {
		flex-grow: 0;

		height: $b-015_logo-wrapper_height_mobile;

		//overflow: hidden;

		@include breakpoint($breakpoint_desktop) {
			height: $b-015_logo-wrapper_height_desktop;
		}
	}

		.b-015__logo-wrapper {
			margin-top: 0.75em;
			margin-left: $layout_main_horizontal-padding_mobile;
			margin-right: $layout_main_horizontal-padding_mobile;

			@include breakpoint($breakpoint_desktop) {
				margin-top: 1.25em;
				margin-left: $layout_main_horizontal-padding_desktop;
				margin-right: 0;
			}
		}

			.b-015__logo {
				display: block;

				width: 100%;
				height: 1.35em;

				//background-color: rgba(255, 255, 255, 0.2); // vizualni debug velikosti obalu

				background-position: left center;
				background-repeat: no-repeat;
				// kdyby se tu nekdo objevil z duvodu diveni se proc nefunguje v IE
				// pozicovani svg loga doleva, tak je potreba pridat konkretni vlastnost svg
				// explorer roztahuje "papir" obrazku
				// preserveAspectRatio="xMinYMid"


				// fallback korporatniho loga, pokud neni ovlivneno strankou pres tridu na body specificky
				// sirku klikaciho elementu nutno kontrolovat tez specificky dle pouziteho loga

				// default nastaveni pro korporatni logo
				// toto predpokladam nikdy prakticky nenastane - jinak je treba resit vert. zarovnani
				background-image: $file_logo_corporate_colored-white;

				@include breakpoint($breakpoint_desktop) {
					width: 17em; // default pro korporatni logo
					height: 2em;
				}

				// specificke rozmery pro specificka loga
				// loga jsou definovana obecne (cro-bg-images-logos) protoze jsou znovupouzitelna
				// velikost v hlavicce je ale pro potreby hlavicky pouze, proto zde v stylu b015 primo
				.radiozurnal & {
					@include breakpoint($breakpoint_desktop) {
						width: 13.25em; // 215px
					}
				}
				.dvojka & {
					height: 1.55em;// kvuli 'j' je treba vertikalne posunout

					@include breakpoint($breakpoint_desktop) {
						width: 8.5em; // 132px
						height: 2.25em; // kvuli 'j' je treba vertikalne posunout
					}
				}
				.vltava & {
					@include breakpoint($breakpoint_desktop) {
						width: 8em; // 126px
					}
				}
				.wave & {
					@include breakpoint($breakpoint_desktop) {
						width: 13.25em; // 212px
					}
				}
				.d-dur & {
					@include breakpoint($breakpoint_desktop) {
						width: 7.5em; // 120px
					}
				}
				.jazz & {
					@include breakpoint($breakpoint_desktop) {
						width: 6.5em; // 104px
					}
				}
				.plus & {
					@include breakpoint($breakpoint_desktop) {
						width: 6.25em; // 100px
					}
				}
				.junior & {
					@include breakpoint($breakpoint_desktop) {
						width: 14.25em; // 227px
					}
				}
				.brno & {
					@include breakpoint($breakpoint_desktop) {
						width: 6.75em; // 107px
					}
				}
				.budejovice & {
					height: 1.55em;// kvuli 'j' je treba vertikalne posunout

					@include breakpoint($breakpoint_desktop) {
						width: 18.75em; // 301px
						height: 2.25em;// kvuli 'j' je treba vertikalne posunout
					}
				}
				.hradec & {
					@include breakpoint($breakpoint_desktop) {
						width: 16.75em; // 268px
					}
				}
				.liberec & {
					@include breakpoint($breakpoint_desktop) {
						width: 9.25em; // 149px
					}
				}
				.olomouc & {
					@include breakpoint($breakpoint_desktop) {
						width: 10.75em; // 173px
					}
				}
				.ostrava & {
					@include breakpoint($breakpoint_desktop) {
						width: 9.5em; // 153px
					}
				}
				.pardubice & {
					@include breakpoint($breakpoint_desktop) {
						width: 11.75em; // 188px
					}
				}
				.plzen & {
					@include breakpoint($breakpoint_desktop) {
						width: 7.25em; // 117px
					}
				}
				/*.regina & {
					height: 1.55em; // kvuli 'g' je treba vertikalne posunout

					@include breakpoint($breakpoint_desktop) {
						width: 19em; // 304px
						height: 2.25em; // kvuli 'g' je treba vertikalne posunout
					}
				}*/
				.regina & { // nove Radio DAB
					@include breakpoint($breakpoint_desktop) {
						width: 18.5em; // 296px
					}
				}
				.sever & {
					@include breakpoint($breakpoint_desktop) {
						width: 7.75em; // 123px
					}
				}
				.vary & {
					height: 1.55em; // kvuli 'y' je treba vertikalne posunout

					@include breakpoint($breakpoint_desktop) {
						width: 14em; // 221px
						height: 2.25em; // kvuli 'y' je treba vertikalne posunout
					}
				}
				.vysocina & {
					height: 1.55em; // kvuli 'y' je treba vertikalne posunout

					@include breakpoint($breakpoint_desktop) {
						width: 10.5em; // 164px
						height: 2.25em; // kvuli 'y' je treba vertikalne posunout
					}
				}
				.zlin & {
					@include breakpoint($breakpoint_desktop) {
						width: 5.75em; // 91px
					}
				}
				.strednicechy & { // ma byt region, ale trida uz je obsazena
					height: 1.55em; // kvuli 'y' je treba vertikalne posunout

					@include breakpoint($breakpoint_desktop) {
						width: 8.5em; // 136px
						height: 2.25em; // kvuli 'y' je treba vertikalne posunout
					}
				}
				.regiony & { // radio vaseho kraje
					height: 1.55em; // kvuli 'g' a 'y' je treba vertikalne posunout

					@include breakpoint($breakpoint_desktop) {
						width: 19.5em; // 311px
						height: 2.25em; // kvuli 'g' a 'y' je treba vertikalne posunout
					}
				}

				.program & {
					@include breakpoint($breakpoint_desktop) {
						width: 19em; // 304px
					}
				}
				.rada & {
					@include breakpoint($breakpoint_desktop) {
						width: 24.5em; // 393px
					}
				}
				.svet & {
					@include breakpoint($breakpoint_desktop) {
						width: 16.25em; // 260px
					}
				}

				.radiopraha & {
					height: 1.55em; // kvuli 'g' a 'y' je treba vertikalne posunout

					@include breakpoint($breakpoint_desktop) {
						width: 26.75em; // 428px
						height: 2.25em; // kvuli 'g' a 'y' je treba vertikalne posunout
					}
				}

				// TODO dodefinovat dalsi, ale toho si vsimnes az ti to tu bude chybet...
			}

				.b-015__logo-textcontent {
					color: $color_text_link_inverted;
					display: none;
				}


	// pravy sloupec - v nem centrovany launcher prehravace
	.b-015__list-item--right {

		@include breakpoint($breakpoint_desktop) {

			display: flex;
			flex-grow: 1;

		}
	}
	// tentyz element ale vyssi vaha selekce aby bylo mozne pretizit barvu pozadi definovanou nasledujici tridou
	.b-015__list-item--right.bg-gradient-vertical-60-color-add-3-to-color-main {
		// pro desktop se pretizi transparentni
		@include breakpoint($breakpoint_desktop) {
			background-image: none !important; // musi pretizit i silnejsi selektor
		}
	}
		// wrapper pro launcher a service links
		.b-015__launcher-wrapper {
			display: flex;
			flex-direction: column;
			flex-grow: 1;

			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;
				justify-content: center;

				padding: 0 $layout_main_horizontal-padding_desktop;
			}
		}

			// Zdenkuv wrapper launcheru
			.b-015 #cro-box-schedule-single {
				order: 2;

				@include breakpoint($breakpoint_desktop) {
					order: 1;

					display: flex;
					flex-grow: 1;
					max-width: $b-015_launcher-width_desktop;
				}
			}

				.b-015__launcher {
					flex-grow: 1;
					max-width: 100%;

					display: flex;
					flex-direction: row;

					height: $b-015_launcher_height;

					@include breakpoint($breakpoint_desktop) {
						flex-grow: 1;
					}
				}

					.b-015__launcher-visual {
						display: none;
						width: $b-015_launcher_height / 2 * 3; // 3:2 pomerna sirka k vysce headeru
						line-height: 0;

						@include breakpoint($breakpoint_desktop) {
							flex-shrink: 0;
							display: inline-block;
						}
					}

						// skryti ALT textu pro nenacteny obrazek
						// netridovano BEMem
						//
						// TODO toto neni reseni
						// toto je pouze rychly vizualni fix aby neproblikl alt text obrazku NEZ se nacte
						// neresi situaci, kdy se obrazek nenacte (zustane klikaci neviditelny text)
						.b-015__launcher-visual > a {
							display: block;

							color: transparent;
					}


					.b-015__launcher-info {
						flex-grow: 1;
						overflow: hidden;

						// Safari Fix: vertikalni zarovnani polozek launcheru (v Safari nefunguje vyska ditete 100% vuci rodici)
						height: 100%;
					}

						.b-015__launcher-info-list {
							height: 100%;
							margin-left: $layout_main_horizontal-padding_mobile;

							display: flex;
							flex-direction: column;
							justify-content: center;

							@include breakpoint($breakpoint_desktop) {
								margin-left: 1.25em;
							}
						}

							.b-015__launcher-info-title {
								@include adjust-font-size-to($b-015_launcher_fontsize-info-title_mobile, $b-015_launcher_lineheight);
								color: $color_text_accent_inverted;
								@include typography_font(bold);
								white-space: nowrap;
								@include breakpoint($breakpoint_desktop) {
									@include adjust-font-size-to($b-015_launcher_fontsize-info-title_desktop, $b-015_launcher_lineheight);
								}
							}

							.b-015__launcher-info-timing {
								display: flex;
								flex-direction: row;
								align-items: center;

							}

								.b-015__launcher-info-timing-timespan {
									margin-right: 1em;

									@include adjust-font-size-to($b-015_launcher_fontsize-info-timing, $b-015_launcher_lineheight);
									color: $color_text_accent_inverted;
									@include typography_font(bold);
								}

								.b-015__launcher-info-timing-progressbar {
									flex-grow: 1;

									display: block;
									height: $b-015_launcher_timing-progressbar-height_mobile;
									margin-top: 0.1em;

									background-color: rgba(255, 255, 255, 0.3);

									@include breakpoint($breakpoint_desktop) {
										height: $b-015_launcher_timing-progressbar-height_desktop;
									}
								}

									.b-015__launcher-info-timing-progressbar-progress {
										display: block;
										height: $b-015_launcher_timing-progressbar-height_mobile;

										background-color: $color_text_accent_inverted;

										// progress
										width: 33%;

										@include breakpoint($breakpoint_desktop) {
											height: $b-015_launcher_timing-progressbar-height_desktop;
										}
									}


							.b-015__launcher-info-description {
								position: relative;

								@include adjust-font-size-to($b-015_launcher_fontsize-info-description, $b-015_launcher_lineheight);
								color: $color_text_accent_inverted;
							}

							.b-015__launcher-info-description-textline a {
								color: $color_text_accent_inverted;
							}

							.b-015__launcher-info-description-overflow-mask {
								//content: "";

								display: block;
								position: absolute;

								top: 0;
								right: 0;

								height: 100%;
								width: 5em;

								pointer-events: none;
							}

								.b-015__launcher-info-description-textline {
									white-space: nowrap;
								}

									.b-015__launcher-info-description-icon {
										display: inline-block;

										width: 1.25em;
										height: 1em;

										margin-right: 0.25em;
										// ciste vizualni zarovnani ikonky k radku textu
										margin-bottom: -2px;

										background-image: $file_icon_note_double_colored-white;
										background-repeat: no-repeat;
										background-position: left center;
									}


					.b-015__launcher-button {
						flex-shrink: 0;

						padding-top: ($b-015_launcher_height - $b-015_launcher_button-size_mobile) / 2;
						padding-right: $layout_main_horizontal-padding_mobile;

						padding-left: 1.25em;

						@include breakpoint($breakpoint_desktop) {
							padding-top: ($b-015_launcher_height - $b-015_launcher_button-size_desktop) / 2;
							padding-right: 0;
						}

						// pretizeni vnoreneho atomu buttonu play
						.button {
							width: $b-015_launcher_button-size_mobile;
							height: $b-015_launcher_button-size_mobile;

							@include breakpoint($breakpoint_desktop) {
								width: $b-015_launcher_button-size_desktop;
								height: $b-015_launcher_button-size_desktop;
							}
						}
				}


			// servisni linky
			.b-015__services_list {
				order: 1;
				display: flex;
				flex-direction: row;
				justify-content: flex-end;

				border-bottom: 1px solid $color_background-0_alpha-high;

				overflow: hidden;

				@include breakpoint($breakpoint_desktop) {
					order: 2;
					display: flex; // kvuli docasnemu skryti na mobile, aby zobrazil na desktop
					flex-direction: column;
					justify-content: center;

					padding-left: 1em;
					margin-left: 1.5em;

					border-left: 1px solid $color_background-0_alpha-high;
					border-bottom: none;
				}
			}

				.b-015__services_list-item {
					color: $color_text_accent_inverted;

					font-size: $b-015_services_fontsize_mobile;
					line-height: $b-015_services_lineheight;
					text-transform: uppercase;
					white-space: nowrap;

					@include breakpoint($breakpoint_desktop) {
						font-size: $b-015_services_fontsize_desktop;
					}
				}

					.b-015__services_link {
						position: relative;

						display: inline-block;

						padding: 0.5em 10px;

						@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, underline);

						@include breakpoint($breakpoint_desktop) {
							padding: 0;
						}
					}

					// zneaktivni odkaz u servisnich linku
					/*.page-program .b-015__services_link--program,
					.page-audioarchiv .b-015__services_link--audioarchiv,
					.page-kamery .b-015__services_link--kamery {
							pointer-events: none;
							cursor: default;
					}*/

					// arrow u servisnich linku
					/*.b-015__services_link:hover:after,
					.page-program .b-015__services_link--program:after,
					.page-audioarchiv .b-015__services_link--audioarchiv:after,
					.page-kamery .b-015__services_link--kamery:after {
							display: block;

							content: "";

							position: absolute;
							top: 27px;
							left: 50%;

							transform: rotate(45deg);

							width: 9px;
							height: 9px;

							background-color: $color_background-0;

							@include breakpoint($breakpoint_desktop) {
								top: 5px;
								left: -21px;
							}
					}*/
