//
// player k B-045b
//


.player-b-045b {
	margin-bottom: 10px;
	&__link {
		flex-basis: 100%;
		&:hover {
			text-decoration: none;
			.player-b-045b__play {
				background-position: 100%;
			}
			.player-b-045b__headline {
				text-decoration: underline;
			}
		}
	}
	&__headline {
		flex-basis: 100%;
		width: 100%;
		margin: 0;
		@include typography_font(medium);
	}

	// leva cast
	&__metadata-and-progressbar {
		color: $color_background-6;
		width: calc(100% - 50px);
		margin-right: 15px;
		font-size: .7rem;
		float: left;
		&:hover {
			text-decoration: none;
		}
	}
	&__time {
		display: inline;
		margin-right: 4px;
		color: $color_background-6;
	}
	&__show-name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		@include typography_font(medium);
		font-size: .75rem;
		color: $color_background-6;
	}
	&__duration {
		display: inline;
		margin-left: 5px;
		@include typography_font(medium);
		font-size: .75rem;
		color: $color_background-6;
	}
	&__wrap {
		display: inline-flex;
		max-width: calc(100% - 110px);
	}

	// prava cast
	&__play {
		background: $file_button_action_play 0 0 no-repeat;
		background-size: 64px 32px;
		width: 32px;
		height: 32px;
		display: inline-block;
		z-index: 1;
	}
	&__progress-bar {
		margin-top: 4px;
		width: 100%;
		.progress-bar {
			&__duration {
				background-color: $color_background-3;
				height: 6px;
			}
			&__position {
				height: 6px;
			}
		}
	}
	&__program {
		flex-basis: 100%;
		a {
			color: $color_background-6;
			font-size: .7rem;
			padding-right: 20px;
			position: relative;
			&:before {
				background: $file_icon_arrow_single_east no-repeat center;
				right: 0;
			}
			&:after {
				background: $file_icon_arrow_single_east no-repeat center;
				right: 4px;
			}
			&:before,
			&:after {
				background-size: 8px 8px;
				position: absolute;
				top: -1px;
				content: '';
				width: 16px;
				height: 16px;
				display: block;
				opacity: .9;
				transition: all .12s ease-out;
			}
			// efekt zamereni
			&:hover:before,
			&:hover:after {
				transform: rotate(180deg);
			}
		}
	}
}
