//
// B-014b Styles
//
// puvodni BEMove classovani zachovano nad vnorenymi selektory
//



// skryta cast hlavniho menu (polozky pro "vice")
.b-014b {

	position: relative;
}



	// sekundarni seznam
	.b-014b__list-wrapper {

		left: 0;
		width: 100%;

		overflow: hidden;

		// height: dynamicky
		// toto je misto kde se urcuje schovavani submenu
		// toto je element ktery se dynamicky muze srolovat
		// menit svou vysku, tim ovlivnovat vysku menu a podkladovy pruh

		background-color: $color_background-2;
	}
	/*.b-014b__list-wrapper::before { // Martinovo reseni

		// podkladovy absolutne pozicovany pruh, sirsi nez sirka stranky
		// jen pro desktop, na mobile postrada smysl
		@include breakpoint($breakpoint_desktop) {
			content: "";

			position: absolute;
			left: -200%;
			right: -200%;

			height: 100%;

			background-color: $color_background-2;

			z-index: -1;
		}
	}*/
	.b-014b__list-wrapper::before {

		// podkladovy absolutne pozicovany pruh, sirsi nez sirka stranky
		// jen pro desktop, na mobile postrada smysl
		@include breakpoint($breakpoint_desktop) {
			content: "";

			position: absolute;
			top: 0;
			left: 0;

			width: 100vw;
			height: 100%;

			background-color: $color_background-2;

			z-index: -1;
		}

		// HACK: nez se poprve pohne desktop grid
		@media screen and (min-width: $layout_page_max-width) {
			left: calc(((100vw - #{$layout_page_max-width}) / 2) * -1);
		}
	}

	// searchbar
	.b-014b__searchbar {
		margin: 0 1.5em;
		padding: 1.25em 0.5em;

		border-bottom: 1px solid $color_background-4;

		@include breakpoint($breakpoint_desktop) {
			display: none;
		}

	}

		form.b-014b__searchbar-form {
			display: block;

			position: relative;

			width: 100%;

			padding: 0;
		}

		input.b-014b__searchbar-input {
			background: transparent;

			font-size: 16px;
			line-height: 20px; // Safari, ie fix

			margin: 0;
			padding-left: 0;
			padding-right: 40px;

			border: none;

			&:focus {
				border: none;
				box-shadow: none;
			}
		}

		.b-014b__searchbar-submit {
			display: inline-block;

			position: absolute;
			top: 0;
			right: 0;

			padding: 0;
			height: 100%;

			background: transparent;
			border: none;
		}

			.b-014b__searchbar-submit-image {
				display: inline-block;

				width: 1.35em;
				height: 100%;

				margin: 0;

				background-image: $file_icon_search;
				background-repeat: no-repeat;
				background-position: right center;

				.b-014b__searchbar-submit:hover & {
					background-image: $file_icon_search_colored-black;
				}
				// varianta pro inverzni hlavicku (modifikator na nejvyssim rodici)
				.b-014b--inverted & {
					filter: brightness(200);
				}
			}


		.b-014b__list {

			display: flex;

			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;

			max-width: $layout_page_max-width;

			flex-wrap: wrap;

			padding: 1em 1em;

			@include breakpoint($breakpoint_desktop) {
				padding: 1em 2.5em;
			}
		}

			// polozka submenu
			//.b-014b__list-item {
			.b-014b__list > li {

				// mobile
				flex-basis: 50%;

				@include breakpoint($breakpoint_tablet) {
					flex-basis: 33%;
					xwhite-space: nowrap;
				}

				@include breakpoint($breakpoint_desktop) {
					flex-basis: 25%;
					xwhite-space: nowrap;
				}
			}

				// jeji vnitrni odkaz
				//.b-014b__list-item > a {
				.b-014b__list > li > a {

					display: inline-flex;
					align-items: center;
					width: 100%;
					height: 100%;

					padding: 0.5em 1em;


					@include link-style ($color_text_accent, none, $color_text_accent_inverted, none);

					text-transform: uppercase;
					font-size: $fontsize_menu_top;

					@include breakpoint($breakpoint_desktop) {
						font-size: $fontsize_menu_top_desktop;
					}
				}
				//.b-014b__list-item > a:active,
				//.b-014b__list-item > a:hover {
				.b-014b__list > li > a:active,
				.b-014b__list > li > a:hover {

					background-color: $color_background-6;
				}


			//.b-014b__list-item--separator {
			.b-014b__list > .b-014b__list-item--separator {
				flex-basis: 100%;
			}

				// kdyby nahodou se do separatoru zatoulalo <a> tak tady nema co delat
				.b-014b__list-item--separator > a {
					display: none;
				}

				// vizual separatoru na drupal default markup - nasleduje
				.b-014b__list-item--separator > .separator {
				}

					.b-014b__list-item--separator > .separator > hr {

						border: 0;
						border-bottom: 1px solid $color_background-4;

						height: 1em;

						margin: 0;
						margin-bottom: 1em;
					}
