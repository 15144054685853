//
// Button Form Flat
//



.button-form-flat {
	
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	
	width: 100%;
	height: 100%;
	
	padding: 1em 2em;
	//padding: 0;
	
	background-color: $color_background-6;
	
	@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, none);
	
	//outline: none;
	
	
	text-transform: uppercase;
	
	@include adjust-font-size-to($fontsize_form_flat_button);
	
	@include breakpoint($breakpoint_desktop) {
		@include adjust-font-size-to($fontsize_form_flat_button_desktop);
	}
	
}
// TODO docasne vizualni reseni nedefinovaneho hover efektu
.button-form-flat:hover {
	opacity: 0.75;
}
	
	.button-form-flat__label {
		
		display: block;
		
		text-align: center;
		
	}



// specificke modifikatory obecneho buttonu - neboli specificka tlacitka

.button-form-flat--default {
	// nic
}

.button-form-flat--confirm {
	background-color: $color_decoration_success;
}