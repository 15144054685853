//
// B-055 Styles
//
//



.b-055 {
}

	// header
	.b-055__header {
		position: relative;
	}

		// h2
		.b-055__header .b-055__h2 { // .b-055__header kvuli jinak silnejsimu pravidlu .box h2
			padding-left: 0;
			margin-bottom: 4px;

			/*font-size: 20px;
			line-height: 1.3;*/
			text-transform: none;
		}

		.b-055__h2--mujrozhlas {
			background-image: $file_logo_mujrozhlas_colored-main;
			background-repeat: no-repeat;

			width: 110px;
			height: 18px;
		}

			.b-055__h2--mujrozhlas a {
				display: block;
				height: 100%;
			}

			.b-055__h2--mujrozhlas span {
				display: none;
			}


		// list dots
		.b-055__dots,
		.cro-carouselDots {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			position: absolute;
			bottom: 6px;
			right: 0;

			@include breakpoint($breakpoint_desktop) {
				display: none;
			}
		}

			// item dots
			.b-055__dots-item,
			.cro-carouselDots .cro-carouselDot {
				margin-right: 0.5em;

				width: 10px;
				height: 10px;

				background-color: $color_background-2;
				border-radius: 50%;
			}

			// item: last
			.b-055__dots-item:last-child,
			.cro-carouselDots .cro-carouselDot:last-child {
				margin-right: 0;
			}

			// item: .selected
			.b-055__dots-item--selected,
			.cro-carouselDots .cro-carouselDot--selected {
				background-color: $color_background-4;
			}


	// list
	.b-055__list {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		position: relative; // kvuli gradientu;

		padding-top: 0.5em;
		padding-bottom: 0.5em;

		border-top: 1px solid $color_divider;
		border-bottom: 1px solid $color_divider;

		overflow: hidden;
	}

	// gradient
	.b-055__list::before {
		content: "";

		display: block;

		position: absolute;
		right: 0;

		width: 20px;
		height: 100%;

		@extend .bg-gradient-transparent-to-color-background-0;

		z-index: 2;

		@include breakpoint($breakpoint_desktop) {
			display: none;
		}
	}

		// item
		.b-055__list-item {
			position: relative;

			min-width: calc(100% - 50px);
			max-width: calc(100% - 50px);

			@include breakpoint($breakpoint_desktop) {
				//min-width: 220px;
				//max-width: 220px;
				min-width: auto;
				max-width: none;

				box-sizing: border-box;

				margin-right: 1em; // (*)
			}
		}

		.b-055__list-item:last-child {

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}

		/* one item */
		.b-055__list-item:first-child:nth-last-child(1) {
		//.b-055__list-item:only-child {

			@include breakpoint($breakpoint_desktop) {
				flex-basis: 99%; // toto neni 100%
				flex-grow: 1; // roztahne se pomoci grow do 100%
				max-width: calc(100% - 0em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 0em? protoze je to 1 * 0 / 1 viz (*)
			}
		}

		/* two items */
		.b-055__list-item:first-child:nth-last-child(2),
		.b-055__list-item:first-child:nth-last-child(2) ~ .b-055__list-item {

			@include breakpoint($breakpoint_desktop) {
				flex-basis: 49%; // toto neni polovina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do polovin
				max-width: calc(50% - 0.5em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 0.5em? protoze je to 1 * 1 / 2 viz (*)
			}
		}

		/* three items */
		.b-055__list-item:first-child:nth-last-child(3),
		.b-055__list-item:first-child:nth-last-child(3) ~ .b-055__list-item {

			@include breakpoint($breakpoint_desktop) {
				flex-basis: 32.3333%; // toto neni tretina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do tretin
				max-width: calc(33.3333% - 0.6666em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 0.6666em? protoze je to 1 * 2 / 3 viz (*)
			}
		}

		/* four items */
		.b-055__list-item:first-child:nth-last-child(4),
		.b-055__list-item:first-child:nth-last-child(4) ~ .b-055__list-item {

			@include breakpoint($breakpoint_desktop) {
				flex-basis: 24%; // toto neni ctvrtina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do ctvrtin
				max-width: calc(25% - 0.75em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 0.75em? protoze je to 1 * 3 / 4 viz (*)
			}
		}

		/* five items */
		.b-055__list-item:first-child:nth-last-child(5),
		.b-055__list-item:first-child:nth-last-child(5) ~ .b-055__list-item {

			@include breakpoint($breakpoint_desktop) {
				flex-basis: 19%; // toto neni petina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do petin
				max-width: calc(20% - 0.8em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 0.8em? protoze je to 1 * 4 / 5 viz (*)
			}
		}

			// item: link
			.b-055__list-item a {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				flex-grow: 1;
			}

				// image: wrappper
				.b-055__image {
					flex-shrink: 0;
					position: relative;

					width: 90px;
					//heigh: 50.6333px;

					margin-bottom: auto;
				}

				// ico play
				.b-055__image::before {
					content: '';

					position: absolute;
					bottom: 5px;
					left: 5px;

					width: 20px;
					height: 20px;

					background-image: $file_button_play;
					background-size: 200%;
					background-repeat: no-repeat;
				}

				// item: link:hover, ico play
				.b-055__list-item a:hover .b-055__image::before {
					background-position: 100%;
				}

					// image
					.b-055__image img {
						border-radius: 3px;
					}


			// h3
			.b-055__list-item h3 {
				padding: 0;
				margin: 0 2em 0 0.65em;

				@include typography_font(regular);
				font-size: 13px;
				//line-height: 1.2;
				line-height: 17px; // v px kvuli rozdilu firefox vs. chrome

				// orez
				//margin-bottom: auto;
				max-height: 50.6333px;
				overflow: hidden;

				@include breakpoint($breakpoint_desktop) {
					margin-right: 0;

					font-size: 12px;
					//line-height: 1.3;
					line-height: 16px; // v px kvuli rozdilu firefox vs. chrome
				}
			}

			// h3: last-item
			.b-055__list-item:last-child h3 {
				padding-right: 0;
			}





// vizualni debug - plab only
.patternlab {
	.b-055:nth-child(2) {

		@include breakpoint($breakpoint_desktop) {
			// vizualni debug
			// tenhle box stejne neni urceny na vetsi sirku nez 8-1 a 8-5 sloupec (v desktop)
			max-width: 750px;
			overflow: hidden;
		}
	}
}
