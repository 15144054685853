//
// Docs Styles
//

$docs-icon: url(../images/development/docs-icon-black.png);

.docs {
	display: none; // na mobilu neni
	
	@include breakpoint($breakpoint_desktop) {
		display: block; // na desktopu ano
	}
	
	position: absolute;
	
	width: 100%;
	
	font-size: $fontsize_h5;
	line-height: 1rem;

	.docs-icon {
		position: absolute;
		z-index: 1;
		left: -2.5em;

		a {
			display: block;
			background: $docs-icon center center no-repeat;
			background-size: contain;
			width: 2em;
			height: 2em;
		}
	}

	.docs-icon--left {
		left: 0;
		top: .2em;	
	}

	.docs-icon--right {
		right: 0;
		top: .2em;
	}

	ol li {
		list-style: decimal;
		list-style-position: inside;
	}

	.docs-body {
		display: none;
		
		position: absolute;
		top: 4em;
		left: -3.75em;
		
		z-index: 999;

		background-color: $color_background-0;
		box-shadow: $shadow_popup_for-overlayed-bg; //$shadow_bubble; 
		border: 1px solid $color_background-4;
		outline: none;

		padding: 2em;

		&:before {
			position: absolute;
			top: -9px;
			left: 20px;
			content: '';
			width: 15px;
			height: 15px;
			border-left: 1px solid $color_background-4;
			border-top: 1px solid $color_background-4;
			background-color: $color_background-0;

			transform: rotate(45deg);
		}
	}

	.docs-body .docs-title {
		font-size: $fontsize_h2;
		color: $color_text_link;
		margin: 0 0 .5em;
		line-height: 2rem;
		float: none;
	}

	.docs-basics__list {
		padding-left: 2em;
	}

	.docs-basics__list-item {
		list-style: disc outside;
	}
	
	.docs-basics__list-item--label {
		margin: 0;
	}

	h2.docs-elements__title {
		font-size: $fontsize_h4_desktop;
		color: $color_text_link;
		line-height: 1.5rem;
		padding: 0;
		text-transform: none;
		margin: .5em 0 .2em;
	}

	.docs-elements__list {
		padding-left: 2em;
	}

	.docs-elements__list-item {
		list-style: disc outside;
	}

	.docs-elements__list-item--label {
		margin: 0;
	}
	
	h2.docs-specifics__title {
		font-size: $fontsize_h4_desktop;
		color: $color_text_link;
		line-height: 1.5rem;
		padding: 0;
		text-transform: none;
		margin: .5em 0 .2em;
	}

	.docs-specifics__list {
		padding-left: 2em;
	}

	.docs-specifics__list-item {
		list-style: disc outside;
	}

	.docs-specifics__list-item--label {
		margin: 0;
	}
}