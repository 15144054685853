//
// A-003 Styles
//



.a-003 {
}

	.a-003__block {
		padding: 1.5em 0;

		border-top: 1px solid $color_divider;
		border-bottom: 1px solid $color_divider;

		@include breakpoint($breakpoint_desktop) {
			padding: 1.5em 0;
		}
	}

		.a-003__row {
			margin-bottom: 1em;

			font-size: 14px;
			line-height: 1.6;

			color: $color_text_paragraph;

			cursor: pointer;
			//cursor: grab;

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 1.25em;
			}
		}

		.a-003__row:last-child {
			margin-bottom: 0;

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 0;
			}
		}

		.a-003__row--opened {
				cursor: grabbing;
		}

			.a-003__question {
				background-image: $file_icon_arrow_single_east_colored-decoration-main;
				background-repeat: no-repeat;
				background-size: auto 10px;
				background-position: 0 8px;

				padding-left: 1em;

				@include typography_font(medium);
				font-size: $fontsize_paragraph_node;
				line-height: 1.6em;

				color: $color_text_accent;

				@include breakpoint($breakpoint_desktop) {
					background-size: auto 14px;
					background-position: 0 8px;

					padding-left: 30px;

					font-size: $fontsize_paragraph_node_desktop;
					line-height: 1.6em;
				}
			}

			.a-003__row--opened .a-003__question {
				background-image: $file_icon_arrow_single_south_colored-decoration-main;
				background-size: 10px auto;
				background-position: 0 10px;

				@include breakpoint($breakpoint_desktop) {
					background-image: $file_icon_arrow_single_south_colored-decoration-main;
					background-size: 14px auto;
					background-position: 0 10px;
				}
			}

			.a-003__answer {
				display: none; // defaultne skryto/zavreno

				font-size: 14px;
				line-height: 1.5em;

				padding-top: 0.5em;
				padding-left: 1em;

				color: $color_text_paragraph_node;

				@include breakpoint($breakpoint_desktop) {
					font-size: 15px;
					line-height: 1.5em;

					padding-left: 30px;
				}
			}

				.a-003__answer strong,
				.a-003 .a-003__block .a-003__row .a-003__answer strong { // kvuli silnejsimu selektoru
					@include typography_font(medium);
					//color: $color_text_paragraph_node;
					color: $color_text_accent;
				}

				.a-003__answer a {
					//@include link-style($color_text_paragraph_node, underline, $color_text_accent, underline);
					@include link-style($color_text_accent, underline, $color_text_accent, underline);
				}



//
// A-003a Styles
//



.a-003a {
}

	.a-003a__block {
		padding: 0;

		border-top: 1px solid $color_divider;
		border-bottom: 1px solid $color_divider;

		@include breakpoint($breakpoint_desktop) {
			padding: 0;
		}
	}

		.a-003a__row {
			padding-top: 1em;
			padding-bottom: 1em;
			margin-bottom: 0;

			font-size: 14px;
			line-height: 1.6;

			color: $color_text_paragraph;

			border-bottom: 1px solid $color_divider;

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 0;
			}
		}

		.a-003a__row:last-child {
			border-bottom: none;
		}





// vizualni debug - plab only
.patternlab .a-003 {

	.a-003__answer {
		display: block; // defaultne skryto/zavreno, v plabu naopak chceme videt
	}

	@include breakpoint($breakpoint_desktop) {
		max-width: 750px;
	}
}
