//
// B-016 Styles
//



$b-016_color_submenu_overlay: rgba(0, 0, 0, 0.25);
$b-016_menu-bar-height_mobile: 40px;
$b-016_menu-bar-height_desktop: 40px;
$b-016_menu-item-vertical-padding: 9px;

// odsazeni mobilnich polozek menu je primo odvisle od paddingu mainu, zarovna se zleva shodne
$b-016_menu-item-horizontal-padding_mobile: $layout_main_horizontal-padding_mobile;
$b-016_menu-item-horizontal-padding_desktop: 12px;


.b-016 {
	margin-top: 0.5em;
	margin-bottom: 1.5em;

	background-color: $color_background-9;

	@include breakpoint($breakpoint_desktop) {
		margin-bottom: 3em;
	}
}

.b-016:not(.--jsfied) {
	overflow-x: hidden;

	.b-016__list {
		flex-wrap: nowrap;
	}
}

	.b-016__list {

		// kvuli zaveseni rozbaleneho absolutne pozicovaneho submenu ve stavu mobile
		position: relative;

		// pevna vyska pro mobile
		height: $b-016_menu-bar-height_mobile;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		//background-color: $color_background-9; // presunuto do rodice .b-016
		padding-right: 0;
		@include breakpoint($breakpoint_desktop) {

			height: $b-016_menu-bar-height_desktop;

			// odsazeni vsech polozek menu z leva
			// pro desktop primo ovlivneno vztahem sirky page > main
			padding-left: $layout_main_horizontal-padding_desktop - $b-016_menu-item-horizontal-padding_desktop;
		}
	}

	.b-016__list.cro_responsivelist {
		//flex-wrap: nowrap;

		height: $b-016_menu-bar-height_mobile;

		//overflow: hidden; // bud toto nebo flex-wrap: wrap v pripade rozbaleneho .b-016__list-more dava cro_responsivelist.js tridu overflow

		@include breakpoint($breakpoint_desktop) {
			height: $b-016_menu-bar-height_desktop;
		}
	}

	/*.b-016__list.cro_responsivelist.overflow {
		overflow: visible;
	}*/

		.b-016__list-item {

			position: relative;

			// podmenu prekryva stranku
			z-index: 1;
		}

			// polozka menu
			.b-016__list-item a {

				display: inline-block;
				padding: $b-016_menu-item-vertical-padding $b-016_menu-item-horizontal-padding_mobile;

				@include link-style ($color_background-4, none, $color_text_accent_inverted, none);
				font-size: $fontsize_menu_main;
				white-space: nowrap;

				@include breakpoint($breakpoint_desktop) {
					padding: $b-016_menu-item-vertical-padding $b-016_menu-item-horizontal-padding_desktop;
					font-size: $fontsize_menu_main_desktop;
				}
			}
			// aktivni polozka menu
			.b-016__list-item a.active {
				@include link-style ($color_text_accent_inverted, none, $color_text_accent_inverted, none);
			}
			.b-016__list-item a.active::after {

				content: "";
				position: absolute;
				left: 0;
				width: 100%;

				// vyska prouzku a vertikalni pozicovani
				bottom: -0.25em;
				height: 0.5em;

				// toto je reseni dle alesova navrhu sirky prouzku,nekdy pro budoucnost nebo pokud na tom bude trvat
				left: $b-016_menu-item-horizontal-padding_mobile;
				width: calc(100% - (2 * #{$b-016_menu-item-horizontal-padding_mobile}));

				@include breakpoint($breakpoint_desktop) {
					// vyska prouzku a vertikalni pozicovani pro desktop
					// tady se z tehle dekorace jinak nez pixely nevykroutime
					bottom: 0;
					height: 5px;

					// toto je reseni dle alesova navrhu sirky prouzku,nekdy pro budoucnost nebo pokud na tom bude trvat
					left: $b-016_menu-item-horizontal-padding_desktop;
					width: calc(100% - (2 * #{$b-016_menu-item-horizontal-padding_desktop}));
				}

				// universal - nutno pretizit konkretni barvou pro web
				background-color: $color_decoration_main;
			}
			// hover
			.b-016__list-item a.active:hover::after {

				display: none;
			}
			.b-016__list-item a.expanded,
			.b-016__list-item a:hover {

				// universal - nutno pretizit konkretni barvou pro web
				background-color: $color_decoration_main;
			}


			// Vice...
			.b-016__list-item--more {

				// obecne polozky jsou relativni, kvuli zaveseni pseudoelementu pro active
				// vice toto nepotrebuje, naopak je treba aby submenu melo prvniho relativniho rodice cely seznam
				position: initial;
				z-index: 100;

				@include breakpoint($breakpoint_desktop) {

					// na desktop je na relativni polohu vice zavesen podlist
					position: relative;
				}
			}

				// button
				.b-016__list-item--more a {
				}

				// seznam podmenu
				.b-016__list-more {

					// podmenu prekryva stranku
					z-index: 1;

					// default skryto
					display: none;

					// universal - nutno pretizit konkretni barvou pro web
					background-color: $color_decoration_main;

					// pozicovani pro mobile - cela sirka stranky
					position: absolute;
					width: 100%;
					top: $b-016_menu-bar-height_mobile;
					left: 0;

					padding: 1em 0 0.5em 0;


					@include breakpoint($breakpoint_desktop) {

						// pozicovani pro desktop (reset mobile)
						top: auto;
						left: auto;
						right: -2em; // TODO nejaka skutecna hodnota a ne toto by woko
						right: 0;
						width: auto;

						padding: 0;
					}
				}

					// polozka submenu
					.b-016__list-more .b-016__list-item {
					}

						.b-016__list-more .b-016__list-item a {
							// vyplnit cele li - muze byt sirsi, protoze je roztazene ve sloupci dle nejsirsiho
							width: 100%;

							padding: 0.5em 1em;

							@include breakpoint($breakpoint_desktop) {

								padding: 0.5em $b-016_menu-item-horizontal-padding_desktop;

								// polozky na jeden radek at jsou jakkoliv dlouhe
								white-space: nowrap;
							}
						}
						.b-016__list-more .b-016__list-item a:hover {
							// universalni reseni s pruhlednosti, pretizeno stejne pro stanice konkretni barvou
							background-color: $b-016_color_submenu_overlay;
						}
