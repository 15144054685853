//
// Infinity Scroll Button
//


// default - samotný button
/*.radiozurnal.page-radiozurnal-hp,
.dvojka.page-dvojka-hp,
.plus.page-plus-hp,
.wave.page-wave-hp,
.wave.section-czeching,
.socr.page-poslech,
.svetluska.page-svetluska-hp,

.wave.page-wavenews,
.neviditelna.page-node .b-047,
.jeziskova-vnoucata {*/

	// load more button / infinity scroll button
	.button-box-flat--loadmore.pager--infinite-scroll {
		position: relative;

		display: flex;
		justify-content: center;

		margin-top: 4em;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 4em;
			margin-left: 0;
			margin-right: 0;
		}

		.button-box-flat__doubleline {
			display: none;
		}

		.button-box-flat {
			position: relative;
		}

		.button-box-flat__label-nest {
			position: absolute;
			top: -2em;
			left: -2.25em;

			width: 9em;

			color: $color_text_paragraph;

			@include breakpoint($breakpoint_desktop) {
				left: -3.5em;
				width: 10.5em;
			}
		}
	}

/*}*/



// addon - přidá k default variantě double-linku
.wave.page-wavenews,
.neviditelna.page-node .b-047,
.jeziskova-vnoucata {

	// left & right lines k infinity scroll buttonu
	.button-box-flat--loadmore.pager--infinite-scroll {

		// cely jen na desktopu
		@include breakpoint($breakpoint_desktop) {
			height: 7.5em;
			overflow: hidden;
			//margin-right: -55%; //pokud ma byt pres celou sirku

			// left & right lines
			.button-box-flat::before,
			.button-box-flat::after {
				content: "";

				position: absolute;

				border-color: $color_background-4;
				border-style: solid;
				border-width: 1px 0;

				display: block;

				width: 100em;
				height: 0.75em;

				top: 20px;
			}
			// left lines
			.button-box-flat::before {
				right: 70px;
			}
			// right lines
			.button-box-flat::after {
				left: 70px;
			}
		}
	}

}



// specifika konkrétních umístění
.wave.page-wavenews,
.neviditelna.page-node .b-047 {
	.button-box-flat--loadmore.pager--infinite-scroll {
		@include breakpoint($breakpoint_desktop) {
			margin-right: -55%; //pokud ma byt pres celou sirku
		}
	}
}

.jeziskova-vnoucata {
	.button-box-flat--loadmore.pager--infinite-scroll {
		.button-box-flat {
			border-radius: 0; // reset bootstrap.min.css
		}
		.button-box-flat__label-nest {
			display: none;
		}
	}
}
