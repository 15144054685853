//
// Tag
//



.tag {
	display: inline-block;

	// https://developer.mozilla.org/en-US/docs/Web/CSS/max-inline-size
	max-inline-size: 100%;
	-webkit-max-inline-size: 100%; // Safari
	-moz--max-inline-size: 100%; // Old Firefox

	vertical-align: bottom; // Safari Fix: vertikalni zarovnani tagu (u boxu b-002b v mob pohledu pretekal)

	padding: 0.15em 0.5em;

	@include typography_font(bold);
	@include adjust-font-size-to($fontsize_tag);

	//background-color: $color_decoration_main; // puvodni barvena varianta
	background-color: $color_background-6_alpha-low;

	// tag nezalinkovany
	span {
		color: $color_text_accent_inverted;

		display: block;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	// tag zalinkovany
	a {
		@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);

		span {
		}
	}


	@include breakpoint($breakpoint_desktop) {

		padding: 0.15em 0.75em;

		@include adjust-font-size-to($fontsize_tag_desktop);
	}
}

.tag-multiline {
	span {
		display: block;
		white-space: normal; // reset hodnot bezneho tagu
		text-overflow: none; // reset hodnot bezneho tagu
		//overflow: visible; // reset hodnot bezneho tagu
	}
}

// tag svg logo
.tag__logo {
	padding: 7px 9px;

	@include breakpoint($breakpoint_desktop) {
		padding: 8px 10px;
	}

	span { display: none; }
}
.tag__logo a::before {
	content: "";
	display: block;

	background-repeat: no-repeat;
	background-position: center center;
}

// tag svg logo iRozhlas
.tag__logo--irozhlas {
	background-color: $color_irozhlas_main;
}
.tag__logo--irozhlas a::before {
	background-image: $file_logo_irozhlas_colored-white;

	width: 92px;
	height: 14px;

	@include breakpoint($breakpoint_desktop) {
		width: 79px;
		height: 12px;
	}
}
