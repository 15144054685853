//
// B-002d Styles
//



.b-002d {
}

	.b-002d__list {

		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {

			flex-direction: row;
		}
	}

		.b-002d__list-item {

			margin-bottom: 1em;

			@include breakpoint($breakpoint_desktop) {

				flex-basis: 25%;

				margin-bottom: 0;
				margin-right: 1em;
			}
		}
		.b-002d__list-item:last-child {

			margin-bottom: 0;

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}

			.b-002d__block {
			}

				.b-002d__image {
					position: relative;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-002d__image .tag {
						position: absolute;
						bottom: 0;
						left: 10px;
						
						max-inline-size: calc(100% - 20px);
					}

					// ikonove priznaky
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-002d__image .badge {
						position: absolute;
						top: 0;
						right: 0;
					}


			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-002d__list-item h3 {

				margin: 0;
				padding: 0.5em 0.5em;

				@include breakpoint($breakpoint_desktop) {
					padding: 0.5em 0;
				}
			}
