//
// Specificke stanicni barveni elementu asset Ctete Take (novy)
//
// nahrazuje bloky pretezovani ve stanicnich stylopisech
// opakuje se - pretezuji se shodne prvky
//



// vars
// list stanicnich trid a barev
// pouziva se pro pretizeni barveni na konkretni stanici viz nize
// promenne s barvou jsou definovane v /sass/01_patternlab/02_variables/_colors.scss
$station_color_list: (
	("irozhlas" $color_irozhlas_main),
	("radiozurnal" $color_radiozurnal_main),
	("dvojka" $color_dvojka_main),
	("vltava" $color_vltava_main),
	("plus" $color_plus_main),
	("wave" $color_wave_main),
	("d-dur" $color_d-dur_main),
	("jazz" $color_jazz_main),
	("regiony" $color_regiony_main),
	("brno" $color_regiony_main),
	("cb" $color_regiony_main),
	("hradec" $color_regiony_main),
	("kv" $color_regiony_main),
	("liberec" $color_regiony_main),
	("olomouc" $color_regiony_main),
	("ostrava" $color_regiony_main),
	("pardubice" $color_regiony_main),
	("plzen" $color_regiony_main),
	("regina" $color_regiony_main),
	("sever" $color_regiony_main),
	("strednicechy" $color_regiony_main),
	("vysocina" $color_regiony_main),
	("zlin" $color_regiony_main)
);



// obecne
@each $i in $station_color_list {

	.page-node.node-type-article,
	.page-node.node-type-page,
	.page-node.node-type-profil,
	.page-node.node-type-serial,
	.page-node.node-type-serialpart,
	.page-node.node-type-show {

		// pretizeni barveni na konkretni stanici
		.asset.a-001.#{nth($i, 1)} h2 {
			color: nth($i, 2);
		}

		.asset.a-001.#{nth($i, 1)} .a-001a__block h3,
		.asset.a-001.#{nth($i, 1)} .a-001b__block h3 {
			background-color: nth($i, 2);
		}

	}

}
